import CONFIG from "core/config";
import { is } from "ramda";

export default async (path, file, params) => {
	const url = `${CONFIG.functionsUrl}${path}`;

	if (!file) throw new Error("Missing file");

	const formData = new FormData();
	if (is(Object, params))
		for (const param in params) {
			formData.append(param, params[param]);
		}

	formData.append("file", file);

	const result = await fetch(url, {
		method: "POST",
		body: formData
	})
		.then(response => response.json())
		.then(response => response.result);

	return result;
};

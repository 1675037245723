import { useMerchantContext } from "context";
import { Grid, SectionLoader } from "core/components";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	margin-top: ${p => p.theme.space.medium};
`;

const DealsTable = ({ deals, schema, actions, refresh, isFetching }) => {
	const { currentStore } = useMerchantContext();

	if (isFetching || !schema) return <SectionLoader />;

	return (
		<Container>
			<Grid
				isFetching={isFetching}
				actions={actions}
				schema={schema}
				tableKey="dealId"
				data={deals}
				reload={refresh}
				pagination
				activeStoreId={currentStore.storeId}
				hideGridCreate={true}
				noDataText="No deals available"
			/>
		</Container>
	);
};

export default DealsTable;

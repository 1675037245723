import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Button } from "core/components";
import "react-times/css/material/default.css";
import ScheduleTime from "./ScheduleTime";
import theme from "styles/theme";

const EditSchedule = ({ schedule, setSchedule, style }) => {
	const [currentSchedule, setcurrentSchedule] = useState(schedule || []);
	const [lastDayTimes, setLastDayTimes] = useState();

	const createScheduleTime = day => {
		const newTime = {
			day,
			fromTime: lastDayTimes?.fromTime || "10:00",
			toTime: lastDayTimes?.toTime || "20:00"
		};

		setcurrentSchedule([...currentSchedule, newTime]);
		setSchedule([...currentSchedule, newTime]);
	};

	const handleSetTime = (timeIndex, data) => {
		const dayTimes = JSON.parse(
			JSON.stringify(currentSchedule.filter(f => f.day === data.day))
		);
		const otherDayTimes = JSON.parse(
			JSON.stringify(currentSchedule.filter(f => f.day !== data.day))
		);
		dayTimes[timeIndex] = { day: data.day, fromTime: data.fromTime, toTime: data.toTime };
		setcurrentSchedule([...dayTimes, ...otherDayTimes]);
		setSchedule([...dayTimes, ...otherDayTimes]);
		setLastDayTimes({ fromTime: data.fromTime, toTime: data.toTime });
	};

	const handleRemoveTime = (timeIndex, day) => {
		const dayTimes = JSON.parse(JSON.stringify(currentSchedule.filter(f => f.day === day)));
		const otherDayTimes = JSON.parse(
			JSON.stringify(currentSchedule.filter(f => f.day !== day))
		);
		dayTimes.splice(timeIndex, 1);
		setcurrentSchedule([...dayTimes, ...otherDayTimes]);
		setSchedule([...dayTimes, ...otherDayTimes]);
	};

	const PlusButton = ({ index }) => (
		<Button icon={faPlus} onClick={() => createScheduleTime(index)} size="small" />
	);

	const ButtonAndDay = ({ label, index }) => (
		<div style={s.buttonAndDayContainer}>
			<div style={s.buttonAndDayContainerInner}>
				<div style={s.day}>{label}</div>
				<PlusButton index={index} />
			</div>
		</div>
	);

	return (
		<div style={{ ...s.container, ...style }}>
			<div style={s.dayContainer}>
				<ButtonAndDay label="Monday" index={1} />

				<div style={s.scheduleContainer}>
					{currentSchedule
						.filter(f => f.day === 1)
						.map((a, i) => (
							<ScheduleTime
								key={i}
								timeIndex={i}
								day={a.day}
								fromTime={a.fromTime}
								toTime={a.toTime}
								setTime={handleSetTime}
								removeTime={handleRemoveTime}
							/>
						))}
				</div>
			</div>
			<div style={s.dayContainer}>
				<ButtonAndDay label="Tuesday" index={2} />

				<div style={s.scheduleContainer}>
					{currentSchedule
						.filter(f => f.day === 2)
						.map((a, i) => (
							<ScheduleTime
								key={i}
								timeIndex={i}
								day={a.day}
								fromTime={a.fromTime}
								toTime={a.toTime}
								setTime={handleSetTime}
								removeTime={handleRemoveTime}
							/>
						))}
				</div>
			</div>
			<div style={s.dayContainer}>
				<ButtonAndDay label="Wednesday" index={3} />

				<div style={s.scheduleContainer}>
					{currentSchedule
						.filter(f => f.day === 3)
						.map((a, i) => (
							<ScheduleTime
								key={i}
								timeIndex={i}
								day={a.day}
								fromTime={a.fromTime}
								toTime={a.toTime}
								setTime={handleSetTime}
								removeTime={handleRemoveTime}
							/>
						))}
				</div>
			</div>
			<div style={s.dayContainer}>
				<ButtonAndDay label="Thursday" index={4} />

				<div style={s.scheduleContainer}>
					{currentSchedule
						.filter(f => f.day === 4)
						.map((a, i) => (
							<ScheduleTime
								key={i}
								timeIndex={i}
								day={a.day}
								fromTime={a.fromTime}
								toTime={a.toTime}
								setTime={handleSetTime}
								removeTime={handleRemoveTime}
							/>
						))}
				</div>
			</div>
			<div style={s.dayContainer}>
				<ButtonAndDay label="Friday" index={5} />

				<div style={s.scheduleContainer}>
					{currentSchedule
						.filter(f => f.day === 5)
						.map((a, i) => (
							<ScheduleTime
								key={i}
								timeIndex={i}
								day={a.day}
								fromTime={a.fromTime}
								toTime={a.toTime}
								setTime={handleSetTime}
								removeTime={handleRemoveTime}
							/>
						))}
				</div>
			</div>
			<div style={s.dayContainer}>
				<ButtonAndDay label="Saturday" index={6} />

				<div style={s.scheduleContainer}>
					{currentSchedule
						.filter(f => f.day === 6)
						.map((a, i) => (
							<ScheduleTime
								key={i}
								timeIndex={i}
								day={a.day}
								fromTime={a.fromTime}
								toTime={a.toTime}
								setTime={handleSetTime}
								removeTime={handleRemoveTime}
							/>
						))}
				</div>
			</div>
			<div style={s.lastDayContainer}>
				<ButtonAndDay label="Sunday" index={7} />

				<div style={s.scheduleContainer}>
					{currentSchedule
						.filter(f => f.day === 7)
						.map((a, i) => (
							<ScheduleTime
								key={i}
								timeIndex={i}
								day={a.day}
								fromTime={a.fromTime}
								toTime={a.toTime}
								setTime={handleSetTime}
								removeTime={handleRemoveTime}
							/>
						))}
				</div>
			</div>
		</div>
	);
};

export default EditSchedule;

const s = {
	container: {
		width: "100%",
		marginBottom: "8px",
		fontWeight: 400,
		backgroundColor: "#fff",
		border: "1px solid #ced4da",
		borderRadius: ".25rem"
	},
	buttonAndDayContainer: {
		display: "flex",
		alignItems: "flex-start",
		borderRight: "1px solid #ced4da",
	},
	buttonAndDayContainerInner: {
		display: "flex",
		alignItems: "center",
		paddingLeft: theme.space.medium,
		paddingRight: theme.space.medium
	},
	dayContainer: {
		display: "flex",
		backgroundColor: "#fff",
		borderBottom: "1px solid #ced4da"
	},
	day: {
		fontSize: 14,
		width: 90,
		height: 55,
		lineHeight: "55px"
	},
	lastDayContainer: {
		display: "flex",
		backgroundColor: "#fff",
		alignItems: "center"
	},
	scheduleContainer: {
		height: "100%",
		display: "flex",
		flexWrap: "wrap",
		paddingBottom: 8
	}
};

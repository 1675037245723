import React from "react";
import BsBadge from "react-bootstrap/Badge";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Badge = styled(BsBadge)`
	margin-right: 8px;
	min-width: 20px;
	min-height: 20px;
	font-size: ${p => (p.size === "small" ? "12px" : "15px")};
	font-weight: 500;

	@media ${mediaQuery(device.tablet)} {
		min-width: 24px;
		min-height: 24px;
		font-size: ${p => (p.size === "small" ? "14px" : "18px")};
	}
`;

const Component = ({ size, children }) => {
	return (
		<Badge variant="light" size={size}>
			{children}
		</Badge>
	);
};

Component.displayName = "Status";

export default Component;

import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import Moment from "react-moment";
import styled from "styled-components";
import { useInterval } from "core/utils";
import { shouldAlert, timePastInSeconds } from "../functions";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${p => (p.isAlert ? "#FF735E" : "transparent")};
`;

const TimeDate = styled(Moment)`
	font-size: 16px;
	font-weight: 500;
	min-width: 75px;
	padding-left: 8px;
	padding-right: 8px;
	text-align: center;
	color: #fff;

	@media ${mediaQuery(device.tablet)} {
		font-size: 18px;
		padding-left: 12px;
		padding-right: 12px;
	}
`;

moment.updateLocale("en", {
	relativeTime: {
		s: "< 1 min",
		m: "1 min",
		mm: "%d min",
		h: "1 hour",
		hh: "%d hours",
		d: "1 day",
		dd: "> 1 day",
		M: "> 1 day",
		MM: "> 1 day",
		y: "> 1 day",
		yy: "> 1 day"
	}
});

const Time = ({ time, alert, alertDelay, liveTime, timeAsDuration }) => {
	const [timePast, setTimePast] = useState(timePastInSeconds(time));
	const [isAlert, setIsAlert] = useState();
	const secondsPast = moment(time).diff(new Date(), "seconds");
	const isRendering = !liveTime || secondsPast <= -1;

	const updateAlert = useCallback(
		(alert, alertDelay) => {
			const isAlert = shouldAlert(timePast, alert, alertDelay);
			setIsAlert(isAlert);
		},
		[timePast]
	);

	useEffect(() => {
		updateAlert(alert, alertDelay);
	}, [updateAlert, alert, alertDelay]);

	useInterval(
		() => {
			setTimePast(timePastInSeconds(time));
		},
		1000,
		!liveTime
	);

	if (!isRendering) {
		return null;
	}

	const durationFormat = timeAsDuration
		? { durationFromNow: true }
		: { fromNow: true, ago: true };

	return (
		<Container isAlert={isAlert}>
			<TimeDate date={time} {...durationFormat} />
		</Container>
	);
};

export default Time;

import { compose, cond, prop } from "ramda";
import { css } from "styled-components";

const device = {
	mobileMedium: 375,
	mobileLarge: 414,
	tablet: 768,
	tabletLarge: 1024,
	laptop: 1200,
	desktop: 1600
};

const displayOn = compose(
	query => css`
		@media ${query} {
			display: none;
		}
	`,
	([type, device]) => `(${type}: ${device}px)`,
	cond([
		[prop("min"), ({ min }) => ["max-width", min - 1]],
		[prop("max"), ({ max }) => ["min-width", max]]
	])
);

const mediaQuery = device => `(min-width: ${device}px)`;

export { device, mediaQuery, displayOn };

import React from "react";
import styled from "styled-components";
import { Badge as BsBadge } from "react-bootstrap";
import getOrderstatus from "orders/getOrderStatus";

const StatusContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Badge = styled(BsBadge)`
	display: flex;
	align-items: center;
	margin-right: 8px;
	min-width: 20px;
	min-height: 24px;
	font-size: 12px;
	font-weight: bold;
	background: ${p => p.color};
	white-space: pre-wrap;
`;

const StatusText = styled.div`
	color: #fff;
`;

const OrderStatusBadge = ({ order }) => {
	const orderStatus = getOrderstatus(order);

	return (
		<StatusContainer>
			<Badge color={orderStatus.color}>
				<StatusText>{orderStatus.label}</StatusText>
			</Badge>
		</StatusContainer>
	);
};

export default OrderStatusBadge;

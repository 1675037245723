import { faCheck, faMinusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import momentTZ from "moment-timezone";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";

import "moment/locale/en-gb";
moment.locale("en-gb");
moment.localeData("en-gb");

const CellContainer = styled.div`
	display: flex;
	align-items: center;
`;

const CellText = styled.div`
	margin: ${props => props.theme.space.small};
	color: ${props => props.theme.color.midGray};
`;

const PreOrderActiveColumn = (_ignore, row, showLabel = true) => {
	const deadlineExeeded = momentTZ
		.tz(row.preOrderDeadline, "Europe/Stockholm")
		.isBefore(momentTZ.tz("Europe/Stockholm"));

	const timeUntilDeadline = moment
		.duration(
			momentTZ
				.tz(row.preOrderDeadline, "Europe/Stockholm")
				.diff(momentTZ.tz("Europe/Stockholm"))
		)
		.humanize(true, { h: 24, d: 7, w: 4 });

	return (
		<CellContainer>
			<FontAwesomeIcon
				icon={row.allowPreOrder ? (!deadlineExeeded ? faCheck : faMinusCircle) : faTimes}
				color={
					row.allowPreOrder
						? !deadlineExeeded
							? theme.color.emeraldGreen
							: theme.color.midGray
						: theme.color.bittersweetRed
				}
			/>

			{row.allowPreOrder && showLabel && (
				<CellText>{deadlineExeeded ? `Closed` : `Closes ${timeUntilDeadline}`}</CellText>
			)}
		</CellContainer>
	);
};

export default PreOrderActiveColumn;

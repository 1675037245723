import React from "react";
import styled from "styled-components";
import { useUserContext } from "context/UserContext";

const Container = styled.div`
	padding: 12px;
	border-bottom: 1px solid #eee;
`;

const Label = styled.div`
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 6px;
	color: #666;
`;

const Email = styled.div`
	font-size: 15px;
	line-height: 1;
`;

const CurrentUser = () => {
	const { email } = useUserContext();

	return (
		<Container>
			<Label>Current user</Label>
			<Email>{email}</Email>
		</Container>
	);
};

export default CurrentUser;

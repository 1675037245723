import { faMapMarkerAlt as faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
	padding: 8px;

	@media ${mediaQuery(device.tablet)} {
		padding: 12px;
	}
`;

const Icon = styled(FontAwesomeIcon)`
	margin-right: 4px;
	color: #fff;
	font-size: 12px;

	@media ${mediaQuery(device.tablet)} {
		font-size: 14px;
	}
`;

const Label = styled.span`
	font-size: 18px;
	line-height: 1;
	font-weight: 500;
	color: #fff;

	@media ${mediaQuery(device.tablet)} {
		font-size: 20px;
	}
`;

const Title = props => {
	const { label, isPickup, reference } = props;

	if (isPickup)
		return (
			<Container>
				<Label>Pickup #{reference}</Label>
			</Container>
		);

	return (
		<Container>
			<Icon icon={faMapMarker} />
			<Label>{label}</Label>
		</Container>
	);
};

export default Title;

import { useMerchantContext } from "context";
import React from "react";
import PayoutsGrid from "./PayoutsGrid";
import Container from "./Container";
import usePayouts from "./use-payouts";
import { Alert } from "react-bootstrap";
import { isEmpty } from "ramda";
import { SectionLoader, SectionHeader } from "core/components";
import styled from "styled-components";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: ${p => p.theme.space.large};
`;

const Payouts = () => {
	const { currentMerchant } = useMerchantContext();
	const timeZone = currentMerchant?.timeZone || "Europe/Stockholm";
	const [payouts, isLoading] = usePayouts();
	const havePayouts = !isLoading && !isEmpty(payouts);

	const renderContent = () => (
		<Content>
			{havePayouts && <PayoutsGrid {...{ payouts, timeZone }} />}

			<Alert
				show={!havePayouts}
				variant="warning"
				style={{ alignSelf: "flex-start" }}
				data-test="payouts-empty-message"
			>
				No payouts have been made.
			</Alert>
		</Content>
	);

	return (
		<Container>
			<SectionHeader heading="Payouts" />
			{!isLoading && renderContent()}
			{isLoading && <SectionLoader />}
		</Container>
	);
};

export default Payouts;

import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	flex-direction: column;

	@media ${mediaQuery(device.tabletLarge)} {
		flex-direction: row;
	}
`;

const Aside = styled.div`
	display: flex;
	flex-direction: column;
`;

const Nav = styled.div`
	margin-bottom: ${p => p.theme.space.huge};
	border-left: 1px solid ${p => p.theme.color.border.primary};

	@media ${mediaQuery(device.tabletLarge)} {
		width: 220px;
		margin-bottom: 0;
	}
`;

const Title = styled.div`
	font-family: Peak;
	font-size: 22px;
	font-weight: 500;
	padding ${p => p.theme.space.small} 0 ${p => p.theme.space.medium};

	@media ${mediaQuery(device.tabletLarge)} {
		font-size: 18px;
		padding ${p => p.theme.space.large} 0;
	}
`;

const Content = styled.div`
	flex-grow: 1;
`;

export { Container, Aside, Nav, Title, Content };

import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Body = styled.div`
	flex: 1;
	border: 1px solid #ddd;
	border-radius: 0 0 4px 4px;
	border-top: 0;
	padding-left: 4px;
	padding-right: 4px;

	@media ${mediaQuery(device.tablet)} {
		padding-left: 8px;
		padding-right: 8px;
	}
`;

export default Body;

import React from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

const Header = styled.div`
	font-weight: bold;
	margin-bottom: ${p => p.theme.space.small};
`;

const Container = styled.div`
	margin-top: ${p => p.theme.space.small};
	display: table;
	min-width: 300px;
	table-layout: fixed;
	margin-bottom: ${p => p.theme.space.small};
	border: ${p => p.theme.color.secondary};
`;

const PropertyTable = styled(Table)`
	margin-bottom: 0;
	border-color: ${p => p.theme.color.primary};
	tr td {
		border-color: ${p => p.theme.color.primary};
	}
	tr:first-child td {
		border-top: none;
	}
`;

const ExternalProductTable = ({ externalProduct }) => (
	<Container>
		<Header>Product is linked to the following product in the POS system:</Header>
		<PropertyTable bordered={false} hover size="sm">
			<tbody>
				<tr>
					<td>ID:</td>
					<td>{externalProduct.externalId}</td>
				</tr>
				<tr>
					<td>Name:</td>
					<td>{externalProduct.importedData?.name}</td>
				</tr>
				<tr>
					<td>Printers:</td>
					<td>{externalProduct.importedData?.externalPrinters?.join(", ")}</td>
				</tr>
			</tbody>
		</PropertyTable>
	</Container>
);

export default ExternalProductTable;

import React from "react";
import { Amount } from "core/components";
import { Created } from "./GridColumns";
import OrderStatusBadge from "orders/components/OrderStatusBadge";

const schema = timeZone => [
	{
		prop: "paymentAmount",
		type: "number",
		align: "left",
		sort: true,
		headerAlign: "left",
		// eslint-disable-next-line react/display-name
		formatter: cell => <Amount value={cell} align="left" />,
		minimumGridVisibility: "xs",
		label: "Amount"
	},
	{
		prop: "status",
		type: "text",
		sort: true,
		minimumGridVisibility: "xs",
		label: "Status",
		// eslint-disable-next-line react/display-name
		formatter: (cell, row) => <OrderStatusBadge order={row} />
	},
	{
		prop: "orderId",
		type: "text",
		minimumGridVisibility: "md",
		label: "Order ID"
	},
	{
		prop: "timeCreated",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Date",
		sort: true,
		// eslint-disable-next-line react/display-name
		formatter: cell => <Created cell={cell} timeZone={timeZone} />
	}
];

export default schema;

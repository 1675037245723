import { faQuestionCircle, faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { httpClient } from "data";
import moment from "moment-timezone";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Alert, FormControl, OverlayTrigger, Popover } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal } from "core/components";

const CreateReportModal = ({ isModal, onClose, reportFunctions, timeZone, refresh }) => {
	const toDateRef = useRef();
	const [selectedReportFunction, setSelectedReportFunction] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [showAlertError, setShowAlertError] = useState(false);
	const [alertErrorMessage, setAlertErrorMessage] = useState();
	const [creatingReport, setCreatingReport] = useState(false);

	const createReport = useCallback(async () => {
		if (!selectedReportFunction) {
			setAlertErrorMessage("Report must be selected");
			setShowAlertError(true);
			return;
		}
		if (!startDate) {
			setAlertErrorMessage("From-date must be selected");
			setShowAlertError(true);
			return;
		}
		if (!endDate) {
			setAlertErrorMessage("To-date must be selected");
			setShowAlertError(true);
			return;
		}
		setShowAlertError(false);
		setAlertErrorMessage(null);

		await httpClient.post("/reporting/reports", {
			body: {
				reportFunctionId: selectedReportFunction.reportFunctionId,
				fromDate: moment(startDate).hour(0).minute(0).format("yyyy-MM-DD HH:mm ZZ"),
				toDate: moment(endDate)
					.add(1, "day")
					.hour(0)
					.minute(0)
					.format("yyyy-MM-DD HH:mm ZZ"),
				timeZone: timeZone
			}
		});
		refresh();
		onClose();
	}, [endDate, onClose, refresh, selectedReportFunction, startDate, timeZone]);

	const popoverDateInfo = () => (
		<Popover id="popover-basic">
			<Popover.Title>To-date is inclusive</Popover.Title>
			<Popover.Content>
				<div>
					Reports will include the to-date. To get a daily report select the same day as
					from and to-date.
				</div>
			</Popover.Content>
		</Popover>
	);

	const body = useMemo(
		() => (
			<>
				<Alert
					variant="danger"
					show={showAlertError}
					onClose={() => setShowAlertError(false)}
					dismissible
				>
					{alertErrorMessage}
				</Alert>

				<div style={{ padding: "8px 0" }}>
					<div
						style={{
							display: "flex",
							marginLeft: 8,
							marginBottom: 16,
							alignItems: "center"
						}}
					>
						<div style={{ fontWeight: 500, width: 80 }}>Report</div>
						<div>
							<FormControl
								as="select"
								className="mr-sm-2"
								id="inlineFormCustomSelectPref"
								value={selectedReportFunction?.reportFunctionId}
								onChange={e => {
									const reportFunction = reportFunctions.find(
										f => f.reportFunctionId === Number(e.target.value)
									);
									setSelectedReportFunction(reportFunction);
								}}
								custom
								data-test="reports-create-report-select"
							>
								<option key="0" value="">
									- Select -
								</option>

								{reportFunctions
									.filter(f => f.functionOutputType === "CSV")
									.map(rf => (
										<option
											key={rf.reportFunctionId}
											value={rf.reportFunctionId}
										>
											{rf.name}
										</option>
									))}
							</FormControl>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							marginLeft: 8,
							marginBottom: 16,
							alignItems: "center"
						}}
					>
						<div style={{ fontWeight: 500, width: 80 }}>From</div>
						<div>
							<DatePicker
								className="datePicker"
								selected={startDate}
								onChange={date => setStartDate(date)}
								dateFormat="MMMM d, yyyy"
								id="reports-create-report-from"
								autoComplete="off"
							/>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							marginLeft: 8,
							alignItems: "center"
						}}
					>
						<div style={{ fontWeight: 500, width: 80 }}>To</div>
						<div>
							<DatePicker
								ref={toDateRef}
								className="datePicker"
								selected={endDate}
								dateFormat="MMMM d, yyyy"
								onChange={setEndDate}
								id="reports-create-report-to"
								autoComplete="off"
							/>
						</div>
						<div style={{ marginLeft: 16 }}>
							<OverlayTrigger
								trigger="click"
								rootClose
								placement="top"
								overlay={popoverDateInfo()}
							>
								<FontAwesomeIcon
									icon={faQuestionCircle}
									style={{ cursor: "hand" }}
								/>
							</OverlayTrigger>
						</div>
					</div>
				</div>
			</>
		),
		[
			alertErrorMessage,
			endDate,
			reportFunctions,
			selectedReportFunction,
			showAlertError,
			startDate
		]
	);

	const footer = useMemo(
		() => (
			<>
				<Button icon={faUndo} onClick={onClose} size="large" collapse={false}>
					Cancel
				</Button>

				<Button
					variant="primary"
					size="large"
					icon={faSave}
					isLoading={creatingReport}
					onClick={async () => {
						setCreatingReport(true);
						await createReport();
						setCreatingReport(false);
					}}
					data-test="reports-create-report-create-button"
					collapse={false}
				>
					Create
				</Button>
			</>
		),
		[createReport, creatingReport, onClose]
	);

	return (
		<Modal show={isModal} onHide={onClose} title="Create report" body={body} footer={footer} />
	);
};

export default CreateReportModal;

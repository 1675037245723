import { faCheck, faMinusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import momentTZ from "moment-timezone";
import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";
import theme from "styles/theme";

import "moment/locale/en-gb";
moment.locale("en-gb");
moment.localeData("en-gb");

const CellContainer = styled.div`
	display: flex;
	align-items: center;
`;

const CellText = styled.div`
	margin-left: ${props => props.theme.space.small};
`;

const PreOrderActive = ({ event }) => {
	const deadlineExeeded = useMemo(
		() =>
			momentTZ
				.tz(event.preOrderDeadline, "Europe/Stockholm")
				.isBefore(momentTZ.tz("Europe/Stockholm")),
		[event]
	);
	const timeUntilDeadline = useMemo(
		() =>
			moment
				.duration(
					momentTZ
						.tz(event.preOrderDeadline, "Europe/Stockholm")
						.diff(momentTZ.tz("Europe/Stockholm"))
				)
				.humanize(true, { h: 24, d: 7, w: 4 }),
		[event]
	);

	const icon = useMemo(
		() => (
			<FontAwesomeIcon
				icon={event.allowPreOrder ? (!deadlineExeeded ? faCheck : faMinusCircle) : faTimes}
				color={
					event.allowPreOrder
						? !deadlineExeeded
							? theme.color.emeraldGreen
							: theme.color.midGray
						: theme.color.bittersweetRed
				}
			/>
		),
		[event, deadlineExeeded]
	);

	const cellText = useMemo(() => {
		if (!event.allowPreOrder) return null;

		return <CellText>{deadlineExeeded ? `Closed` : `Closes ${timeUntilDeadline}`}</CellText>;
	}, [event, deadlineExeeded, timeUntilDeadline]);

	return (
		<CellContainer>
			{icon}
			{cellText}
		</CellContainer>
	);
};

export default PreOrderActive;

import isStringNumber from "core/utils/is-string-number";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";

const Number = ({ item, value, onChange, valid }) => {
	const handleChange = useCallback(
		e => {
			const number = isStringNumber(e.target.value) ? parseFloat(e.target.value) : null;

			onChange(number);
		},
		[onChange]
	);

	return (
		<Form.Control
			pattern="\d*"
			size="sm"
			type="text"
			isInvalid={valid}
			placeholder={item.placeholder}
			style={item.editStyle}
			onChange={handleChange}
			value={value}
		/>
	);
};

Number.validate = value => value;

export default Number;

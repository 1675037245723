import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { Button, PageLayout, SectionHeader, SectionLoader } from "core/components";
import { capitalize } from "lodash";
import "moment/locale/sv";
import { pluck } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import DetailsList from "./DetailsList";
import EventGroupingPicker from "./EventGroupingPicker";
import PreOrders from "./PreOrders";
import PrintEvent from "./PrintEvent";
import Section from "./Section";
import StationPicker from "./StationPicker";
import useEvent from "./use-event";
import useStations from "./use-stations";

const Container = styled.div`
	width: 100%;
`;

const Content = styled.div`
	margin-top: ${p => p.theme.space.large};
	margin-bottom: ${p => p.theme.space.huge};
	font-size: 14px;
	margin-left: 0px;
	margin-right: 0px;
	@media ${mediaQuery(device.tablet)} {
		margin-left: 8px;
		margin-right: 8px;
	}
`;

const PrintContainer = styled.div`
	display: none;
`;

const ButtonsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	gap: 8px;
	flex: 1 1 auto;
`;

const HeaderContainer = styled.div`
	display: flex;
	align-items: baseline;
`;

const HeaderText = styled.div`
	margin-right: 12px;
`;

const Event = () => {
	const params = useParams();
	const eventId = params.eventId;
	let printRef;

	const [event, eventIsLoading] = useEvent({ eventId });
	const [stations, stationsIsLoading] = useStations();
	const [selectedPreOrderStations, setSelectedPreOrderStations] = useState([]);
	const [selectedPreOrderGrouping, setSelectedPreOrderGrouping] = useState([]);

	const isLoading = eventIsLoading || stationsIsLoading;

	useEffect(() => {
		setSelectedPreOrderStations(pluck("stationId", stations));
	}, [stations]);

	const handlePreOrderStationChange = useCallback(
		stations => {
			setSelectedPreOrderStations(stations);
		},
		[setSelectedPreOrderStations]
	);

	const handlePreOrderGroupingChange = useCallback(grouping => {
		setSelectedPreOrderGrouping(grouping);
	}, []);

	const hasEvent = useMemo(() => !isLoading && event.eventId, [isLoading, event]);
	const hasSelectedPreOrderStations = selectedPreOrderStations.length > 0;

	const getSectionHeading = () => (
		<HeaderContainer>
			<HeaderText>{event.name}</HeaderText>
		</HeaderContainer>
	);

	if (isLoading) {
		return (
			<PageLayout>
				<SectionLoader />
			</PageLayout>
		);
	}

	if (!hasEvent)
		return (
			<PageLayout>
				<Alert
					variant="warning"
					style={{ alignSelf: "flex-start" }}
					data-test="Events-empty-message"
				>
					Event does not exist.
				</Alert>
			</PageLayout>
		);

	return (
		<PageLayout>
			<Container>
				<SectionHeader heading={getSectionHeading()}>
					<ButtonsContainer>
						<ReactToPrint
							pageStyle={`@page { margin: 0; }`}
							trigger={() => (
								<Button icon={faPrint} variant="primary">
									Print
								</Button>
							)}
							content={() => printRef}
						></ReactToPrint>

						<PrintContainer>
							<div ref={el => (printRef = el)}>
								<PrintEvent
									event={event}
									stations={stations}
									selectedPreOrderStations={selectedPreOrderStations}
									selectedPreOrderGrouping={selectedPreOrderGrouping}
								/>
							</div>
						</PrintContainer>
					</ButtonsContainer>
				</SectionHeader>
				<Content>
					<DetailsList event={event} />
					<Section title="Pre orders">
						<ButtonsContainer>
							<EventGroupingPicker onChange={handlePreOrderGroupingChange} />
							<StationPicker
								stations={stations}
								selectedStations={selectedPreOrderStations}
								onChange={handlePreOrderStationChange}
							/>
						</ButtonsContainer>
					</Section>
					{!hasSelectedPreOrderStations && (
						<Alert
							variant="warning"
							style={{ alignSelf: "flex-start" }}
							data-test="Events-empty-message"
						>
							Please select a station.
						</Alert>
					)}
					{hasSelectedPreOrderStations && (
						<PreOrders
							event={event}
							isLoading={isLoading}
							stations={stations}
							selectedStations={selectedPreOrderStations}
							selectedGrouping={selectedPreOrderGrouping}
						/>
					)}
				</Content>
			</Container>
		</PageLayout>
	);
};

export default Event;

import { isDoubleClick } from "core/utils";
import { allPass, always, when } from "ramda";
import React from "react";
import { Body, Card, EnterFlash, Header, Items, RelatedOrderTickets } from "./components";
import { useEnterSound } from "./functions";

const Ticket = props => {
	const {
		orderTicketId,
		onDoubleClick,
		isAdded,
		soundOnEnter,
		flashOnEnter,
		orderItems,
		relatedOrderTickets,
		isPickup
	} = props;

	useEnterSound({ orderTicketId, isAdded, soundOnEnter });

	const handleClick = when(allPass([isDoubleClick, always(!!onDoubleClick)]), onDoubleClick);

	const isClickable = !!onDoubleClick;

	return (
		<Card onClick={handleClick} isClickable={isClickable}>
			<Header {...props} />

			<Body>
				<Items items={orderItems} />

				<RelatedOrderTickets isPickup={isPickup} orderTickets={relatedOrderTickets} />
			</Body>

			<EnterFlash {...{ isAdded, flashOnEnter }} />
		</Card>
	);
};

export default Ticket;

import { useMerchantContext } from "context";
import { PageLayout, SideNav } from "core/components";
import { useTabs } from "core/utils";
import React, { useMemo } from "react";
import {
	Accounting,
	Fortnox,
	Payouts,
	Reports,
	ScheduledReports,
	SIE,
	ControlUnit
} from "./components";

const Reporting = () => {
	const { hasFunctionAccess } = useMerchantContext();
	const items = useMemo(
		() => [
			{
				id: "reports",
				label: "Reports",
				content: <Reports />
			},
			{
				parent: "reports",
				id: "scheduled-reports",
				label: "Scheduled",
				content: <ScheduledReports />
			},
			{
				id: "accounting",
				label: "Accounting",
				content: <Accounting />
			},
			...(hasFunctionAccess("report_view")
				? [
						{
							parent: "accounting",
							id: "sie",
							label: "SIE",
							content: <SIE />
						}
				  ]
				: []),
			...(hasFunctionAccess("report_edit")
				? [
						{
							parent: "accounting",
							id: "fortnox",
							label: "Fortnox",
							content: <Fortnox />
						}
				  ]
				: []),
			{
				id: "payouts",
				label: "Payouts",
				content: <Payouts />
			},
			...(hasFunctionAccess("control_unit")
				? [
						{
							id: "control-unit",
							label: "Control unit",
							content: <ControlUnit />
						}
				  ]
				: [])
		],
		[hasFunctionAccess]
	);
	const [activeItem, setActiveItem] = useTabs("reports", items);

	return (
		<PageLayout>
			<SideNav
				title="Reporting"
				items={items}
				activeItem={activeItem}
				onActiveItemChanged={setActiveItem}
			/>
		</PageLayout>
	);
};

export default Reporting;

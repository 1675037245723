import { PageLayout } from "core/components";
import React from "react";
import StationSwitcher from "./StationSwitcher";

const Stations = () => {
	return (
		<PageLayout>
			<StationSwitcher />
		</PageLayout>
	);
};

export default Stations;

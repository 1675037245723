import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { reportingRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useXReport = () => {
	const [xReport, setXReport] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch(false);

	const { currentStore } = useMerchantContext();

	const refresh = useCallback(
		(args = { loader: true }) => {
			if (!currentStore) return;
			args?.loader && startLoading();
			reportingRepo
				.getXReport({ storeId: currentStore.storeId, format: { type: "text" } })
				.then(r => {
					setXReport(r?.getXReportText.replace(/\\n/, "\r\n"));
					stopLoading();
				});
		},
		[currentStore, startLoading, stopLoading]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [xReport, isLoading, refresh];
};

export default useXReport;

import React, { useState } from "react";
import OrderItemsGrid from "./OrderItemsGrid";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import RefundButtons from "./RefundButtons";
import theme from "styles/theme";
import { Amount, Button, ConfirmationModal, PageLayout, SectionHeader } from "core/components";
import { faReceipt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RefundsGrid from "./RefundsGrid";
import Section from "./Section";
import DetailsList from "./DetailsList";
import { SectionLoader } from "core/components";
import useOrder from "./use-order";
import Receipt from "../Receipt";
import { useSwitch } from "core/utils";
import { device, mediaQuery } from "styles/device";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import OrderStatusBadge from "../OrderStatusBadge";
import { capitalize } from "lodash";

const Container = styled.div`
	width: 100%;
`;

const Content = styled.div`
	margin-top: ${p => p.theme.space.large};
	margin-bottom: ${p => p.theme.space.huge};
	font-size: 14px;
	margin-left: 0px;
	margin-right: 0px;
	@media ${mediaQuery(device.tablet)} {
		margin-left: 8px;
		margin-right: 8px;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
`;

const RefundPickerTextContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: ${p => p.theme.space.small};
	margin-left: ${p => p.theme.space.small};
`;

const RefundPickerText = styled.div`
	margin-left: ${p => p.theme.space.small};
`;

const HeaderContainer = styled.div`
	display: flex;
	align-items: baseline;
`;

const HeaderText = styled.div`
	margin-right: 12px;
`;

const HeaderAmount = styled.div`
	font-size: 22px;
`;

const Order = () => {
	const params = useParams();
	const orderId = params.orderId;

	const [order, isLoading, refresh] = useOrder({ orderId: orderId });
	const haveOrder = !isLoading && order.orderId;
	const [isReceiptShowing, showReceipt, hideReceipt] = useSwitch(false);
	const haveRefunds = order?.refunds?.length > 0;
	const showRefunds = order.type === "PURCHASE" && haveRefunds;
	const orderType = capitalize(order.type);

	const [mappedItems, setMappedItems] = useState();
	const [refundRows, setRefundRows] = useState({});
	const [refundPickerActive, setRefundPickerActive] = useState(false);

	const getSectionHeading = () => (
		<HeaderContainer>
			<HeaderText>{orderType}</HeaderText>
			<HeaderAmount>
				<Amount value={order.total} />
			</HeaderAmount>
		</HeaderContainer>
	);

	const clearRefundPickerState = () => {
		setRefundPickerActive(false);
		setMappedItems();
		setRefundRows({});
	};

	const renderReceiptModal = () => {
		return (
			<ConfirmationModal
				show={isReceiptShowing}
				title={"Receipt copy"}
				text={<Receipt order={order}></Receipt>}
				onConfirm={hideReceipt}
				onCancel={hideReceipt}
				onlyConfirm
				confirmButtonProps={{ label: "Close" }}
			/>
		);
	};

	if (isLoading) {
		return (
			<PageLayout>
				<SectionLoader />
			</PageLayout>
		);
	}

	if (!haveOrder)
		return (
			<PageLayout>
				<Alert
					variant="warning"
					style={{ alignSelf: "flex-start" }}
					data-test="orders-empty-message"
				>
					Order does not exist.
				</Alert>
			</PageLayout>
		);

	return (
		<PageLayout>
			<Container>
				<SectionHeader heading={getSectionHeading()}>
					<OrderStatusBadge order={order} />
				</SectionHeader>
				<Content>
					{renderReceiptModal()}
					<DetailsList order={order} />
					<Section title="Items" noMargin>
						<ButtonsContainer>
							<RefundButtons
								order={order}
								refundPickerActive={refundPickerActive}
								setRefundPickerActive={setRefundPickerActive}
								setRefundRows={setRefundRows}
								refresh={refresh}
								mappedItems={mappedItems}
								setMappedItems={setMappedItems}
								clearState={clearRefundPickerState}
							/>

							{order.hasReceipt && (
								<Button size="small" onClick={showReceipt} icon={faReceipt}>
									Receipt copy
								</Button>
							)}
						</ButtonsContainer>
					</Section>
					{refundPickerActive && (
						<RefundPickerTextContainer>
							<FontAwesomeIcon icon={faInfoCircle} color={theme.color.goldenSand} />
							<RefundPickerText>Select items to refund</RefundPickerText>
						</RefundPickerTextContainer>
					)}
					<OrderItemsGrid
						{...{
							order,
							refresh,
							refundRows,
							setRefundRows,
							setMappedItems,
							refundPickerActive
						}}
					/>
					{showRefunds && (
						<>
							<Section title="Refunds" noMargin />

							<RefundsGrid
								refunds={order.refunds}
								clearRefundPickerState={clearRefundPickerState}
							/>
						</>
					)}
				</Content>
			</Container>
		</PageLayout>
	);
};

export default Order;

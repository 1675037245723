import React from "react";
import { useMerchantContext } from "context";
import DeleteAction from "./DeleteAction";
import DownloadAction from "./DownloadAction";
import GeneratingReportLoader from "./GeneratingReportLoader";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Actions = ({ row, refresh }) => {
	const { hasFunctionAccess } = useMerchantContext();

	const renderButtons = () => (
		<ButtonsContainer key={`action-${row.reportId}`} data-test="reports-actions-buttons">
			<DownloadAction data={row} />

			{hasFunctionAccess("report_edit") && (
				<DeleteAction reportId={row.reportId} refresh={refresh} />
			)}
		</ButtonsContainer>
	);

	return (
		<Container>{row.timeGenerated ? renderButtons() : <GeneratingReportLoader />}</Container>
	);
};

export default Actions;

import React from "react";
import { useHistory } from "react-router";
import { useUserContext } from "context/UserContext";
import ConfirmationModal from "../ConfirmationModal";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const SignOutModal = ({ isOpen, onClose }) => {
	const { signOut } = useUserContext();
	const history = useHistory();

	const signOutAndRedirect = async () => {
		await signOut();
		onClose();
		history.push("/sign-in");
	};

	return (
		<ConfirmationModal
			title="Sign out"
			text="Are you sure you want to sign out?"
			show={isOpen}
			onConfirm={signOutAndRedirect}
			onCancel={onClose}
			confirmButtonProps={{
				label: "Sign out",
				icon: faSignOutAlt
			}}
		/>
	);
};

export default SignOutModal;

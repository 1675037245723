import { useMerchantContext } from "context";
import { matchPath, useLocation } from "react-router";

const useNavItem = item => {
	const { to, functionAccess } = item;
	const { hasFunctionAccess } = useMerchantContext();
	const location = useLocation();
	const isActive = !!matchPath(location.pathname, { path: to });
	const haveAccess = !functionAccess || hasFunctionAccess(functionAccess);

	return { isActive, haveAccess };
};

export default useNavItem;

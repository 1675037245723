import React from "react";
import { TicketConfig } from "./functions";
import Ticket from "./Ticket";

const OrderTicketReadyForPickup = ({ orderTicket, station, onDoubleClick }) => {
	const config = TicketConfig(
		{ orderTicket, station },
		{
			headerColor: "goldenSand",
			timeAsDuration: true,
			liveTime: true,
			onDoubleClick
		}
	);

	return <Ticket {...config} />;
};

export default OrderTicketReadyForPickup;

import React from "react";
import { Button } from "core/components";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import OutputType from "./OutputType";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: ${p => p.theme.space.small};
	margin-bottom: ${p => p.theme.space.small};

	&:last-child {
		margin-bottom: 0;
	}
`;

const Content = styled.div`
	width: 100%;
	border: 1px solid ${p => p.theme.color.border.primary};
	padding: ${p => p.theme.space.small};
	background-color: #fefefe;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: "100%";
`;

const Name = styled.div`
	margin-right: 8px;
`;

const ButtonContainer = styled.div`
	margin-left: ${p => p.theme.space.medium};
`;

const Report = ({ report, onRemove, removable }) => {
	const { name, key, outputType } = report;

	const handleRemoveButtonClick = () => onRemove(report);

	return (
		<Container key={key}>
			<Content>
				<Name>{name}</Name>
				<OutputType type={outputType} />
			</Content>

			{removable && (
				<ButtonContainer>
					<Button icon={faTrashAlt} onClick={handleRemoveButtonClick} size="large" />
				</ButtonContainer>
			)}
		</Container>
	);
};

export default Report;

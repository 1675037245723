import React from "react";
import styled from "styled-components";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { device, displayOn } from "styles/device";

const Container = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	display: flex;
	user-select: none;
	padding-left: 12px;
	padding-right: 12px;
	position: relative;
	right: -12px;

	${displayOn({ max: device.tabletLarge })}
`;

const Icon = styled(FontAwesomeIcon)`
	font-size: 20px;
	color: #fff;
`;

const HamburgerButton = ({ onClick }) => {
	return (
		<Container onClick={onClick}>
			<Icon icon={faBars} />
		</Container>
	);
};

export default HamburgerButton;

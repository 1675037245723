import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import styled from "styled-components";

const Item = styled.div`
	background: #fff;
	width: 100%;
	padding: ${p => p.theme.space.medium};
	display: flex;
	align-content: left;
	align-items: center;

	&:hover {
		background: #efefef;
		cursor: pointer;
	}
`;

const IconContainer = styled.div`
	width: ${p => p.theme.space.huge};
	margin-right: ${p => p.theme.space.medium};
	display: flex;
	justify-content: center;
`;

const Label = styled.div`
	margin-top: 1px;
	font-size: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
	color: ${p => (p.active ? p.theme.color.emeraldGreen : "initial")};
`;

const ChoiceModalItem = ({ choice, onClick }) => {
	const icon = choice.checked ? faCheck : choice.icon;

	const handleItemClick = useCallback(() => {
		choice.onClick();
		onClick();
	}, [choice, onClick]);

	return (
		<Item onClick={handleItemClick}>
			<IconContainer>
				{icon && (
					<Icon icon={icon} active={choice.checked} style={choice.iconStyle} fixedWidth />
				)}
			</IconContainer>
			<Label>{choice.label}</Label>
		</Item>
	);
};

export default ChoiceModalItem;

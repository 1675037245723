import { useMerchantContext } from "context";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { validate as validateEmail } from "react-email-validator";
import EditReportJobForm from "./EditReportJobForm";
import Details from "./Details";
import Reports from "./Reports";
import useReportSchedule from "./use-report-schedule";
import { Container, Header, Body, Group, Label } from "./styles";
import Title from "./Title";
import Footer from "./Footer";
import EditBadge from "./EditBadge";

const ReportJob = ({
	timeZone,
	reportJob,
	reportFunctions,
	handleCreate,
	handleUpdate,
	handleRemove
}) => {
	const reportsWithKey =
		reportJob?.reports?.map((report, i) => {
			return { ...report, key: `${i}${Math.floor(100000 + Math.random() * 900000)}` };
		}) || [];

	const { hasFunctionAccess, currentMerchant } = useMerchantContext();
	const [reports, setReports] = useState(reportsWithKey);
	const [emails, setEmails] = useState(reportJob?.emails);
	const [active, setActive] = useState(true);
	const [nextRun, setNextRun] = useState(reportJob.nextRun);
	const [editMode, setEditMode] = useState(reportJob.new || false);
	const [isNew, setIsNew] = useState(reportJob.new || false);
	const [showAlertError, setShowAlertError] = useState(false);
	const [alertErrorMessage, setAlertErrorMessage] = useState();
	const [schedule, cronExpression, intervalSettings, handleScheduleChange, resetSchedule] =
		useReportSchedule({
			timeZone,
			initialCronExpression: reportJob.cronExpression,
			initialIntervalSettings: reportJob.intervalSettings
		});

	const handleSave = async () => {
		if (!cronExpression) {
			setAlertErrorMessage("Schedule must be selected");
			setShowAlertError(true);
			return;
		}

		if (emails.filter(f => f).some(e => !validateEmail(e))) {
			setAlertErrorMessage("There are invalid email adresses");
			setShowAlertError(true);
			return;
		}

		const data = {
			description: `${schedule.text?.toLowerCase()} reporting ${
				currentMerchant?.name ? "- " + currentMerchant.name : ""
			}`,
			active,
			cronExpression,
			emails: emails.filter(f => f),
			intervalSettings,
			reportFunctionIds: reports.map(r => r.reportFunctionId),
			timeZone
		};

		setNextRun(null);
		let result;
		if (isNew) {
			result = await handleCreate({ reportJobKey: reportJob.key, data });
			setIsNew(false);
			if (result?.errorMessage) {
				setAlertErrorMessage(result.errorMessage);
				setShowAlertError(true);
				return;
			}
		} else {
			result = await handleUpdate({ reportJobId: reportJob.reportJobId, data });
			if (result?.errorMessage) {
				setAlertErrorMessage(result.errorMessage);
				setShowAlertError(true);
				return;
			}
		}
		setShowAlertError(false);
		setEditMode(false);
		setNextRun(await result.nextRun);
	};

	const handleCancel = () => {
		setAlertErrorMessage();
		setShowAlertError(false);
		if (isNew) handleRemove(reportJob);
		else {
			setReports(reportsWithKey);
			setEmails(reportJob?.emails);
			resetSchedule();
			setEditMode(false);
		}
	};

	const onConfirmRemove = async () => {
		const result = await handleRemove(reportJob);
		if (result?.errorMessage) {
			setAlertErrorMessage(result.errorMessage);
			setShowAlertError(true);
		}
		setEditMode(false);
	};

	const onEditMode = () => setEditMode(true);

	return (
		<Container {...{ $isNew: isNew }}>
			<Header>
				<Title>{schedule?.type && schedule?.text}</Title>

				{editMode && <EditBadge />}
			</Header>

			<Body>
				<Alert
					variant="danger"
					show={showAlertError}
					onClose={() => setShowAlertError(false)}
					dismissible
				>
					{alertErrorMessage}
				</Alert>

				<Group>
					<Label>Reports</Label>

					<Reports
						reports={reports}
						setReports={setReports}
						reportFunctions={reportFunctions}
						editMode={editMode}
					/>
				</Group>

				{editMode && (
					<EditReportJobForm
						reportJobData={reportJob}
						active={active}
						setActive={setActive}
						emails={emails}
						setEmails={setEmails}
						schedule={schedule}
						handleScheduleChange={handleScheduleChange}
					/>
				)}

				{!editMode && <Details active={active} nextRun={nextRun} emails={emails} />}
			</Body>

			{hasFunctionAccess("report_edit") && (
				<Footer
					{...{ isNew, editMode, handleSave, handleCancel, onConfirmRemove, onEditMode }}
				/>
			)}
		</Container>
	);
};

export default ReportJob;

import React from "react";
import { Loader } from "core/components";
import styled from "styled-components";

const ReportContainer = styled.div`
	background-color: ${p => p.theme.color.dirtyWhite};
	border-radius: 12px;
	border: 1px solid ${p => p.theme.color.border.primary};
	padding: ${p => p.theme.space.large};
	margin-bottom: ${p => p.theme.space.large};
	justify-content: space-between;
`;

const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	align-content: center;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
`;

const XReport = ({ xReport, isXReportLoading }) => {
	if (isXReportLoading) return <Loader />;

	return (
		<Container>
			<ReportContainer>
				<pre>{xReport}</pre>
			</ReportContainer>
		</Container>
	);
};
export default XReport;

import React from "react";
import { Amount } from "core/components";
import moment from "moment-timezone";
import OrderStatusBadge from "orders/components/OrderStatusBadge";

const schema = timeZone => [
	{
		prop: "timeCreated",
		label: "Date",
		minimumGridVisibility: "xs",
		formatter: cell =>
			moment.tz(cell, timeZone || "Europe/Stockholm").format("YYYY-MM-DD HH:MM")
	},
	{
		prop: "total",
		label: "Amount",
		minimumGridVisibility: "xs",
		// eslint-disable-next-line react/display-name
		formatter: cell => <Amount value={cell} />
	},
	{
		prop: "status",
		label: "status",
		minimumGridVisibility: "xs",
		// eslint-disable-next-line react/display-name
		formatter: (_ignore, row) => <OrderStatusBadge order={row} />
	}
];

export default schema;

import moment from "moment-timezone";
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const getDay = day => days[day % 7];

const calculateSchedule = ({ cronExpression, intervalSettings, timeZone }) => {
	const schedule = {};
	if (!cronExpression) {
		schedule.type = "none";
		return;
	}
	const cronRegex = new RegExp(/^(.+)[ ](.+)[ ](.+)[ ](.+)[ ](.+)/);
	const matches = cronExpression.match(cronRegex);

	if (typeof matches[1] !== "*" && typeof matches[2] !== "*") { // eslint-disable-line valid-typeof
		// schedule is in UTC so we need to convert to mechants timezone
		const scheduleTime = moment.tz(timeZone).hour(matches[2]).minute(matches[1]);

		schedule.hour = scheduleTime.hour();
		schedule.minute = scheduleTime.minute();
		schedule.timeText = scheduleTime.format("HH:mm");
	}
	if (matches[3] === "*" && matches[4] === "*" && matches[5] === "*") {
		schedule.type = "daily";
		schedule.text = "Daily";
		if (intervalSettings.from?.useDateTime && intervalSettings.to?.useDateTime) {
			schedule.description = `yesterday ${schedule.timeText} to today ${schedule.timeText}`;
		} else if (intervalSettings.from?.useDateTime) {
			schedule.description = `yesterday ${schedule.timeText} to today 00:00}`;
		} else if (intervalSettings.to?.useDateTime && intervalSettings.useToDateAsFromDate) {
			schedule.description = `today 00:00 to ${schedule.timeText}`;
		} else if (intervalSettings.to?.useDateTime) {
			schedule.description = `yesterday 00:00 to today ${schedule.timeText}`;
		} else if (intervalSettings.useToDateAsFromDate) {
			schedule.type = "today";
			schedule.description = `today 00:00 to ${schedule.timeText}`;
		} else {
			schedule.description = `yesterday 00:00 to today 00:00`;
		}
	}
	if (matches[3] === "*" && matches[4] === "*" && matches[5] !== "*") {
		schedule.day = getDay(matches[5]);
		schedule.dayNumber = matches[5];
		schedule.type = "weekly";
		schedule.text = "Weekly";
		schedule.description = `${schedule.day} 00:00 to next ${schedule.day} 00:00`;
		if (intervalSettings.from?.useDateTime && intervalSettings.to?.useDateTime) {
			schedule.description = `${schedule.day} ${schedule.timeText} to next ${schedule.day} ${schedule.timeText}`;
		} else if (intervalSettings.from?.useDateTime) {
			schedule.description = `${schedule.day} ${schedule.timeText} to next ${schedule.day} 00:00)`;
		} else if (intervalSettings.to?.useDateTime) {
			schedule.description = `${schedule.day} 00:00 to next ${schedule.day} ${schedule.timeText}`;
		}
	}
	if (matches[3] !== "*" && matches[4] === "*" && matches[5] === "*") {
		schedule.type = "monthly";
		schedule.text = "Monthly";
		schedule.description = `first day of month 00:00 to next first day of month 00:00`;

		if (intervalSettings.from?.useDateTime && intervalSettings.to?.useDateTime) {
			schedule.description = `first day of month ${schedule.timeText} to next first day of month ${schedule.timeText}`;
		} else if (intervalSettings.from?.useDateTime) {
			schedule.description = `first day of month ${schedule.timeText} to next first day of quarter 00:00`;
		} else if (intervalSettings.to?.useDateTime) {
			schedule.description = `first day of month 00:00 to next first day of month ${schedule.timeText}`;
		}
	}
	if (matches[3] === "1" && matches[4] === "1/3" && matches[5] === "*") {
		schedule.type = "quarterly";
		schedule.text = "Quarterly";
		schedule.description = `first day of quarter 00:00 to next first day of quarter 00:00`;
		if (intervalSettings.from?.useDateTime && intervalSettings.to?.useDateTime) {
			schedule.description = `first day of quarter ${schedule.timeText} to next first day of quarter ${schedule.timeText}`;
		} else if (intervalSettings.from?.useDateTime) {
			schedule.description = `first day of quarter ${schedule.timeText} to next first day of quarter 00:00`;
		} else if (intervalSettings.to?.useDateTime) {
			schedule.description = `first day of quarter 00:00 to next first day of quarter ${schedule.timeText}`;
		}
	}
	if (intervalSettings.to?.useDateTime) {
		schedule.IncludeEventsUntilScheduleTime = true;
	}
	if (intervalSettings.useToDateAsFromDate) {
		schedule.useToDateAsFromDate = true;
	}

	return schedule;
};

const getScheduleTypes = () => {
	return [
		{ type: "none", text: "Choose schedule" },
		{ type: "daily", text: "Daily" },
		{ type: "weekly", text: "Weekly" },
		{ type: "monthly", text: "Monthly" },
		{ type: "quarterly", text: "Quarterly" }
	];
};
const getScheduleDays = () => {
	return [
		{ day: 1, text: "Monday" },
		{ day: 2, text: "Tuesday" },
		{ day: 3, text: "Wednesday" },
		{ day: 4, text: "Thursday" },
		{ day: 5, text: "Friday" },
		{ day: 6, text: "Saturday" },
		{ day: 7, text: "Sunday" }
	];
};

export { calculateSchedule, getScheduleTypes, getScheduleDays };

export default (text, data) => {
	if (!text) return;
	if (typeof text !== "string") return text;
	const re = new RegExp(/\{([a-zA-Z0-9]+)\}/, "g");
	const matches = text.matchAll(re);
	if (matches)
		for (const match of matches) {
			if (data && data[match[1]]) text = text.replace(match[0], data[match[1]]);
		}
	return text;
};

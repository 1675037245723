import React from "react";
import styled from "styled-components";
import { map } from "ramda";
import { displayOn, device } from "styles/device";
import { Container as CoreContainer } from "core/components";
import Item from "./Item";
import items from "./items";

const Container = styled(CoreContainer)`
	width: 100%;
	align-self: center;

	${displayOn({ min: device.tabletLarge })}
`;

const Items = styled.div`
	margin-bottom: 4px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #eee;
`;

const MainNav = () => {
	const renderItem = i => <Item item={i} key={i.label} />;

	const renderItems = map(renderItem);

	return (
		<Container>
			<Items>{renderItems(items)}</Items>
		</Container>
	);
};

export default MainNav;

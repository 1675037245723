import React, { useState } from "react";
import { Loader, ToggleSwitch } from "core/components";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";
import { httpClient } from "data";
import { useMerchantContext } from "context";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import ActivationForm from "./ActivationForm";

const Container = styled.div`
	align-items: baseline;
	margin-top: ${p => p.theme.space.medium};
	align-self: flex-start;
`;

const Status = ({
	integrationSettings,
	setIntegrationSettings,
	refresh,
	exportFromDate,
	oldIntegrationSettings,
	setExportFromDate
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const { currentMerchant } = useMerchantContext();
	const [alertMessageTop, setAlertMessageTop] = useState();
	const [alertTypeTop, setAlertTypeTop] = useState("danger");
	const [showAlertTop, setShowAlertTop] = useState(false);

	const handleFortnoxEnableChange = async enabled => {
		if (
			enabled &&
			[
				oldIntegrationSettings.accountChecking,
				oldIntegrationSettings.accountCheckingSwish,
				oldIntegrationSettings.accountReceivableStripe,
				oldIntegrationSettings.accountSales6,
				oldIntegrationSettings.accountSales12,
				oldIntegrationSettings.accountSales25,
				oldIntegrationSettings.accountVat6,
				oldIntegrationSettings.accountVat12,
				oldIntegrationSettings.accountVat25,
				oldIntegrationSettings.accountTransactionFee,
				oldIntegrationSettings.accountVatIncoming
			].includes(null)
		) {
			setAlertMessageTop(
				"All bookkeeping accounts must be configured to enable Fortnox export"
			);
			setAlertTypeTop("danger");
			setShowAlertTop(true);
			return { success: false };
		}
		setAlertMessageTop();
		setAlertTypeTop();
		setShowAlertTop(false);

		setIsLoading(true);
		return httpClient
			.post("/reporting/fortnox-merchant/toggle-export", {
				body: {
					exportEnabled: enabled,
					exportFromDate: exportFromDate
						? moment(exportFromDate).format("YYYY-MM-DD")
						: null
				}
			})
			.then(r => {
				if (!r || r?.error) {
					setAlertMessageTop(
						`Could not ${enabled ? "enable" : "disable"} Fortnox export.`
					);
					setAlertTypeTop("danger");
					setShowAlertTop(true);
					return { success: false };
				} else {
					setIntegrationSettings({
						...integrationSettings,
						exportEnabled: enabled,
						nextExportDate: null
					});
					setIsLoading(false);
					new Promise(() =>
						setTimeout(() => {
							const { stripeId: stripeAccount } = currentMerchant;

							return httpClient
								.put("/reporting/fortnox-merchant/", { body: { stripeAccount } })
								.then(setIntegrationSettings);
						}, 5000)
					);

					setAlertMessageTop();
					setAlertTypeTop();
					setShowAlertTop(false);
				}
				refresh();
			});
	};

	const renderContent = () => (
		<>
			<div style={{ display: "flex", alignItems: "flex-start" }}>
				<div
					style={{
						marginTop: 8,
						marginRight: 8,
						width: 260,
						height: 50,
						display: "flex"
					}}
				>
					Export sales data to Fortnox:
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						fontWeight: 500,
						color: integrationSettings.exportEnabled ? "#41ce84" : "#ff735e"
					}}
				>
					<ToggleSwitch
						disabled={alertTypeTop === "warning"}
						value={integrationSettings.exportEnabled}
						handleChange={handleFortnoxEnableChange}
					/>

					{isLoading && (
						<div style={{ marginLeft: 16 }}>
							<Loader size="small" />
						</div>
					)}
				</div>
			</div>
			<div style={{ display: "flex", alignItems: "flex-baseline" }}>
				<div
					style={{
						marginRight: 8,
						width: 260,
						height: 50
					}}
				>
					Next export:
				</div>
				<div
					style={{
						display: "flex",
						fontWeight: 500,
						...(!integrationSettings?.nextExportDate && {
							color: "#999"
						})
					}}
				>
					{integrationSettings.exportEnabled
						? integrationSettings?.nextExportDate
							? moment(integrationSettings?.nextExportDate)
									.tz(integrationSettings?.timeZone)
									.format("YYYY-MM-DD")
							: "Calculating..."
						: "Unavaliabe"}
				</div>
			</div>
			<div style={{ display: "flex", alignItems: "flex-baseline" }}>
				<div
					style={{
						marginRight: 8,
						width: 260,
						height: 50
					}}
				>
					Last export:
				</div>
				<div
					style={{
						display: "flex",
						fontWeight: 500,
						...(!integrationSettings?.lastExportDate && {
							color: "#999"
						})
					}}
				>
					{integrationSettings?.lastExportDate
						? moment(integrationSettings?.lastExportDate)
								.tz(integrationSettings?.timeZone)
								.format("YYYY-MM-DD")
						: "Unavaliabe"}
				</div>
			</div>
			<div style={{ display: "flex" }}>
				<div
					style={{
						marginRight: 8,
						width: 260,
						display: "flex",
						alignItems: "flex-end",
						marginBottom: 8
					}}
				>
					<div>
						<div>Start import from:</div>
						<div style={{ fontSize: 12, color: "#666" }}>
							Note that if your Passbuy account has been connected to Fortnox
							previously, already exported orders will not be exported again.
						</div>
					</div>
				</div>
				<div>
					<ReactDatePicker
						className="datePicker"
						selected={exportFromDate}
						disabled={integrationSettings?.exportEnabled === true}
						isClearable={integrationSettings?.exportEnabled === false}
						onChange={e => {
							setExportFromDate(e);
						}}
						dateFormat="MMMM d, yyyy"
					/>
				</div>
			</div>
		</>
	);

	return (
		<Container>
			<Alert
				variant={alertTypeTop}
				show={showAlertTop}
				onClose={() => setShowAlertTop(false)}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					{alertMessageTop}
				</div>
			</Alert>

			{!integrationSettings?.fortnoxActivated && (
				<ActivationForm
					{...{
						refresh,
						integrationSettings,
						setIntegrationSettings,
						setAlertMessageTop,
						setAlertTypeTop,
						setShowAlertTop
					}}
				/>
			)}

			{integrationSettings?.fortnoxActivated && renderContent()}
		</Container>
	);
};

export default Status;

/* eslint-disable react/display-name */
import fallbackProductImage from "assets/fallback-product-image.svg";
import Amount from "core/components/Amount";
import allowEmpty from "core/components/Grid/Validation/allow-empty";
import between from "core/components/Grid/Validation/between";
import required from "core/components/Grid/Validation/required";
import React from "react";
import getExternalProductSyncSchema from "./get-external-product-sync-schema";
import getProductGroupSchema from "./get-product-group-schema";
import getStationSchema from "./get-station-schema";

export default ({ merchant, store, stations, productGroups }) => [
	{
		prop: "imageUrl",
		type: "image",
		label: null,
		sort: false,
		minimumGridVisibility: "sm",
		width: 50,
		formatter: (cell, row) => {
			const src = row?.imageUrl || fallbackProductImage;
			return <img style={{ width: "30px", height: "30px" }} src={src} alt="row" />;
		},
		hideEditOnMobile: false,
		editable: false
	},
	{
		prop: "name",
		type: "text",
		width: 350,
		label: "Name",
		minimumGridVisibility: "xs",
		sort: true,
		editable: false,
		validate: [required("Name is required")]
	},
	{
		prop: "description",
		type: "text",
		label: "Description",
		sort: true,
		editable: false
	},
	{
		prop: "price",
		type: "number",
		formatter: price => <Amount value={price} />,
		minimumGridVisibility: "xs",
		label: "Price",
		sort: true,
		width: 80,
		editable: false,
		editStyle: { width: 150 },
		group: "pricerow",
		groupSortOrder: 1,
		validate: [allowEmpty(between(0, 9999999, "Price must be a positive number"))],
		visible: () => !merchant?.externalProductSync
	},
	{
		prop: "barcodes",
		type: "barcodes",
		label: "Barcodes",
		columnVisible: false,
		visible: () => store?.type === "STORE"
	},
	...getStationSchema(stations),
	...getProductGroupSchema({ merchant, productGroups }),
	...getExternalProductSyncSchema(merchant)
];

import { httpClient } from "data";
import urlcat from "urlcat";

const orderRepo = {
	getOrders: ({ storeId, orderType, page, pageSize, sortField, sortOrder, search }) =>
		httpClient.get(
			urlcat("/orders", { storeId, orderType, page, pageSize, sortField, sortOrder, search })
		),
	getOrder: ({ storeId, orderId }) => httpClient.get(urlcat(`/orders/${orderId}`, { storeId }))
};

export default orderRepo;

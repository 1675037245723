import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

import {
	StyledRowContainer,
	ActivatedContainer,
	Title,
	Icons,
	Icon,
	Description,
	AutomaticClosingHourTextContainer,
	StyledButton
} from "./Status.styles";

// eslint-disable-next-line react/display-name
const AutomaticClosingHourButton = forwardRef(({ value, onClick, isUpdating }, ref) => (
	<div ref={ref}>
		<StyledButton isLoading={isUpdating} variant="primary" onClick={onClick}>
			{value}
		</StyledButton>
	</div>
));

const ActivatedStatus = ({ cashRegister, updateAutomaticClosingHour, isUpdating, isLoading }) => {
	const selectedAutomaticClosingHour = cashRegister?.automaticClosingHour
		? moment().hour(cashRegister.automaticClosingHour).minute(0).toDate()
		: null;

	const handleAutomaticClosingHourChange = e => {
		updateAutomaticClosingHour(Number(moment(e).format("HH")));
	};
	return (
		<>
			<ActivatedContainer>
				<Title>Activated</Title>
				<Icons>
					<Icon icon={faCheckCircle} />
				</Icons>
			</ActivatedContainer>
			<StyledRowContainer>
				<AutomaticClosingHourTextContainer>
					<Title>Automatic closing hour</Title>
					<Description>
						Closes the day and generates a Z report at the specified hour.
					</Description>
				</AutomaticClosingHourTextContainer>
				<ReactDatePicker
					className="timePickerMenu"
					selected={selectedAutomaticClosingHour}
					onChange={handleAutomaticClosingHourChange}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={60}
					timeCaption="Change hour"
					timeFormat="HH"
					dateFormat="HH"
					customInput={
						<AutomaticClosingHourButton isUpdating={isUpdating || isLoading} />
					}
				/>
			</StyledRowContainer>
		</>
	);
};

export default ActivatedStatus;

const brandColors = {
	emeraldGreen: "#41CE84",
	purpleStreet: "#8E72F9",
	grannySmithGreen: "#A0E695",
	bittersweetRed: "#FF735E",
	goldenSand: "#FFC54A",
	black: "#000000",
	dark: "#1D1D1D",
	midGray: "#666666",
	lightGray: "#999999",
	softGray: "#EEEEEE",
	dirtyWhite: "#F6F6F6",
	white: "#FFFFFF"
};

export default brandColors;

import React, { useMemo } from "react";
import { always, equals, T, cond } from "ramda";
import { compose } from "ramda";
import { BtnPrimary, BtnDanger, BtnDefault } from "./Button.styles";
import mapSizeToBootstrapSize from "./map-size-to-bootstrap-size";

const Variant = ({ variant, size, ...props }) => {
	const bootstrapSize = useMemo(() => mapSizeToBootstrapSize(size), [size]);
	const btnProps = useMemo(
		() => ({
			...props,
			size: bootstrapSize,
			$size: size
		}),
		[size, bootstrapSize, props]
	);

	return compose(
		Btn => <Btn {...btnProps} />,
		cond([
			[equals("primary"), always(BtnPrimary)],
			[equals("danger"), always(BtnDanger)],
			[equals("default"), always(BtnDefault)],
			[T, always(BtnDefault)]
		])
	)(variant);
};

export default Variant;

import React, { useRef, useState } from "react";
import { not } from "ramda";
import styled from "styled-components";
import SignOutButton from "./SignOutButton";
import Dropdown from "../Dropdown";
import AccountButton from "./AccountButton";
import CurrentUser from "./CurrentUser";
import { device, displayOn } from "styles/device";

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	${displayOn({ min: device.tabletLarge })}
`;

const DropdownContainer = styled.div`
	min-width: 220px;
`;

const Account = () => {
	const buttonRef = useRef();
	const [isOpen, setIsOpen] = useState();

	const toggleDropdown = () => setIsOpen(not);

	const closeDropdown = () => setIsOpen(false);

	return (
		<Container>
			<AccountButton onClick={toggleDropdown} ref={buttonRef} />

			<Dropdown isOpen={isOpen} buttonRef={buttonRef} onClose={closeDropdown} right>
				<DropdownContainer>
					<CurrentUser />
					<SignOutButton />
				</DropdownContainer>
			</Dropdown>
		</Container>
	);
};

export default Account;

import { Loader } from "core/components";
import React from "react";

const GeneratingReportLoader = () => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "flex-end"
			}}
			data-test="reports-generating-report-loader"
		>
			<div
				style={{
					fontSize: 12,
					fontWeight: 500
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ marginRight: 8, display: "flex", alignItems: "center" }}>
						<Loader size="small" color="green" />
					</div>
					Generating...
				</div>
			</div>
		</div>
	);
};

export default GeneratingReportLoader;

import { curry, defaultTo, compose, sortBy, prop, head } from "ramda";
import findMerchantById from "./find-merchant-by-id";

const resolveCurrentMerchant = curry((merchants, merchantId) =>
	compose(
		defaultTo(compose(head, sortBy(prop("name")))(merchants)),
		findMerchantById(merchants)
	)(merchantId)
);

export default resolveCurrentMerchant;

import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { useSwitch } from "core/utils";
import { Button, Modal } from "core/components";
import styled from "styled-components";
import { httpClient } from "data";
import moment from "moment-timezone";

const BodyContainer = styled.div`
	padding: ${p => p.theme.space.small} 0;
`;

const DatepickerContainer = styled.div`
	display: flex;
	margin-left: ${p => p.theme.space.small};
	margin-bottom: ${p => p.theme.space.large};
	align-items: center;

	&:last-child {
		margin-bottom: 0;
	}
`;

const DateLabel = styled.div`
	font-weight: 500;
	width: 60px;
`;

const SIEFileModal = ({ show, onSubmit, onCancel, timeZone }) => {
	const toDateRef = useRef();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch(false);
	const [showAlertError, setShowAlertError] = useState(false);
	const [alertErrorMessage, setAlertErrorMessage] = useState();

	const setError = error => {
		setAlertErrorMessage(error);
		setShowAlertError(true);
	};

	const clearError = () => {
		setShowAlertError(false);
		setAlertErrorMessage(null);
	};

	const handleSubmit = async () => {
		if (!startDate) {
			setError("From-date must be selected");
			return;
		}
		if (!endDate) {
			setError("To-date must be selected");
			return;
		}
		clearError();

		try {
			startLoading();

			const success = await httpClient.post("/reporting/sie-merchant/files/create", {
				body: {
					fromDate: moment
						.tz(startDate, timeZone)
						.hour(0)
						.minute(0)
						.format("yyyy-MM-DD HH:mm"),
					toDate: moment
						.tz(endDate, timeZone)
						.hour(0)
						.minute(0)
						.format("yyyy-MM-DD HH:mm"),
					timeZone: timeZone
				}
			});

			if (!success) {
				setError("Something when wrong");
				return;
			}

			onSubmit();
		} catch {
			setError("Something when wrong");
		} finally {
			stopLoading();
		}
	};

	const Body = () => (
		<BodyContainer>
			<Alert variant="danger" show={showAlertError} onClose={clearError} dismissible>
				{alertErrorMessage}
			</Alert>

			<DatepickerContainer>
				<DateLabel>From</DateLabel>
				<DatePicker
					className="datePicker"
					selected={startDate}
					onChange={setStartDate}
					dateFormat="MMMM d, yyyy"
					autoComplete="off"
				/>
			</DatepickerContainer>

			<DatepickerContainer>
				<DateLabel>To</DateLabel>
				<DatePicker
					ref={toDateRef}
					className="datePicker"
					selected={endDate}
					dateFormat="MMMM d, yyyy"
					onChange={setEndDate}
					autoComplete="off"
				/>
			</DatepickerContainer>
		</BodyContainer>
	);

	const Footer = () => (
		<>
			<Button icon={faUndo} onClick={onCancel} size="large" collapse={false}>
				Cancel
			</Button>

			<Button
				variant="primary"
				icon={faSave}
				isLoading={isLoading}
				onClick={handleSubmit}
				size="large"
				collapse={false}
			>
				Create
			</Button>
		</>
	);

	return (
		<Modal
			title="Create SIE file"
			show={show}
			onHide={onCancel}
			body={<Body />}
			footer={<Footer />}
		/>
	);
};
export default SIEFileModal;

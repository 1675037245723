import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import getCheckinGroupSchema from "../get-checkin-group-schema";
import QRModal from "./QRModal";
import { find, eqProps } from "ramda";

const getTableActions = ({ hasFunctionAccess, merchant, storeId, checkinGroups }) => {
	return {
		customActions: [
			{
				// eslint-disable-next-line react/display-name
				context: ({ rowData }) => <QRModal {...{ rowData, checkinGroups }} />,
				visible: () => true
			}
		],
		...(hasFunctionAccess("checkin_edit") && {
			delete: !merchant?.externalProductSync && {
				apiPath: "/checkins/{checkinId}",
				apiMethod: "DELETE",
				message: "Do you really want to delete {checkinLabelPrefix} {label}?",
				customRow: rowData => ({
					...rowData,
					...find(eqProps("checkinGroupId", rowData), checkinGroups)
				})
			},
			edit: {
				inline: true,
				modal: true,
				modalTitle: "Edit QR code",
				apiPath: "/checkins/{checkinId}",
				apiMethod: "PATCH"
			},
			top: [
				{
					type: "edit",
					hideOnEmptySelect: false,
					disabledOnEmptySelect: true,
					selectedKeyProp: "checkinIds",
					schema: [...getCheckinGroupSchema({ merchant, checkinGroups })],
					buttonProps: {
						label: "Change group",
						icon: faArrowRight
					},
					modalTitle: `Change group`,
					modalMessage: `Change group for the selected $selectedCount$ QR codes`,
					inline: true,
					modal: true,
					apiPath: `/checkins/?storeId=${storeId}`,
					apiMethod: "PATCH"
				},
				{
					type: "delete",
					hideOnEmptySelect: false,
					disabledOnEmptySelect: true,
					selectedKeyProp: "checkinIds",
					schema: [],
					buttonProps: {
						label: "Delete"
					},
					modalTitle: `Delete`,
					modalMessage: `Do you want to delete the selected $selectedCount$ QR codes?`,
					inline: true,
					modal: true,
					apiPath: `/checkins/delete/?storeId=${storeId}`,
					apiMethod: "POST"
				}
			]
		})
	};
};

export default getTableActions;

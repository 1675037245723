import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { ConfirmationModal } from "core/components";
import { GridModal } from "core/components/Grid";
import { useSwitch } from "core/utils";
import { menuRepo, productRepo } from "data/repos";
import getTableSchema from "products/components/ProductTable/get-table-schema";
import React, { useCallback, useState } from "react";
import Item from "./Item";
import useItem from "./use-item";

const pickEditProductProperties = item => ["name", "description", "price"].includes(item?.prop);

const MenuProduct = ({ item, menuId, menuSectionId, onDelete, onProductChange }) => {
	const { currentMerchant, currentStore, currentPriceListId } = useMerchantContext();

	const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);
	const [isDeleteLoading, startDeleteLoading, stopDeleteLoading] = useSwitch(false);
	const [editProductModalVisible, setEditProductModalVisible] = useState(false);
	const [editProductErrorMessage, setEditProductErrorMessage] = useState();

	const productTableSchema = getTableSchema({ currentMerchant });
	const editProductModalSchema = productTableSchema.filter(pickEditProductProperties);

	const setProductUnavailable = useCallback(
		async product => {
			const productId = item.product.productId;

			await menuRepo.editProduct({ menuId, menuSectionId, productId, product });
			await onProductChange();
		},
		[menuId, menuSectionId, item, onProductChange]
	);

	const deleteProduct = useCallback(async () => {
		startDeleteLoading();
		const productId = item.product.productId;
		await menuRepo.removeProduct({ menuId, menuSectionId, productId });
		await onDelete(productId, menuSectionId);
		stopDeleteLoading();
	}, [menuId, menuSectionId, item, onDelete, startDeleteLoading, stopDeleteLoading]);

	const saveProduct = useCallback(
		async product => {
			const response = await productRepo.setProduct({
				storeId: currentStore.storeId,
				priceListId: currentPriceListId,
				productId: item.product.productId,
				product
			});
			if (response.code)
				return setEditProductErrorMessage(response.message || "Could not save changes");

			setEditProductErrorMessage(undefined);
			await onProductChange();
			setEditProductModalVisible(false);
		},
		[
			setEditProductErrorMessage,
			item,
			currentStore,
			currentPriceListId,
			setEditProductModalVisible,
			onProductChange
		]
	);

	const { itemChoices, unavailableUntil, isItemUnavailableUntilInFuture } = useItem({
		item,
		setProductUnavailable,
		setEditProductModalVisible,
		setDeleteConfirmationModalVisible
	});

	return (
		<>
			<Item
				item={item}
				itemChoices={itemChoices}
				unavailableUntil={unavailableUntil}
				isItemUnavailableUntilInFuture={isItemUnavailableUntilInFuture}
			/>

			<ConfirmationModal
				title="Remove from menu"
				text={`Are you sure you want to remove "${item.product.name}" from this menu?`}
				show={deleteConfirmationModalVisible}
				onConfirm={deleteProduct}
				onCancel={() => setDeleteConfirmationModalVisible(false)}
				confirmButtonProps={{
					label: "Remove",
					icon: faTrashAlt,
					variant: "danger",
					isLoading: isDeleteLoading
				}}
			/>

			<GridModal
				schema={editProductModalSchema}
				row={{ ...item.product, price: item.price }}
				title="Edit"
				show={editProductModalVisible}
				onSave={saveProduct}
				onHide={() => setEditProductModalVisible(false)}
				errorMessage={editProductErrorMessage}
			/>
		</>
	);
};

export default MenuProduct;

import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "core/components";
import { Form } from "react-bootstrap";

const StyledButton = styled(Button)`
	width: 48px;
`;

const StyledFormGroup = styled(Form.Group)`
	margin-bottom: 0px;
`;

const StyledFormLabel = styled(Form.Label)`
	font-weight: 500;
	margin-bottom: 0.2rem;
`;

const StyledRow = styled.div`
	margin-bottom: ${p => (p.lastRow ? "0" : "1rem")};
	margin-left: 0;
	margin-right: ${p => (p.addMargin ? "64px" : "0")};
`;

const StyledRowContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: top;
`;

const Container = styled.div`
	background-color: ${p => p.theme.color.dirtyWhite};
	border-radius: 12px;
	border: 1px solid ${p => p.theme.color.border.primary};
	padding: ${p => p.theme.space.large};
	margin-bottom: ${p => p.theme.space.large};
	justify-content: space-between;

	@media ${mediaQuery(device.tablet)} {
		padding: 22px;
	}
`;

const ActivatedContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
`;

const AutomaticClosingHourTextContainer = styled.div`
	display: grid;
`;
const Title = styled.span`
	font-size: 16px;
	font-family: Peak;
	line-height: 1.2;

	@media ${mediaQuery(device.tablet)} {
		font-size: 18px;
	}
`;

const Icons = styled.div`
	display: flex;
	align-items: center;
	flex-shrink: 1;
	padding-left: 32px;
`;

const Icon = styled(FontAwesomeIcon)`
	font-size: 16px;
	margin-right: 12px;
	color: ${p => p.theme.color.emeraldGreen};

	&:last-child {
		margin-right: 0;
	}
`;

const Description = styled(Form.Label)`
	font-weight: 400;
	margin-bottom: ${p => p.theme.space.small};
	margin-top: ${p => p.theme.space.mini};
	color: ${p => p.theme.color.text.muted};
	font-size: 13.5px;
	margin-right: ${p => p.theme.space.large};
`;

export {
	StyledButton,
	StyledFormGroup,
	StyledFormLabel,
	StyledRow,
	ActivatedContainer,
	StyledRowContainer,
	Container,
	Title,
	Icons,
	Icon,
	Description,
	AutomaticClosingHourTextContainer
};

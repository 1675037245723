import { Card } from "auth/components";
import { useUserContext } from "context/UserContext";
import React, { useCallback, useState } from "react";
import { Alert } from "react-bootstrap";
import { Button } from "core/components";
import Form from "react-bootstrap/Form";
import Success from "./Success";
import styled from "styled-components";
import PasswordStrengthBar from "react-password-strength-bar";
import { welcomeTokenRepo } from "data/repos";
import { useHistory } from "react-router-dom";
import { Email, ExplainerText, Heading } from "./Welcome.styles";

const PasswordStrength = styled(PasswordStrengthBar)`
	p {
		text-align: left !important;
	}
`;

const ActivateForm = ({ token, email }) => {
	const { signIn } = useUserContext();
	const history = useHistory();
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState();
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState();

	const handleSubmit = useCallback(async () => {
		setLoading(true);
		welcomeTokenRepo
			.activate(token, { password })
			.then(response => {
				if (response.success) {
					setSuccess(true);

					setTimeout(
						() => signIn({ email, password }).then(() => history.push("/")),
						3000
					);
				} else {
					if (response.error) setErrorMessage(response.error);
					setSuccess(false);
				}
			})
			.catch(error => {
				console.warn(error.message);
				setErrorMessage(error.message);
			})
			.finally(() => setLoading(false));
	}, [email, password, setLoading, setErrorMessage, signIn, history, token]);

	if (success) return <Success />;

	return (
		<Card size="huge">
			<Form
				onSubmit={e => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				<>
					<Form.Group>
						<Heading>Welcome to Passbuy! You&apos;re almost done.</Heading>
						<ExplainerText>
							To be able to log in to Passbuy dashboard you need to set a password for
							your account.
						</ExplainerText>
					</Form.Group>

					<Form.Group>
						<Email>{email}</Email>
					</Form.Group>

					<Form.Group>
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							autoFocus={true}
							value={password}
							onChange={e => setPassword(e?.target?.value)}
						/>
						{password.length > 0 && (
							<PasswordStrength minLength={6} password={password} />
						)}
					</Form.Group>

					<Form.Group>
						{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
					</Form.Group>
					<Form.Group>
						<Button isLoading={loading} type="submit" size="large">
							Set password
						</Button>
					</Form.Group>
				</>
			</Form>
		</Card>
	);
};

export default ActivateForm;

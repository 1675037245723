import React from "react";
import styled from "styled-components";
import { faSave, faUndo, faPen } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import RemoveAction from "./RemoveAction";
import { Button } from "core/components";

const Container = styled(Card.Footer)`
	display: flex;
	justify-content: space-between;
`;

const Right = styled.div`
	display: flex;
	align-items: center;

	& > * {
		margin-left: ${p => p.theme.space.medium};
	}
`;

const Footer = ({ isNew, editMode, handleSave, handleCancel, onConfirmRemove, onEditMode }) => {
	return (
		<Container>
			{editMode && !isNew ? <RemoveAction onRemove={onConfirmRemove} /> : <div />}

			<Right>
				{!editMode && (
					<Button icon={faPen} onClick={onEditMode}>
						Edit
					</Button>
				)}

				{editMode && (
					<>
						<Button icon={faUndo} onClick={handleCancel}>
							Cancel
						</Button>

						<Button icon={faSave} onClick={handleSave} variant="primary">
							Save
						</Button>
					</>
				)}
			</Right>
		</Container>
	);
};

export default Footer;

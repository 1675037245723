import required from "core/components/Grid/Validation/required";
import { constantCaseToTitleCase } from "core/utils";

const mapDeliveryMethodToLabel = deliveryMethod => constantCaseToTitleCase(deliveryMethod);
const mapAppTypeToLabel = appType => constantCaseToTitleCase(appType);

export default ({ currentStore }) => [
	{
		prop: "name",
		type: "text",
		minimumGridVisibility: "xs",
		width: 350,
		label: "Name",
		sort: true,
		editable: false,
		validate: [required("Name is missing")]
	},
	{
		prop: "checkinLabelPrefix",
		hideOnMobile: true,
		type: "text",
		width: 350,
		label: "Label prefix",
		description: "Label prefix is shown in front of the QR code label",
		sort: true,
		editable: false,
		validate: [required("Label prefix is missing")]
	},
	{
		type: "divider"
	},
	{
		prop: "apps",
		label: "Apps",
		type: "sortableOptions",
		keyProp: "type",
		sortable: true,
		options: currentStore?.availableApps.map(app => ({
			key: app.type,
			label: mapDeliveryMethodToLabel(app.type)
		})),
		columnVisible: false,
		validate: [required("No app is selected")]
	},
	{
		prop: "deliveryMethods",
		label: "Delivery Methods",
		type: "sortableOptions",
		sortable: true,
		keyProp: "deliveryMethod",
		options: currentStore?.availableDeliveryMethods.map(dm => ({
			key: dm.deliveryMethod,
			label: mapAppTypeToLabel(dm.deliveryMethod)
		})),
		columnVisible: false
	},
	{
		prop: "checkinsCount",
		type: "text",
		width: 150,
		label: "Number of QR codes",
		sort: true,
		editable: false,
		visible: () => false
	},
	{
		type: "divider"
	},
	{
		prop: "active",
		type: "switch",
		minimumGridVisibility: "xs",
		width: 150,
		label: "Active",
		sort: true,
		editable: false
	}
];

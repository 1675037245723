import { always, cond, equals, T } from "ramda";
import React from "react";
import { Container, Dots } from "./Loader.styles";
import theme from "styles/theme";
import useDelay from "./use-delay";

const spinnerColor = cond([
	[equals("green"), always(theme.color.emeraldGreen)],
	[equals("white"), always(theme.color.white)],
	[T, always(theme.color.lightGray)]
]);

const Loader = ({ size, fadeIn, padding, center, delay, color }) => {
	const isVisible = useDelay(delay);

	if (!isVisible) {
		return null;
	}

	return (
		<Container {...{ size, padding, center }}>
			<Dots size={size} name="three-bounce" color={spinnerColor(color)} fadeIn={fadeIn} />
		</Container>
	);
};

Loader.defaultProps = {
	size: "medium",
	fadeIn: "none",
	delay: false
};

export default Loader;

import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import functionsMultipartFormCall from "core/utils/functions-multipart-form-call";
import { assocPath, path } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";
import Modal from "../Modal";
import FormItem from "./FormItem";

const StyledRow = styled(Row)`
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
`;
const StyledMessage = styled.div`
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
`;

const GridModal = ({
	schema = [],
	title,
	row,
	show = false,
	errorMessage,
	message,
	onSave = async () => {},
	onHide = async () => {}
}) => {
	const [showPossibleError, setShowPossibleError] = useState(true);
	const [saving, setSaving] = useState(false);
	const [validated, setValidated] = useState(false);
	const [editRow, setEditRow] = useState({});

	const resetForm = useCallback(() => {
		setEditRow(row || {});
		setSaving(false);
		setValidated(false);
		setShowPossibleError(true);
	}, [row]);

	const isFormItemVisible = useCallback((editRow, item) => {
		if (typeof item.visible === "function") {
			return item.visible(editRow);
		}

		return true;
	}, []);

	useEffect(() => {
		if (row) setEditRow(row);
	}, [row]);

	const validateItem = useCallback(
		item => {
			if (!isFormItemVisible(editRow, item)) return null;
			if (typeof item.validate !== "function" && !Array.isArray(item.validate)) return null;

			if (typeof item.validate === "function") {
				return item.validate(item.prop.split(/\./), editRow);
			}
			if (Array.isArray(item.validate)) {
				for (const val of item.validate) {
					if (typeof val !== "function") continue;
					const validationMessage = val(item.prop.split(/\./), editRow);
					if (validationMessage) return validationMessage;
				}
			}
		},
		[editRow, isFormItemVisible]
	);

	const uploadPossibleImages = async editRow => {
		for (const key in editRow) {
			const value = editRow[key];

			// Check if value is a png blob, in that case upload the image
			if (typeof value === "string" && /^blob:/.test(value)) {
				const blob = await fetch(value).then(response => response.blob());
				if (blob.type === "image/png") {
					const { publicUrl } = await functionsMultipartFormCall("/uploadPhoto", blob);

					editRow[key] = publicUrl;
				}
			}
		}

		return editRow;
	};

	const handleSave = useCallback(async () => {
		const valid = schema.map(validateItem).filter(msg => msg);

		if (valid.length > 0) {
			setValidated(true);
			return;
		}

		setSaving(true);
		setShowPossibleError(true);
		await onSave(await uploadPossibleImages(editRow));
		setSaving(false);
	}, [editRow, onSave, schema, validateItem]);

	const ErrorMessage = ({ show, message, setShow }) => {
		return show && errorMessage ? (
			<Alert
				style={{ marginBottom: 0, borderRadius: 0 }}
				variant="danger"
				onClose={() => setShow(false)}
				dismissible
			>
				{message}
			</Alert>
		) : (
			<></>
		);
	};

	const getValues = (editRow, values) => {
		if (typeof values === "function") {
			return values(editRow);
		}

		return values;
	};

	const set = useCallback(
		(prop, cEr, val) => {
			const er = assocPath(prop.split(/\./), val, cEr);

			for (const s of schema.filter(s => s.prop !== prop && s.depends === prop)) {
				if (s.values) {
					const vals = getValues(er, s.values);
					const v = vals[0];
					set(s.prop, er, v);
					return;
				}
			}

			setEditRow(er);
			return er;
		},
		[schema]
	);

	const renderItem = useCallback(
		(item, i, arr) => {
			if (item.group && item.groupSortOrder !== 1) return null;

			if (!isFormItemVisible(editRow, item)) {
				return null;
			}

			if (item.type === "divider") {
				return <hr className="row" key={i} />;
			}

			const renderFormItem = itemToRender => {
				const handleFormItemChange = (value, otherProps) => {
					let e = set(itemToRender.prop, editRow, value);

					if (otherProps) {
						for (const op of Object.entries(otherProps)) {
							e = set(op[0], e, op[1]);
						}
					}
				};

				const value = path(itemToRender.prop.split(/\./), editRow);
				const valid = validateItem(itemToRender);

				// Set default value false for switch if it is undefined
				if (itemToRender.type === "switch" && value === undefined)
					set(itemToRender.prop, editRow, false);

				return (
					<FormItem
						item={itemToRender}
						invalidMessage={valid}
						validated={validated}
						row={editRow}
						value={value}
						onChange={handleFormItemChange}
					/>
				);
			};

			return (
				<StyledRow md="auto" xl="auto" lg="auto" key={item.prop}>
					<Col style={item.containerStyle}>{renderFormItem(item)}</Col>
					{arr
						.filter(i => i.group && i.group === item.group && i.groupSortOrder >= 2)
						.filter(i => isFormItemVisible(editRow, i))
						.map(i => (
							<Col key={i.prop}>{renderFormItem(i)}</Col>
						))}
				</StyledRow>
			);
		},
		[editRow, set, validateItem, validated, isFormItemVisible]
	);

	const body = useMemo(
		() => (
			<>
				<ErrorMessage
					show={showPossibleError}
					setShow={setShowPossibleError}
					message={errorMessage}
				/>
				{message && <StyledMessage>{message}</StyledMessage>}
				{schema &&
					[...schema]
						.sort((a, b) => (a.modalSortOrder < b.modalSortOrder ? -1 : 1))
						.map(renderItem)}
			</>
		),
		[schema, renderItem, showPossibleError, errorMessage, message]
	);

	const footer = useMemo(
		() => (
			<>
				<Button icon={faUndo} onClick={onHide} size="large" collapse={false}>
					Cancel
				</Button>

				<Button
					variant="primary"
					icon={faSave}
					isLoading={saving}
					onClick={handleSave}
					size="large"
					collapse={false}
				>
					Save
				</Button>
			</>
		),
		[onHide, handleSave, saving]
	);

	return <Modal {...{ title, show, body, footer, onHide }} onExited={resetForm} />;
};

export default GridModal;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { Modal, PageLayout, SectionHeader, SectionLoader, Button } from "core/components";
import { useSwitch } from "core/utils";
import { menuRepo } from "data/repos";
import React, { useCallback, useEffect, useState } from "react";
import MenuCard from "./MenuCard/MenuCard";
import SaveMenuForm from "./SaveMenuForm";

const MenusOverview = () => {
	const { hasFunctionAccess, currentStore } = useMerchantContext();
	const [menus, setMenus] = useState([]);
	const [isModalOpen, openModal, closeModal] = useSwitch(false);
	const [loadingMenus, setLoadingMenus] = useState(false);

	const refresh = useCallback(
		async ({ showSpinner = false }) => {
			if (showSpinner) setLoadingMenus(true);

			const menus = await menuRepo.getMenus(currentStore.storeId);

			setMenus(menus);
			setLoadingMenus(false);
			closeModal();
		},
		[currentStore, closeModal]
	);

	useEffect(() => {
		refresh({ showSpinner: true });
	}, [refresh]);

	return (
		<PageLayout>
			<div style={{ flexGrow: 1 }}>
				<SectionHeader heading="Menus">
					{hasFunctionAccess("menu_edit") && (
						<Button onClick={openModal} icon={faPlus} variant="primary">
							New menu
						</Button>
					)}
				</SectionHeader>

				{loadingMenus ? (
					<SectionLoader />
				) : (
					<div style={{ maxWidth: 650 }}>
						{menus.map(menu => (
							<MenuCard
								key={menu?.menuId}
								menu={menu}
								onDelete={refresh}
								onSave={refresh}
							/>
						))}
					</div>
				)}
			</div>

			<Modal title="Add menu" show={isModalOpen} onHide={closeModal}>
				<SaveMenuForm
					onSave={() => refresh({ showSpinner: false })}
					onCancel={closeModal}
				/>
			</Modal>
		</PageLayout>
	);
};

export default MenusOverview;

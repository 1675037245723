import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import K from "../k";

const Container = styled.div`
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	box-shadow: 0 3px 10px rgba(0 0 0 / 0.15);
	height: ${p => p.height + "px"};
`;

const ContainerInner = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
`;

const Graph = ({ from, to, height = 210, contentHeight = 100, children, title, value }) => {
	return (
		<Container height={height}>
			<ContainerInner>
				<Row>
					<Col>
						<div style={{ fontSize: "16px" }}>{title}</div>
						{typeof value === "number" && (
							<div style={{ fontSize: "20px", fontWeight: "500" }}>{K(value)}</div>
						)}
						{from && (
							<div style={{ fontSize: "13px", marginBottom: "15px" }}>
								{from} - {to}
							</div>
						)}
					</Col>
				</Row>
			</ContainerInner>

			<div style={{ marginBottom: "10px", height: contentHeight }}>{children}</div>
		</Container>
	);
};

export default Graph;

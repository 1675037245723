import { useUserContext } from "context";
import { useSwitch } from "core/utils";
import { httpClient } from "data";
import { empty } from "ramda";
import { useEffect, useState } from "react";

const useMerchants = () => {
	const { user } = useUserContext();
	const [merchants, setMerchants] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch(true);

	useEffect(() => {
		if (!user) {
			setMerchants(empty);
			return;
		}

		startLoading();
		httpClient
			.get("/merchants", { merchantId: undefined })
			.then(setMerchants)
			.finally(stopLoading);
	}, [user, startLoading, stopLoading]);

	return [merchants, isLoading];
};

export default useMerchants;

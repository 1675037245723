import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Button } from "core/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";

const Icon = styled(FontAwesomeIcon)`
	margin-right: 8px;
`;

const ChangeStationButton = () => {
	const history = useHistory();

	const onClick = () => history.push("/stations");

	return (
		<Button onClick={onClick} data-test="change-station-button">
			<Icon icon={faRandom} />
			Change station
		</Button>
	);
};

export default ChangeStationButton;

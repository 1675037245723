import React, { useEffect, useState } from "react";
import { parse } from "query-string";
import ResetPasswordForm from "./ResetPasswordForm";
import Expired from "./Expired";
import { useUserContext } from "context/UserContext";
import { PageContainer } from "auth/components";

const ResetPasswordPage = ({ location }) => {
	const { oobCode } = parse(location.search);
	const { verifyPasswordResetCode } = useUserContext();
	const [email, setEmail] = useState();
	const [expired, setExpired] = useState(false);

	if (!oobCode) throw new Error("Incorrect query param oobCode");

	useEffect(() => {
		try {
			verifyPasswordResetCode(oobCode)
				.then(email => setEmail(email))
				.catch(() => setExpired(true));
		} catch (err) {
			setExpired(true);
		}
	}, [oobCode, setEmail, verifyPasswordResetCode]);

	return (
		<PageContainer>
			{email && <ResetPasswordForm email={email} actionCode={oobCode} />}
			{expired && <Expired />}
		</PageContainer>
	);
};

export default ResetPasswordPage;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "styles/theme";
import GlobalCSS from "styles/global.css";
import { UserProvider, MerchantProvider } from "context";

const Providers = ({ children }) => (
	<ThemeProvider theme={theme}>
		<GlobalCSS />

		<UserProvider>
			<MerchantProvider>
				<Router>{children}</Router>
			</MerchantProvider>
		</UserProvider>
	</ThemeProvider>
);

export default Providers;

import { useEffect } from "react";
import subscribeToOrderTickets from "./subscribe-to-order-tickets";

const useOrderTicketSubscription = ({
	stationId,
	statuses,
	limit = 50,
	orderBy,
	orderDirection,
	callback,
	onError
}) => {
	useEffect(() => {
		if (!stationId) {
			return;
		}

		if (!Array.isArray(statuses) || statuses.length === 0) {
			return;
		}

		try {
			const unsubscribe = subscribeToOrderTickets({
				callback,
				stationId,
				statuses,
				limit,
				orderBy,
				orderDirection
			});

			return () => unsubscribe();
		} catch (error) {
			onError(error);
		}
	}, [stationId, statuses, limit, callback, onError, orderBy, orderDirection]);
};

export default useOrderTicketSubscription;

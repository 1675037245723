import { useEffect, useState } from "react";
import { empty } from "ramda";
import { httpClient } from "data";
import { useSwitch } from "core/utils";
import { useUserContext } from "context";
import { logEvent } from "core/utils";

const useStores = merchant => {
	const { user } = useUserContext();
	const [stores, setStores] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch(true);

	useEffect(() => {
		if (!user) {
			setStores(empty);
			return;
		}

		if (!merchant) {
			setStores(empty);
			return;
		}

		logEvent("merchant_loaded", merchant);

		startLoading();
		httpClient
			.get("/stores", { merchantId: merchant.merchantId })
			.then(setStores)
			.finally(stopLoading);
	}, [user, merchant, startLoading, stopLoading]);

	return [stores, isLoading];
};

export default useStores;

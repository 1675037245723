import { removeByProp, updateByProp } from "core/utils";
import { append, assoc, compose, cond, dissoc, equals, forEach, pluck, prop, reject } from "ramda";
import { useCallback, useEffect, useRef, useState } from "react";
import sortOrderTickets from "./sort-order-tickets";
import useOrderTicketSubscription from "./use-order-ticket-subscription";

const useOrderTickets = ({
	stationId,
	statuses,
	limit = 50,
	orderBy = "timeCreated",
	orderDirection = "asc"
}) => {
	const [orderTickets, setOrderTickets] = useState([]);
	const [resultOrderTickets, setResultOrderTickets] = useState([]);
	const isInitializedRef = useRef(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState();

	useEffect(() => {
		compose(
			setResultOrderTickets,
			sortOrderTickets(orderBy, orderDirection),
			reject(ot => ot.eagerStatus && !statuses.includes(ot.eagerStatus)),
			reject(prop("isEagerRemoved"))
		)(orderTickets);
	}, [orderTickets, orderBy, orderDirection, statuses]);

	const initialize = useCallback(events => {
		const orderTickets = pluck("orderTicket", events);
		setOrderTickets(orderTickets);
		isInitializedRef.current = true;
		setIsLoading(false);
	}, []);

	const addTicket = useCallback(t => {
		const orderTicketWithFlag = assoc("isAdded", true, t);
		setOrderTickets(append(orderTicketWithFlag));
	}, []);

	const modifyTicket = useCallback(t => setOrderTickets(updateByProp("orderTicketId", t)), []);
	const removeTicket = useCallback(t => setOrderTickets(removeByProp("orderTicketId", t)), []);

	const eagerRemoveTicket = useCallback(
		t => compose(modifyTicket, assoc("isEagerRemoved", true))(t),
		[modifyTicket]
	);

	const cancelEagerRemoveTicket = useCallback(
		t => compose(modifyTicket, dissoc("isEagerRemoved"))(t),
		[modifyTicket]
	);

	const eagerSetStatus = useCallback(
		(t, status) => compose(modifyTicket, assoc("eagerStatus", status))(t),
		[modifyTicket]
	);

	const cancelEagerStatus = useCallback(
		t => compose(modifyTicket, dissoc("eagerStatus"))(t),
		[modifyTicket]
	);

	const applyChangeEvent = useCallback(
		e =>
			cond([
				[equals("added"), () => addTicket(e.orderTicket)],
				[equals("modified"), () => modifyTicket(e.orderTicket)],
				[equals("removed"), () => removeTicket(e.orderTicket)]
			])(e.type),
		[addTicket, modifyTicket, removeTicket]
	);

	const applyChangeEvents = useCallback(
		events => forEach(applyChangeEvent, events),
		[applyChangeEvent]
	);

	const callback = useCallback(
		events => (isInitializedRef.current ? applyChangeEvents(events) : initialize(events)),
		[initialize, applyChangeEvents]
	);

	useOrderTicketSubscription({
		stationId,
		statuses,
		limit,
		callback,
		onError: setError,
		orderBy,
		orderDirection
	});

	return {
		orderTickets: resultOrderTickets,
		isLoading,
		error,
		eagerRemoveTicket,
		cancelEagerRemoveTicket,
		eagerSetStatus,
		cancelEagerStatus
	};
};

export default useOrderTickets;

import { path, compose, defaultTo } from "ramda";
import React from "react";
import styled from "styled-components";
import fallbackStoreImage from "assets/fallback-store-image.png";

const Container = styled.div`
	display: flex;
	justify-content: left;
	align-content: center;
	align-items: center;
	padding: 8px;
	width: 100%;

	&:hover {
		cursor: pointer;
		background-color: #eee;
	}
`;

const Image = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 32px;
`;

const Name = styled.div`
	font-weight: 500;
	margin-left: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
	padding-right: 8px;
`;

const Store = ({ store, onSelect }) => {
	const { name } = store;
	const imageSrc = compose(defaultTo(fallbackStoreImage), path(["image", "small"]))(store);

	const handleClick = () => onSelect(store);

	return (
		<Container onClick={handleClick} data-test="store-switcher-store">
			<Image src={imageSrc} alt={name} />
			<Name> {name} </Name>
		</Container>
	);
};

Store.defaultProps = {
	onSelect: () => {}
};

export default Store;

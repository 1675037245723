import styled from "styled-components";

export const ToolbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
	display: flex;
`;

export const ActionsContainer = styled.div`
	display: flex;
`;

export const ZoomSlider = styled.input`
	display: flex;
	margin-right: 10px;
`;

export const CropContainer = styled.div`
	position: relative;
	width: 100%;
	height: 300px;
	background: "#333";
	margin-bottom: 10px;
`;

import { faCheck, faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { propOr } from "ramda";
import React from "react";
import styled from "styled-components";
const { OverlayTrigger, Popover, Table } = require("react-bootstrap");

const SuccessIcon = styled(FontAwesomeIcon)`
	margin-top: ${p => p.theme.space.small};
	color: ${p => p.theme.color.emeraldGreen};
`;

const WarningIcon = styled(FontAwesomeIcon)`
	margin-top: ${p => (p.inline ? 0 : p.theme.space.small)};
	color: ${p => p.theme.color.goldenSand};
`;

const WarningText = styled.div`
	font-size: 13px;
	color: ${p => p.theme.color.text.muted};
`;

const MutedTable = styled(Table)`
	font-size: 13px;
	color: ${p => p.theme.color.text.muted};
	&:last-child {
		margin-bottom: 0;
	}
`;

const ErrorIcon = styled(FontAwesomeIcon)`
	margin-top: ${p => p.theme.space.small};
	color: ${p => p.theme.color.bittersweetRed};
`;

const Header = styled.div`
	font-weight: bold;
	margin-bottom: ${p => p.theme.space.small};
`;

const IconContainer = styled.div`
	cursor: pointer;
`;

const StyledPopover = styled(Popover)`
	max-width: 1000px;
`;

const Updates = ({ updates }) => (
	<>
		<Header>The import succeeded with the following updates:</Header>
		<MutedTable size="sm">
			<tbody>
				<tr>
					<td>Created product groups:</td>
					<td>{updates.createdProductGroups}</td>
				</tr>
				<tr>
					<td>Updated product groups:</td>
					<td>{updates.updatedProductGroups}</td>
				</tr>
				<tr>
					<td>Removed product groups:</td>
					<td>{updates.deletedProductGroups}</td>
				</tr>
				<tr>
					<td>Created products:</td>
					<td>{updates.createdProducts}</td>
				</tr>
				<tr>
					<td>Updated products:</td>
					<td>{updates.updatedProducts}</td>
				</tr>
				<tr>
					<td>Removed products:</td>
					<td>{updates.deletedProducts}</td>
				</tr>
			</tbody>
		</MutedTable>
	</>
);

const MaxWarningLimit = 10;
const Warnings = ({ warnings }) => (
	<>
		<Header>The import succeeded with the following issues:</Header>
		<MutedTable size="sm">
			<tbody>
				{warnings.slice(0, MaxWarningLimit).map(warning => (
					<tr key={warning}>
						<td>
							<WarningIcon
								inline
								icon={faExclamationTriangle}
								style={{ marginRight: 4 }}
							/>{" "}
						</td>
						<td>
							<WarningText>{warning}</WarningText>
						</td>
					</tr>
				))}
				{warnings.length > MaxWarningLimit && (
					<tr>
						<td>+ {warnings.length - MaxWarningLimit} more</td>
					</tr>
				)}
			</tbody>
		</MutedTable>
	</>
);

const Icon = ({ productImport }) => {
	const warnings = propOr([], "warnings", productImport);

	if (productImport.timeError) return <ErrorIcon icon={faTimes} />;

	if (productImport.timeCompleted && warnings.length > 0)
		return <WarningIcon icon={faExclamationTriangle} />;

	if (productImport.timeCompleted) return <SuccessIcon icon={faCheck} />;

	if (productImport.timeCompleted) return null;
};

const ProductImportPopover = ({ productImport }) => {
	const warnings = propOr([], "warnings", productImport);

	return (
		<OverlayTrigger
			rootClose
			trigger="click"
			placement="bottom"
			overlay={
				<StyledPopover>
					<Popover.Title as="h3">Import status</Popover.Title>
					<Popover.Content>
						{productImport.timeError &&
							`The product import was unsuccessful. Please contact Passbuy for help.`}
						{productImport.timeCompleted && (
							<Updates updates={productImport.response} />
						)}
						{warnings.length > 0 && <Warnings warnings={warnings} />}
					</Popover.Content>
				</StyledPopover>
			}
		>
			<IconContainer>
				<Icon productImport={productImport} />
			</IconContainer>
		</OverlayTrigger>
	);
};

export default ProductImportPopover;

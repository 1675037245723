import EventItemsColumn from "./EventItemsColumn";

const gridSchema = ({ selectedGrouping, makePrinterFriendly }) => [
	...(selectedGrouping?.checkin
		? [
				{
					prop: "checkinLabel",
					type: "text",
					label: "Seat",
					minimumGridVisibility: "xs",
					width: 150
				}
		  ]
		: []),
	{
		prop: "items",
		type: "text",
		label: "Items",
		minimumGridVisibility: "xs",

		formatter: cell => EventItemsColumn(cell, makePrinterFriendly)
	}
];

export default gridSchema;

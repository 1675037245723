import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { reportingRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useZReports = format => {
	const [zReports, setZReports] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch(true);

	const { currentStore } = useMerchantContext();

	const refresh = useCallback(
		(args = { loader: true }) => {
			if (!currentStore) return;
			args?.loader && startLoading();
			reportingRepo
				.getZReports({ storeId: currentStore.storeId, format })
				.then(r => {
					setZReports(r);
				})
				.finally(stopLoading());
		},
		[currentStore, startLoading, stopLoading, format]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [zReports, isLoading, refresh];
};

export default useZReports;

import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import ConfirmationModal from "../ConfirmationModal";
import { useSwitch } from "core/utils";
import replacePlaceholderTagsFromData from "core/utils/replace-placeholder-tags-from-data";

const GridInfoModal = ({ row, body, title, buttonProps, disabled, confirmButtonProps }) => {
	const [isOpen, open, close] = useSwitch(false);

	const {
		label: buttonLabel,
		icon: buttonIcon,
		size: buttonSize,
		variant: buttonVariant
	} = buttonProps;

	return (
		<>
			<Button
				icon={buttonIcon || faCheck}
				disabled={disabled}
				onClick={open}
				size={buttonSize || "small"}
				variant={buttonVariant}
			>
				{buttonLabel}
			</Button>
			<ConfirmationModal
				onlyConfirm
				title={replacePlaceholderTagsFromData(title, row)}
				text={replacePlaceholderTagsFromData(body, row)}
				onConfirm={close}
				show={isOpen}
				onCancel={close}
				confirmButtonProps={confirmButtonProps}
			/>
		</>
	);
};

export default GridInfoModal;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "styles/theme";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const Checkmark = ({ value }) => {
	return (
		<FontAwesomeIcon
			icon={value ? faCheck : faTimes}
			color={value ? theme.color.emeraldGreen : theme.color.bittersweetRed}
		/>
	);
};

export default Checkmark;

import { multiply } from "ramda";

export default (timeWaited, alert, alertDelay) => {
	if (!alert || !alertDelay) {
		return false;
	}

	const delay = multiply(60, alertDelay);
	const isLate = timeWaited >= delay;

	return isLate;
};

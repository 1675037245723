import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "core/components";
import { poll, useSwitch } from "core/utils";
import React from "react";
import CreateReportModal from "./CreateReportModal";

const CreateReportButton = ({ reportFunctions, refresh, timeZone }) => {
	const [isModal, openModal, closeModal] = useSwitch();

	const handleModalClose = () => {
		closeModal();
		poll(1000, 8, () => refresh({ loader: false }));
	};

	return (
		<>
			<Button
				variant="primary"
				icon={faPlus}
				onClick={openModal}
				data-test="reports-create-button"
			>
				New report
			</Button>

			<CreateReportModal
				isModal={isModal}
				onClose={handleModalClose}
				{...{ reportFunctions, timeZone, refresh }}
			/>
		</>
	);
};

export default CreateReportButton;

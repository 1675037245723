import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
import { Button } from "core/components";
import styled from "styled-components";

const IconContainer = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 2px;
	padding-right: 2px;
`;

const FullScreenButton = ({ style, onClick, isFullScreen }) => {
	const icon = isFullScreen ? faCompress : faExpand;

	return (
		<Button onClick={onClick} style={style}>
			<IconContainer>
				<FontAwesomeIcon style={s.icon} icon={icon} />
			</IconContainer>
		</Button>
	);
};

FullScreenButton.defaultProps = {
	style: {}
};

export default FullScreenButton;

const s = {
	icon: {
		cursor: "pointer"
	}
};

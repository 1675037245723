import moment from "moment-timezone";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	min-width: 140px;
`;

const TimeContainer = styled.div`
	display: flex;
	flex-wrap: no-wrap;
	margin-right: 4px;
`;

const DateContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	color: ${p => p.theme.color.lightGray};
	font-size: 14px;
`;

const DateTimeColumn = ({ cell, timeZone }) => (
	<Container>
		<TimeContainer>{moment.tz(cell, timeZone).format("HH:mm")}</TimeContainer>
		<DateContainer>({moment.tz(cell, timeZone).format("YYYY-MM-DD")})</DateContainer>
	</Container>
);

export default DateTimeColumn;

import React from "react";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Item from "./Item";
import { useSwitch } from "core/utils";
import SignOutModal from "../SignOutModal";

const SignOutItem = () => {
	const [isModal, openModal, closeModal] = useSwitch(false);

	const signOutItem = {
		label: "Sign Out",
		icon: faSignOutAlt,
		onClick: openModal
	};

	return (
		<>
			<Item item={signOutItem} />
			<SignOutModal isOpen={isModal} onClose={closeModal} />
		</>
	);
};

export default SignOutItem;

import React from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSwitch } from "core/utils";
import { Button, ConfirmationModal } from "core/components";

const RemoveAction = ({ onRemove }) => {
	const [showModal, openModal, closeModal] = useSwitch();
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const handleConfirm = async () => {
		startLoading();
		await onRemove();
		closeModal();
		stopLoading();
	};

	return (
		<>
			<Button icon={faTrashAlt} variant="danger" onClick={openModal}>
				Delete
			</Button>

			<ConfirmationModal
				title="Remove schedule"
				text="Are you sure you want to remove this schedule?"
				show={showModal}
				onConfirm={handleConfirm}
				onCancel={closeModal}
				confirmButtonProps={{
					label: "Delete",
					icon: faTrashAlt,
					variant: "danger",
					isLoading
				}}
			/>
		</>
	);
};

export default RemoveAction;

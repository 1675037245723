import { Button, SectionHeader, TabContainer } from "core/components";
import React, { useCallback, useMemo, useState } from "react";
import Container from "./Container";
import Status from "./Status";
import XReport from "./XReport";
import ZReports from "./ZReports";
import useXReport from "./use-x-report";
import { faPrint, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import useCashRegister from "./use-cash-register";
import useZReports from "./use-z-reports";

const ControlUnit = () => {
	const [
		cashRegister,
		setCashRegister,
		isCashRegisterLoading,
		refreshCashRegister,
		updateAutomaticClosingHour,
		isCashRegisterUpdating,
		activateCashRegister,
		cashRegisterError
	] = useCashRegister();

	const [xReport, isXReportLoading, refreshXReport] = useXReport({ type: "text" });
	const [zReports, isZReportsLoading, refreshZReports] = useZReports();

	let printRef;

	const handleActivateCashRegister = useCallback(async () => {
		await activateCashRegister();
		refreshXReport();
	}, [activateCashRegister, refreshXReport]);

	const tabs = useMemo(
		() => [
			{
				id: "status",
				label: "Status",
				content: (
					<Status
						cashRegister={cashRegister}
						setCashRegister={setCashRegister}
						isLoading={isCashRegisterLoading}
						refresh={refreshCashRegister}
						updateAutomaticClosingHour={updateAutomaticClosingHour}
						isUpdating={isCashRegisterUpdating}
						activate={handleActivateCashRegister}
						error={cashRegisterError}
					/>
				)
			},
			...(cashRegister?.timeActivated
				? [
						{
							id: "xreport",
							label: "X Report",
							content: (
								<XReport xReport={xReport} isXReportLoading={isXReportLoading} />
							)
						},
						{
							id: "zreports",
							label: "Z Reports",
							content: (
								<ZReports
									zReports={zReports}
									isLoading={isZReportsLoading}
									refresh={refreshZReports}
								/>
							)
						}
				  ]
				: [])
		],
		[
			xReport,
			isXReportLoading,
			cashRegister,
			cashRegisterError,
			isCashRegisterLoading,
			isCashRegisterUpdating,
			handleActivateCashRegister,
			isZReportsLoading,
			refreshCashRegister,
			refreshZReports,
			setCashRegister,
			updateAutomaticClosingHour,
			zReports
		]
	);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	return (
		<Container>
			<SectionHeader
				heading="Control unit"
				tabs={tabs}
				activeTab={activeTab}
				onTabSelected={setActiveTab}
			>
				{activeTab.id === "xreport" && (
					<>
						<ReactToPrint
							pageStyle={`@page { margin: 10mm; }`}
							trigger={() => <Button icon={faPrint} />}
							content={() => printRef}
						/>
						<div style={{ display: "none" }}>
							<pre style={{ border: "none" }} ref={el => (printRef = el)}>
								{xReport}
							</pre>
						</div>
					</>
				)}
				{activeTab.id === "xreport" && <Button icon={faSyncAlt} onClick={refreshXReport} />}
			</SectionHeader>
			<TabContainer tabs={tabs} activeTab={activeTab} />
		</Container>
	);
};

export default ControlUnit;

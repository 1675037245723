import React from "react";
import { httpClient } from "data";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import ActionButton from "./ActionButton";
import papaparse from "papaparse";

const downloadReport = async reportRow => {
	const report = await httpClient.get(`/reporting/reports/${reportRow.reportId}`);
	const fromDate = moment(reportRow.reportFunctionParameters.fromDate).format("YYYY-MM-DD");
	const toDate = moment(reportRow.reportFunctionParameters.toDate)
		.subtract(1, "day")
		.format("YYYY-MM-DD");

	const fileName =
		reportRow.name
			.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
			.map(x => x.toLowerCase())
			.join("_") +
		"_" +
		(fromDate === toDate ? fromDate : fromDate + "_" + toDate) +
		".csv";

	papaparse.parse(report.data, {
		header: true,
		dynamicTyping: true,
		skipEmptyLines: true,
		transformHeader: header => {
			const h = header.replace(/([_])/g, " ").toLowerCase();
			return h.charAt(0).toUpperCase() + h.slice(1);
		},
		complete: parsedData => {
			const reparsed = papaparse.unparse(parsedData, {
				quotes: false,
				quoteChar: '"',
				escapeChar: '"',
				delimiter: ";",
				header: true,
				newline: "\r\n",
				skipEmptyLines: true
			});

			const data = new Blob(["\ufeff", reparsed], { type: "text/csv" });
			const csvURL = window.URL.createObjectURL(data);
			const tempLink = document.createElement("a");
			tempLink.href = csvURL;
			tempLink.setAttribute("download", fileName);
			tempLink.click();
		}
	});
};

const DownloadAction = ({ data }) => {
	const handleClick = () => downloadReport(data);

	return (
		<ActionButton onClick={handleClick} icon={faDownload}>
			Download
		</ActionButton>
	);
};

export default DownloadAction;

import React from "react";
import styled from "styled-components";
import { Container as CoreContainer } from "core/components";
import Header from "../Header";

const Content = styled(CoreContainer)`
	padding-top: 8px;
	padding-bottom: 8px;
	margin: 0 auto;
`;

const PageLayout = ({ children }) => {
	return (
		<div>
			<Header />
			<Content>{children}</Content>
		</div>
	);
};

export default PageLayout;

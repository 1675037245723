import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// eslint-disable-next-line react/no-render-return-value
const renderApp = Component => ReactDOM.render(<Component />, document.getElementById("root"));

renderApp(App);

if (module.hot) {
	module.hot.accept("./App", () => renderApp(require("./App").default));
}

import { useMerchantContext } from "context";
import { Grid, GridCreate, SectionHeader, SectionLoader } from "core/components";
import { useSwitch } from "core/utils";
import { checkinGroupRepo, checkinRepo } from "data/repos";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import urlcat from "urlcat";
import getTableActions from "./get-table-actions";
import getTableSchema from "./get-table-schema";

const Container = styled.div`
	flex-direction: column;
	flex-grow: 1;
`;

const GridContainer = styled.div`
	margin-top: 12px;
`;

const CheckinTable = () => {
	const { currentStore, currentMerchant, hasFunctionAccess } = useMerchantContext();
	const [gridSchema, setGridSchema] = useState(null);
	const [createSchema, setCreateSchema] = useState(null);

	const [actions, setActions] = useState(null);
	const [checkins, setCheckins] = useState([]);
	const [totalCheckinCount, setTotalCheckinCount] = useState(0);
	const [sizePerPage, setSizePerPage] = useState(30);
	const [page, setPage] = useState(1);
	const [isFetching, startFetching, stopFetching] = useSwitch();
	const isLoading = !gridSchema || !createSchema || !actions;

	const refreshCheckins = useCallback(
		async ({ sortField, sortOrder, search, setFetching = true } = {}) => {
			if (setFetching) startFetching();
			const merchantCheckins = await checkinRepo.getCheckins({
				storeId: currentStore.storeId,
				page,
				pageSize: sizePerPage,
				search,
				sortField,
				sortOrder
			});
			setTotalCheckinCount(merchantCheckins?.totalCheckinCount);
			setCheckins(merchantCheckins?.checkins || []);

			if (setFetching) stopFetching();
		},
		[currentStore.storeId, page, sizePerPage, startFetching, stopFetching]
	);

	const refreshSchema = useCallback(
		async ({ setFetching = true }) => {
			if (setFetching) startFetching();
			const checkinGroups = await checkinGroupRepo.getCheckinGroups({
				storeId: currentStore.storeId
			});

			setGridSchema(
				getTableSchema({
					source: "edit",
					merchant: currentMerchant,
					store: currentStore,
					checkinGroups: checkinGroups.checkinGroups
				})
			);
			setCreateSchema(
				getTableSchema({
					source: "create",
					merchant: currentMerchant,
					store: currentStore,
					checkinGroups: checkinGroups.checkinGroups
				})
			);
			setActions(
				getTableActions({
					hasFunctionAccess,
					merchant: currentMerchant,
					checkinGroups: checkinGroups.checkinGroups,
					storeId: currentStore.storeId
				})
			);
			if (setFetching) stopFetching();
		},
		[currentMerchant, currentStore, hasFunctionAccess, startFetching, stopFetching]
	);

	const refreshAll = useCallback(async () => {
		startFetching();
		refreshSchema({ setFetching: false })
			.then(() => refreshCheckins({ setFetching: false }))
			.finally(stopFetching);
	}, [refreshSchema, refreshCheckins, startFetching, stopFetching]);

	useEffect(() => {
		refreshAll();
	}, [refreshAll]);

	const renderGrid = () => (
		<GridContainer>
			<Grid
				isFetching={isFetching}
				actions={actions}
				schema={gridSchema}
				tableKey="checkinId"
				data={checkins}
				reload={refreshCheckins}
				hideGridCreate={true}
				noDataText="No QR-codes available"
				selection
				remote={{
					search: true,
					pagination: true,
					sort: true
				}}
				pagination
				remotePagination
				remotePaginationOptions={{
					page,
					setPage,
					setSizePerPage,
					sizePerPage,
					totalSize: totalCheckinCount
				}}
				search
			/>
		</GridContainer>
	);

	return (
		<Container>
			<SectionHeader heading="QR Codes">
				{!currentMerchant.externalProductSync && hasFunctionAccess("checkin_edit") && (
					<GridCreate
						buttonProps={{
							label: "New QR code",
							variant: "primary"
						}}
						row={{ active: true }}
						title="New QR code"
						schema={createSchema}
						onChange={refreshCheckins}
						apiPath={urlcat("/checkins", {
							storeId: currentStore.storeId
						})}
						apiMethod="POST"
					/>
				)}
			</SectionHeader>

			{isLoading && <SectionLoader />}
			{!isLoading && renderGrid()}
		</Container>
	);
};

export default CheckinTable;

import React from "react";
import { Form } from "react-bootstrap";

import { StyledRow, StyledFormGroup, StyledFormLabel } from "./Status.styles";

const FormItem = ({ label, property, cashRegister, edit, lastRow, onChange, children }) => {
	const handleOnChange = e => {
		onChange(property, e.target.value);
	};

	return (
		<StyledRow addMargin={!edit} lastRow={lastRow} key={property}>
			<StyledFormGroup>
				<div>
					<StyledFormLabel>{label || children}</StyledFormLabel>
				</div>
				{edit && (
					<Form.Control
						defaultValue={cashRegister[property]}
						onChange={handleOnChange}
						size="sm"
						type="text"
						required={true}
					/>
				)}
				{!edit && <div>{cashRegister[property]}</div>}
			</StyledFormGroup>
		</StyledRow>
	);
};
export default FormItem;

import React from "react";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";

const Component = ({ style, tabs, activeTab, children }) => {
	const paneStyle = t => (t.id === activeTab.id ? s.pane : {});

	const renderTabPanes = () =>
		tabs.map(t => (
			<TabPane style={paneStyle(t)} id={t.id} eventKey={t.id} key={t.id}>
				{t.content}
			</TabPane>
		));

	if (!activeTab) {
		return null;
	}

	return (
		<div style={style}>
			<TabContainer activeKey={activeTab.id} transition={false}>
				{children}
				<TabContent style={s.content}>{renderTabPanes()}</TabContent>
			</TabContainer>
		</div>
	);
};

Component.displayName = "TabContainer";
Component.defaultProps = {
	style: {},
	tabs: [],
	activeTab: {}
};

export default Component;

const s = {
	content: {
		display: "flex",
		width: "100%"
	},
	pane: {
		display: "flex",
		width: "100%"
	}
};

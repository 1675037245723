import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useSwitch } from "core/utils";
import { httpClient } from "data";
import React, { useCallback, useState } from "react";
import Button from "../Button";
import GridModal from "./GridModal";

const GridEdit = ({
	onChange,
	title,
	buttonProps,
	schema,
	row,
	apiPath,
	apiMethod,
	disabled,
	message
}) => {
	const [show, open, close] = useSwitch(false);
	const [errorMessage, setErrorMessage] = useState();
	const {
		label: buttonLabel,
		icon: buttonIcon,
		size: buttonSize,
		variant: buttonVariant
	} = buttonProps;

	const onSave = useCallback(
		async data => {
			const response = await httpClient.fetch(apiPath, {
				method: apiMethod,
				body: data
			});
			if (response.code) {
				setErrorMessage(response.message || "Could not save changes");
				return;
			} else {
				setErrorMessage(undefined);
				close();
				await onChange();
			}
		},
		[apiPath, apiMethod, onChange, close]
	);

	return (
		<>
			<Button
				onClick={open}
				disabled={disabled}
				icon={buttonIcon || faPen}
				size={buttonSize || "small"}
				variant={buttonVariant}
			>
				{buttonLabel}
			</Button>

			<GridModal
				{...{ schema, row, title, show, errorMessage, onSave, message }}
				onHide={close}
			/>
		</>
	);
};

GridEdit.defaultProps = {
	buttonProps: {}
};

export default GridEdit;

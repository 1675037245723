import React, { useRef, useState } from "react";
import { useMerchantContext } from "context";
import styled from "styled-components";
import MerchantSwitcher from "../MerchantSwitcher";
import Stores from "./Stores";
import Dropdown from "../Dropdown";
import CurrentStore from "./CurrentStore";
import useIsDisabled from "./use-is-disabled";
import { device, mediaQuery } from "styles/device";
import { LoadingModal } from "core/components";
import { useSwitch } from "core/utils";

const Container = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	padding-right: 20px;
	min-width: 0;
`;

const DropdownContainer = styled.div`
	min-width: 250px;
	max-width: 280px;
	overflow: hidden;

	@media ${mediaQuery(device.mobileMedium)} {
		max-width: 320px;
	}

	@media ${mediaQuery(device.mobileLarge)} {
		max-width: 360px;
	}

	@media ${mediaQuery(device.tablet)} {
		max-width: 100%;
	}
`;

const StoreSwitcher = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isLoadingModal, openModal, closeModal] = useSwitch(false);
	const currentStoreRef = useRef();
	const isDisabled = useIsDisabled();
	const { currentStore } = useMerchantContext();

	const toggleDropdown = () => setIsOpen(v => !v);

	const closeDropdown = () => setIsOpen(false);

	const handleStoreSelect = () => closeDropdown();

	const handleMerchantSelect = () => closeDropdown();

	const handleClick = () => {
		if (isDisabled) {
			return;
		}

		toggleDropdown();
	};

	if (!currentStore) {
		return null;
	}

	return (
		<Container>
			<CurrentStore
				store={currentStore}
				isDisabled={isDisabled}
				onClick={handleClick}
				ref={currentStoreRef}
			/>

			<Dropdown isOpen={isOpen} onClose={closeDropdown} buttonRef={currentStoreRef}>
				<DropdownContainer data-test="store-switcher-dropdown">
					<Stores onSelect={handleStoreSelect} />

					<MerchantSwitcher
						onSelect={handleMerchantSelect}
						onLoading={openModal}
						onLoadingEnd={closeModal}
					/>
				</DropdownContainer>
			</Dropdown>

			<LoadingModal label="Switching" isOpen={isLoadingModal} />
		</Container>
	);
};

export default StoreSwitcher;

import { curry, path } from "ramda";

export default curry((min, max, msg, propPath, row) => {
	const value = path(propPath, row);

	if (value === null) return msg;
	if (typeof value === "undefined") return msg;
	if (value >= min && value <= max) return null;

	return msg;
});

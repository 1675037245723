import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { eventRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useReports = ({ eventId }) => {
	const [event, setEvent] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const {
		currentStore: { storeId }
	} = useMerchantContext();
	const refresh = useCallback(
		(args = { loader: true }) => {
			args?.loader && startLoading();
			return eventRepo.getEvent({ storeId, eventId }).then(setEvent).finally(stopLoading);
		},
		[startLoading, stopLoading, storeId, eventId]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [event, isLoading, refresh];
};

export default useReports;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { httpClient } from "data";
import {
	signIn as signInToFirebase,
	signOut as signOutFromFirebase,
	verifyPasswordResetCode,
	confirmPasswordReset,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	subscribeToAuthState
} from "../auth/functions";

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [isUserResolved, setIsUserResolved] = useState(false);

	const onAuthStateChange = useCallback(u => {
		httpClient.setUser(u);
		setUser(u);
		setIsUserResolved(true);
	}, []);

	useEffect(() => {
		const unsubscribe = subscribeToAuthState(onAuthStateChange);
		return () => unsubscribe();
	}, [onAuthStateChange]);

	const signIn = args => signInToFirebase(args).then(({ user }) => onAuthStateChange(user));

	const signOut = () => signOutFromFirebase().then(onAuthStateChange);

	return (
		<UserContext.Provider
			value={{
				user,
				email: user?.email,
				signIn,
				signOut,
				verifyPasswordResetCode,
				confirmPasswordReset,
				sendPasswordResetEmail,
				signInWithEmailAndPassword
			}}
		>
			{isUserResolved ? children : null}
		</UserContext.Provider>
	);
};

const useUserContext = () => {
	const context = useContext(UserContext);
	if (context === undefined) {
		throw new Error("useUser must be used within a UserProvider");
	}
	return context;
};

export { UserProvider, useUserContext };

/* eslint-disable react/display-name */
import required from "core/components/Grid/Validation/required";
import getCheckinGroupSchema from "../get-checkin-group-schema";
import CheckinGroupActiveColumn from "./CheckinGroupActiveColumn";
import { find, propEq } from "ramda";

export default ({ merchant, source, checkinGroups }) => [
	{
		prop: "checkinLabelPrefix",
		label: "Prefix",
		width: 80,
		visible: () => false,
		sort: true
	},
	{
		prop: "label",
		description: "The group prefix is always shown in front of the QR code label",
		inputGroup: ({ row }) =>
			find(propEq("checkinGroupId", Number(row?.checkinGroupId)), checkinGroups)
				?.checkinLabelPrefix,
		type: source === "create" ? "multiText" : "text",
		multiTextButtonTitle: "Add another QR code",
		width: 350,
		label: "Label",
		minimumGridVisibility: "xs",
		sort: true,
		editable: false,
		visible: rowData => rowData.checkinGroupId,
		validate: [required("Label is required")],
		modalSortOrder: 1
	},
	...getCheckinGroupSchema({ merchant, checkinGroups }),
	{
		prop: "active",
		type: "switch",
		minimumGridVisibility: "xs",
		width: 150,
		label: "Active",
		sort: true,
		editable: false,
		visible: rowData => rowData.checkinGroupId && source === "edit",
		formatter: CheckinGroupActiveColumn,
		modalSortOrder: 2
	}
];

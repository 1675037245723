import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { deepCopy, randomNumber } from "core/utils";
import { isEmpty } from "ramda";
import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
`;

const Rows = styled.div`
	width: 100%;
	margin-bottom: ${p => (p.isEmpty ? 0 : p.theme.space.medium)};
`;

const Row = styled(Form.Group)`
	display: flex;
	align-items: center;
	margin-bottom: ${p => p.theme.space.small};
	justify-content: space-between;
	&:last-child {
		margin-bottom: 0;
	}
`;

const Input = styled(Form.Control)`
	height: 38px;
	width: ${p => p.inputWidth};
	margin-right: ${p => p.theme.space.small};
`;

const StyledInputGroupText = styled(InputGroup.Text)`
	border-radius: 0.2rem;
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;
	padding: 0px;
	border-right: 0px;
	padding-left: 8px;
	padding-right: 8px;
`;

const MultiRowInput = ({
	rows,
	setRows,
	newRowButtonTitle,
	newRowButtonFontAwesomeIcon,
	validationFunction,
	inputWidth = "300px",
	item,
	parentRow
}) => {
	const [currentRows, setCurrentRows] = useState(
		rows?.map(e => ({ data: e, key: Math.round(Math.random() * 100000) })) || []
	);

	const createRow = () => {
		const r = deepCopy(currentRows, []);
		r.push({
			data: "",
			key: randomNumber(),
			focused: true
		});
		setCurrentRows(r);
	};

	const updateRows = r => {
		setCurrentRows(r);
		setRows(r.map(d => d.data));
	};

	const getInputRow = row => (
		<Input
			key={`row-${row.key}`}
			size="sm"
			type="text"
			inputwidth={inputWidth}
			autoFocus={row.focused}
			defaultValue={row.data}
			onChange={e => {
				const currentRow = currentRows.find(o => o.key === row.key);
				currentRow.data = e.target.value;
				updateRows(currentRows);
			}}
			isInvalid={validationFunction && row.error}
			isValid={validationFunction && !row.focused && !row.error}
			onBlur={() => {
				const currentRow = currentRows.find(o => o.key === row.key);

				if (validationFunction) {
					currentRow.error = validationFunction(row.data);
					console.log("validating", validationFunction(row.data));
				}
				delete currentRow.focused;
				updateRows(currentRows);
			}}
			onKeyUp={e => {
				if (e.key === "Enter") createRow();
			}}
		/>
	);
	return (
		<Container>
			<Rows isEmpty={isEmpty(currentRows)}>
				{currentRows?.map(row => (
					<Row key={row.key}>
						{item?.inputGroup ? (
							<InputGroup size="sm">
								<StyledInputGroupText id={`${item.prop}_group`}>
									{item.inputGroup({ item, row: parentRow })}
								</StyledInputGroupText>
								{getInputRow(row)}
							</InputGroup>
						) : (
							getInputRow(row)
						)}
						<Form.Control.Feedback type="invalid">{row.error}</Form.Control.Feedback>

						<Button
							icon={faTrashAlt}
							onClick={() => updateRows(currentRows.filter(o => o.key !== row.key))}
							size="large"
						/>
					</Row>
				))}
			</Rows>

			<Button
				onClick={createRow}
				icon={newRowButtonFontAwesomeIcon}
				key="add-row"
				size="small"
			>
				{newRowButtonTitle}
			</Button>
		</Container>
	);
};

export default MultiRowInput;

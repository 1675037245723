import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { reportingRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useReceipt = ({ orderId }) => {
	const [receipt, setReceipt] = useState();
	const [error, setError] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const {
		currentStore: { storeId }
	} = useMerchantContext();

	const refresh = useCallback(() => {
		startLoading();
		return reportingRepo
			.createReceiptCopy({ orderId, storeId })
			.then(receipt => {
				if (!receipt.receiptCopy)
					setError(receipt.errorMessage || "Could not create receipt copy.");
				else setReceipt(receipt);
			})
			.finally(stopLoading);
	}, [startLoading, stopLoading, storeId, orderId]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [receipt, isLoading, error];
};

export default useReceipt;

import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";

const Container = styled.div`
	font-size: 14px;
`;

const TimeCreated = ({ time, timeZone }) => (
	<Container>{moment(time).tz(timeZone).format("YYYY-MM-DD HH:mm")}</Container>
);

export default TimeCreated;

import React from "react";
import Tabs from "./Tabs";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.header`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Body = styled.div`
	padding-top: 8px;
	padding-bottom: 4px;
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;

	@media ${mediaQuery(device.tablet)} {
		padding-top: 16px;
		padding-bottom: 8px;
	}
`;

const Heading = styled.h1`
	font-size: 30px;
	font-weight: 700;
	margin-bottom: 0;

	@media ${mediaQuery(device.tablet)} {
		font-size: 32px;
	}
`;

const Children = styled.div`
	display: flex;

	& > button {
		margin-left: ${p => p.theme.space.small};
	}
`;

const SectionHeader = ({ heading, children, tabs = [], activeTab, onTabSelected }) => {
	return (
		<Container>
			<Body>
				<Heading data-test="section-header-heading">{heading}</Heading>
				<Children>{children}</Children>
			</Body>

			<Tabs tabs={tabs} activeTab={activeTab} onTabSelected={onTabSelected} />
		</Container>
	);
};

export default SectionHeader;

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { httpClient } from "data";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import Loader from "../Loader";
import Button from "../Button";

const Container = styled.div`
	display: flex;
	align-items: center;
	width: 40px;
	justify-content: center;

	@media ${mediaQuery(device.tablet)} {
		width: 80px;
	}
`;

const LoaderContainer = styled.div`
	display: flex;
	flex-grow: 1;
`;

const GridSync = ({
	onBeginSync = () => {},
	onFinishSync = () => {},
	buttonProps,
	apiPath,
	apiMethod,
	currentPriceListId
}) => {
	const [isFetching, setIsFetching] = useState(false);
	const {
		label: buttonLabel,
		icon: buttonIcon,
		size: buttonSize,
		variant: buttonVariant
	} = buttonProps;

	const onClick = useCallback(async () => {
		setIsFetching(true);
		await onBeginSync();

		const response = await httpClient.fetch(apiPath, {
			method: apiMethod,
			priceListId: currentPriceListId
		});
		setIsFetching(false);
		await onFinishSync();
		if (response.code) {
			console.error("Failed sync", response);
			return;
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Container>
			{!isFetching && (
				<Button
					icon={buttonIcon || faSync}
					size={buttonSize}
					variant={buttonVariant}
					onClick={onClick}
				>
					{buttonLabel || "Sync"}
				</Button>
			)}
			{isFetching && (
				<LoaderContainer>
					<Loader size="small" color="green" center />
				</LoaderContainer>
			)}
		</Container>
	);
};

GridSync.defaultProps = {
	buttonProps: {}
};

export default GridSync;

import React from "react";
import { PageContainer } from "auth/components";
import RequestPasswordResetForm from "./RequestPasswordResetForm";

const RequestPasswordRequestPage = () => {
	return (
		<PageContainer>
			<RequestPasswordResetForm />
		</PageContainer>
	);
};

export default RequestPasswordRequestPage;

import { PageLayout, SideNav } from "core/components";
import { useTabs } from "core/utils";
import React, { useMemo } from "react";
import { CheckinTable, CheckinGroups } from "./components";

const Checkins = () => {
	const items = useMemo(
		() => [
			{
				id: "codes",
				label: "Codes",
				content: <CheckinTable />
			},
			{
				id: "groups",
				label: "Groups",
				content: <CheckinGroups />
			}
		],
		[]
	);
	const [activeItem, setActiveItem] = useTabs("codes", items);

	return (
		<PageLayout>
			<SideNav
				title="QR Codes"
				items={items}
				activeItem={activeItem}
				onActiveItemChanged={setActiveItem}
			/>
		</PageLayout>
	);
};

export default Checkins;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import barcodeValidator from "barcode-validator";
import { MultiRowInput } from "core/components";
import React from "react";

const padWithLeadingZero = b => b.padStart(14, "0");
const last13 = b => b.substr(-13);

const Barcodes = ({ value, onChange }) => {
	const validateBarcode = barcode => {
		return !barcode || barcode.length !== 13 || !barcodeValidator(barcode)
			? "Barcode is not a valid EAN-13 code"
			: null;
	};

	const setBarcodes = barcodes => {
		const validBarcodes = Array.isArray(barcodes)
			? barcodes.filter(barcode => !validateBarcode(barcode)).map(padWithLeadingZero)
			: [];

		onChange(validBarcodes);
	};
	return (
		<div style={{ width: "100%" }}>
			<MultiRowInput
				newRowButtonTitle="Add Barcode"
				newRowButtonFontAwesomeIcon={faPlus}
				rows={(value || []).map(last13)}
				onlySetValidRows={true}
				setRows={setBarcodes}
				inputWidth="180px"
				validationFunction={validateBarcode}
			/>
		</div>
	);
};

export default Barcodes;

import { FullScreenButton } from "core/components";
import React from "react";
import styled from "styled-components";
import { device, displayOn } from "styles/device";
import ChangeStationButton from "./ChangeStationButton";
import ConfigModal from "./ConfigModal";
import TestAudioButton from "./TestAudioButton";

const Container = styled.div`
	display: flex;

	& > * {
		margin-left: 12px;
	}

	${displayOn({ min: device.tablet })}
`;

const Component = ({ station, isFullScreen, onFullScreenToggle, onRefresh }) => (
	<Container>
		<ChangeStationButton />

		{station.sound && <TestAudioButton />}

		<ConfigModal station={station} onSave={onRefresh} />

		<FullScreenButton isFullScreen={isFullScreen} onClick={onFullScreenToggle} />
	</Container>
);

Component.displayName = "Actions";

export default Component;

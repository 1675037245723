import { faCheck, faChevronDown, faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridCreate, GridDelete, GridEdit, Loader } from "core/components";
import { httpClient } from "data";
import _ from "lodash";
import { intersection, path, pluck } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import styled from "styled-components";
import theme from "styles/theme";
import { GridSync } from ".";
import GridTopAction from "./GridTopAction";
import PaginationRenderer from "./PaginationRenderer";
import { Alert as CoreAlert } from "react-bootstrap";

const Container = styled.div`
	${p => (p.flexContainer ? "display: inline-flex;" : "")}
`;

const TopActionsContainer = styled.div`
	display: flex;
	margin-bottom: ${p => p.theme.space.small};
`;

const RowActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	& > button {
		margin-left: ${p => p.theme.space.small};
	}
`;
const HeaderCellContainer = styled.div`
	display: flex;
`;

const SearchBarContainer = styled.div`
	width: 100%;
`;

const SearchBar = styled.input`
	width: 100%;
`;

const ExpandButton = styled(FontAwesomeIcon)`
	font-size: 13;
	position: relative;
	top: 1;
`;

const Alert = styled(CoreAlert)`
	align-self: flex-start;
	width: fit-content;
`;

const Searchbar = ({ setSelectedRows, onSearch, placeholder = "Search", delay = 250 }) => {
	const onSearchDebounced = useMemo(() => _.debounce(onSearch, delay), [delay, onSearch]);
	const handleChange = e => {
		setSelectedRows([]);
		return onSearchDebounced(e.target.value);
	};
	return (
		<SearchBarContainer>
			<SearchBar
				className="form-control"
				placeholder={placeholder}
				onChange={handleChange}
				type="text"
			/>
		</SearchBarContainer>
	);
};

const Component = ({
	schema,
	data,
	noDataText,
	tableKey,
	reload,
	isFetching,
	actions,
	pagination = true,
	search,
	remote,
	selection,
	remotePagination,
	paginationOptions,
	remotePaginationOptions,
	expandedRowContent,
	parentRowData,
	currentPriceListId,
	noHeader,
	rowStyle,
	onRowClick,
	onRowDoubleClick,
	transparentHeader,
	noBorder,
	flexContainer,
	...extraParams
}) => {
	const [errorMessage, setErrorMessage] = useState();
	const pageSizes = [10, 25, 50, 100, 500, 1000];
	const [selectedRows, setSelectedRows] = useState([]);

	const [gridData, setGridData] = useState(data);

	useEffect(() => {
		// Unselect rows that are not visible in the grid
		if (selection) setSelectedRows(s => intersection(s, pluck([tableKey], data)));
		setGridData(data);
	}, [data, tableKey, selection]);

	const populateApiParams = useCallback(
		({ rowData, apiPath, apiExtraParams }) => {
			if (apiExtraParams)
				for (const param of apiExtraParams) {
					apiPath = apiPath.replace(`{${param}}`, extraParams[param]);
				}
			if (rowData)
				for (const [key, value] of Object.entries(rowData)) {
					apiPath = apiPath.replace(`{${key}}`, value);
				}
			return apiPath;
		},
		[extraParams]
	);

	const handleTableChange = async (action, data) => {
		if (remote?.sort || remote?.search || remote?.filter) {
			remotePaginationOptions.setPage(1);
			reload({
				search: data?.searchText,
				sortField: data?.sortField,
				sortOrder: data?.sortOrder
			});
		}
		if (action === "cellEdit") {
			const row = data.data.filter(d => d[tableKey] === data.cellEdit.rowId)[0];
			const response = await inlineUpdateRow({
				...row,
				[data.cellEdit.dataField]: data.cellEdit.newValue
			});
			if (response.code) {
				setErrorMessage(response.message || "Could not save changes");
				return;
			} else {
				setErrorMessage(undefined);
				await reload();
			}
		}
		if (action === "pagination") {
			remotePaginationOptions.setPage(data.page);
			remotePaginationOptions.setSizePerPage(data.sizePerPage);
		}
	};

	const inlineUpdateRow = useCallback(
		async row => {
			return await httpClient.fetch(
				populateApiParams({
					rowData: row,
					apiPath: actions.edit.apiPath,
					apiExtraParams: actions.edit.apiExtraParams
				}),
				{
					method: actions.edit.apiMethod,
					body: row
				}
			);
		},
		[actions, populateApiParams] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const handleCustomActionSave = useCallback(
		async (parentRowData, originalRow, actionIndex, customActionData) => {
			await httpClient.fetch(
				populateApiParams({
					rowData: { ...originalRow, ...parentRowData },
					apiPath: actions.customActions[actionIndex].apiPath,
					apiExtraParams: actions.customActions[actionIndex].apiExtraParams
				}),
				{
					method: actions.customActions[actionIndex].apiMethod,
					body: customActionData
				}
			);
			reload();
			return true;
		},
		[actions, populateApiParams] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const handleErrorMessageDisappear = () => {
		setErrorMessage(null);
	};

	// eslint-disable-next-line react/display-name
	const actionsFormatter = (cell, row, parentRowData) => {
		const getCustomActions = () => {
			const customActions = [];
			for (const index in actions.customActions) {
				if (
					actions.customActions[index].visible &&
					actions.customActions[index].visible({ parentRowData, rowData: row })
				)
					customActions.push(
						<div key={`action_${index}`}>
							{React.cloneElement(
								actions.customActions[index].context({
									rowData: row,
									parentRowData
								}),
								{
									handleSave: customActionData =>
										handleCustomActionSave(
											parentRowData,
											row,
											index,
											customActionData
										)
								}
							)}
						</div>
					);
			}
			return customActions;
		};

		return (
			<RowActionsContainer>
				{actions.customActions && getCustomActions()}

				{actions.edit && (
					<GridEdit
						row={actions.edit.customRow ? actions.edit.customRow(row) : row}
						onChange={reload}
						apiPath={populateApiParams({
							rowData: row,
							apiPath: actions.edit.apiPath,
							apiExtraParams: actions.edit.apiExtraParams
						})}
						apiMethod={actions.edit.apiMethod}
						title={actions.edit.modalTitle || "Edit"}
						schema={schema}
					/>
				)}

				{actions.delete && (
					<GridDelete
						row={actions.delete.customRow ? actions.delete.customRow(row) : row}
						message={actions.delete.message}
						disabled={actions.delete.disabled}
						disabledModalMessage={actions.delete.disabledModalMessage}
						buttonProps={actions.delete.buttonProps}
						onChange={reload}
						apiPath={populateApiParams({
							rowData: row,
							apiPath: actions.delete.apiPath,
							apiExtraParams: actions.delete.apiExtraParams
						})}
						apiMethod={actions.delete.apiMethod}
					/>
				)}
			</RowActionsContainer>
		);
	};
	const visibleOnMobileClasses = (minimumGridVisibility, maximumGridVisibility) => {
		if (maximumGridVisibility) return `d-block d-${maximumGridVisibility}-none`;

		if (minimumGridVisibility === "xs") return "";

		return `d-none${
			minimumGridVisibility ? ` d-${minimumGridVisibility}-table-cell` : ` d-lg-table-cell`
		}`;
	};
	const columns = schema
		.filter(f => f.columnVisible !== false)
		.map(c => {
			return {
				headerStyle: c.headerStyle ? c.headerStyle : c.width ? { width: c.width } : null,
				dataField: c.prop,
				editable: c.editable,
				isDummyField: c.isDummyField,
				text: c.label || "",
				sort: c.sort,
				style: c.noWrap ? { whiteSpace: "nowrap" } : {},
				classes: visibleOnMobileClasses(c.minimumGridVisibility, c.maximumGridVisibility),
				headerClasses: visibleOnMobileClasses(
					c.minimumGridVisibility,
					c.maximumGridVisibility
				),
				showExpandColumn: false,
				// eslint-disable-next-line react/display-name
				formatter: (cell, row) => {
					if (c.formatter) return c.formatter(cell, row);
					if (c.type === "switch")
						return (
							<FontAwesomeIcon
								icon={cell ? faCheck : faTimes}
								color={cell ? theme.color.emeraldGreen : theme.color.bittersweetRed}
							/>
						);
					return cell || "";
				},
				align: c.align,
				headerAlign: c.align,
				// eslint-disable-next-line react/display-name
				headerFormatter: (col, index, props) => {
					const getSortCaret = () => {
						const sortOrder = props?.sortElement?.props?.order;
						if (sortOrder === "asc") return <div className="caret-4-asc" />;
						if (sortOrder === "desc") return <div className="caret-4-desc" />;
						return props?.sortElement;
					};

					return (
						<HeaderCellContainer>
							{col.text}
							{getSortCaret()}
						</HeaderCellContainer>
					);
				},
				...(c.filter && { filter: textFilter() })
			};
		});

	if (actions)
		columns.push({
			keyField: "actionsKey",
			dataField: "actions",
			editable: false,
			text: actions.options?.headerText || "",
			isDummyField: true,
			csvExport: false,
			formatter: (cell, row) => actionsFormatter(cell, row, parentRowData),
			headerStyle: {
				width:
					actions.options?.width ||
					30 + (actions.edit ? 30 : 0) + (actions.delete ? 30 : 0)
			}
		});

	const getRowStyle = () =>
		rowStyle ? rowStyle : !!expandedRowContent ? { cursor: "pointer" } : {};

	const expandRow = {
		// eslint-disable-next-line react/display-name
		parentClassName: "tableExpandedParentRow",
		className: "tableExpandedRow",
		renderer: row => expandedRowContent(row),
		showExpandColumn: true,
		expandColumnPosition: "right",
		expandHeaderColumnRenderer: () => {},
		// eslint-disable-next-line react/display-name
		expandColumnRenderer: ({ expanded }) => (
			<ExpandButton icon={expanded ? faChevronUp : faChevronDown} fixedWidth />
		)
	};
	const selectRow = {
		mode: "checkbox",
		clickToSelect: false,
		selected: selectedRows,
		onSelect: (row, isSelect) => {
			if (isSelect) {
				const newRows = [...selectedRows, row[tableKey]];
				setSelectedRows(newRows);
				return true;
			}
			const newRows = selectedRows.filter(f => f !== row[tableKey]);
			setSelectedRows(newRows);
			return true;
		},
		onSelectAll: (isSelect, rows) => {
			if (isSelect) {
				setSelectedRows(rows.map(c => c[tableKey]));
				return rows.map(c => c[tableKey]);
			}
			setSelectedRows([]);
			return [];
		}
	};

	const cellEdit = {
		mode: "dbclick",
		errorMessage: errorMessage,
		timeToCloseMessage: 3000,
		onErrorMessageDisappear: handleErrorMessageDisappear,
		beforeSaveCell: (oldValue, newValue, row) => {
			inlineUpdateRow(row);
		}
	};

	const emptyDataMessage = () => {
		if (isFetching) {
			return <Loader center padding="giant" />;
		}

		if (noDataText)
			return (
				<Alert variant="warning" data-test="grid-empty-message">
					{noDataText}
				</Alert>
			);
	};

	const options = remotePagination
		? {
				custom: true,
				sizePerPage: remotePaginationOptions.sizePerPage,
				totalSize: remotePaginationOptions.totalSize,
				page: 1
		  }
		: {
				custom: true,
				sizePerPage: paginationOptions?.sizePerPage || 50,
				page: 1
		  };

	const handleNextPage =
		({ page, onPageChange }) =>
		() => {
			if (remotePagination) remotePaginationOptions.setPage(remotePaginationOptions.page + 1);
			else onPageChange(page + 1);
		};

	const handlePage =
		({ onPageChange }, newPage) =>
		() => {
			setSelectedRows([]);
			if (remotePagination) remotePaginationOptions.setPage(newPage);
			else onPageChange(newPage);
		};

	const handlePrevPage =
		({ page, onPageChange }) =>
		() => {
			if (remotePagination) remotePaginationOptions.setPage(remotePaginationOptions.page - 1);
			else onPageChange(page - 1);
		};

	const handleSizePerPage =
		({ onSizePerPageChange }, newSizePerPage) =>
		() => {
			if (remotePagination) {
				remotePaginationOptions.setPage(1);
				remotePaginationOptions.setSizePerPage(newSizePerPage);
			} else onSizePerPageChange(newSizePerPage, 1);
		};

	const afterSearch = ({ onPageChange }) => {
		setSelectedRows([]);
		if (!remotePagination) {
			onPageChange(1);
		}
	};
	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			if (onRowClick) onRowClick(e, row, rowIndex);
		},
		onDoubleClick: (e, row, rowIndex) => {
			if (onRowDoubleClick) onRowDoubleClick(e, row, rowIndex);
		}
	};

	return (
		<PaginationProvider pagination={paginationFactory(options)}>
			{({ paginationProps, paginationTableProps }) => (
				<ToolkitProvider
					keyField={tableKey}
					data={remotePagination ? data || [] : JSON.parse(JSON.stringify(data || []))}
					columns={columns}
					search={{
						afterSearch: afterSearchResult =>
							afterSearch(paginationProps, afterSearchResult)
					}}
					bootstrap4
				>
					{props => (
						<Container flexContainer={flexContainer}>
							<TopActionsContainer>
								{actions?.new && (
									<div style={{ marginBottom: 8 }}>
										<GridCreate
											title={actions.new.modalTitle || "New"}
											apiPath={populateApiParams({
												apiPath: actions.new.apiPath,
												apiExtraParams: actions.new.apiExtraParams
											})}
											style={{ marginRight: 8 }}
											schema={actions.schema || schema}
											onChange={reload}
											buttonProps={actions.new.buttonProps}
											row={actions.new.initialValues}
											apiMethod={actions.new.apiMethod || "PUT"}
										/>
									</div>
								)}
								{actions?.sync && (
									<div style={{ marginBottom: 8 }}>
										<GridSync
											schema={schema}
											onChange={reload}
											buttonTitle="Sync"
											apiPath={populateApiParams({
												apiPath: actions.sync.apiPath,
												apiExtraParams: actions.sync.apiExtraParams
											})}
											apiMethod={actions.sync.apiMethod}
											style={{ marginRight: 8 }}
											currentPriceListId={currentPriceListId}
										/>
									</div>
								)}

								{search && (
									<Searchbar
										delay={300}
										placeholder="Search"
										setSelectedRows={setSelectedRows}
										{...props.searchProps}
									/>
								)}
							</TopActionsContainer>
							<TopActionsContainer>
								{Array.isArray(actions?.top) &&
									actions.top.map((action, i) => (
										<GridTopAction
											key={i}
											action={action}
											selectedRows={selectedRows}
											reload={reload}
											apiPath={populateApiParams({
												apiPath: action.apiPath,
												apiExtraParams: action.apiExtraParams
											})}
											apiMethod={action.apiMethod}
											{...(action.selectedKeyProp && {
												row: {
													[action.selectedKeyProp]: selectedRows
												}
											})}
										/>
									))}
							</TopActionsContainer>
							<div>
								<BootstrapTable
									{...{
										keyField: tableKey,
										bootstrap4: true,
										...(selection && { selectRow: selectRow }),
										filter: filterFactory(),
										filterPosition: "top",
										noDataIndication: emptyDataMessage,
										wrapperClasses: "tableWrapper",
										headerWrapperClasses: noHeader
											? "tableNoHeader"
											: "tableHeaderWrapper",
										bodyClasses: "tableBody",
										headerClasses: `tableHeader ${
											transparentHeader ? "tableHeaderTransparent" : ""
										}`,
										rowClasses: noBorder ? "noBorder" : "tableRow",
										rowEvents: rowEvents,
										options: { paginationShowsTotal: true },
										data: remotePagination
											? gridData || []
											: JSON.parse(JSON.stringify(gridData || [])),
										remote: remotePagination
											? { search: true, filter: true, sort: true }
											: false,
										columns: columns,
										bordered: false,
										hover: true,
										paginationShowsTotal: true,
										paginationPosition: "top",
										...(expandedRowContent && { expandRow: expandRow }),
										...(actions?.edit?.inline && {
											cellEdit: cellEditFactory(cellEdit)
										}),
										onTableChange: handleTableChange,
										...props.baseProps,
										...paginationTableProps,
										rowStyle: getRowStyle()
									}}
								/>
								{pagination &&
									PaginationRenderer(
										remotePagination
											? {
													paginationProps,
													pageSizes,
													handlePage,
													handleNextPage,
													handlePrevPage,
													handleSizePerPage,
													page: remotePaginationOptions.page,
													sizePerPage:
														remotePaginationOptions.sizePerPage,
													totalSize: remotePaginationOptions.totalSize
											  }
											: {
													paginationProps,
													pageSizes,
													handlePage,
													handleNextPage,
													handlePrevPage,
													handleSizePerPage,
													page: paginationProps.page,
													sizePerPage: paginationProps.sizePerPage,
													totalSize: path(
														["pagination", "options", "dataSize"],
														paginationTableProps
													)
											  }
									)}
							</div>
						</Container>
					)}
				</ToolkitProvider>
			)}
		</PaginationProvider>
	);
};

Component.displayName = "Table";

export default Component;

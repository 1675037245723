import React from "react";
import { ListGroup } from "react-bootstrap";
import styled from "styled-components";
import Item from "./Item";

const Container = styled.div`
	flex-grow: 1;
	padding-top: 4px;
	padding-bottom: 4px;
`;

const Items = ({ items }) => {
	return (
		<Container>
			<ListGroup className="list-group-flush">
				{items.map((item, i) => (
					<Item item={item} key={i} last={i === items.length - 1} />
				))}
			</ListGroup>
		</Container>
	);
};

export default Items;

import { useMerchantContext } from "context";
import { GridCreate, SectionHeader } from "core/components";
import { useSwitch } from "core/utils";
import { checkinGroupRepo } from "data/repos";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import urlcat from "urlcat";
import CheckinGroupsTable from "./CheckinGroupsTable";
import getTableActions from "./CheckinGroupsTable/get-table-actions";
import getTableSchema from "./CheckinGroupsTable/get-table-schema";

const Container = styled.div`
	flex-direction: column;
	flex-grow: 1;
`;

const CheckinGroups = () => {
	const { currentStore, currentMerchant, hasFunctionAccess } = useMerchantContext();
	const [checkinGroups, setCheckinGroups] = useState([]);
	const [schema, setSchema] = useState(null);
	const [actions, setActions] = useState(null);
	const [isFetching, startFetching, stopFetching] = useSwitch();

	const refresh = useCallback(async () => {
		startFetching();
		const checkinGroups = await checkinGroupRepo.getCheckinGroups({
			storeId: currentStore.storeId
		});
		stopFetching();

		setCheckinGroups(checkinGroups.checkinGroups);
		setActions(
			getTableActions({
				merchant: currentMerchant,
				store: currentStore,
				hasFunctionAccess
			})
		);
		setSchema(getTableSchema({ currentMerchant, currentStore, hasFunctionAccess }));
	}, [currentStore, currentMerchant, hasFunctionAccess, startFetching, stopFetching]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return (
		<Container>
			<SectionHeader heading="Groups">
				{!currentMerchant.externalCheckinSync && hasFunctionAccess("checkin_edit") && (
					<GridCreate
						buttonProps={{
							label: "New group",
							variant: "primary"
						}}
						title="New group"
						schema={schema}
						row={{ active: true }}
						onChange={refresh}
						apiPath={urlcat("/checkin-groups", {
							storeId: currentStore.storeId
						})}
						apiMethod="POST"
					/>
				)}
			</SectionHeader>

			<CheckinGroupsTable
				checkinGroups={checkinGroups}
				schema={schema}
				actions={actions}
				isFetching={isFetching}
				refresh={refresh}
			/>
		</Container>
	);
};

export default CheckinGroups;

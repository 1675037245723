import { prop } from "ramda";

export default doc => {
	const data = doc.data();
	const { timeCreated, timeDone } = data;

	return {
		...data,
		orderTicketId: prop("id", doc),
		timeCreated: timeCreated.toDate(),
		timeDone: timeDone?.toDate()
	};
};

import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	max-width: ${props => props.theme.pageContentMaxWidth};
	padding-left: ${props => props.theme.pageContentPadding};
	padding-right: ${props => props.theme.pageContentPadding};

	@media ${mediaQuery(device.tabletLarge)} {
		padding-left: ${props => props.theme.pageContentPaddingLarge};
		padding-right: ${props => props.theme.pageContentPaddingLarge};
	}
`;

export default Container;

import { httpClient } from "data";

const welcomeTokenRepo = {
	verifyWelcomeToken: tokenId => httpClient.get(`/welcome-tokens/${tokenId}`),
	activate: (tokenId, { password }) =>
		httpClient.post(`/welcome-tokens/activate/${tokenId}`, {
			body: { password }
		})
};

export default welcomeTokenRepo;

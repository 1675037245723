import { httpClient } from "data";
import urlcat from "urlcat";

const productRepo = {
	getProducts: ({
		storeId,
		page,
		pageSize,
		filter,
		priceListId,
		search = "",
		sortOrder = "",
		sortField = ""
	}) =>
		httpClient.get(
			urlcat("/products", {
				storeId,
				priceListId,
				filter,
				page,
				pageSize,
				search,
				sortOrder,
				sortField
			})
		),
	setProduct: ({ storeId, priceListId, productId, product }) =>
		httpClient.put(`/products/${productId}?storeId=${storeId}&priceListId=${priceListId}`, {
			body: product
		})
};

export default productRepo;

import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { eventRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useEvents = ({ dateFilter }) => {
	const [events, setEvents] = useState([]);
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const [eventsCount, setEventsCount] = useState(0);
	const [search, setSearch] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const {
		currentStore: { storeId }
	} = useMerchantContext();

	const refresh = useCallback(
		(args = {}) => {
			startLoading();
			return eventRepo
				.getEvents({
					storeId,
					dateFilter,
					page: page || 1,
					pageSize,
					sortField: args.sortField,
					sortOrder: args.sortOrder,
					search: args.search
				})
				.then(response => {
					setSearch(args.search);
					setEventsCount(response.eventsCount);
					setEvents(response.events);
				})
				.finally(stopLoading);
		},
		[startLoading, stopLoading, page, pageSize, dateFilter, storeId]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [events, eventsCount, isLoading, refresh, setPageSize, setPage, pageSize, page, search];
};

export default useEvents;

import React from "react";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import SignOutModal from "../SignOutModal";
import { useSwitch } from "core/utils";

const Button = styled.div`
	color: #111;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
	padding: 12px;

	&:hover {
		background-color: #eee;
	}
`;

const Icon = styled(FontAwesomeIcon)`
	color: "#111";
	margin-right: 8px;
	font-size: 14px;
`;

const Label = styled.div`
	white-space: nowrap;
`;

const SignOutButton = () => {
	const [isModal, openModal, closeModal] = useSwitch(false);

	return (
		<>
			<Button onClick={openModal} data-test="sign-out">
				<Icon icon={faSignOutAlt} fixedWidth />
				<Label>Sign out</Label>
			</Button>

			<SignOutModal isOpen={isModal} onClose={closeModal} />
		</>
	);
};

export default SignOutButton;

import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Alert, Form, FormControl } from "react-bootstrap";
import { Button } from "core/components";
import React, { useEffect, useState } from "react";
import TextBookkeepingAccount from "./TextBookkeepingAccount";
import { useMerchantContext } from "context";
import { productGroupRepo } from "data/repos";
import { indexBy, prop } from "ramda";
import styled from "styled-components";
import { Card as BsCard } from "react-bootstrap";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Card = styled(BsCard)`
	background: ${p => p.theme.color.dirtyWhite};
	margin-right: ${p => p.theme.space.large};
`;

const CardBody = styled(BsCard.Body)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 40px;
`;

const CardFooter = styled(BsCard.Footer)`
	display: flex;
	justify-content: flex-end;
`;

const CardSection = styled.div``;

const Heading = styled.div`
	font-size: 20px;
	font-family: Peak;
	margin-bottom: ${p => p.theme.space.medium};
	font-weight: 500;
	line-height: 0.95;
`;

const BookkeepingAccounts = ({
	integrationSettings,
	setIntegrationSettings,
	oldIntegrationSettings,
	onSave,
	productGroups,
	setProductGroups,
	setOldIntegrationSettings
}) => {
	const { currentMerchant, currentStore } = useMerchantContext();
	const [oldProductGroups, setOldProductGroups] = useState([]);
	const [alertMessageBottom, setAlertMessageBottom] = useState();
	const [alertTypeBottom, setAlertTypeBottom] = useState("danger");
	const [showAlertBottom, setShowAlertBottom] = useState(false);

	useEffect(() => {
		productGroupRepo.getProductGroups({ storeId: currentStore.storeId }).then(r => {
			const indexedGroups = indexBy(prop("productGroupId"), r);
			setProductGroups(indexedGroups);
			setOldProductGroups(indexedGroups);
		});
	}, [currentMerchant, currentStore, setProductGroups]);

	const handleSalesAccountsTypeChange = e => {
		let useSalesAccountsFromProductGroup;
		if (e.target.id === "useVatBasedSalesAccounts") useSalesAccountsFromProductGroup = false;
		else if (e.target.id === "useProductBasedSalesAccounts")
			useSalesAccountsFromProductGroup = true;
		else return;

		setIntegrationSettings({ ...integrationSettings, useSalesAccountsFromProductGroup });
		if (
			JSON.stringify(oldIntegrationSettings) !==
			JSON.stringify({ ...integrationSettings, useSalesAccountsFromProductGroup })
		) {
			setAlertMessageBottom("You have unsaved changes.");
			setAlertTypeBottom("warning");
			setShowAlertBottom(true);
		} else {
			setAlertMessageBottom();
			setAlertTypeBottom();
			setShowAlertBottom(false);
		}
	};

	const handleProductGroupAccountChange = data => {
		const [productGroupId, account] = Object.entries(data)[0];
		const pg = JSON.parse(JSON.stringify(productGroups));
		pg[productGroupId].bookkeepingSalesAccount = account;
		setProductGroups(pg);
		if (JSON.stringify(oldProductGroups) !== JSON.stringify(pg)) {
			setAlertMessageBottom("You have unsaved changes.");
			setAlertTypeBottom("warning");
			setShowAlertBottom(true);
		} else {
			setAlertMessageBottom();
			setAlertTypeBottom();
			setShowAlertBottom(false);
		}
	};

	const handleAccountChange = data => {
		setIntegrationSettings({ ...integrationSettings, ...data });
		if (
			JSON.stringify(oldIntegrationSettings) !==
			JSON.stringify({ ...integrationSettings, ...data })
		) {
			setAlertMessageBottom("You have unsaved changes.");
			setAlertTypeBottom("warning");
			setShowAlertBottom(true);
		} else {
			setAlertMessageBottom();
			setAlertTypeBottom();
			setShowAlertBottom(false);
		}
	};

	const handleDiscardChanges = () => {
		setIntegrationSettings(oldIntegrationSettings);
		setProductGroups(oldProductGroups);
		setAlertMessageBottom();
		setAlertTypeBottom();
		setShowAlertBottom(false);
	};

	const handleSave = async () => {
		const r = await onSave();

		if (!r || r?.error || r?.code) {
			setAlertMessageBottom(`Could not save changes.`);
			setAlertTypeBottom("danger");
			setShowAlertBottom(true);
			return { success: false };
		}

		setAlertMessageBottom("Successfully saved account settings.");
		setAlertTypeBottom("success");
		setShowAlertBottom(true);
		setOldIntegrationSettings(integrationSettings);
	};

	const renderSaveButton = () => (
		<Button variant="primary" icon={faSave} onClick={handleSave} size="large">
			Save
		</Button>
	);

	if (!integrationSettings) {
		return null;
	}

	return (
		<Container>
			<Alert
				variant={alertTypeBottom}
				show={showAlertBottom}
				onClose={() => setShowAlertBottom(false)}
				{...(alertTypeBottom !== "warning" && { dismissible: true })}
				style={{ marginRight: 16 }}
			>
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					{alertMessageBottom}
					{alertTypeBottom === "warning" && (
						<Button icon={faUndo} onClick={handleDiscardChanges}>
							Discard changes
						</Button>
					)}
				</div>
			</Alert>

			<Card>
				<CardBody>
					<CardSection>
						<Heading>General</Heading>
						<div style={{ display: "flex" }}>
							<div
								style={{
									marginRight: 8,
									width: 260,
									height: 50,
									display: "flex",
									alignItems: "center"
								}}
							>
								<div>Journal:</div>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<FormControl
									style={{ marginLeft: 8, borderRadius: 4 }}
									type="text"
									value={integrationSettings?.voucherSeries}
									onChange={e =>
										handleAccountChange({
											voucherSeries: e.target.value
										})
									}
								/>
							</div>
						</div>
						<TextBookkeepingAccount
							text="Checking account"
							dataKey="accountChecking"
							accountRangeFrom={1900}
							accountRangeTo={1999}
							selectedAccount={integrationSettings.accountChecking}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Checking account (Swish)"
							dataKey="accountCheckingSwish"
							accountRangeFrom={1900}
							accountRangeTo={1999}
							selectedAccount={integrationSettings.accountCheckingSwish}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Accounts receivable Passbuy"
							dataKey="accountReceivableStripe"
							accountRangeFrom={1500}
							accountRangeTo={1799}
							selectedAccount={integrationSettings.accountReceivableStripe}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Outgoing VAT 6%"
							dataKey="accountVat6"
							accountRangeFrom={2400}
							accountRangeTo={2999}
							selectedAccount={integrationSettings.accountVat6}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Outgoing VAT 12%"
							dataKey="accountVat12"
							accountRangeFrom={2400}
							accountRangeTo={2999}
							selectedAccount={integrationSettings.accountVat12}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Outgoing VAT 25%"
							dataKey="accountVat25"
							accountRangeFrom={2400}
							accountRangeTo={2999}
							selectedAccount={integrationSettings.accountVat25}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Passbuy service fee"
							dataKey="accountTransactionFee"
							accountRangeFrom={5000}
							accountRangeTo={6999}
							selectedAccount={integrationSettings.accountTransactionFee}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Incoming VAT"
							dataKey="accountVatIncoming"
							accountRangeFrom={2400}
							accountRangeTo={2999}
							selectedAccount={integrationSettings.accountVatIncoming}
							handleChange={handleAccountChange}
						/>
						<TextBookkeepingAccount
							text="Employee tips"
							dataKey="accountTips"
							accountRangeFrom={2400}
							accountRangeTo={2999}
							selectedAccount={integrationSettings.accountTips}
							handleChange={handleAccountChange}
						/>
					</CardSection>

					<CardSection>
						{/* Sales */}
						<Heading>Sales</Heading>
						<Form.Group onChange={handleSalesAccountsTypeChange}>
							<Form.Label>Group sales by:</Form.Label>
							<Form.Check
								id="useVatBasedSalesAccounts"
								type="radio"
								size="sm"
								name="useProductBasedSalesAccounts"
								label={`VAT percentage`}
								style={{ marginLeft: 8 }}
								checked={!integrationSettings.useSalesAccountsFromProductGroup}
							/>
							<Form.Check
								id="useProductBasedSalesAccounts"
								type="radio"
								size="sm"
								name="useProductBasedSalesAccounts"
								label={`Product groups`}
								style={{ marginLeft: 8 }}
								checked={integrationSettings.useSalesAccountsFromProductGroup}
							/>
						</Form.Group>
						<div style={{ flexGrow: 1 }}>
							{!integrationSettings.useSalesAccountsFromProductGroup && (
								<>
									<TextBookkeepingAccount
										text="Sales 6% VAT"
										dataKey="accountSales6"
										accountRangeFrom={3000}
										accountRangeTo={3700}
										selectedAccount={integrationSettings.accountSales6}
										handleChange={handleAccountChange}
									/>
									<TextBookkeepingAccount
										text="Sales 12% VAT"
										dataKey="accountSales12"
										accountRangeFrom={3000}
										accountRangeTo={3700}
										selectedAccount={integrationSettings.accountSales12}
										handleChange={handleAccountChange}
									/>
									<TextBookkeepingAccount
										text="Sales 25% VAT"
										dataKey="accountSales25"
										accountRangeFrom={3000}
										accountRangeTo={3700}
										selectedAccount={integrationSettings.accountSales25}
										handleChange={handleAccountChange}
									/>
								</>
							)}
							{integrationSettings.useSalesAccountsFromProductGroup && (
								<>
									{Object.entries(productGroups).map(([productGroupId, pg]) => (
										<TextBookkeepingAccount
											key={productGroupId}
											text={`${pg.name} (${pg.vatRate * 100}%)`}
											dataKey={productGroupId}
											accountRangeFrom={3000}
											accountRangeTo={3700}
											selectedAccount={pg.bookkeepingSalesAccount}
											handleChange={handleProductGroupAccountChange}
										/>
									))}
								</>
							)}
						</div>
					</CardSection>
				</CardBody>

				<CardFooter>{renderSaveButton()}</CardFooter>
			</Card>
		</Container>
	);
};

export default BookkeepingAccounts;

import { createGlobalStyle } from "styled-components";
import { darken } from "polished";
import { device, mediaQuery } from "./device";

export default createGlobalStyle`
	@font-face {
		font-family: "Peak";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600198d98dd135d347e15071_Peak-Semibold.woff2")
				format("woff2"),
			url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600198d90f079a7f3addd81a_Peak-Semibold.woff")
				format("woff"),
			url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600197ffc5bbb7a31557c600_Peak-Semibold.ttf")
				format("truetype"),
			url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600198d90b2631aafeed4084_Peak-Semibold.otf")
				format("opentype");
		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "RedHatText";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/5ff98f0ecd25785d1184e814_RedHatText-Regular.ttf")
			format("truetype");
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "RedHatText";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/5ff9a07279a885525b38ae5e_RedHatText-Bold.ttf")
			format("truetype");
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "RedHatText";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/5ff9a073ec24441088579375_RedHatText-Medium.ttf")
			format("truetype");
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}

  	body {
		margin: 0;
		padding: 0;
		font-family: ${p => p.theme.typography.text};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		touch-action: manipulation;
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
	}
	
	h1,
	h2,
	h3,
	h4,
	h6 {
		font-family: ${p => p.theme.typography.heading};
		font-weight: bold;
	}

	.btn-secondary {
		background-color: ${p => p.theme.color.dirtyWhite} !important;
		border-color: ${p => darken(0.05, p.theme.color.border.primary)} !important;
		color: ${p => p.theme.color.text.primary} !important;

		&:hover {
			background-color: ${p => darken(0.05, p.theme.color.dirtyWhite)} !important;
			border-color: ${p => darken(0.1, p.theme.color.border.primary)} !important;
		}
	}

	/* Tables */
	.react-bootstrap-table .tableHeader {
		font-family: ${p => p.theme.typography.text};
		font-weight: 500;
		color: ${p => p.theme.color.text.primary};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding-top: 1px;
		border-top-width: 0px;
		border-bottom-width: 0px;
		border-left-width: 0px;
		border-right-width: 0px;
		border: 1px solid ${p => p.theme.color.border.primary};
		background: ${p => p.theme.color.dirtyWhite};

		&:hover {
			border: 1px solid ${p => p.theme.color.border.primary};
			background: ${p => p.theme.color.dirtyWhite};
		}
	}

	.react-bootstrap-table .tableRow {
		font-family: ${p => p.theme.typography.text};
		min-width: 200px;
		word-wrap: break-word;
		border-style: solid;
		border-color: transparent;
		border-left-width: 3px;
		border-right-width: 1px;
		border-bottom: 1px solid ${p => p.theme.color.border.primary};
		
		&:hover {
			background-color: ${p => p.theme.color.dirtyWhite} !important;
			border-color: ${p => p.theme.color.border.primary};
		}
	}
	
	.react-bootstrap-table noBorder  {
		border-bottom: none;
		margin-bottom: 0;
	}

	@media screen and (min-width: 991px) {
		.react-bootstrap-table .tableRow {
			font-size: 14px;
		}
		.react-bootstrap-table .tableHeader {
			font-size: 14px;
		}
	}
	.react-bootstrap-table .tableHeaderTransparent {
		background: none;
		border-width: 0;
		border-bottom-width: 1px;
		&:hover {
			border-width: 0;
		    border-bottom-width: 1px;
			background: none;
			color: ${p => p.theme.color.text};
		}
	}
	
	
	.react-bootstrap-table .tableExpandedParentRow {
		border-color: ${p => p.theme.color.border.primary};
		border-bottom-color: transparent;
	}

	.react-bootstrap-table tbody .tableExpandedParentRow + tr:hover {
		background: none;
		color: ${p => p.theme.color.text};
	}

	.react-bootstrap-table .tableExpandedRow {
		border-color: ${p => p.theme.color.border.primary};
		border-style: solid;
		border-bottom-width: 1px;
		border-left-width: 3px;
		border-right-width: 1px;
		border-bottom-width: 3px;
		margin-bottom: ${p => p.theme.space.large};

		& .tableRow:last-child {
			border-bottom: 0;
		}

		& .tableHeader {
			background: none;
			border-width: 0;
			border-bottom-width: 1px;
		}
	}

	.tableNoHeader{
		margin: 0;
		display:none;
	}

	.tableWrapper{
		padding: 0;
		margin: -8px;
		@media ${mediaQuery(device.mobileLarge)} {
			margin: 0px;
		}
	}


	.react-bootstrap-table .row-expansion-style {
		padding: ${p => p.theme.space.small};
		background: none !important;
	}

	.react-bootstrap-table tbody tr:hover {
		background: none;
		color: ${p => p.theme.color.text.primary};
	}

	.react-bootstrap-table .table td,
	.react-bootstrap-table .table th {
		vertical-align: middle;
		border-top: none !important;
		line-height: 1.3;
	}

	.react-bootstrap-table .table thead th {
		border-bottom: none;
	}

	.react-bootstrap-table .table th:focus {
		outline: none !important;
	}

	.react-bootstrap-table-pagination {
		flex-wrap: unset;
		margin-top: ${p => p.theme.space.large};
		margin-bottom: 100px;
	}

	.react-bootstrap-table-pagination-list {
		text-align: center;
	}

	.react-bootstrap-table-page-btns-ul {
		float: right;
	}

	.react-bootstrap-table-page-btns-ul li a {
		border-radius: 1px !important;
		background-color: #f6f6f6 !important;
		border-color: #cccccc !important;
		border: 1px solid;
		display: inline-block;
		cursor: pointer;
		color: #666666 !important;
		font-family: ${p => p.theme.typography.text};
		font-weight: 500;
		text-decoration: none;
		align-items: baseline;
		outline: none;
		-webkit-transition: all 400ms ease;
		transition: all 400ms ease;
		box-shadow: 2px 2px 5px -5px #000000;
	}

	.react-bootstrap-table-page-btns-ul li.active a {
		border-radius: 1px !important;
		background-color: rgba(141, 114, 249, 0.2) !important;
		border-color: #cccccc !important;
		border: 1px solid;
		display: inline-block;
		cursor: pointer;
		color: #666666 !important;
		font-family: ${p => p.theme.typography.text};
		font-weight: 500;
		text-decoration: none;
		align-items: baseline;
		outline: none;
		-webkit-transition: all 400ms ease;
		transition: all 400ms ease;
		box-shadow: 2px 2px 5px -5px #000000;
	}

	/* ----END Tables----- */

	/* reporting integrations */
	.datePicker {
		border-radius: 5px;
		border: 1px solid #ccc;
		padding: 8px;
		height: 38px;
	}

	/* reporting scheduled reports edit */
	.timePicker {
		border-radius: 5px;
		border: 1px solid #ccc;
		padding: 8px;
		width: 68px;
		height: 38px;
		text-align: center;
	}

	.timePickerMenu {
		border-radius: 5px;
		border: 1px solid #ccc;
		padding: 8px;
		width: 58px;
		height: 26px;
		text-align: center;
		font-size: 14px;
		font-weight: 500;
	}

	.react-datepicker__time-container
	{
		width: auto !important;
	}
	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
	{
		width: auto;
	}
	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
		background: #41ce84;
		width: auto;
	}


	.navbar-light button:focus {
		outline: none;
	}

	[type="checkbox"]:checked + label:before {
		background-color: #41ce84 !important;
		border-color: #33a46a !important;
	}

	.form-control:focus {
		border-color: #d5dbdf !important;
		box-shadow: unset !important;
		box-shadow: inset 0 0 0 1px #d5dbdf !important;
	}

	.search-label {
		width: 100%;
	}

	/* t.ex i scheduled reports under edit */
	.popover {
		box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05), 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
	}


	/* fel i refund */
	.errorSvg {
		width: 100px;
		display: block;
		margin: auto;
	}

	.ErrorSvgPath {
		stroke-dasharray: 1000;
		stroke-dashoffset: 0;
		&.circle {
			-webkit-animation: errorSvgDash 0.5s ease-in-out;
			animation: errorSvgDash 0.5s ease-in-out;
		}
		&.line {
			stroke-dashoffset: 1000;
			-webkit-animation: errorSvgDash 0.5s 0.35s ease-in-out forwards;
			animation: errorSvgDash 0.5s 0.35s ease-in-out forwards;
		}
	}

	@keyframes errorSvgDash {
		0% {
			stroke-dashoffset: 1000;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}

	/* time picker för menu editor */
	.time_picker_modal_container {
		width: 300px !important;
	}
	.time_picker_modal_header,
	.time_picker_modal_footer,
	.timezone_picker_modal_header {
		background-color: #41ce84 !important;
	}
	.picker_pointer,
	.picker_center,
	.picker_minute_point,
	.picker_pointer .pointer_drag {
		background-color: #41ce84 !important;
	}

	.bigButton {
		color: #616161 !important;
		background-color: #fcfcfc !important;
		border: 1px solid #ccc;
		padding: 4px;
		padding-left: 8px;
		padding-right: 8px;
	}

	.react-bootstrap-table table {
		margin-bottom: 0px !important;
		width: 100%;
		table-layout: auto;
	}
	.react-bootstrap-table .table td:first-child, .table th:first-child {
	}
	.hide { display: none; }

	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
		border: none;
		color: #333;
		padding-left: 16px;
		padding-right: 16px;
		border: 0;
		box-shadow: none;
		outline: none;
		border-bottom: 1px solid transparent;
	}
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
		background-color: inherit;
		border-bottom-color: #41ce83;
		border-bottom-width: 2px;
	}

	// react-bootstrap-typeahead
	.rbt-input-multi {
		padding: ${p => p.theme.space.small};
	}
	
	.rbt-token {
		align-items: center;
    	color: ${p => p.theme.color.text.primary};
		background-color: ${p => p.theme.color.dirtyWhite};

		& .rbt-token-remove-button {
			display: flex;
			align-items: stretch;
			padding-top: 0;
			padding-bottom: 0;

			& span {
				display: flex;
				align-items: center;
				line-height: 1;
				position: relative;
				top: 2px;
			}
		}
	}
	.custom-control-input {
		background-color: #f00 !important;;
		&:checked {
			background-color: #41ce83 !important;;
			border-color: #41ce83 !important;;
		}
	
		&:focus {
			box-shadow: none;
		}
	}
`;

import blobFromCanvas from "./blob-from-canvas";
import createImage from "./create-image";

/**
 * A common issue on new mobile phones (like iPhone 13 Pro) is that they
 * take the photo in such a high resolution, that it exceeds the memory
 * limit on Safari to draw a canvas which makes cropping impossible. To avoid
 * this we can scale down the image to maximum 2000px in either direction, so
 * we will never have an image larger than 2000x2000.
 */
const scaleDownLargePhoto = async imageSrc => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	// Don't allow any side to be larger than 2000 px;
	const sideLimit = 2000;

	const largestSide = Math.max(image.width, image.height);
	const scale = largestSide < sideLimit ? 1 : sideLimit / largestSide;

	canvas.width = image.width * scale;
	canvas.height = image.height * scale;

	ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

	return blobFromCanvas(canvas);
};

export default scaleDownLargePhoto;

import { useSwitch } from "core/utils";
import { useEffect } from "react";

const useIsLoading = value => {
	const [isLoading, show, hide] = useSwitch(false);

	useEffect(() => {
		value ? show() : setTimeout(() => hide(), 500);
	}, [value, show, hide]);

	return isLoading;
};

export default useIsLoading;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import useNavItem from "../use-nav-item";

const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0;
	cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
	font-size: 14px;
	line-height: 1;
	color: ${p => (p.active === "true" ? "#41ce83" : "#fff")};
	margin-right: 12px;
	position: relative;
	top: -1.2px;
`;

const Label = styled.div`
	font-size: 14px;
	line-height: 1;
	font-weight: 500;
	color: ${p => (p.active ? "#41ce83" : "#fff")};
`;

const Item = ({ item }) => {
	const { to, onClick, icon, label } = item;
	const history = useHistory();
	const { isActive, haveAccess } = useNavItem(item);

	const handleClick = () => (to ? history.push(to) : onClick());

	if (!haveAccess) {
		return null;
	}

	return (
		<Container onClick={handleClick}>
			{/* Font awesome don't allow arbitrary boolean props, hence .toString() */}
			<Icon icon={icon} active={isActive.toString()} fixedWidth />

			<Label active={isActive}>{label}</Label>
		</Container>
	);
};

export default Item;

import { useSwitch } from "core/utils";
import { httpClient } from "data";
import { useCallback, useEffect, useState } from "react";

const useReports = () => {
	const [payouts, setPayouts] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const fetchPayouts = useCallback(
		(args = { loader: true }) => {
			args?.loader && startLoading();
			return httpClient
				.get("/reporting/merchant/payouts")
				.then(setPayouts)
				.finally(stopLoading);
		},
		[startLoading, stopLoading]
	);

	useEffect(() => {
		fetchPayouts();
	}, [fetchPayouts]);

	return [payouts, isLoading, fetchPayouts];
};

export default useReports;

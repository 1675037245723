import React from "react";
import { Routes } from "core/components";
import Providers from "./Providers";

const App = () => (
	<Providers>
		<Routes />
	</Providers>
);

export default App;

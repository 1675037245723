import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { MultiRowInput } from "core/components";
import React from "react";

const MultiText = ({ value, onChange, item, row, ...props }) => {
	const validateText = text => {
		if (typeof item.validate === "function") {
			return !item.validate(["text"], { text })
				? props.invalidMessage || "Can´t be empty"
				: null;
		}
		if (Array.isArray(item.validate)) {
			for (const validate of item.validate) {
				if (typeof validate !== "function") continue;
				if (validate(["text"], { text })) return props.invalidMessage || "Can´t be empty";
			}
		}
	};

	const setTextRows = textRows => {
		const validTextRows = Array.isArray(textRows)
			? textRows.filter(text => !validateText(text))
			: [];

		onChange(validTextRows);
	};
	return (
		<div style={{ width: "100%" }}>
			<MultiRowInput
				newRowButtonTitle={item.multiTextButtonTitle || "Add"}
				newRowButtonFontAwesomeIcon={faPlus}
				rows={value || [""]}
				parentRow={row}
				item={item}
				onlySetValidRows={false}
				setRows={setTextRows}
				inputWidth="100%"
				validationFunction={item.validateMultiTextRows ? validateText : null}
			/>
		</div>
	);
};

export default MultiText;

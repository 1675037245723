import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	align-items: stretch;
`;

const SecondaryMenu = ({ children }) => {
	return <Container>{children}</Container>;
};

export default SecondaryMenu;

import React from "react";
import { Form } from "react-bootstrap";

const Switch = ({ item, value, onChange }) => (
	<Form.Check
		type="switch"
		id={`switch-${item.prop}`}
		label={item.labelOnTop ? "" : item.editLabel || item.label}
		onChange={e => onChange(e.target.checked)}
		checked={value}
	/>
);

export default Switch;

import React from "react";
import { Col, Container as BootstrapContainer, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import K from "./k";

const Container = styled.div`
	padding-bottom: 16px;
`;

const Label = styled.div`
	clear: both;
	margin-left: 15px;
	margin-top: 20px;
`;

const StatNumber = styled.div`
	margin-top: 10px;
	text-align: left;
	font-size: 16px;
	font-weight: 500;
	height: 30px;
`;

const StatDescription = styled.div`
	font-size: 13px;
	text-align: left;
	margin-bottom: 20px;
`;

const Today = ({ dashboard }) => {
	if (!dashboard) {
		return null;
	}

	return (
		<Container>
			<Label>Today</Label>

			<BootstrapContainer {...{ ...(isMobile ? { xs: "auto" } : { fluid: true }) }}>
				<Row>
					<Col>
						<StatNumber>{K(dashboard.today_total)}</StatNumber>
						<StatDescription>Volume</StatDescription>
					</Col>
					<Col
						style={{
							borderLeft: "1px solid #efefef",
							borderRight: "1px solid #efefef"
						}}
					>
						<StatNumber>{K(dashboard.today_orders)}</StatNumber>
						<StatDescription>Orders</StatDescription>
					</Col>
					<Col>
						<StatNumber>{K(dashboard.today_users)}</StatNumber>
						<StatDescription>Active Users</StatDescription>
					</Col>
				</Row>
			</BootstrapContainer>
		</Container>
	);
};

export default Today;

import React from "react";
import { DateTimeColumn, PreOrderActiveColumn } from "./GridColumns";

const schema = timeZone => [
	{
		prop: "name",
		type: "text",
		align: "left",
		sort: true,
		headerAlign: "left",
		// eslint-disable-next-line react/display-name
		minimumGridVisibility: "xs",
		label: "Name"
	},
	{
		prop: "timeStart",
		type: "text",
		sort: true,
		minimumGridVisibility: "xs",
		label: "Time",
		// eslint-disable-next-line react/display-name
		formatter: cell => <DateTimeColumn cell={cell} timeZone={timeZone} />
	},
	{
		prop: "allowPreOrder",
		type: "text",
		sort: false,
		minimumGridVisibility: "md",
		label: "Pre order",
		// eslint-disable-next-line react/display-name
		formatter: PreOrderActiveColumn
	},
	{
		prop: "allowPreOrderNoLabel",
		type: "text",
		sort: false,
		maximumGridVisibility: "md",
		label: "Pre order",
		// eslint-disable-next-line react/display-name
		formatter: (cell, row) => PreOrderActiveColumn(cell, row, false)
	},
	{
		prop: "hasTimeSlots",
		type: "switch",
		sort: false,
		minimumGridVisibility: "md",
		label: "Has time slots"
		// eslint-disable-next-line react/display-name
	},
	{
		prop: "preOrderCount",
		type: "text",
		sort: false,
		minimumGridVisibility: "md",
		label: "No. of pre orders"
		// eslint-disable-next-line react/display-name
	}
];

export default schema;

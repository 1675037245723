import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "../Loader";
import Backdrop from "./Backdrop";
import Label from "./Label";
import LoaderContainer from "./LoaderContainer";
import Modal from "./Modal";

const LoadingModal = ({ isOpen: shouldBeOpen, label }) => {
	const [isOpen, setIsOpen] = useState();

	const setIsOpenDebounced = useMemo(
		() => debounce(setIsOpen, 750, { leading: true, trailing: true }),
		[]
	);

	useEffect(() => {
		setIsOpenDebounced(shouldBeOpen);
	}, [shouldBeOpen, setIsOpenDebounced]);

	if (!isOpen) {
		return null;
	}

	return (
		<Backdrop>
			<Modal>
				<LoaderContainer>
					<Loader size="large" color="green" />
				</LoaderContainer>

				{label && <Label label="">{label}</Label>}
			</Modal>
		</Backdrop>
	);
};

export default LoadingModal;

import React, { useEffect } from "react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Alert, Form } from "react-bootstrap";
import { useMerchantContext } from "context";
import { Button } from "core/components";
import { useSwitch, wait } from "core/utils";
import { reportingRepo } from "data/repos";
import urlcat from "urlcat";
import styled from "styled-components";
import CONFIG from "core/config";

const ButtonContainer = styled.div`
	display: flex,
	align-items: center
`;

const TextContainer = styled(Form.Text)`
	margin-left: 2px;
	margin-top: 8px;
`;

const ActivationForm = ({
	integrationSettings,
	setIntegrationSettings,
	setAlertMessageTop,
	setAlertTypeTop,
	setShowAlertTop
}) => {
	const { currentMerchant } = useMerchantContext();
	const [isLoading, startLoading, stopLoading] = useSwitch();
	const isFetching = !integrationSettings;

	useEffect(() => {
		currentMerchant &&
			reportingRepo
				.getOrSetFortnoxSettings({
					stripeAccount: currentMerchant.stripeId
				})
				.then(r => setIntegrationSettings(r));
	}, [currentMerchant, setIntegrationSettings]);

	const authenticateFortnox = async () => {
		window.open(
			urlcat(CONFIG.fortnoxAuthUrl, {
				client_id: integrationSettings.fortnoxClientId,
				scope: integrationSettings.defaultFortnoxScope?.join(" "),
				state: currentMerchant.merchantId,
				access_type: "offline",
				response_type: "code",
				account_type: "service"
			}),
			"_blank"
		);
		startLoading();
		getFortnoxStatus(currentMerchant);
	};

	const getFortnoxStatus = async currentMerchant => {
		while (true) {
			await wait(3000);
			const settings = await reportingRepo.getOrSetFortnoxSettings(currentMerchant);
			if (settings.fortnoxActivated) {
				setIntegrationSettings(settings);
				setAlertMessageTop("Successfully connected to Fortnox.");
				setAlertTypeTop("success");
				setShowAlertTop(true);
				stopLoading();
				return;
			}
		}
	};

	return (
		<div>
			<TextContainer>
				{isLoading && (
					<Alert variant="warning">
						Waiting for you to complete authentication in Fortnox.
					</Alert>
				)}
				{!isLoading && (
					<Alert variant="primary">
						Click connect to log in to Fortnox and authenticate Passbuy (opens external
						link).
					</Alert>
				)}
			</TextContainer>
			<ButtonContainer>
				<Button
					disabled={isFetching}
					icon={faExternalLinkAlt}
					onClick={authenticateFortnox}
					isLoading={isLoading}
					size="large"
					variant="primary"
				>
					Connect
				</Button>
			</ButtonContainer>
		</div>
	);
};

export default ActivationForm;

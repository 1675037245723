import imgLogo from "assets/passbuy-logo-204x50.png";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "context/UserContext";
import { Card } from "auth/components";
import { Button } from "core/components";

const PasswordLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const ForgotPassword = styled(Link)`
	font-size: 14px;
	align-self: center;
	margin-top: 12px;
	color: #000;

	&:hover,
	&:visited {
		color: #000;
	}
`;

const SignInForm = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState();
	const [signingIn, setSigningIn] = useState(false);
	const { signIn } = useUserContext();
	const history = useHistory();

	const dismissError = () => setError(false);

	const handleSubmit = () => {
		setSigningIn(true);
		signIn({ email, password })
			.then(() => history.push("/home"))
			.catch(error => {
				setError(error);
				setSigningIn(false);
			});
	};

	return (
		<Card>
			<Form
				onSubmit={e => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				<Form.Group>
					<div style={{ display: "flex", justifyContent: "center", marginBottom: 24 }}>
						<Image src={imgLogo} />
					</div>
					{error && (
						<Alert variant="danger" dismissible onClose={dismissError}>
							{error?.message}
						</Alert>
					)}
				</Form.Group>

				<Form.Group>
					<Form.Label>Email</Form.Label>
					<Form.Control
						type="text"
						value={email}
						autoFocus={true}
						onChange={e => setEmail(e?.target?.value)}
						data-test="email"
					/>
				</Form.Group>

				<Form.Group>
					<PasswordLabelContainer>Password</PasswordLabelContainer>

					<Form.Control
						type="password"
						value={password}
						onChange={e => setPassword(e?.target?.value)}
						data-test="password"
					/>
				</Form.Group>

				<Form.Group>
					<Button
						isLoading={signingIn}
						type="submit"
						data-test="submit"
						block
						size="large"
					>
						Sign in
					</Button>
				</Form.Group>
			</Form>

			<ForgotPassword to="/reset-password">Forgot your password?</ForgotPassword>
		</Card>
	);
};

export default SignInForm;

import { useMerchantContext } from "context";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";

const ProtectedRoute = props => {
	const { user } = useUserContext();
	const { hasFunctionAccess } = useMerchantContext();
	const { functionAccess } = props;

	if (!user) {
		return <Redirect to="/sign-in" />;
	}

	if (functionAccess && !hasFunctionAccess(functionAccess)) {
		return <Route />;
	}

	return <Route {...props} />;
};

export default ProtectedRoute;

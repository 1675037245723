import { equals } from "ramda";
import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	margin-bottom: ${p => p.theme.space.small};
	font-size: 16px;
	font-weight: ${p => (p.active ? "500" : "400")};
	cursor: pointer;
	position: relative;
	left: -1px;
	border-left: solid ${p => (p.active ? p.theme.color.emeraldGreen : "transparent")};
	border-left-width: ${p => (p.active ? 2 : 1)}px;
	padding-left: ${p => (p.active ? 1 : 2)}px;

	&:hover {
		${p => !p.active && `border-left: 1px solid ${p.theme.color.lightGray}`};
	}

	&:last-child {
		margin-bottom: 0;
	}

	@media ${mediaQuery(device.tabletLarge)} {
		font-size: 14.5px;
	}
`;

const ContainerInner = styled.div`
	padding-left: 10px; // theme.space.medium - Container padding / border
	padding-left: ${p => p.isChild && p.theme.space.huge};
`;

const Item = ({ item, activeItem, onClick }) => {
	const { id, label, parent } = item;
	const isItemActive = itemId => activeItem && equals(itemId, activeItem.id);
	const isActive = isItemActive(id);
	const isChild = !!parent;
	const isParentActive = isItemActive(parent);
	const isSiblingActive = activeItem?.parent === parent;

	const handleClick = () => onClick(item);

	if (isChild && !isActive && !isParentActive && !isSiblingActive) {
		return null;
	}

	return (
		<Container active={isActive} onClick={handleClick} key={id}>
			<ContainerInner isChild={isChild}>{label}</ContainerInner>
		</Container>
	);
};

export default Item;

import React, { useEffect, useMemo, useState } from "react";
import { SectionHeader, SectionLoader, TabContainer } from "core/components";
import styled from "styled-components";
import BookkeepingAccounts from "./BookkeepingAccounts";
import { httpClient } from "data";
import { useMerchantContext } from "context";
import Status from "./Status";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Fortnox = ({ refresh }) => {
	const { currentMerchant } = useMerchantContext();
	const [integrationSettings, setIntegrationSettings] = useState();
	const [oldIntegrationSettings, setOldIntegrationSettings] = useState();
	const [exportFromDate, setExportFromDate] = useState();
	const tabs = useMemo(
		() => [
			{
				id: "status",
				label: "Status",
				content: (
					<Status
						{...{
							integrationSettings,
							setIntegrationSettings,
							refresh,
							exportFromDate,
							oldIntegrationSettings,
							setExportFromDate
						}}
					/>
				)
			},
			...(integrationSettings?.fortnoxActivated
				? [
						{
							id: "bookkeeping-accounts",
							label: "Accounts",
							content: (
								<BookkeepingAccounts
									{...{
										integrationSettings,
										setIntegrationSettings,
										oldIntegrationSettings,
										setOldIntegrationSettings
									}}
								/>
							)
						}
				  ]
				: [])
		],
		[
			integrationSettings,
			setIntegrationSettings,
			oldIntegrationSettings,
			setOldIntegrationSettings,
			refresh,
			exportFromDate,
			setExportFromDate
		]
	);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	useEffect(() => {
		const { stripeId: stripeAccount, timeZone } = currentMerchant;

		httpClient
			.put("/reporting/fortnox-merchant/", { body: { stripeAccount, timeZone } })
			.then(r => {
				setIntegrationSettings(r);
				setOldIntegrationSettings(r);
				if (r.exportFromDate) setExportFromDate(new Date(r.exportFromDate));
			});
	}, [currentMerchant, setIntegrationSettings]);

	return (
		<Container>
			<SectionHeader
				heading="Fortnox"
				tabs={tabs}
				activeTab={activeTab}
				onTabSelected={setActiveTab}
			/>

			{!integrationSettings && <SectionLoader />}

			{integrationSettings && <TabContainer tabs={tabs} activeTab={activeTab} />}
		</Container>
	);
};

Fortnox.defaultProps = {
	refresh: () => {}
};

export default Fortnox;

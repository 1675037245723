import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { prop } from "ramda";
import { useMerchantContext } from "context";
import { httpClient } from "data";
import { PageLayout, SectionHeader, SectionLoader } from "core/components";
import Today from "./Today";
import ChartTabs from "./ChartTabs";
import TopProducts from "./TopProducts";

const Container = styled.div`
	flex-direction: column;
	flex-grow: 1;
`;

const Dashboard = () => {
	const { hasFunctionAccess, currentStore } = useMerchantContext();
	const [dashboard, setDashboard] = useState();
	const [period, setPeriod] = useState("1W");
	const [graphData, setGraphData] = useState([]);
	const history = useHistory();

	if (!hasFunctionAccess("dashboard_view")) {
		history.push("/order-tickets");
	}

	useEffect(() => {
		if (currentStore) {
			httpClient
				.fetch(`/merchants/dashboard?period=${period}&storeId=${currentStore.storeId}`)
				.then(prop("dashboard"))
				.then(d => {
					setDashboard(d);
					const data = d.orders_revenue.map(o => ({
						name: o.date,
						orders: o.orders,
						users: o.users,
						total: o.total
					}));
					setGraphData(data);
				});
		}
	}, [period, currentStore]);

	return (
		<PageLayout>
			<Container>
				<SectionHeader heading="Overview"></SectionHeader>

				{!dashboard && <SectionLoader />}

				<Today dashboard={dashboard} />

				<ChartTabs dashboard={dashboard} graphData={graphData} onPeriodChange={setPeriod} />

				<TopProducts dashboard={dashboard} />
			</Container>
		</PageLayout>
	);
};

export default Dashboard;

import React from "react";
import SignInForm from "./SignInForm";
import { PageContainer } from "auth/components";

const SignInPage = () => {
	return (
		<PageContainer>
			<SignInForm />
		</PageContainer>
	);
};

export default SignInPage;

import React, { forwardRef } from "react";
import styled from "styled-components";
import { compose, defaultTo, path } from "ramda";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fallbackStoreImage from "assets/fallback-store-image.png";

const Container = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 0;
`;

const RoundImage = styled.img`
	width: 28px;
	height: 28px;
	border-radius: 28px;
`;

const Name = styled.span`
	color: #fff;
	font-family: Peak;
	font-weight: 500;
	text-align: left;
	padding-left: 8px;
	font-size: 14.5px;
	line-height: 1.05;
	user-select: none;
	margin-bottom: 1.5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const DropdownContainer = styled.div`
	padding-left: 8px;
`;

const DropdownIcon = styled(FontAwesomeIcon)`
	color: #fff;
	font-size: 10.5px;
`;

const CurrentStore = ({ store, onClick, isDisabled }, ref) => {
	const { name } = store;
	const imageSrc = compose(defaultTo(fallbackStoreImage), path(["image", "small"]))(store);

	return (
		<Container onClick={onClick} ref={ref} data-test="current-store">
			<RoundImage src={imageSrc} alt="store" />

			<Name>{name}</Name>

			{!isDisabled && (
				<DropdownContainer>
					<DropdownIcon icon={faChevronDown} />
				</DropdownContainer>
			)}
		</Container>
	);
};

export default forwardRef(CurrentStore);

import React from "react";

const Component = ({ openStyle, closedStyle, children, isFullScreen }) => {
	const containerStyle = () =>
		isFullScreen
			? { ...s.conatinerFullScreen, ...openStyle }
			: { ...s.containerNotFullScreen, ...closedStyle };

	return <div style={containerStyle()}>{children}</div>;
};

Component.displayName = "FullScreenContainer";

export default Component;

const s = {
	conatinerFullScreen: {
		background: "#fff",
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		overflowY: "scroll"
	},
	containerNotFullScreen: {
		flexGrow: 1
	}
};

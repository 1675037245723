import {
	addIndex,
	ascend,
	assocPath,
	compose,
	descend,
	filter,
	map,
	prop,
	sortWith,
	toPairs
} from "ramda";
import React, { useCallback, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Item from "./Item";

const sortOptions = sortWith([
	descend(prop("checked")),
	ascend(prop("sortOrder")),
	ascend(prop("key"))
]);

const mapIndexed = addIndex(map);

/*
	Shows options that can be switch on/off and sorted.

	Example:

	Item properties:
		prop: "colors"
		keyProp: "color"
		sortable: true
		options: [
			{
				key: "RED",
				label: "Red"
			},
			{
				key: "GREEN",
				label: "Green"
			},
			{
				key: "BLUE",
				label: "Blue"
			}
		]

	Input/Output:
		[
			{
				color: "RED",
				sortOrder: 0
			},
			// Green is ignored since it's not checked
			,
			{
				color: "BLUE",
				sortOrder: 1
			}
		]
*/

const SortableOptions = ({ item, value, onChange }) => {
	// Merge the options and the values to create an array
	// with checked and sort order
	const options = useMemo(
		() =>
			compose(
				mapIndexed((o, index) => ({ ...o, sortOrder: index })),
				sortOptions,
				map(o => {
					const v = value?.find(x => x[item.keyProp] === o.key);
					return {
						key: o.key,
						label: o.label,
						checked: !!v,
						sortOrder: v ? v.sortOrder : undefined
					};
				})
			)(item.options),
		[item, value]
	);

	// Keep a map with the option key as key and the checked/sort order properties
	const keys = useMemo(
		() =>
			options.reduce((prev, current) => {
				return {
					...prev,
					[current.key]: {
						checked: current.checked,
						sortOrder: current.sortOrder
					}
				};
			}, {}),
		[options]
	);

	// Post process function to process the checked values into an array that will be set as value
	const postProcess = useCallback(
		(keys, dragSourceIndex, dragDestinationIndex) =>
			compose(
				map(([key, properties]) => {
					let sortOrder = properties.sortOrder;

					// Flip the sort order when source / destination indexes
					// from the onDragEnd result is provided
					if (dragSourceIndex === properties.sortOrder) sortOrder = dragDestinationIndex;
					if (dragDestinationIndex === properties.sortOrder) sortOrder = dragSourceIndex;

					return {
						[item.keyProp]: key,
						sortOrder
					};
				}),
				// eslint-disable-next-line no-unused-vars
				filter(([key, properties]) => properties.checked),
				toPairs
			)(keys),
		[item]
	);

	const onDragEnd = useCallback(
		result => onChange(postProcess(keys, result.source.index, result.destination?.index)),
		[keys, onChange, postProcess]
	);

	const onSwitch = useCallback(
		(key, checked) =>
			onChange(
				postProcess(
					// Update the checked property the key that was switched
					assocPath([key, "checked"], checked, keys)
				)
			),
		[keys, onChange, postProcess]
	);

	const content = useMemo(() => {
		if (item.sortable)
			return (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={item.prop} type="Section">
						{provided => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{options.map(option => (
									<Draggable
										key={option.key}
										draggableId={option.key}
										index={option.sortOrder}
										isDragDisabled={!option.checked}
										type="Section"
									>
										{provided => (
											<Item
												provided={provided}
												option={option}
												onSwitch={onSwitch}
											/>
										)}
									</Draggable>
								))}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			);

		return options.map(option => <Item key={option.key} option={option} onSwitch={onSwitch} />);
	}, [options, item, onDragEnd, onSwitch]);

	return content;
};

export default SortableOptions;

import { Loader } from "core/components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	font-size: 16px;
	align-items: center;
`;
const RedirectMessage = styled.div`
	margin-bottom: ${p => p.theme.space.huge};
`;

const FallbackLink = styled.div`
	margin-top: ${p => p.theme.space.huge};
`;

const SigninRedirectMessage = ({ seconds = 3 }) => {
	const [showFallbackLink, setShowFallbackLink] = useState(false);

	useEffect(() => {
		setTimeout(() => setShowFallbackLink(true), (seconds + 2) * 1000);
	});

	return (
		<Container>
			{!showFallbackLink && (
				<RedirectMessage>
					You will be signed in automatically in {seconds} seconds.
				</RedirectMessage>
			)}
			{!showFallbackLink && <Loader center padding="large" />}
			{showFallbackLink && (
				<FallbackLink>
					Not redirected? Log in <a href="/">here</a>
				</FallbackLink>
			)}
		</Container>
	);
};

export default SigninRedirectMessage;

const getTableActions = ({ merchant, hasFunctionAccess }) => {
	if (!hasFunctionAccess("product_edit")) return {};
	if (merchant.externalProductSync) return {};

	return {
		delete: {
			apiPath: "/product-groups/{productGroupId}?storeId={activeStoreId}",
			apiMethod: "DELETE",
			apiExtraParams: ["activeStoreId"]
		},
		edit: {
			inline: true,
			modal: true,
			modalTitle: "Edit product group",
			apiPath: "/product-groups/{productGroupId}?storeId={activeStoreId}",
			apiExtraParams: ["activeStoreId"],
			apiMethod: "PUT"
		}
	};
};

export default getTableActions;

import { Card } from "auth/components";
import SigninRedirectMessage from "auth/components/SigninRedirectMessage";
import React from "react";
import { Heading } from "./Welcome.styles";

const Consumed = () => {
	return (
		<Card size="huge">
			<Heading style={{ textAlign: "center" }}>You are ready to go!</Heading>
			<SigninRedirectMessage seconds={3}></SigninRedirectMessage>
		</Card>
	);
};

export default Consumed;

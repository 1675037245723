import React from "react";
import BsBadge from "react-bootstrap/Badge";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Badge = styled(BsBadge)`
	margin-right: 8px;
	min-width: 20px;
	font-size: 12px;
	font-weight: 500;
	background: ${p => p.theme.color.softGray};

	@media ${mediaQuery(device.tablet)} {
		min-width: 24px;
		font-size: 14px;
	}
`;

const QuantityColumn = quantity => {
	return <Badge>{quantity}</Badge>;
};

export default QuantityColumn;

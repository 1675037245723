import { always, compose, cond, equals, prop } from "ramda";
import React from "react";
import styled from "styled-components";
import Time from "./Time";
import Title from "./Title";

const Container = styled.div`
	display: flex;
	justify-content: center;
	border-radius: 4px 4px 0 0;
	overflow: hidden;
	background-color: ${compose(
		cond([
			[equals("green"), always("#41CE84")],
			[equals("purple"), always("#8E72F9")],
			[equals("goldenSand"), always("#FFC54A")]
		]),
		prop("headerColor")
	)};
`;

const Header = props => {
	const { headerColor } = props;

	return (
		<Container headerColor={headerColor}>
			<Title {...props} />
			<Time {...props} />
		</Container>
	);
};

export default Header;

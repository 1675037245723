import React from "react";
import BsModal from "react-bootstrap/Modal";
import { Footer } from "./Modal.styles";

const Modal = ({ show, onHide, onExited, title, body, footer, children }) => {
	return (
		<BsModal
			{...{
				show,
				onHide,
				onExited
			}}
			centered
		>
			{title && (
				<BsModal.Header>
					<BsModal.Title>{title}</BsModal.Title>
				</BsModal.Header>
			)}

			<BsModal.Body>{body || children}</BsModal.Body>

			{footer && <Footer>{footer}</Footer>}
		</BsModal>
	);
};

Modal.defaultProps = {
	show: false,
	onConfirm: () => {},
	onCancel: () => {}
};

export default Modal;

/* eslint-disable react/display-name */
import moment from "moment-timezone";

const schema = ({ timeZone }) => [
	{
		prop: "status",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Status",
		sort: true
	},
	{
		prop: "arrivalDate",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Arrival date",
		sort: true,
		formatter: cell => moment.utc(cell).tz(timeZone).format("YYYY-MM-DD")
	},
	{
		prop: "amount",
		type: "number",
		minimumGridVisibility: "xs",
		label: "Amount",
		sort: true
	},
	{
		prop: "fee",
		type: "number",
		minimumGridVisibility: "xs",
		label: "Fee",
		sort: true
	}
];

export default schema;

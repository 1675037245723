import React from "react";
import { compose, prop, sortBy, map, sort } from "ramda";
import Report from "./Report";
import Dropdown from "./Dropdown";
import styled from "styled-components";

const ReportsContainer = styled.div`
	margin-bottom: ${p => p.theme.space.medium};
`;

const Reports = ({ reports, setReports, reportFunctions, editMode }) => {
	const handleRemove = r => {
		const data = JSON.parse(JSON.stringify(reports || []));
		setReports(data?.filter(o => o.key !== r.key));
	};

	const renderReports = compose(
		map(r => <Report report={r} onRemove={handleRemove} removable={editMode} key={r.key} />),
		sort(sortBy(prop("key")))
	);

	return (
		<>
			<ReportsContainer>{reports.length > 0 && renderReports(reports)}</ReportsContainer>

			{editMode && <Dropdown {...{ reports, reportFunctions, setReports }} />}
		</>
	);
};

export default Reports;

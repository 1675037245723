import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Dropdown as BsDropdown } from "react-bootstrap";
import DropdownItem from "./DropdownItem";
import { compose, map, propEq, add, filter, differenceWith, eqProps } from "ramda";
import { Button } from "core/components";
import { useSwitch } from "core/utils";

const Dropdown = ({ reports, reportFunctions, setReports }) => {
	const [isOpen, open, close] = useSwitch(false);

	const availableReportFunctions = compose(
		filter(propEq("exportType", "EMAIL")),
		differenceWith(eqProps("reportFunctionId"), reportFunctions)
	)(reports);

	const handleClick = rf => {
		const nextIndex = reports?.length > 0 ? add(reports[reports.length - 1].key[0], 1) : 0;

		const reportJobFunction = {
			reportFunctionId: rf.reportFunctionId,
			outputType: rf.functionOutputType,
			name: rf.name,
			description: rf.description,
			key: `${nextIndex}${Math.floor(100000 + Math.random() * 900000)}`
		};

		setReports(reports => [...reports, reportJobFunction]);
	};

	const handleToggle = (isOpen, ignore, { source }) => {
		if (!source) {
			return;
		}

		isOpen ? open() : close();
	};

	const renderReportFunctions = map(rf => (
		<DropdownItem reportFunction={rf} onClick={handleClick} key={rf.reportFunctionId} />
	));

	return (
		<BsDropdown onToggle={handleToggle} show={isOpen}>
			<BsDropdown.Toggle
				as="span"
				id="dropdown-basic-button"
				bsPrefix="reportFunctionDropdown"
			>
				<Button icon={faPlus} onClick={open} size="small">
					Add report
				</Button>
			</BsDropdown.Toggle>

			<BsDropdown.Menu align="end">
				{renderReportFunctions(availableReportFunctions)}
			</BsDropdown.Menu>
		</BsDropdown>
	);
};

export default Dropdown;

import { useSwitch } from "core/utils";
import { httpClient } from "data";
import { useCallback, useEffect, useState } from "react";

const usePayoutDetails = payoutId => {
	const [payoutDetails, setPayoutDetails] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const fetchPayoutDetails = useCallback(
		(args = { loader: true }) => {
			args?.loader && startLoading();
			return httpClient
				.get(`/reporting/merchant/payout-details/${payoutId}`)
				.then(setPayoutDetails)
				.finally(stopLoading);
		},
		[startLoading, stopLoading, payoutId]
	);

	useEffect(() => {
		fetchPayoutDetails();
	}, [fetchPayoutDetails, payoutId]);

	return [payoutDetails, isLoading, fetchPayoutDetails];
};

export default usePayoutDetails;

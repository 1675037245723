import React from "react";
import { Amount } from "core/components";
import { Badge } from "react-bootstrap";
import theme from "styles/theme";

const schema = ({ refundPickerActive }) => [
	{
		prop: "product.name",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Name"
	},
	{
		prop: "quantity",
		type: "text",
		align: "right",
		headerAlign: "right",
		minimumGridVisibility: "xs",
		label: "Quantity"
	},
	...(!refundPickerActive
		? [
				{
					prop: "discountTotal",
					type: "text",
					label: "Discount",
					align: "right",
					headerAlign: "right",
					// eslint-disable-next-line react/display-name
					formatter: discountTotal => <Amount value={discountTotal} />
				}
		  ]
		: []),
	{
		prop: "refunds",
		isDummyField: true,
		editable: false,
		label: "Total",
		align: "right",
		headerAlign: "right",
		minimumGridVisibility: refundPickerActive ? "md" : "xs",
		// eslint-disable-next-line react/display-name
		formatter: (cell, row) => {
			if (row.refundedCount > 0 || row.refundingCount > 0) {
				return (
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ width: "50%", placeSelf: "center", marginRight: "8px" }}>
							<Amount value={row.total} />
						</div>
						<div style={{ overflow: "auto", display: "block", textAlign: "right" }}>
							{row.refundingCount > 0 && (
								<Badge
									style={{
										backgroundColor: theme.color.goldenSand,
										color: "#fff",
										marginBottom: 3,
										marginLeft: 10,
										wordBreak: "break-word",
										whiteSpace: "break-spaces",
										margin: "auto"
									}}
								>
									{row.refundingCount}
									{row.refundingCount > 1 ? " refunds " : " refund "}
									in progress
								</Badge>
							)}
							{row.refundedCount > 0 && (
								<Badge
									style={{
										backgroundColor: theme.color.purpleStreet,
										color: "#fff",
										marginLeft: 10,
										wordBreak: "break-word",
										whiteSpace: "break-spaces",
										margin: "auto"
									}}
								>
									{row.refundedCount} refunded
								</Badge>
							)}
						</div>
					</div>
				);
			}
			return <Amount value={row.total} />;
		}
	}
];

export default schema;

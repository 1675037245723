import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Station from "./Station";
import { PageLayout, SectionLoader } from "core/components";
import { fetchStation as fetchStationFromApi } from "./functions";
import { useMerchantContext } from "context";
import logEvent from "core/utils/log-event";

const StationView = () => {
	const [station, setStation] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();
	const { currentStore } = useMerchantContext();
	const { stationId } = useParams();

	useEffect(() => {
		if (!station) {
			return;
		}

		if (station.storeId === currentStore.storeId) {
			return;
		}

		history.push("/stations");
	}, [currentStore, station, history]);

	const fetchStation = useCallback(async stationId => {
		try {
			setIsLoading(true);
			const station = await fetchStationFromApi({ stationId });

			if (station.error) {
				return;
			}

			logEvent("station_loaded", station);

			setStation(station);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		if (!stationId) {
			return;
		}

		fetchStation(stationId);
	}, [fetchStation, stationId]);

	// A refresh is needed to fetch new settings applied by to the station from the config modal.
	// It also rerenders the tickets, preventing them from preforming unwanted effects, such as
	// playing the enter sound when enabling sound in the station config.
	const handleRefresh = useCallback(() => {
		fetchStation(stationId);
	}, [fetchStation, stationId]);

	return (
		<PageLayout>
			{isLoading && <SectionLoader />}
			{station && !isLoading && <Station station={station} onRefresh={handleRefresh} />}
		</PageLayout>
	);
};

export default StationView;

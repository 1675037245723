import React from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DeleteReportModal from "./DeleteReportModal";
import { useSwitch } from "core/utils";
import { httpClient } from "data";
import ActionButton from "./ActionButton";

const DeleteAction = ({ reportId, refresh }) => {
	const [isModal, openModal, closeModal] = useSwitch();
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const deleteReport = async () => {
		startLoading();
		await httpClient.delete(`/reporting/reports/${reportId}`);
		await refresh();
		stopLoading();
	};

	return (
		<>
			<ActionButton
				onClick={openModal}
				icon={faTrashAlt}
				data-test="reports-actions-delete-button"
			/>

			<DeleteReportModal
				show={isModal}
				onConfirm={deleteReport}
				onClose={() => closeModal()}
				isLoading={isLoading}
			/>
		</>
	);
};

export default DeleteAction;

import { useSwitch } from "core/utils";
import { httpClient } from "data";
import { useCallback, useEffect, useState } from "react";

const useReports = () => {
	const [reports, setReports] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const fetchReports = useCallback(
		(args = { loader: true }) => {
			args?.loader && startLoading();
			return httpClient.get("/reporting/reports/").then(setReports).finally(stopLoading);
		},
		[startLoading, stopLoading]
	);

	useEffect(() => {
		fetchReports();
	}, [fetchReports]);

	return [reports, isLoading, fetchReports];
};

export default useReports;

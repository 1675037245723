import { useMerchantContext } from "context";
import { GridSync } from "core/components/Grid";
import { useSwitch } from "core/utils";
import { productImportLogRepo } from "data/repos";
import { compose, defaultTo, filter, head } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import urlcat from "urlcat";
import ProductImportStatus from "./ProductImportStatus";

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const ProductSync = ({ onRefresh }) => {
	const {
		currentStore,
		currentPriceListId,
		currentMerchant: { externalProductSync }
	} = useMerchantContext();

	const [isLoading, startLoading, stopLoading] = useSwitch(false);
	const [lastProductImport, setLastProductImport] = useState(null);

	const fetchProductImportLogs = useCallback(async () => {
		if (!externalProductSync) return;

		const getLastFinalizedLog = compose(
			head,
			filter(l => l.timeError || l.timeCompleted),
			defaultTo([])
		);
		const lastFinalizedLog = await productImportLogRepo
			.getProductImportLogs()
			.then(getLastFinalizedLog);

		if (lastFinalizedLog) setLastProductImport(lastFinalizedLog);
	}, [externalProductSync]);

	const handleBeginSync = useCallback(() => {
		startLoading();
	}, [startLoading]);
	const handleFinishSync = useCallback(async () => {
		await fetchProductImportLogs();
		await onRefresh();
		stopLoading();
	}, [stopLoading, onRefresh, fetchProductImportLogs]);

	useEffect(() => {
		fetchProductImportLogs();
	}, [fetchProductImportLogs]);

	return (
		<Container>
			{lastProductImport && !isLoading && (
				<ProductImportStatus productImport={lastProductImport} />
			)}
			<GridSync
				buttonProps={{ label: "Sync" }}
				onBeginSync={handleBeginSync}
				onFinishSync={handleFinishSync}
				apiMethod="GET"
				apiPath={urlcat("/products/sync", {
					storeId: currentStore.storeId,
					priceListId: currentPriceListId
				})}
			/>
		</Container>
	);
};

export default ProductSync;

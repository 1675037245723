import firebase from "core/firebase-consumer-api";
import { mapFirestoreDocChanges } from "core/utils";
import { compose, prop } from "ramda";
import OrderTicket from "./OrderTicket";

const mapChangeToEvent = change => ({
	type: change.type,
	orderTicket: compose(OrderTicket, prop("doc"))(change)
});

const handleSnapshot = cb => compose(cb, mapFirestoreDocChanges(mapChangeToEvent));

export default ({
	stationId,
	statuses,
	limit,
	orderBy = "timeCreated",
	orderDirection = "asc",
	callback
}) => {
	if (typeof stationId !== "string") {
		throw new Error("stationId is required");
	}

	if (!Array.isArray(statuses)) {
		throw new Error("statuses is required");
	}

	if (typeof callback !== "function") {
		throw new Error("callback is required");
	}

	if (typeof limit !== "number") {
		throw new Error("limit is required");
	}

	return firebase
		.firestore()
		.collection("orderTickets")
		.where("stationId", "==", stationId)
		.where("status", "in", statuses)
		.orderBy(orderBy, orderDirection)
		.limit(limit)
		.onSnapshot(handleSnapshot(callback));
};

import { always, both, complement, cond, either, has, propEq, T } from "ramda";
import React from "react";
import OrderTicketCompleted from "./OrderTicketCompleted";
import OrderTicketOpen from "./OrderTicketOpen";
import OrderTicketReadyForPickup from "./OrderTicketReadyForPickup";

const isNotEager = complement(has("eagerStatus"));
const isCreated = both(isNotEager, propEq("status", "CREATED"));
const isReadyForPickup = either(
	propEq("eagerStatus", "READY_FOR_PICKUP"),
	propEq("status", "READY_FOR_PICKUP")
);
const isDone = both(isNotEager, propEq("status", "DONE"));

const OrderTicket = props => {
	return cond([
		[isCreated, always(<OrderTicketOpen {...props} />)],
		[isReadyForPickup, always(<OrderTicketReadyForPickup {...props} />)],
		[isDone, always(<OrderTicketCompleted {...props} />)],
		[T, always(null)]
	])(props.orderTicket);
};

export default OrderTicket;

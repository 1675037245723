import blobFromCanvas from "./blob-from-canvas";
import createImage from "./create-image";

const fillBackground = async (imageSrc, expectedRatio, fillColor) => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	canvas.width = image.width;
	canvas.height = image.height;

	const ratio = image.width / image.height;
	const tooHigh = ratio < 4 / 3;
	const tooWide = ratio > 4 / 3;

	if (tooHigh) canvas.width = canvas.height * expectedRatio;
	else if (tooWide) canvas.height = canvas.width / expectedRatio;
	else return imageSrc;

	ctx.fillStyle = fillColor;
	ctx.fillRect(0, 0, canvas.width, canvas.height);

	if (tooHigh)
		ctx.drawImage(image, canvas.width / 2 - image.width / 2, 0, image.width, image.height);
	else if (tooWide)
		ctx.drawImage(image, 0, canvas.height / 2 - image.height / 2, image.width, image.height);

	return blobFromCanvas(canvas);
};

export default fillBackground;

import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import ExternalProductSyncTable from "./ExternalProductSyncTable";

export default merchant => {
	if (!merchant?.externalProductSync) {
		return [];
	}

	return [
		{
			prop: "externalProductId",
			sort: false,
			type: "typeahead",
			typeaheadOption: option => `${option.name ? option.name : ""} (${option.externalId})`,
			apiUrl: "/externalProducts",
			label: "POS",
			width: 100,
			editLabel: "Linked POS product",
			editable: false,
			visible: () => false,
			// eslint-disable-next-line react/display-name
			formatter: (cell, row) => {
				const badgeStyle = {
					...s.badge,
					background: row.externalProduct?.active ? "#41CE84" : "#FF735E"
				};

				return (
					<OverlayTrigger
						trigger="click"
						rootClose
						placement="top-end"
						overlay={
							!row.externalProduct?.active ? (
								<Popover id="popover-basic" style={s.popOver}>
									<Popover.Title as="h3">
										<FontAwesomeIcon
											icon={faTimesCircle}
											style={s.popOverTitleIcon}
										/>
										Missing product link
									</Popover.Title>
									<Popover.Content>
										Product is <strong>not</strong> linked to a product in the
										POS system. You can link this product in the product edit
										view.
									</Popover.Content>
								</Popover>
							) : (
								<Popover id="popover-basic" style={s.externalPopOver}>
									<Popover.Title as="h3">
										<FontAwesomeIcon
											icon={faCheckCircle}
											style={s.externalPopOverTitleIcon}
										/>
										Product linked
									</Popover.Title>
									<Popover.Content>
										<ExternalProductSyncTable
											externalProduct={row?.externalProduct}
										></ExternalProductSyncTable>
									</Popover.Content>
								</Popover>
							)
						}
					>
						<Badge style={badgeStyle}>
							{row.externalProduct?.active ? "linked" : "not linked"}
						</Badge>
					</OverlayTrigger>
				);
			}
		}
	];
};

const s = {
	popOver: { maxWidth: 300 },
	popOverTitleIcon: { marginRight: 8, color: "#FF735E" },
	externalPopOver: { maxWidth: 500 },
	externalPopOverTitleIcon: { marginRight: 8, color: "#41CE84" },
	externalPopOverIdLabel: {
		display: "table-cell",
		paddingLeft: 8,
		fontWeight: 500,
		borderBottom: "1px solid #f1f1f1",
		borderRight: "1px solid #f1f1f1",
		background: "#f9f9f9"
	},
	externalPopOverNameLabel: {
		paddingLeft: 8,
		fontWeight: 500,
		borderBottom: "1px solid #f1f1f1",
		background: "#f9f9f9"
	},
	externalPopOverIdValue: {
		paddingLeft: 8,
		display: "table-cell",
		marginRight: 10,
		borderRight: "1px solid #f1f1f1"
	},
	badge: {
		color: "#fff",
		padding: 4,
		marginRight: 30,
		fontSize: 12,
		cursor: "pointer",
		margin: "auto"
	}
};

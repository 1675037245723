import React, { useCallback, useState } from "react";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router";
import { useUserContext } from "context/UserContext";
import { Card } from "auth/components";
import { Button } from "core/components";
import PasswordStrengthBar from "react-password-strength-bar";
import SigninRedirectMessage from "auth/components/SigninRedirectMessage";
import styled from "styled-components";

export const Heading = styled.div`
	font-family: Peak;
	font-weight: 500;
	margin-bottom: 12px;
	font-size: 32px;
	line-height: 0.95;
`;

const PasswordStrength = styled(PasswordStrengthBar)`
	p {
		text-align: left !important;
	}
`;

const ResetPasswordForm = ({ email, actionCode }) => {
	const { confirmPasswordReset, signIn, signOut } = useUserContext();
	const [password, setPassword] = useState("");
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState();
	const history = useHistory();
	const [errorMessage, setErrorMessage] = useState();

	const handleSubmit = useCallback(() => {
		setLoading(true);
		try {
			confirmPasswordReset(actionCode, password)
				.then(() => {
					setSuccess(true);
					setTimeout(() => {
						signOut()
							.catch(e => console.error(e))
							.finally(() =>
								signIn({ email, password }).then(() => history.push("/"))
							);
					}, 3000);
				})
				.catch(error => {
					setErrorMessage(error.message);
				})
				.finally(() => setLoading(false));
		} catch (err) {
			setErrorMessage("Password reset was not successful.");
			setLoading(false);
		}
	}, [
		email,
		actionCode,
		password,
		setLoading,
		setErrorMessage,
		signIn,
		signOut,
		confirmPasswordReset,
		history
	]);

	return (
		<Card size="huge">
			<Form
				autoComplete={true}
				onSubmit={e => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				{success ? (
					<>
						<Form.Group>
							<Heading style={{ textAlign: "center" }}>Password was changed!</Heading>
						</Form.Group>
						<Form.Group>
							<div
								style={{
									display: "flex",
									justifyContent: "left",
									marginBottom: 24
								}}
							>
								<SigninRedirectMessage seconds={3}>
									Password was changed.
								</SigninRedirectMessage>
							</div>
						</Form.Group>
					</>
				) : (
					<>
						<Form.Group>
							<Heading>Set your new password</Heading>
						</Form.Group>
						<Form.Group>
							{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Email</Form.Label>
							<Form.Control name="email" type="email" disabled={true} value={email} />
						</Form.Group>

						<Form.Group>
							<Form.Label>New Password</Form.Label>
							<Form.Control
								name="password"
								type="password"
								value={password}
								onChange={e => setPassword(e?.target?.value)}
							/>
							{password.length > 0 && (
								<PasswordStrength minLength={6} password={password} />
							)}
						</Form.Group>

						<Form.Group>
							<Button isLoading={loading} type="submit" size="large">
								Save
							</Button>
						</Form.Group>
					</>
				)}
			</Form>
		</Card>
	);
};

export default ResetPasswordForm;

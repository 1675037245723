import { httpClient } from "data";
import urlcat from "urlcat";

const checkinRepo = {
	getCheckins: ({ storeId, page, pageSize, search, sortField, sortOrder }) =>
		httpClient.get(
			urlcat("/checkins", { storeId, page, pageSize, search, sortField, sortOrder })
		)
};

export default checkinRepo;

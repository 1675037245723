import { SectionLoader } from "core/components";
import { completeOrderTicket, setOrderTicketIsReadyForPickup } from "order-tickets/functions";
import { isEmpty } from "ramda";
import React, { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { OrderTicketGrid } from "../../../components";
import { useOrderTickets } from "../../../functions";
import WaitingMessage from "./WaitingMessage";

const OpenTab = ({ station }) => {
	const statuses = useMemo(() => ["CREATED", "READY_FOR_PICKUP"], []);
	const {
		orderTickets,
		isLoading,
		error,
		eagerRemoveTicket,
		cancelEagerRemoveTicket,
		eagerSetStatus,
		cancelEagerStatus
	} = useOrderTickets({
		stationId: station?.stationId,
		statuses
	});
	const isWaiting = isEmpty(orderTickets);

	const handleDoubleClick = async orderTicket => {
		const { possibleStatusTransitions = [], status, eagerStatus } = orderTicket;
		const currentStatus = eagerStatus || status;

		// Set order ticket as ready for pickup
		if (currentStatus === "CREATED" && possibleStatusTransitions.includes("READY_FOR_PICKUP")) {
			eagerSetStatus(orderTicket, "READY_FOR_PICKUP");
			try {
				await setOrderTicketIsReadyForPickup({
					orderTicketId: orderTicket.orderTicketId
				});
			} catch (error) {
				cancelEagerStatus(orderTicket);
			}
		}
		// Complete order ticket
		else if (
			possibleStatusTransitions.length === 0 ||
			possibleStatusTransitions.includes("DONE")
		) {
			eagerRemoveTicket(orderTicket);

			try {
				await completeOrderTicket({ orderTicketId: orderTicket.orderTicketId });
			} catch (error) {
				cancelEagerRemoveTicket(orderTicket);
			}
		}
	};

	if (error) {
		return (
			<Alert variant="danger">
				There was an error, please refresh the page to try again.
			</Alert>
		);
	}

	if (isLoading) {
		return <SectionLoader />;
	}

	if (isWaiting) {
		return <WaitingMessage />;
	}

	return (
		<OrderTicketGrid
			station={station}
			orderTickets={orderTickets}
			onDoubleClick={handleDoubleClick}
		/>
	);
};

export default OpenTab;

import React from "react";
import styled from "styled-components";
const { faTimes } = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

const Container = styled.div`
	display: flex;
`;

const RemoveImage = styled.div`
	position: relative;
	cursor: pointer;
	left: -15px;
	font-size: 18px;
	height: 24px;
	display: block;
	top: -9px;
	padding: 3px 6px;
	border-radius: 15px;
	background: rgb(255, 115, 94);
	color: white;
	line-height: 14px;
`;

const ProductImage = styled.img`
	border: 2px solid #e1e1e1;
	border-radius: 8px;
	max-width: 136px;
	max-height: 102px;
`;

const ShowImage = ({ image, onChange }) => {
	return (
		<Container>
			<ProductImage src={image} />
			<RemoveImage onClick={() => onChange(null)}>
				<FontAwesomeIcon icon={faTimes} />
			</RemoveImage>
		</Container>
	);
};

export default ShowImage;

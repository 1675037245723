import between from "core/components/Grid/Validation/between";
import required from "core/components/Grid/Validation/required";

const getDealPropertiesSchema = () => [
	// PRODUCT_PERCENTAGE
	{
		prop: "properties.percentage",
		type: "percentage",
		columnVisible: false,
		label: "Percentage",
		visible: row => row.type === "PRODUCT_PERCENTAGE",
		validate: [
			required("Percentage is missing"),
			between(0.01, 0.99, "Percentage must between 1 and 99%")
		]
	},

	// BUY_X_PAY_FOR_Y
	{
		prop: "properties.x",
		type: "number",
		placeholder: "X",
		columnVisible: false,
		group: "BUY_X_PAY_FOR_Y",
		groupSortOrder: 1,
		label: "Buy",
		visible: row => row.type === "BUY_X_PAY_FOR_Y",
		validate: [required("X is missing"), between(1, 10, "X must be between 1 and 10")]
	},
	{
		prop: "properties.y",
		type: "number",
		placeholder: "Y",
		columnVisible: false,
		group: "BUY_X_PAY_FOR_Y",
		groupSortOrder: 2,
		label: "Pay for",
		visible: row => row.type === "BUY_X_PAY_FOR_Y",
		validate: [required("Y is missing"), between(1, 10, "Y must be between 1 and 10")]
	},

	// BUY_X_PAY_FOR_AMOUNT
	{
		prop: "properties.x",
		type: "number",
		placeholder: "X",
		group: "BUY_X_PAY_FOR_AMOUNT",
		groupSortOrder: 1,
		columnVisible: false,
		label: "Buy",
		visible: row => row.type === "BUY_X_PAY_FOR_AMOUNT",
		validate: [required("X is missing"), between(1, 10, "X must be between 1 and 10")]
	},
	{
		prop: "properties.amount",
		type: "number",
		placeholder: "Amount",
		group: "BUY_X_PAY_FOR_AMOUNT",
		groupSortOrder: 2,
		columnVisible: false,
		label: "For",
		visible: row => row.type === "BUY_X_PAY_FOR_AMOUNT",
		validate: [required("Amount is missing")]
	},

	// FLAT_PERCENTAGE
	{
		prop: "properties.percentage",
		type: "percentage",
		columnVisible: false,
		label: "Percentage",
		visible: row => row.type === "FLAT_PERCENTAGE",
		validate: [
			required("Percentage is missing"),
			between(0.01, 0.99, "Percentage must between 1 and 99%")
		]
	},

	// FLAT_AMOUNT
	{
		prop: "properties.amount",
		type: "number",
		columnVisible: false,
		group: "FLAT_AMOUNT",
		groupSortOrder: 1,
		label: "Amount",
		visible: row => row.type === "FLAT_AMOUNT",
		validate: [required("Amount is missing")]
	},
	{
		prop: "properties.minimumCartTotal",
		type: "number",
		columnVisible: false,
		group: "FLAT_AMOUNT",
		groupSortOrder: 2,
		label: "Minimum Cart Total",
		visible: row => row.type === "FLAT_AMOUNT"
	}
];

export default getDealPropertiesSchema;

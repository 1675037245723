import _ from "lodash";

const FetchConfig = ({ method = "GET", merchantId, authToken, body }) => {
	const trimmedBody = _.mapValues(body, v => (v === "" ? null : v));
	const trimmedStringifiedBody = body && JSON.stringify(trimmedBody);
	const headers = {
		...(merchantId && { Merchant: merchantId }),
		Accept: "application/json",
		"Content-Type": "application/json",
		GITHUB_SHA: process.env.REACT_APP_GITHUB_SHA,
		Authorization: `Bearer ${authToken}`
	};

	return {
		method,
		headers,
		...(body && { body: trimmedStringifiedBody })
	};
};

export default FetchConfig;

import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { reportingRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useCashRegister = () => {
	const [cashRegister, setCashRegister] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch(true);
	const [isUpdating, startUpdating, stopUpdating] = useSwitch(false);
	const [error, setError] = useState();

	const { currentMerchant, currentStore } = useMerchantContext();
	const refresh = useCallback(
		(args = { loader: true }) => {
			if (!currentMerchant) return;
			args?.loader && startLoading();
			reportingRepo.getCashRegister({ storeId: currentStore.storeId }).then(r => {
				if (!r) {
					r = {
						companyName: currentMerchant.name,
						orgNo: currentStore.orgNo.replace(/\D/g, ""),
						address: currentStore.address,
						postalCode: currentStore.postalCode,
						city: currentStore.city
					};
				}
				setCashRegister(r);
				stopLoading();
			});
		},
		[currentStore, startLoading, stopLoading, currentMerchant]
	);

	const updateAutomaticClosingHour = useCallback(
		automaticClosingHour => {
			startUpdating();
			reportingRepo
				.updateAutomaticClosingHour({
					cashRegisterId: cashRegister.cashRegisterId,
					storeId: currentStore.storeId,
					automaticClosingHour: automaticClosingHour
				})
				.then(() => {
					refresh({ loader: false });
					stopUpdating();
				});
		},
		[currentStore, cashRegister, refresh, startUpdating, stopUpdating]
	);

	const activate = useCallback(async () => {
		setError();
		await reportingRepo
			.activateCashRegister({
				storeId: currentStore.storeId,
				cashRegister
			})
			.then(r => {
				if (r.code) setError("Could not activate central tax unit");
				else refresh();
			});
	}, [currentStore, cashRegister, refresh]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [
		cashRegister,
		setCashRegister,
		isLoading,
		refresh,
		updateAutomaticClosingHour,
		isUpdating,
		activate,
		error
	];
};

export default useCashRegister;

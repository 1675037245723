import React, { forwardRef } from "react";
import styled from "styled-components";
import accountIcon from "assets/icons/account-linear-white.svg";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	width: 20px;
	overflow: hidden;
	cursor: pointer;
`;

const Icon = styled.img`
	height: 100%;
	width: auto;
`;

const AccountButton = ({ onClick }, ref) => {
	return (
		<Container onClick={onClick} ref={ref} data-test="account-button">
			<Icon src={accountIcon} />
		</Container>
	);
};

export default forwardRef(AccountButton);

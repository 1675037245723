import React, { useMemo } from "react";
import { Alert } from "react-bootstrap";
import TimeSlot from "./TimeSlot";

const PreOrders = ({
	event,
	isLoading,
	stations,
	selectedStations,
	selectedGrouping,
	makePrinterFriendly
}) => {
	const preOrderItems = useMemo(() => {
		if (isLoading) return [];

		if (selectedGrouping.checkin && selectedGrouping.timeSlot)
			return event.preOrderItemsCheckinTimeSlot;
		else if (selectedGrouping.checkin) return event.preOrderItemsCheckin;
		else if (selectedGrouping.timeSlot) return event.preOrderItemsTimeSlot;

		return event.preOrderItems;
	}, [event, isLoading, selectedGrouping]);

	const hasPreOrderItems = preOrderItems && preOrderItems.length > 0;

	const timeSlots = useMemo(() => {
		if (!selectedGrouping?.timeSlot) return null;

		return event.timeSlots.map(t => (
			<TimeSlot
				key={t.eventTimeSlotId}
				eventId={event.eventId}
				stations={stations}
				timeSlot={t}
				selectedGrouping={selectedGrouping}
				selectedStations={selectedStations}
				items={preOrderItems}
				makePrinterFriendly={makePrinterFriendly}
			/>
		));
	}, [event, makePrinterFriendly, preOrderItems, selectedGrouping, selectedStations, stations]);

	if (!hasPreOrderItems)
		return (
			<Alert
				variant="warning"
				style={{ alignSelf: "flex-start" }}
				data-test="Events-empty-message"
			>
				No pre orders have been made.
			</Alert>
		);

	return (
		<>
			<TimeSlot
				eventId={event.eventId}
				stations={stations}
				selectedGrouping={selectedGrouping}
				selectedStations={selectedStations}
				items={preOrderItems}
				makePrinterFriendly={makePrinterFriendly}
			/>
			{timeSlots}
		</>
	);
};

export default PreOrders;

import { faEllipsisV, faGripLines, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import imgPlaceHolder from "assets/img-placeholder.jpg";
import ChoiceModal from "core/components/ChoiceModal";
import React from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import Amount from "core/components/Amount";

const OuterContainer = styled.div`
	border-bottom: 1px dotted lightgrey;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	user-select: none;
`;

const InnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Content = styled.div`
	display: flex;
`;

const Grip = styled.div`
	display: flex;
	align-items: center;
`;

const ProductImage = styled.img`
	border: 1px solid #e1e1e1;
	border-radius: 5px;
	height: 60px;
	width: 80px;
	margin-right: 8px;
`;

const ProductNameContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	margin-right: 8px;
`;

const ProductName = styled.div``;

const ProductPrice = styled.div`
	display: flex;
	align-items: center;
`;

const ActionButtons = styled.div`
	display: flex;
	align-items: center;
`;

const MissingExternalIdContainer = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	color: #ff735e;
`;

const HamburgerIcon = styled(FontAwesomeIcon)`
	margin-right: 20px;
`;

const UnlinkIcon = styled(FontAwesomeIcon)`
	margin-right: 8px;
	font-size: 12px;
`;

const InactiveBadge = styled(Badge)`
	margin-right: 10px;
	background-color: #ff735e;
	font-size: 14px;
	margin-top: 4px;
	color: #fff;
`;

const Item = ({ item, itemChoices, unavailableUntil, isItemUnavailableUntilInFuture }) => {
	return (
		<OuterContainer>
			<Grip>
				<HamburgerIcon icon={faGripLines} />
			</Grip>
			<InnerContainer>
				<Content>
					<ProductImage src={item.product.imageUrl || imgPlaceHolder} alt="product" />
					<ProductNameContainer>
						<ProductName>{item.product.name}</ProductName>
						{isItemUnavailableUntilInFuture && (
							<InactiveBadge variant="danger">
								<Moment
									style={{ whiteSpace: "break-spaces" }}
									calendar={{
										sameDay: "[Unavailable until] HH:mm",
										nextDay: "[Unavailable until tomorrow] HH:mm",
										nextWeek: "[Unavailable until] dddd HH:mm"
									}}
								>
									{unavailableUntil}
								</Moment>
							</InactiveBadge>
						)}
						{!item.available && <InactiveBadge>Unavailable</InactiveBadge>}
					</ProductNameContainer>
				</Content>
				<Content>
					<ProductPrice>
						<Amount value={item.price} />
					</ProductPrice>
					<ActionButtons>
						<ChoiceModal
							title={item.product.name}
							choices={itemChoices}
							icon={faEllipsisV}
						/>
					</ActionButtons>
				</Content>
				{item.product.missingExternalId && (
					<MissingExternalIdContainer>
						<UnlinkIcon icon={faUnlink} /> External product link missing
					</MissingExternalIdContainer>
				)}
			</InnerContainer>
		</OuterContainer>
	);
};

export default Item;

import { useMerchantContext } from "context";
import { productRepo } from "data/repos";
import React, { useCallback, useEffect, useState } from "react";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import Form from "react-bootstrap/Form";
import styled from "styled-components";

const DropdownItem = styled(MenuItem)`
	border-bottom: 1px solid #e1e1e1;
	white-space: normal;
	display: flex;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
`;
const Name = styled.div``;
const Description = styled.small`
	color: #666666;
`;

const Products = ({ value, onChange }) => {
	const { currentStore, currentPriceListId } = useMerchantContext();
	const [products, setProducts] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);

	const labelWithPrice = option => {
		return `${option?.name}`;
	};

	const handleTypeaheadChange = useCallback(
		products => {
			setSelectedProducts(products);
			onChange(products.map(p => p.productId));
		},
		[setSelectedProducts, onChange]
	);

	useEffect(() => {
		productRepo
			.getProducts({
				storeId: currentStore.storeId,
				priceListId: currentPriceListId,
				page: 1,
				pageSize: 10000
			})
			.then(products => {
				setProducts(products.products);
				if (value)
					setSelectedProducts(
						value.map(productId =>
							products.products.find(p => p.productId === productId)
						)
					);
			});
	}, [setProducts, currentPriceListId, currentStore.storeId, value]);

	const handleRenderMenu = (results, menuProps) => (
		<Menu {...menuProps}>
			{results.map((result, index) => {
				return (
					<DropdownItem key={result.productId} option={result} position={index}>
						<Name>{result.name}</Name>
						<Description>{result.description}</Description>
					</DropdownItem>
				);
			})}
		</Menu>
	);

	return (
		<Form.Group>
			<Typeahead
				id="products-autocomplete"
				labelKey={option => labelWithPrice(option)}
				multiple
				options={products}
				placeholder="Choose product(s)"
				emptyLabel="No products found"
				selected={selectedProducts}
				positionFixed={true}
				onChange={handleTypeaheadChange}
				renderMenu={handleRenderMenu}
			/>
		</Form.Group>
	);
};

export default Products;

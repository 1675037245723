import React, { useCallback } from "react";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import fileToImageUrl from "core/utils/file-to-image-url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { fillBackground, scaleDownLargePhoto } from "core/utils/canvas";

const Container = styled.div`
	background: "#FFF";
	width: 136px;
	height: 102px;
	border: 3px dashed #e1e1e1;
	border-radius: 16px;
	text-align: center;
	cursor: pointer;
	color: #999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
	margin-right: 8px;
`;

const DropImage = ({ onImageDrop }) => {
	const inputFileRef = useRef(null);

	const chooseFile = () => inputFileRef.current.click();

	const onDrop = useCallback(
		async acceptedFiles => {
			const file = acceptedFiles[0];
			let imageDataUrl = await fileToImageUrl(file);

			// Scale down images so width/height is max 2000 px
			imageDataUrl = await scaleDownLargePhoto(imageDataUrl);

			// Fill background with white so image is 4/3
			imageDataUrl = await fillBackground(imageDataUrl, 4 / 3, "white");

			onImageDrop(imageDataUrl);
		},
		[onImageDrop]
	);
	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<Container onClick={chooseFile} {...getRootProps()}>
			<Icon icon={faCamera} />
			Select
			<input
				ref={inputFileRef}
				style={{ display: "none" }}
				type="file"
				{...getInputProps()}
			/>
		</Container>
	);
};

export default DropImage;

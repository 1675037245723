const poll = async (interval, times, func) => {
	let t = 0;
	new Promise(resolve => {
		const intervalFunc = setInterval(() => {
			t = t + 1;
			if (t >= times) {
				clearInterval(intervalFunc);
			} else {
				func()
					.then(r => {
						if (r) {
							clearInterval(intervalFunc);
							resolve(r);
						}
					})
					.catch(err => {
						console.error(err);
						clearInterval(intervalFunc);
					});
			}
		}, interval);
	});
};

export default poll;

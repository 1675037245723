import { faBell } from "@fortawesome/free-solid-svg-icons";
import enterSound from "assets/order-ticket-enter.mp3";
import Button from "core/components/Button";
import Modal from "core/components/Modal";
import { useSound } from "core/functions";
import { logEvent } from "core/utils";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

const InfoText = styled.div`
	margin-bottom: ${p => p.theme.space.large};
`;

const TestAudioModal = ({ show, onClose }) => {
	const [playSound, isSoundLoaded] = useSound(enterSound, {
		enabled: true
	});

	const handlePlayClick = useCallback(() => {
		if (isSoundLoaded) {
			const soundId = playSound();
			logEvent("test_sound", { soundId });
		}
	}, [playSound, isSoundLoaded]);

	const body = useMemo(
		() => (
			<>
				<InfoText>
					Sound is enabled for this station. Please test it to make sure that you hear it
					properly.
				</InfoText>
				<Button
					size="large"
					onClick={handlePlayClick}
					icon={faBell}
					variant="primary"
					collapse={false}
				>
					Test sound
				</Button>
			</>
		),
		[handlePlayClick]
	);

	const footer = useMemo(
		() => (
			<Button size="large" collapse={false} onClick={onClose}>
				Close
			</Button>
		),
		[onClose]
	);

	return (
		<Modal
			show={show}
			onHide={onClose}
			title="Test notification sound"
			body={body}
			footer={footer}
		/>
	);
};

export default TestAudioModal;

import React from "react";
import { NumberPicker } from "core/components";

const handleNumberPickerChange = ({
	quantity,
	rowData,
	refundRows,
	setRefundRows,
	setMappedItems
}) => {
	const { productId } = rowData;
	refundRows[productId] = { quantity, rowData };
	setRefundRows(refundRows);
	const mappedItems = Object.keys(refundRows)
		.map(key => {
			return {
				productId: key,
				quantity: refundRows[key].quantity,
				rowData: refundRows[key].rowData
			};
		})
		.filter(x => x.quantity > 0);
	setMappedItems(mappedItems.length > 0 ? mappedItems : null);
};

const actions = ({ refundRows, setRefundRows, setMappedItems }) => ({
	options: {
		headerText: "Refund",
		width: 130
	},
	customActions: [
		{
			// eslint-disable-next-line react/display-name
			context: ({ rowData }) => {
				const maxValue =
					rowData.quantity - (rowData.refundingCount || 0) - (rowData.refundedCount || 0);
				return (
					<NumberPicker
						key={1}
						disabled={maxValue === 0}
						disabledText="All items refunded"
						maxValue={maxValue}
						onChange={v =>
							handleNumberPickerChange({
								quantity: v.currentValue,
								rowData: v.rowData,
								refundRows,
								setRefundRows,
								setMappedItems
							})
						}
						rowData={rowData}
					/>
				);
			},
			visible: () => true
		}
	]
});

export default actions;

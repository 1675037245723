import React from "react";
import { Grid, Button } from "core/components";
import moment from "moment-timezone";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { httpClient } from "data";
import styled from "styled-components";
import { useMerchantContext } from "context";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const DownloadButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	float: right;
`;

const Files = ({ sieFiles, isFetching, timeZone }) => {
	const { hasFunctionAccess } = useMerchantContext();

	const schema = [
		{
			prop: "sieFileId",
			type: "number",
			columnVisible: false,
			sort: false,
			minimumGridVisibility: "xs",
			editable: false
		},
		{
			prop: "type",
			type: "text",
			minimumGridVisibility: "xs",
			label: "Type",
			sort: true,
			editable: false
		},
		{
			prop: "fromDate",
			type: "text",
			minimumGridVisibility: "xs",
			label: "From",
			formatter: c => moment.tz(c, timeZone).format("YYYY-MM-DD"),
			sort: true,
			editable: false
		},
		{
			prop: "toDate",
			type: "text",
			minimumGridVisibility: "xs",
			label: "To",
			formatter: c => moment.tz(c, timeZone).format("YYYY-MM-DD"),
			sort: true,
			editable: false
		},
		{
			prop: "timeCreated",
			type: "text",
			label: "Created",
			formatter: c => moment.tz(c, timeZone).format("YYYY-MM-DD HH:mm"),
			sort: true,
			editable: false
		},
		{
			prop: "dummy",
			type: "text",
			label: "",
			// eslint-disable-next-line react/display-name
			formatter: (_cell, rowData) => (
				<DownloadButtonContainer>
					<Button icon={faDownload} size="small" onClick={() => downloadSIEFile(rowData)}>
						Download
					</Button>
				</DownloadButtonContainer>
			)
		}
	];

	const downloadSIEFile = async sieFileRow => {
		const file = await httpClient.get(`/reporting/sie-merchant/files/${sieFileRow.sieFileId}`);

		const fileName =
			sieFileRow.type
				.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
				.map(x => x.toLowerCase())
				.join("_") +
			"_" +
			moment(sieFileRow.transactionDate).format("YYYYMMDD") +
			".SI";

		const data = new Blob([file.data], { type: "text" });
		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement("a");
		tempLink.href = csvURL;
		tempLink.setAttribute("download", fileName);
		tempLink.click();
	};

	return (
		hasFunctionAccess("report_view") && (
			<Container>
				<Grid
					isFetching={isFetching}
					schema={schema}
					tableKey="sieFileId"
					data={sieFiles}
					hideGridCreate={true}
					noDataText="No files available"
				/>
			</Container>
		)
	);
};

export default Files;

import React from "react";
import styled from "styled-components";
import { useToggle } from "core/utils";
import StoreSwitcher from "./StoreSwitcher";
import Account from "./Account";
import SecondaryMenu from "./SecondaryMenu";
import HamburgerMenu, { HamburgerButton } from "./HamburgerMenu";
import { Container as CoreContainer } from "core/components";

const Container = styled.div`
	background-color: #1d1d1d;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Content = styled(CoreContainer)`
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	width: 100%;
	height: 44px;
`;

const Body = () => {
	const [isHamburgerMenu, toggleHamburgerMenu] = useToggle(false);

	return (
		<Container>
			<Content>
				<StoreSwitcher />

				<SecondaryMenu>
					<Account />

					<HamburgerButton onClick={toggleHamburgerMenu} />
				</SecondaryMenu>
			</Content>

			<HamburgerMenu isOpen={isHamburgerMenu} />
		</Container>
	);
};

export default Body;

import { useMerchantContext } from "context";
import { GridCreate, SectionHeader } from "core/components";
import { useSwitch } from "core/utils";
import { productGroupRepo } from "data/repos";
import getTableActions from "./ProductGroupsTable/get-table-actions";
import getTableSchema from "./ProductGroupsTable/get-table-schema";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import urlcat from "urlcat";
import ProductGroupsTable from "./ProductGroupsTable";

const Container = styled.div`
	flex-direction: column;
	flex-grow: 1;
`;

const ProductGroups = () => {
	const { currentStore, currentMerchant, hasFunctionAccess } = useMerchantContext();
	const [productGroups, setProductGroups] = useState([]);
	const [schema, setSchema] = useState(null);
	const [actions, setActions] = useState(null);
	const [isFetching, startFetching, stopFetching] = useSwitch();

	const refresh = useCallback(async () => {
		startFetching();
		const productGroups = await productGroupRepo.getProductGroups({
			storeId: currentStore.storeId
		});
		stopFetching();

		setProductGroups(productGroups);
		setActions(
			getTableActions({
				merchant: currentMerchant,
				store: currentStore,
				hasFunctionAccess
			})
		);
		setSchema(getTableSchema({ currentMerchant, hasFunctionAccess }));
	}, [currentStore, currentMerchant, hasFunctionAccess, startFetching, stopFetching]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return (
		<Container>
			<SectionHeader heading="Groups">
				{!currentMerchant.externalProductSync && (
					<GridCreate
						buttonProps={{
							label: "New group",
							variant: "primary"
						}}
						title="New product group"
						schema={schema}
						onChange={refresh}
						apiPath={urlcat("/product-groups", {
							storeId: currentStore.storeId
						})}
						apiMethod="PUT"
					/>
				)}
			</SectionHeader>

			<ProductGroupsTable
				productGroups={productGroups}
				schema={schema}
				actions={actions}
				isFetching={isFetching}
				refresh={refresh}
			/>
		</Container>
	);
};

export default ProductGroups;

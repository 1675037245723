import { useMerchantContext } from "context";
import { Grid } from "core/components";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import schema from "./schema";
import useEvents from "./use-events";

const Container = styled.div`
	width: 100%;
`;

const Content = styled.div`
	margin-top: ${p => p.theme.space.large};
`;

const ActivityGrid = ({ dateFilter, noDataText }) => {
	const history = useHistory();
	const {
		currentMerchant: { timeZone }
	} = useMerchantContext();

	const [events, eventsCount, isLoading, refresh, setPageSize, setPage, pageSize, page] =
		useEvents({ dateFilter });

	const gridSchema = schema(timeZone || "Europe/Stockholm");

	const handleRowClick = useCallback(
		(e, row) => {
			history.push(`/events/event/${row.eventId}`);
		},
		[history]
	);

	return (
		<Container data-test="events">
			<Content>
				<Grid
					schema={gridSchema}
					isFetching={isLoading}
					tableKey="eventId"
					data={events}
					noDataText={noDataText || "There are no events"}
					pagination
					search
					remote={{
						pagination: true,
						search: true,
						sort: true
					}}
					remotePagination
					remotePaginationOptions={{
						setPage: setPage,
						setSizePerPage: setPageSize,
						page: page,
						sizePerPage: pageSize,
						totalSize: Number(eventsCount)
					}}
					reload={refresh}
					onRowClick={handleRowClick}
					rowStyle={{ cursor: "pointer" }}
				/>
			</Content>
		</Container>
	);
};

export default ActivityGrid;

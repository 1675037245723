import { useParams, useHistory, useRouteMatch, generatePath } from "react-router";
import { find, propEq } from "ramda";
import { useEffect, useState } from "react";

const useTab = (initialTabId, tabs) => {
	const [activeTab, setActiveTab] = useState();
	const params = useParams();
	const history = useHistory();
	const match = useRouteMatch();

	// Syncs activeTab with path
	useEffect(() => {
		const tabId = params.tabId || initialTabId;
		const tab = find(propEq("id", tabId), tabs);
		setActiveTab(tab);
	}, [tabs, history, params, initialTabId]);

	const activateTab = tab => {
		const newPath = generatePath(match.path, {
			...match.params,
			tabId: tab.id
		});

		if (newPath === match.url) {
			return;
		}

		history.replace(newPath);
	};

	return [activeTab, activateTab];
};

export default useTab;

import { useMerchantContext } from "context";
import { GridCreate, PageLayout, SectionHeader } from "core/components";
import { useSwitch } from "core/utils";
import { dealRepo } from "data/repos";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import urlcat from "urlcat";
import { DealsTable } from "./components";
import getTableActions from "./components/DealsTable/get-table-actions";
import getTableSchema from "./components/DealsTable/get-table-schema";
const Container = styled.div`
	flex-direction: column;
	flex-grow: 1;
`;
const Deals = () => {
	const { currentStore, currentMerchant, hasFunctionAccess } = useMerchantContext();
	const [deals, setDeals] = useState([]);
	const [schema, setSchema] = useState(null);
	const [actions, setActions] = useState(null);
	const [isFetching, startFetching, stopFetching] = useSwitch();

	const refresh = useCallback(async () => {
		startFetching();
		dealRepo.getDeals({ storeId: currentStore.storeId }).then(deals => {
			setDeals(deals);
			setActions(
				getTableActions({
					merchant: currentMerchant,
					store: currentStore,
					hasFunctionAccess
				})
			);
			setSchema(getTableSchema({ merchant: currentMerchant, store: currentStore }));
		});
		stopFetching();
	}, [currentStore, currentMerchant, hasFunctionAccess, startFetching, stopFetching]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return (
		<PageLayout>
			<Container>
				<SectionHeader heading="Deals">
					<GridCreate
						buttonProps={{
							label: "New deal",
							variant: "primary"
						}}
						title="New Deal"
						schema={schema}
						onChange={refresh}
						apiPath={urlcat("/deals", {
							storeId: currentStore.storeId
						})}
						row={{
							active: true,
							firstTimeUsersOnly: false,
							category: "CART",
							type: "FLAT_PERCENTAGE"
						}}
						apiMethod="PUT"
					/>
				</SectionHeader>
				<DealsTable
					isFetching={isFetching}
					deals={deals}
					schema={schema}
					actions={actions}
					refresh={refresh}
				/>
			</Container>
		</PageLayout>
	);
};

export default Deals;

import React from "react";
import schema from "./schema";
import { Grid } from "core/components";
import styled from "styled-components";

const Container = styled.div`
	width: 100%;
`;
const PayoutDetailsGrid = ({ payoutDetails }) => {
	const gridSchema = schema();

	return (
		<Container data-test="payouts">
			<Grid schema={gridSchema} tableKey="orderId" data={payoutDetails} pagination />
		</Container>
	);
};

export default PayoutDetailsGrid;

import React, { useState } from "react";
import Button from "./Button";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const Component = ({ maxValue, disabled, disabledText, onChange, ...otherProps }) => {
	const [value, setValue] = useState(0);

	return (
		<div style={{ display: "flex" }}>
			{!disabled && (
				<Button
					icon={faMinus}
					disabled={disabled}
					size="small"
					onClick={() => {
						const nextValue = value <= 0 ? 0 : value - 1;
						onChange({
							currentValue: nextValue,
							...otherProps
						});
						setValue(nextValue);
					}}
				/>
			)}

			<div
				className="borderedText"
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
					fontSize: !disabled ? "14px" : "10px",
					width: !disabled ? 40 : 106
				}}
			>
				{!disabled && <>{value}</>}
				{disabled && <>{disabledText}</>}
			</div>

			{!disabled && (
				<Button
					icon={faPlus}
					disabled={disabled}
					size="small"
					onClick={() => {
						const nextValue = value >= maxValue ? maxValue : value + 1;
						onChange({
							currentValue: nextValue,
							...otherProps
						});
						setValue(nextValue);
					}}
				/>
			)}
		</div>
	);
};

Component.displayName = "RefundPopoverTrigger";

export default Component;

import { faPlug } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { Button, ConfirmationModal } from "core/components";
import { useSwitch } from "core/utils";
import { capitalize, lowerCase } from "lodash";
import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import FormItem from "./FormItem";

const InfoText = styled.div`
	color: ${p => p.theme.color.midGray};
	margin-bottom: ${p => p.theme.space.large};
`;

const ActivationModal = ({ cashRegister, activate, onChange, error }) => {
	const {
		currentStore: { type: storeType }
	} = useMerchantContext();

	const [isActivating, startActivating, stopActivating] = useSwitch(false);
	const [isShowing, show, hide] = useSwitch(false);

	const handleActivate = async e => {
		startActivating();
		await activate(e);
		stopActivating();
	};

	return (
		<>
			<Button variant="primary" icon={faPlug} onClick={show}>
				Activate
			</Button>
			<ConfirmationModal
				show={isShowing}
				onCancel={hide}
				onConfirm={handleActivate}
				confirmButtonProps={{ isLoading: isActivating }}
				title={"Activate control unit"}
				text={
					<>
						<Alert
							show={!!error}
							variant="danger"
							data-test="control-unit-activation-error"
						>
							{error}
						</Alert>
						<InfoText>
							Please verify that the {lowerCase(storeType)} information is correct
							before activating the control unit.
						</InfoText>

						<FormItem
							cashRegister={cashRegister}
							onChange={onChange}
							edit
							property="orgNo"
							label="Org number"
						/>
						<FormItem
							cashRegister={cashRegister}
							onChange={onChange}
							edit
							property="companyName"
							label={`${capitalize(storeType)} name`}
						/>
						<FormItem
							cashRegister={cashRegister}
							onChange={onChange}
							edit
							property="address"
							label="Address"
						/>
						<FormItem
							cashRegister={cashRegister}
							onChange={onChange}
							edit
							property="postalCode"
							label="Postal code"
						/>
						<FormItem
							cashRegister={cashRegister}
							onChange={onChange}
							edit
							property="city"
							label="City"
						/>
					</>
				}
			/>
		</>
	);
};
export default ActivationModal;

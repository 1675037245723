import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import CONFIG from "./config";

const app = firebase.initializeApp(CONFIG.firebase_consumer_api, "consumerApi");

if (CONFIG.firebase_consumer_api_settings) {
	const db = firebase.firestore(app);
	db.settings(CONFIG.firebase_consumer_api_settings);
}

export default app;

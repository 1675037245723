import React, { useEffect, useRef, useState } from "react";
import Expire from "../../../../core/components/Expire";
import styled, { keyframes } from "styled-components";

const flashAnimation = keyframes`
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`;

const Flash = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	opacity: 0;
	animation-name: ${flashAnimation};
	animation-duration: 1s;
	background-color: #41ce84;
`;

const EnterFlash = ({ isAdded, flashOnEnter }) => {
	const haveFlashed = useRef(false);
	const [isFlashing, setIsFlashing] = useState(false);

	useEffect(() => {
		if (!isAdded || !flashOnEnter) {
			return;
		}

		if (haveFlashed.current) {
			return null;
		}

		setIsFlashing(true);
		haveFlashed.current = true;
	}, [isAdded, flashOnEnter]);

	if (!isFlashing) {
		return null;
	}

	return (
		<Expire delay={1250}>
			<Flash />
		</Expire>
	);
};

export default EnterFlash;

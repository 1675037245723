import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { MiniChart } from "./components";
import { device, mediaQuery } from "styles/device";

const TabsContainer = styled.div`
	margin-bottom: 22px;
`;

const Charts = styled.div`
	display: flex;
	flex-direction: column;

	@media ${mediaQuery(device.tabletLarge)} {
		flex-direction: row;
	}
`;

const ChartContainer = styled.div`
	@media ${mediaQuery(device.tabletLarge)} {
		flex-basis: 33.33%;
		padding-right: ${p => (p.last ? "0px" : "22px")};
	}
`;

const ChartTabs = ({ dashboard, graphData, onPeriodChange }) => {
	if (!dashboard) {
		return null;
	}

	return (
		<>
			<TabsContainer>
				<Tabs onSelect={onPeriodChange} id="controlled-tab-example">
					<Tab eventKey="1W" title="1w"></Tab>
					<Tab eventKey="4W" title="4w"></Tab>
					<Tab eventKey="1Y" title="1y"></Tab>
					<Tab eventKey="MTD" title="Mtd"></Tab>
					<Tab eventKey="QTD" title="Qtd"></Tab>
					<Tab eventKey="YTD" title="Ytd"></Tab>
				</Tabs>
			</TabsContainer>

			<Charts>
				<ChartContainer>
					<MiniChart
						title="Gross Volume"
						dashboard={dashboard}
						datakey="total"
						data={graphData}
					/>
				</ChartContainer>

				<ChartContainer>
					<MiniChart
						title="Orders"
						dashboard={dashboard}
						datakey="orders"
						data={graphData}
					/>
				</ChartContainer>

				<ChartContainer last>
					<MiniChart
						title="Active Users"
						dashboard={dashboard}
						datakey="users"
						data={graphData}
					/>
				</ChartContainer>
			</Charts>
		</>
	);
};

export default ChartTabs;

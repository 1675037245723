import { RequestPasswordResetPage } from "auth/RequestPasswordReset";
import { ResetPasswordPage } from "auth/ResetPassword";
import { SignInPage } from "auth/SignIn";
import { WelcomePage } from "auth/Welcome";
import Dashboard from "dashboard";
import Deals from "deals";
import { Menu, MenusOverview } from "menus";
import { Stations, StationView } from "order-tickets";
import Orders from "orders";
import Checkins from "checkins";
import Products from "products";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Reporting from "reporting";
import FortnoxActivationStatus from "reporting/components/Fortnox/ActivationStatus";

import ProtectedRoute from "./ProtectedRoute";
import Order from "orders/components/Order";
import Events from "store-events";
import Event from "store-events/components/Event";

const Routes = () => {
	return (
		<Switch>
			<ProtectedRoute exact path="/home" render={() => <Dashboard />} />
			<ProtectedRoute
				exact
				path="/stations"
				functionAccess="order_ticket_view"
				render={() => <Stations />}
			/>
			<ProtectedRoute
				exact
				path="/stations/:stationId/:tabId?"
				functionAccess="order_ticket_view"
				render={() => <StationView />}
			/>
			{/* Redirect for Backwards compability */}
			<Route
				exact
				path={["/order-tickets", "/bongs"]}
				render={() => <Redirect to="/stations" functionAccess="order_ticket_view" />}
			/>
			<ProtectedRoute
				exact
				path="/products/:tabId?"
				functionAccess="product_view"
				render={() => <Products />}
			/>
			<ProtectedRoute
				exact
				path="/orders/:tabId?"
				functionAccess="order_view"
				render={() => <Orders />}
			/>
			<ProtectedRoute
				exact
				path="/orders/order/:orderId"
				functionAccess="order_view"
				render={() => <Order />}
			/>
			<ProtectedRoute
				exact
				path="/qr-codes/:tabId?"
				functionAccess="checkin_view"
				render={() => <Checkins />}
			/>
			<ProtectedRoute
				exact
				path="/menus"
				functionAccess="menu_view"
				render={() => <MenusOverview />}
			/>
			<ProtectedRoute
				exact
				path="/events/:tabId?"
				functionAccess="events_view"
				render={() => <Events />}
			/>
			<ProtectedRoute
				exact
				path="/events/event/:eventId"
				functionAccess="events_view"
				render={() => <Event />}
			/>
			<ProtectedRoute
				exact
				path="/deals"
				functionAccess="deal_view"
				render={() => <Deals />}
			/>
			<ProtectedRoute path="/menus/:id" functionAccess="menu_view" render={() => <Menu />} />
			<ProtectedRoute
				exact
				path="/reporting/:tabId?"
				functionAccess="report_view"
				render={() => <Reporting />}
			/>
			<Route path="/sign-in" render={() => <SignInPage />} />
			<Route path="/fortnox" render={() => <FortnoxActivationStatus />} />

			<Route
				path="/confirm-password-reset"
				render={props => <ResetPasswordPage {...props} />}
			/>
			<Route
				path="/reset-password"
				render={props => <RequestPasswordResetPage {...props} />}
			/>
			<Route path="/welcome/:token" render={props => <WelcomePage {...props} />} />
			<Route>
				<Redirect to="/home" />
			</Route>
		</Switch>
	);
};

export default Routes;

import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const TypeaheadComponent = ({ item, value, onChange }) => (
	<Typeahead
		apiUrl={item.apiUrl}
		onChange={e => onChange(e[0] ? e[0][item.prop] : null)}
		getLabelKey={item.typeaheadOption}
		value={value}
		valueProp={item.prop}
	/>
);

export default TypeaheadComponent;

import enterSound from "assets/order-ticket-enter.mp3";
import { useSound } from "core/functions";
import { logEvent } from "core/utils";
import { useEffect } from "react";

const useEnterSound = ({ orderTicketId, isAdded, soundOnEnter }) => {
	const [playSound, isSoundLoaded] = useSound(enterSound, {
		enabled: isAdded && soundOnEnter
	});

	useEffect(() => {
		if (!isAdded) {
			return;
		}

		if (!soundOnEnter) {
			return;
		}
		if (!isSoundLoaded) {
			// The async loading of order-ticket sound
			// has yet not loaded
			return;
		}

		const soundId = playSound();

		logEvent(`play_sound`, {
			soundId,
			orderTicketId
		});

		return () => {};
	}, [isAdded, isSoundLoaded, soundOnEnter, playSound, orderTicketId]);
};

export default useEnterSound;

const getTableActions = ({ hasFunctionAccess }) => {
	if (!hasFunctionAccess("checkin_edit")) return {};

	return {
		delete: {
			apiPath: "/checkin-groups/{checkinGroupId}?storeId={activeStoreId}",
			apiMethod: "DELETE",
			apiExtraParams: ["activeStoreId"],
			message: "Do you really want to delete group {name}?",
			disabled: rowData => rowData.checkinsCount > 0,
			disabledModalMessage:
				"Group {name} can't be deleted because it contains {checkinsCount} QR code(s)."
		},
		edit: {
			inline: true,
			modal: true,
			modalTitle: "Edit group",
			apiPath: "/checkin-groups/{checkinGroupId}",
			apiMethod: "PATCH"
		}
	};
};

export default getTableActions;

/* eslint-disable react/display-name */
const schema = () => [
	{
		prop: "orderId",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Order ID",
		sort: true
	},
	{
		prop: "type",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Type",
		sort: true
	},
	{
		prop: "transactionDate",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Transaction date",
		sort: true
	},
	{
		prop: "amount",
		type: "number",
		minimumGridVisibility: "xs",
		label: "Amount",
		sort: true
	},
	{
		prop: "fee",
		type: "number",
		minimumGridVisibility: "xs",
		label: "Fee",
		sort: true
	}
];

export default schema;

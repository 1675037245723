import { Button } from "core/components";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	margin-left: ${p => p.theme.space.small};
`;

const ActionButton = ({ onClick, children, icon, "data-test": dataTest }) => {
	const handleClick = e => {
		e.stopPropagation();
		onClick();
	};

	return (
		<Container>
			<Button size="small" onClick={handleClick} data-test={dataTest} icon={icon}>
				{children}
			</Button>
		</Container>
	);
};

export default ActionButton;

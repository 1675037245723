import { FullScreenContainer, SectionHeader, TabContainer } from "core/components";
import { useTabs, useToggle } from "core/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Actions, CompletedTab, OpenTab } from "./components";
import TestAudioModal from "./components/Actions/TestAudioModal";

const Station = ({ station, onRefresh }) => {
	const [isFullScreen, toggleFullScreen] = useToggle(false);
	const [showAudioCheck, setShowAudioCheck] = useState(false);

	const tabs = useMemo(
		() => [
			{
				id: "open",
				label: "Open",
				content: <OpenTab station={station} />
			},
			{
				id: "completed",
				label: "Completed",
				content: <CompletedTab station={station} />
			}
		],
		[station]
	);

	const [activeTab, setActiveTab] = useTabs("open", tabs);

	useEffect(() => {
		if (station.sound) setShowAudioCheck(true);
	}, [station, setShowAudioCheck]);

	const handleCloseAudioCheck = useCallback(() => setShowAudioCheck(false), [setShowAudioCheck]);

	return (
		<FullScreenContainer openStyle={s.containerOpen} isFullScreen={isFullScreen}>
			<TestAudioModal show={showAudioCheck} onClose={handleCloseAudioCheck} />
			<SectionHeader
				{...{ heading: station.name, tabs, activeTab, onTabSelected: setActiveTab }}
			>
				<Actions
					{...{
						station,
						onFullScreenToggle: toggleFullScreen,
						isFullScreen,
						onRefresh
					}}
				/>
			</SectionHeader>

			<TabContainer tabs={tabs} activeTab={activeTab} />
		</FullScreenContainer>
	);
};

export default Station;

const s = {
	containerOpen: {
		padding: "0 20px 20px"
	}
};

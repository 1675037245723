import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { Button, Grid } from "core/components";
import Checkmark from "core/components/Checkmark";
import { useSwitch } from "core/utils";
import { eventRepo } from "data/repos";
import React, { useMemo } from "react";
import styled from "styled-components";
import gridSchema from "./timeSlotGridSchema";

const Container = styled.div`
	margin-bottom: 32px;
	width: 100%;

	padding: 16px;
	border-radius: 5px;
	border: 1px solid ${p => p.theme.color.softGray};
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const GridContainer = styled.div`
	margin-bottom: 8px;
`;

const ButtonsContainer = styled.div`
	display: flex;
`;

const Badge = styled.div`
	display: flex;
	align-items: center;
	margin-right: 8px;
	min-height: 34px;
	font-size: ${p => (p.makePrinterFriendly ? "24px" : "16px")};
	font-weight: bold;
	background: ${p => p.theme.color.purpleStreet};
	white-space: pre-wrap;
	color: ${p => (p.makePrinterFriendly ? "#000" : "#fff")};
	line-height: 1;
	border-radius: 5px;
	padding-left: ${p => (p.makePrinterFriendly ? "0" : "16px")};
	padding-right: 16px;
`;

const CheckmarkContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const TimeSlot = ({
	eventId,
	stations,
	timeSlot,
	items,
	makePrinterFriendly,
	selectedStations,
	selectedGrouping
}) => {
	const schema = useMemo(
		() => gridSchema({ selectedGrouping, makePrinterFriendly }),
		[selectedGrouping, makePrinterFriendly]
	);
	const {
		currentStore: { storeId }
	} = useMerchantContext();

	const [
		createOrderTicketsLoading,
		createOrderTicketsStartLoading,
		createOrderTicketsStopLoading
	] = useSwitch();

	const [orderTicketsPrinted, setOrderTicketsPrinted, setOrderTicketsNotPrinted] = useSwitch();

	const gridItems = useMemo(() => {
		const filteredItems = items?.filter(
			f =>
				((selectedGrouping.timeSlot &&
					(f.eventTimeSlotId === timeSlot?.eventTimeSlotId ||
						(!f.eventTimeSlotId && !timeSlot))) ||
					!selectedGrouping.timeSlot) &&
				!!f.items.find(i => selectedStations?.includes(i.stationId))
		);
		const filteredProducts = filteredItems?.map(i => ({
			...i,
			items: i.items.filter(f => selectedStations?.includes(f.stationId))
		}));

		return filteredProducts;
	}, [items, selectedGrouping, selectedStations, timeSlot]);

	const printerStations = useMemo(
		() =>
			stations
				?.filter(f => selectedStations.includes(f.stationId) && f.enablePrinter)
				.map(s => s.stationId),
		[selectedStations, stations]
	);

	const showPrintOrderTicketsButton = useMemo(() => {
		if (!selectedGrouping.timeSlot || !selectedGrouping.checkin) return false;

		return !!gridItems.find(f => f.items.find(i => printerStations?.includes(i.stationId)));
	}, [gridItems, selectedGrouping, printerStations]);

	const createOrderTickets = () => {
		createOrderTicketsStartLoading();

		eventRepo
			.createOrderTickets({
				storeId,
				eventId,
				stationIds: selectedStations
					.filter(stationId => printerStations.includes(stationId))
					.join(","),
				eventTimeSlotId: timeSlot?.eventTimeSlotId
			})
			.then(r => {
				if (r.code) setOrderTicketsNotPrinted();
				else setOrderTicketsPrinted();
			})
			.finally(createOrderTicketsStopLoading);
	};

	if (gridItems?.length === 0) return <></>;

	return (
		<Container>
			<TitleContainer>
				{selectedGrouping.timeSlot && (
					<Badge makePrinterFriendly={makePrinterFriendly}>
						<div>{timeSlot ? timeSlot.timeSlot : "No time slot"}</div>
					</Badge>
				)}
				{!selectedGrouping.timeSlot && <div />}
				{showPrintOrderTicketsButton && !makePrinterFriendly && (
					<ButtonsContainer>
						<Button
							size="small"
							isLoading={createOrderTicketsLoading}
							icon={!orderTicketsPrinted && faReceipt}
							onClick={createOrderTickets}
							disabled={orderTicketsPrinted}
							collapse={false}
						>
							{!orderTicketsPrinted && "Print order tickets"}
							{orderTicketsPrinted && (
								<CheckmarkContainer>
									<Checkmark value={true} />
									Order tickets printed
								</CheckmarkContainer>
							)}
						</Button>
					</ButtonsContainer>
				)}
			</TitleContainer>
			<GridContainer>
				<Grid
					{...{
						schema,
						tableKey: "rowId",
						data: gridItems,
						noDataText: "No pre orders",
						rowStyle: { padding: 0 },
						pagination: false,
						transparentHeader: true
					}}
				/>
			</GridContainer>
		</Container>
	);
};

export default TimeSlot;

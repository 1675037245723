import { Loader } from "core/components";
import React from "react";
import { Alert } from "react-bootstrap";
import { Container } from "./Status.styles";
import ActivationModal from "./ActivationModal";
import CompanyInfo from "./CompanyInfo";
import ActivatedStatus from "./ActivatedStatus";

const Status = ({
	cashRegister,
	setCashRegister,
	isLoading,
	updateAutomaticClosingHour,
	isUpdating,
	activate,
	error
}) => {
	const isActivated = !!cashRegister?.timeActivated;

	const handleFormChange = (property, value) =>
		setCashRegister({ ...cashRegister, [property]: value });

	if (isLoading) return <Loader />;

	if (isActivated)
		return (
			<div>
				<Container>
					<ActivatedStatus
						cashRegister={cashRegister}
						isUpdating={isUpdating}
						isLoading={isLoading}
						updateAutomaticClosingHour={updateAutomaticClosingHour}
					/>
				</Container>
				<Container>
					<CompanyInfo cashRegister={cashRegister} />
				</Container>
			</div>
		);

	return (
		<>
			<div>
				<Alert variant="warning">Control unit is not activated.</Alert>
				<ActivationModal
					cashRegister={cashRegister}
					onChange={handleFormChange}
					activate={activate}
					error={error}
				/>
			</div>
		</>
	);
};

export default Status;

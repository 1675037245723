import React, { useMemo } from "react";
import { Badge as BsBadge } from "react-bootstrap";
import styled from "styled-components";
import theme from "styles/theme";

const Container = styled.div`
	display: flex;
`;

const Badge = styled(BsBadge)`
	display: flex;
	align-items: center;
	margin-right: 8px;
	min-width: 20px;
	min-height: 24px;
	font-size: 12px;
	font-weight: bold;
	background: ${p => p.color};
	white-space: pre-wrap;
`;

const TimeSlotsColumn = cell => {
	const badges = useMemo(
		() =>
			Object.entries(cell).map(([k, v]) => {
				const full = v.reserved === v.slots;

				return (
					<Badge key={k} color={theme.color.softGray}>
						{k}: {full ? "full" : `${v.slots - v.reserved} left`}
					</Badge>
				);
			}),
		[cell]
	);

	return <Container>{badges}</Container>;
};

export default TimeSlotsColumn;

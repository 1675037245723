import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Text = styled.div`
	font-family: ${p => p.theme.typography.heading};
	font-size: 21px;
	display: flex;
	align-items: center;
	font-weight: 500;
`;

const Title = ({ children }) => {
	return (
		<Container>
			<Text>{children || "New Report..."}</Text>
		</Container>
	);
};

export default Title;

import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import useTime from "core/functions/use-time";

const useItem = ({
	item,
	setProductUnavailable,
	setEditProductModalVisible,
	setDeleteConfirmationModalVisible
}) => {
	const { hasFunctionAccess } = useMerchantContext();
	const { getNextCutHour, isInFuture } = useTime();

	const isItemAvailable = item.available;
	const unavailableUntil = item.unavailableUntil;
	const isItemUnavailableUntilInFuture = isInFuture(item.unavailableUntil);

	const itemChoices = [
		{
			type: "section",
			label: "Actions"
		},
		...(hasFunctionAccess("product_edit") && [
			{
				label: "Edit product",
				icon: faPen,
				onClick: () => setEditProductModalVisible(true)
			}
		]),
		{
			label: "Remove",
			icon: faTrashAlt,
			onClick: () => setDeleteConfirmationModalVisible(true)
		},
		{
			type: "section",
			label: "Availability"
		},
		{
			label: "Available",
			checked: !isItemUnavailableUntilInFuture && isItemAvailable,
			onClick: () =>
				setProductUnavailable({
					available: true,
					unavailableUntil: null
				})
		},
		{
			label: "Unavailable today",
			checked: isItemUnavailableUntilInFuture,
			onClick: () =>
				setProductUnavailable({
					available: true,
					unavailableUntil: getNextCutHour()
				})
		},
		{
			label: "Unavailable",
			checked: !isItemAvailable,
			onClick: () =>
				setProductUnavailable({
					available: false,
					unavailableUntil: null
				})
		}
	];

	return {
		itemChoices,
		unavailableUntil,
		isItemUnavailableUntilInFuture
	};
};

export default useItem;

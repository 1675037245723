import React from "react";
import ReportCsvTable from "./ReportCsvTable";
import schema from "./schema";
import { Grid } from "core/components";
import { useMerchantContext } from "context";
import styled from "styled-components";

const Container = styled.div`
	width: 100%;
`;
const ReportsGrid = ({ reports, refresh, timeZone }) => {
	const { hasFunctionAccess } = useMerchantContext();
	const gridSchema = schema({ timeZone, hasFunctionAccess, refresh });

	return (
		<Container data-test="reports">
			<Grid
				schema={gridSchema}
				expandedRowContent={parentRow => <ReportCsvTable reportId={parentRow.reportId} />}
				tableKey="reportId"
				data={reports}
				pagination
			/>
		</Container>
	);
};

export default ReportsGrid;

import React from "react";
import { useMerchantContext } from "context";
import { Amount } from "core/components";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import DetailsItem from "./DetailsItem";
import { capitalize } from "lodash";

const DetailsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin-bottom: 16px;
`;

const DetailsGrid = styled.div`
	display: flex;
`;

const DetailsGridSection = styled.div`
	margin-right: 24px;
	@media ${mediaQuery(device.tablet)} {
		margin-right: 128px;
	}
`;

const ParentOrder = styled.div`
	margin-top: 8px;
`;

const DateContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 80px;
	@media ${mediaQuery(device.tablet)} {
		flex-direction: unset;
		width: auto;
	}
`;

const DateDiv = styled.div`
	width: unset;
	margin-right: 0px;
	@media ${mediaQuery(device.tablet)} {
		width: 80px;
		margin-right: 4px;
	}
`;

const OrderLink = styled.a`
	font-weight: 500;
	margin-top: 8px;

	color: ${p => p.theme.color.emeraldGreen};
	&:hover {
		color: ${p => p.theme.color.emeraldGreen};
	}
	cursor: pointer;
`;

const OrderLinkIcon = styled(FontAwesomeIcon)`
	margin-right: 8px;
`;

const DetailsList = ({ order }) => {
	const {
		currentMerchant: { timeZone }
	} = useMerchantContext();

	const history = useHistory();
	const handleOrderClick = () => {
		history.push(`/orders/order/${order.parentOrderId}`);
	};
	const hasParentOrder = !!order.parentOrderId;

	return (
		<DetailsContainer>
			<DetailsGrid>
				<DetailsGridSection>
					<DetailsItem label="Total">
						<Amount value={order.total} />
					</DetailsItem>
					<DetailsItem label="Tip">
						<Amount value={order.tip || "0"} />
					</DetailsItem>
					<DetailsItem label="Net total">
						<Amount value={order.netTotal} />
					</DetailsItem>
					<DetailsItem label="VAT">
						<Amount value={order.vatTotal} />{" "}
					</DetailsItem>
				</DetailsGridSection>
				<DetailsGridSection>
					<DetailsItem label="ID" value={order.orderId} width={120} />
					<DetailsItem label="Date" width={120}>
						<DateContainer>
							<DateDiv>
								{moment
									.tz(order.timeCreated, timeZone || "Europe/Stockholm")
									.format("YYYY-MM-DD")}
							</DateDiv>
							<div>
								{moment
									.tz(order.timeCreated, timeZone || "Europe/Stockholm")
									.format("HH:MM")}
							</div>
						</DateContainer>
					</DetailsItem>
					<DetailsItem label="Discount" width={120}>
						<Amount value={order.discountTotal || "0"} />
					</DetailsItem>
					<DetailsItem
						label="Payment method"
						value={capitalize(order.paymentType)}
						width={120}
					/>
				</DetailsGridSection>
			</DetailsGrid>

			{hasParentOrder && (
				<ParentOrder>
					<OrderLink onClick={handleOrderClick}>
						<OrderLinkIcon icon={faMoneyCheck} />
						Original purchase order
					</OrderLink>
				</ParentOrder>
			)}
		</DetailsContainer>
	);
};
export default DetailsList;

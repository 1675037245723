import React, { useCallback } from "react";
import { faCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { httpClient } from "data";
import Button from "../Button";
import ConfirmationModal from "../ConfirmationModal";
import { useSwitch } from "core/utils";
import replacePlaceholderTagsFromData from "core/utils/replace-placeholder-tags-from-data";

const GridDelete = ({
	onChange,
	apiPath,
	apiMethod,
	row,
	message,
	title,
	buttonProps,
	disabled,
	disabledModalMessage
}) => {
	const [isOpen, open, close] = useSwitch(false);
	const [isLoading, startLoading, stopLoading] = useSwitch(false);

	let buttonDisabled;
	let onlyConfirm = false;

	const confirmButtonProps = {
		label: "Delete",
		variant: "danger",
		icon: faTrashAlt,
		isLoading: isLoading
	};

	if (typeof disabled === "function") {
		const isDisabled = disabled(row);
		if (isDisabled && disabledModalMessage) {
			confirmButtonProps.label = "Ok";
			confirmButtonProps.variant = "default";
			confirmButtonProps.icon = faCheck;
			message = disabledModalMessage;
			onlyConfirm = true;
		} else buttonDisabled = isDisabled;
	} else {
		buttonDisabled = disabled;
	}

	const {
		label: buttonLabel,
		icon: buttonIcon,
		size: buttonSize,
		variant: buttonVariant
	} = buttonProps;

	const save = useCallback(
		async data => {
			if (onlyConfirm) {
				close();
				return;
			}
			startLoading();
			await httpClient.fetch(apiPath, { method: apiMethod, body: data }).finally(stopLoading);
			close();
			onChange();
		},
		[apiPath, apiMethod, onChange, close, startLoading, stopLoading, onlyConfirm]
	);

	const handleConfirm = () => save(row);

	return (
		<>
			<Button
				icon={buttonIcon || faTrashAlt}
				disabled={buttonDisabled}
				onClick={open}
				size={buttonSize || "small"}
				variant={buttonVariant}
			>
				{buttonLabel}
			</Button>
			<ConfirmationModal
				onlyConfirm={onlyConfirm}
				title={title || "Delete"}
				text={
					replacePlaceholderTagsFromData(message, row) ||
					"Do you want to delete this item?"
				}
				show={isOpen}
				onConfirm={handleConfirm}
				onCancel={close}
				confirmButtonProps={confirmButtonProps}
			/>
		</>
	);
};

GridDelete.defaultProps = {
	buttonProps: {}
};

export default GridDelete;

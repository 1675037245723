import styled from "styled-components";
import BsModal from "react-bootstrap/Modal";

const Container = styled(BsModal)``;

const Header = styled(BsModal.Header)``;

const Title = styled(BsModal.Title)``;

const Body = styled(BsModal.Body)``;

const Footer = styled(BsModal.Footer)`
	padding-top: ${p => p.theme.space.small};
	padding-bottom: ${p => p.theme.space.small};
`;

export { Container, Header, Title, Body, Footer };

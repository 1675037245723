import { faCheck, faUndo } from "@fortawesome/free-solid-svg-icons";
import { pick } from "ramda";
import React, { useMemo } from "react";
import Modal from "./Modal";
import Button from "./Button";

const ConfirmationModal = ({
	show,
	title,
	text,
	confirmButtonProps,
	onConfirm,
	onCancel,
	onlyConfirm
}) => {
	const {
		label: confirmButtonLabel,
		icon: confirmButtonIcon,
		variant: confirmButtonVariant,
		isLoading: confirmButtonIsLoading
	} = pick(["label", "icon", "variant", "isLoading"], confirmButtonProps);

	const footer = useMemo(
		() => (
			<>
				{!onlyConfirm && (
					<Button size="large" onClick={onCancel} icon={faUndo} collapse={false}>
						Cancel
					</Button>
				)}
				<Button
					size="large"
					onClick={onConfirm}
					data-test="confirm-modal"
					icon={confirmButtonIcon || faCheck}
					variant={confirmButtonVariant || "primary"}
					isLoading={confirmButtonIsLoading}
					collapse={false}
				>
					{confirmButtonLabel || "Confirm"}
				</Button>
			</>
		),
		[
			confirmButtonIcon,
			confirmButtonVariant,
			confirmButtonIsLoading,
			confirmButtonLabel,
			onCancel,
			onConfirm,
			onlyConfirm
		]
	);

	return <Modal show={show} onHide={onCancel} title={title} body={text} footer={footer} />;
};

ConfirmationModal.defaultProps = {
	confirmButtonProps: {}
};

export default ConfirmationModal;

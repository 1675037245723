import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import useNavItem from "../use-nav-item";

const Container = styled.div`
	cursor: pointer;
	padding: 8px 4px 6px 0px;
	user-select: none;
`;

const Label = styled.span`
	padding: 3px 12px;
	font-size: 14.5px;
	font-weight: 500;
	line-height: 1;
	background-color: ${p => (p.active ? "#41ce83" : "rgba(0, 0, 0, 0)")};
	text-shadow: ${p => (p.active ? "0px 0px 2px rgba(0, 0, 0, 0.2)" : "none")};
	color: ${p => (p.active ? "#FFF" : "inherit")};
	border-radius: 50px;
	white-space: nowrap;

	&:hover {
		transition: 200ms;
		background-color: ${p => (p.active ? "#41ce83" : "#EEE")};
	}
`;

const Item = ({ item }) => {
	const { label, to } = item;
	const history = useHistory();
	const { isActive, haveAccess } = useNavItem(item);

	const handleClick = () => history.push(to);

	if (!haveAccess) {
		return null;
	}

	return (
		<Container onClick={handleClick}>
			<Label active={isActive}>{label}</Label>
		</Container>
	);
};

export default Item;

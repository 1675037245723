import { useMerchantContext } from "context";
import { Grid, SectionLoader } from "core/components";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	margin-top: 12px;
`;

const ProductGroupsTable = ({ productGroups, schema, actions, isFetching, refresh }) => {
	const { currentStore } = useMerchantContext();

	if (isFetching || !schema) return <SectionLoader />;

	return (
		<Container>
			<Grid
				isFetching={isFetching}
				actions={actions}
				schema={schema}
				tableKey="dealId"
				data={productGroups}
				reload={refresh}
				pagination
				activeStoreId={currentStore.storeId}
				hideGridCreate={true}
				noDataText="No product groups available"
			/>
		</Container>
	);
};

export default ProductGroupsTable;

import { compose, filter, isEmpty, map, prop, reduce } from "ramda";
import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import QuantityBadge from "./QuantityBadge";

const Container = styled.div`
	margin-top: 4px;
	margin-bottom: 8px;

	@media ${mediaQuery(device.tablet)} {
		margin-bottom: 12px;
	}
`;

const Heading = styled.div`
	font-size: 11px;
	line-height: 1;
	font-weight: 500;
	color: rgb(102, 102, 102);
	margin-bottom: 8px;

	@media ${mediaQuery(device.tablet)} {
		font-size: 13px;
		margin-bottom: 12px;
	}
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4px;

	&:last-child {
		margin-bottom: 0px;
	}
`;

const ItemDescription = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
`;

const ItemName = styled.span`
	font-size: 15px;
	line-height: 1;

	@media ${mediaQuery(device.tablet)} {
		font-size: 18px;
		position: relative;
		bottom: 0.5px;
	}
`;

const ItemNote = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	margin-top: 4px;

	@media ${mediaQuery(device.tablet)} {
		font-size: 19px;
	}
`;

const totalQuantityAtStation = compose(
	reduce((acc, { quantity }) => acc + quantity, 0),
	prop("orderItems")
);

const RelatedPickupOrderTickets = ({ orderTickets }) => {
	const renderNote = item => (item.note ? <ItemNote>Note: {item.note}</ItemNote> : <></>);

	const renderRelatedItems = map(item => (
		<Container>
			<Item key={item.productName}>
				<QuantityBadge size="small">{item.quantity}</QuantityBadge>
				<ItemDescription>
					<ItemName>{item.productName}</ItemName>
					{renderNote(item)}
				</ItemDescription>
			</Item>
		</Container>
	));

	const renderRelatedStations = map(t => (
		<>
			<Heading>{t.stationName}</Heading>
			{renderRelatedItems(t.orderItems)}
		</>
	));

	if (!orderTickets || isEmpty(orderTickets)) {
		return null;
	}

	return renderRelatedStations(orderTickets);
};

const RelatedOrderTickets = ({ orderTickets, isPickup }) => {
	if (!orderTickets || isEmpty(orderTickets)) return null;

	if (isPickup) return <RelatedPickupOrderTickets orderTickets={orderTickets} />;

	const renderRelatedStations = map(station => (
		<Item key={station.stationId}>
			<QuantityBadge size="small">{station.quantity}</QuantityBadge>
			<ItemName>{station.name}</ItemName>
		</Item>
	));

	const stations = compose(
		filter(s => s.quantity > 0),
		map(t => ({
			stationId: t.stationId,
			name: t.stationName,
			quantity: totalQuantityAtStation(t)
		}))
	)(orderTickets);

	if (stations.length === 0) return null;

	return (
		<Container>
			<Heading>Related</Heading>
			{renderRelatedStations(stations)}
		</Container>
	);
};

export default RelatedOrderTickets;

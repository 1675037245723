import { curry, path } from "ramda";

export default curry((msg, propPath, row) => {
	const value = path(propPath, row);

	if (Array.isArray(value) && value.length === 0) return msg;
	if (Array.isArray(value)) {
		let hasValues = false;
		for (const row of value) {
			if (row) hasValues = true;
			break;
		}
		if (!hasValues) return msg;
	}
	if (typeof value === "string" && value.trim() === "") return msg;
	return typeof value === "undefined" || value === null ? msg : null;
});

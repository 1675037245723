import React from "react";
import { Badge as BsBadge } from "react-bootstrap";
import styled from "styled-components";

const Badge = styled(BsBadge)`
	font-size: 13px;
`;

const EditBadge = () => <Badge variant="warning">Unsaved</Badge>;

export default EditBadge;

import React from "react";
import { propEq } from "ramda";
import styled from "styled-components";
import Tab from "./Tab";

const TabList = styled.ul`
	margin-bottom: 0;
	display: flex;
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 22px;
`;

const Tabs = ({ tabs, activeTab, onTabSelected }) => {
	const isActiveTab = propEq("id", activeTab?.id);

	const handleTabClick = tab => () => onTabSelected(tab);

	if (tabs.length === 0) {
		return null;
	}

	return (
		<TabList>
			{tabs.map(tab => (
				<Tab
					tab={tab}
					active={isActiveTab(tab)}
					key={tab.id}
					onClick={handleTabClick(tab)}
				/>
			))}
		</TabList>
	);
};

export default Tabs;

const envs = {
	local: {
		apiUrl: "http://localhost:8003",
		functionsUrl: "http://localhost:5001/way-development/europe-west2",
		qrBaseUrl: "https://localhost:6003",
		fortnoxAuthUrl: "https://apps.fortnox.se/oauth-v1/auth",
		firebase_consumer_api: {
			databaseURL: "http://localhost:8080",
			projectId: "way-development"
		},
		firebase_consumer_api_settings: {
			host: "localhost:8080",
			ssl: false
		},
		firebase_merchant_api: {
			apiKey: "AIzaSyCeaIju74LvIoj4I4nDkCulUAU7W5kIMtk",
			authDomain: "merchant-api---development.firebaseapp.com",
			projectId: "merchant-api---development",
			storageBucket: "merchant-api---development.appspot.com",
			messagingSenderId: "818900621387",
			appId: "1:818900621387:web:a7ab5eeb489732f64415ce"
		}
	},
	development: {
		apiUrl: "https://merchant-api.dev.passbuy.com",
		functionsUrl: "https://europe-west2-way-development.cloudfunctions.net",
		qrBaseUrl: "https://open.dev.passbuy.com",
		fortnoxAuthUrl: "https://apps.fortnox.se/oauth-v1/auth",
		firebase_consumer_api: {
			apiKey: "AIzaSyADqgAsFC6rHVigZHxTHkSf9t7emU1XXyc",
			authDomain: "way-development.firebaseapp.com",
			databaseURL: "https://way-development.firebaseio.com",
			projectId: "way-development",
			storageBucket: "way-development.appspot.com",
			messagingSenderId: "165198280014",
			appId: "1:165198280014:web:1ca27d52f629bf663b5a1f",
			measurementId: "G-5FBF3J2ZMW"
		},
		firebase_merchant_api: {
			apiKey: "AIzaSyCeaIju74LvIoj4I4nDkCulUAU7W5kIMtk",
			authDomain: "merchant-api---development.firebaseapp.com",
			projectId: "merchant-api---development",
			storageBucket: "merchant-api---development.appspot.com",
			messagingSenderId: "818900621387",
			appId: "1:818900621387:web:a7ab5eeb489732f64415ce"
		}
	},
	staging: {
		apiUrl: "https://merchant-api.staging.passbuy.com",
		functionsUrl: "https://europe-west2-way-staging-123.cloudfunctions.net",
		qrBaseUrl: "https://open.staging.passbuy.com",
		fortnoxAuthUrl: "https://apps.fortnox.se/oauth-v1/auth",
		firebase_consumer_api: {
			apiKey: "AIzaSyCg1MKUMfM3xfagQkSA14ocp_DzKoTd5QE",
			authDomain: "way-staging-123.firebaseapp.com",
			databaseURL: "https://way-staging-123.firebaseio.com",
			projectId: "way-staging-123",
			storageBucket: "way-staging-123.appspot.com",
			messagingSenderId: "192652521278",
			appId: "1:192652521278:web:db7d441ed9e5a93ad5c66f",
			measurementId: "G-8HE4GJYHKM"
		},
		firebase_merchant_api: {
			apiKey: "AIzaSyD41IuQvA9IOCUfL99SfgHkSl3_ePfP_qo",
			authDomain: "merchant-api---staging.firebaseapp.com",
			projectId: "merchant-api---staging",
			storageBucket: "merchant-api---staging.appspot.com",
			messagingSenderId: "626592776712",
			appId: "1:626592776712:web:98ee7f5720b1e59e03c7bd"
		}
	},
	production: {
		apiUrl: "https://merchant-api.passbuy.com",
		functionsUrl: "https://europe-west2-way-production-730b6.cloudfunctions.net",
		qrBaseUrl: "https://open.passbuy.com",
		fortnoxAuthUrl: "https://apps.fortnox.se/oauth-v1/auth",
		firebase_consumer_api: {
			apiKey: "AIzaSyC0nGtWUMPUzMONxX6zaRRuB2cJCu60kZg",
			authDomain: "way-production-730b6.firebaseapp.com",
			databaseURL: "https://way-production-730b6.firebaseio.com",
			projectId: "way-production-730b6",
			storageBucket: "way-production-730b6.appspot.com",
			messagingSenderId: "1064468219873",
			appId: "1:1064468219873:web:c733a7632c8c3bc363f4a0",
			measurementId: "G-MJ2LB160KT"
		},
		firebase_merchant_api: {
			apiKey: "AIzaSyCQLL2r_ZfolcVS0TS4uObQDvGCI_BkJG4",
			authDomain: "merchant-api-1116b.firebaseapp.com",
			projectId: "merchant-api-1116b",
			storageBucket: "merchant-api-1116b.appspot.com",
			messagingSenderId: "830652503730",
			appId: "1:830652503730:web:650d0b8c5610d15ed79bab"
		}
	}
};

export default envs[process.env.REACT_APP_ENVIRONMENT] || envs.development;

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Modal } from "core/components";
import styled from "styled-components";
import Item from "./Item";
import Section from "./Section";
import { useSwitch } from "core/utils";

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const Icon = styled(FontAwesomeIcon)`
	color: #999;
`;

const IconButton = styled.div`
	padding: ${p => p.theme.space.medium};
	margin: 0 ${p => p.theme.space.medium};
	cursor: pointer;
`;

const ChoiceModal = ({ title, choices }) => {
	const [show, open, close] = useSwitch(false);

	const onItemClick = useCallback(() => close(), [close]);

	const renderChoice = ({ type, ...choice }) => {
		if (type === "section") return <Section choice={choice}></Section>;

		return <Item onClick={onItemClick} choice={choice} />;
	};

	return (
		<>
			<IconButton onClick={open}>
				<Icon icon={faEllipsisV} />
			</IconButton>

			<Modal title={title} show={show} onHide={close}>
				<Container>{choices.map(renderChoice)}</Container>
			</Modal>
		</>
	);
};

export default ChoiceModal;

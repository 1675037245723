import formatOrderTicketLabel from "core/utils/format-order-ticket-label";
import { mergeRight, pick } from "ramda";

const getConfig = ({ orderTicket: t, station: s }, config) => {
	const defaultConfig = {
		orderTicketId: t.orderTicketId,
		...pick(["orderItems", "reference", "isPickup", "relatedOrderTickets", "isAdded"], t),
		label: formatOrderTicketLabel(t),
		alert: false,
		alertDelay: s.alertDelay,
		time: t.timeCreated,
		liveTime: false,
		timeAsDuration: false,
		onDoubleClick: null,
		flashOnEnter: false,
		soundOnEnter: false
	};

	return mergeRight(defaultConfig, config);
};

export default getConfig;

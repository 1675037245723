import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import QuantityBadge from "../QuantityBadge";

const Container = styled.div`
	display: flex;
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom: ${p => (p.last ? "0" : "1px solid #eee")};
	align-items: center;

	@media ${mediaQuery(device.tablet)} {
		padding-top: 8px;
		padding-bottom: 8px;
	}
`;

const Body = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
`;

const ProductName = styled.div`
	font-size: 16px;
	line-height: 1.3;
	display: block;
	flex-grow: 1;

	@media ${mediaQuery(device.tablet)} {
		font-size: 20px;
	}
`;

const Note = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	margin-top: 4px;

	@media ${mediaQuery(device.tablet)} {
		font-size: 19px;
	}
`;

const Item = ({ item, last }) => {
	const renderNote = item => (item.note ? <Note>Note: {item.note}</Note> : <></>);

	return (
		<Container last={last}>
			<QuantityBadge>{item.quantity.toString()}</QuantityBadge>

			<Body>
				<ProductName>{item.productName}</ProductName>
				{renderNote(item)}
			</Body>
		</Container>
	);
};

export default Item;

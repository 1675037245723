import moment from "moment-timezone";
import { useCallback } from "react";
const { useUserContext } = require("context/UserContext");

const useTime = () => {
	const { currentMerchant } = useUserContext();

	const timeZone = currentMerchant?.timeZone || "Europe/Stockholm";
	const cutHour = 5;

	// Convert Javascript Date (UTC ending with Z) to moment date,
	// default with now
	const momentDate = useCallback(jsDate => moment(jsDate).tz(timeZone), [timeZone]);

	// Get next cut hour
	const getNextCutHour = useCallback(() => {
		const now = momentDate();
		const hourNow = now.hour();
		let nextCutDate = now.hour(cutHour).minute(0).second(0);

		if (hourNow >= cutHour) nextCutDate = now.add(1, "day");

		return nextCutDate.toDate();
	}, [momentDate]);

	// Format a javascript UTC date to local string
	const formatDate = useCallback(
		(jsDate, formatStr) => momentDate(jsDate).format(formatStr),
		[momentDate]
	);

	// Check if a javascript UTC date is in the future
	const isInFuture = useCallback(
		jsDate => (jsDate ? momentDate().isBefore(momentDate(jsDate)) : false),
		[momentDate]
	);

	return {
		formatDate,
		getNextCutHour,
		isInFuture
	};
};

export default useTime;

import { useMerchantContext } from "context";
import { SectionLoader } from "core/components";
import { map, isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import fetchStations from "../../functions/fetch-stations";
import Station from "./Station";

const Container = styled.div`
	display: flex;
	justify-content: center;
`;

const Content = styled.div`
	width: 100%;
	margin-top: 16px;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 12px;

	@media ${mediaQuery(device.tablet)} {
		margin-top: 32px;
		width: auto;
		min-width: 400px;
		padding: 16px;
	}
`;

const Label = styled.span`
	font-family: Peak;
	font-size: 24px;
	margin-bottom: 12px;

	@media ${mediaQuery(device.tablet)} {
		font-size: 28px;
		margin-bottom: 16px;
	}
`;

const StationSwitcher = () => {
	const [stations, setStations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { currentStore } = useMerchantContext();
	const isStations = !isEmpty(stations);
	const history = useHistory();

	useEffect(() => {
		if (!currentStore) {
			setStations([]);
			return;
		}

		try {
			setIsLoading(true);

			fetchStations({ storeId: currentStore.storeId }).then(stations => {
				setStations(stations);
				setIsLoading(false);
			});
		} catch (error) {
			setIsLoading(false);
		}
	}, [currentStore]);

	const handleClick = ({ stationId }) => history.push(`/stations/${stationId}`);

	const renderStation = s => <Station station={s} key={s.stationId} onSelect={handleClick} />;

	const renderStations = map(renderStation);

	if (isLoading) {
		return <SectionLoader />;
	}

	return (
		<Container>
			<Content>
				<Label>Choose station</Label>

				{isStations && renderStations(stations)}

				{!isStations && (
					<Alert variant="warning" show>
						There are no existing stations. Please contact Passbuy to start using this
						feature.
					</Alert>
				)}
			</Content>
		</Container>
	);
};

export default StationSwitcher;

import React from "react";
import FormItem from "./FormItem";
import { StyledRow, StyledRowContainer, Title } from "./Status.styles";

const CompanyInfo = ({ cashRegister }) => (
	<>
		<StyledRow>
			<Title>Company information</Title>
		</StyledRow>
		<StyledRowContainer>
			<FormItem cashRegister={cashRegister} property="companyName" label="Name" />
			<FormItem cashRegister={cashRegister} property="orgNo" label="Org number" />
		</StyledRowContainer>
		<StyledRowContainer>
			<FormItem cashRegister={cashRegister} property="address" label="Address" />
			<FormItem cashRegister={cashRegister} property="postalCode" label="Postal code" />
		</StyledRowContainer>
		<StyledRowContainer>
			<FormItem cashRegister={cashRegister} property="city" lastRow label="City" />
		</StyledRowContainer>
	</>
);

export default CompanyInfo;

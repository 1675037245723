import { useMerchantContext } from "context";
import { Button, Loader } from "core/components";
import { reportingRepo } from "data/repos";
import React, { useEffect, useState } from "react";
import { Alert as CoreAlert } from "react-bootstrap";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const ReportContainer = styled.div`
	background-color: ${p => p.theme.color.dirtyWhite};
	border-radius: 12px;
	border: 1px solid ${p => p.theme.color.border.primary};
	padding: ${p => p.theme.space.large};
	margin-bottom: ${p => p.theme.space.large};
	justify-content: space-between;
`;

const PrintButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: ${p => p.theme.space.small};
`;

const Alert = styled(CoreAlert)`
	justify-content: center;
	display: flex;
	margin-bottom: 0;
`;

const ReportTable = ({ zReportId }) => {
	const [reportData, setReportData] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const {
		currentStore: { storeId }
	} = useMerchantContext();

	let printRef;

	useEffect(() => {
		if (!zReportId || !storeId) {
			return;
		}
		reportingRepo
			.getZReport({
				storeId,
				zReportId,
				format: { type: "text", minFirstColumnWidth: 24, reportWidth: 64 }
			})
			.then(r => {
				setIsLoading(false);
				setReportData(r);
			});
	}, [storeId, zReportId]);

	if (isLoading) return <Loader size="small" center />;

	if (!reportData) {
		return <Alert variant="warning">This z-report contains no transactions.</Alert>;
	}

	return (
		<>
			<PrintButtonContainer>
				<ReactToPrint
					pageStyle={`@page { margin: 10mm; }`}
					trigger={() => <Button icon={faPrint}>Print</Button>}
					content={() => printRef}
				/>
			</PrintButtonContainer>
			<div style={{ display: "none" }}>
				<pre style={{ border: "none" }} ref={el => (printRef = el)}>
					{reportData}
				</pre>
			</div>
			<ReportContainer>
				<pre>{reportData}</pre>
			</ReportContainer>
		</>
	);
};

export default ReportTable;

import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMerchantContext } from "context";
import { ConfirmationModal, Modal } from "core/components";
import { menuRepo } from "data/repos";
import React, { useState } from "react";
import Badge from "react-bootstrap/Badge";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SaveMenu from "../SaveMenuForm";
import { useSwitch } from "core/utils";

const Menu = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid #dedede;
	transition: border 0.5s ease;
	background: #fafafa;
	&:hover {
		border: 1px solid #adadad;
	}
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%), 0 4px 15px 0 rgb(0 0 0 / 5%);
	padding: 18px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-bottom: 8px;
	margin-top: 20px;
`;
const MenuLeft = styled.div`
	display: flex;
	align-items: center;
`;
const MenuRight = styled.div``;
const Description = styled.div`
	font-size: 14px;
	color: #4e4e4e;
`;
const ActionButtons = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`;
const IconButton = styled.span`
	padding: 10px;
	cursor: pointer;
`;
const HeaderContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
const Header = styled.h4`
	margin-right: 8px;
	margin-bottom: 0;
	display: flex;
	align-items: center;
`;
const HeaderBadges = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: -8px;
`;
const ScheduledBadge = styled(Badge)`
	background-color: #8e72f9;
	font-size: 14px;
	margin-top: 8px;
`;
const InactiveBadge = styled(Badge)`
	margin-top: 8px;
	margin-right: 10px;
	background-color: #ff735e;
	font-size: 14px;
`;
const Footer = styled.div`
	font-size: 14px;

	& span {
		margin-right: 10px;
	}
`;

const ActionButtonIcon = styled(FontAwesomeIcon)`
	color: #999;
	&:hover {
		color: #41ce84;
	}
`;

const MenuCard = ({ menu, onDelete, onSave }) => {
	const history = useHistory();
	const { hasFunctionAccess } = useMerchantContext({});
	const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);
	const [editMenuModalVisible, openEditModal, closeEditModal] = useSwitch(false);
	const [isDeleteLoading, startDeleteLoading, stopDeleteLoading] = useSwitch(false);

	if (!menu) return null;

	const sectionTitle = length => {
		return length === 1 ? "section" : "sections";
	};

	const productTitle = length => {
		return length === 1 ? "product" : "products";
	};

	const productsCount = () => {
		let nbrOfProducts = 0;
		menu.sections.forEach(section => {
			nbrOfProducts += section.items.length;
		});
		return nbrOfProducts;
	};

	const deleteMenu = async () => {
		startDeleteLoading();
		await menuRepo.deleteMenu(menu?.menuId);
		await onDelete({ showSpinner: false });
		setDeleteConfirmationModalVisible(false);
		stopDeleteLoading();
	};

	return (
		<>
			<Menu onClick={() => history.push(`/menus/${menu?.menuId}`)}>
				<MenuLeft>
					<div>
						<HeaderContainer>
							<Header>{menu?.name}</Header>
							<HeaderBadges>
								{!menu?.active && (
									<InactiveBadge variant="danger">Inactive</InactiveBadge>
								)}
								{menu?.scheduleActive && (
									<ScheduledBadge variant="secondary">
										<FontAwesomeIcon icon={faClock} color="white" />
										<span style={{ marginLeft: 4 }}>Scheduled</span>
									</ScheduledBadge>
								)}
							</HeaderBadges>
						</HeaderContainer>
						{menu?.description && <Description>{menu.description}</Description>}
					</div>
				</MenuLeft>

				<MenuRight>
					<ActionButtons>
						{hasFunctionAccess("menu_edit") && (
							<IconButton
								onClick={e => {
									e.stopPropagation();
									openEditModal();
								}}
							>
								<ActionButtonIcon icon={faPen} />
							</IconButton>
						)}
						{hasFunctionAccess("menu_edit") && (
							<IconButton
								onClick={e => {
									e.stopPropagation();
									setDeleteConfirmationModalVisible(true);
								}}
							>
								<ActionButtonIcon icon={faTrashAlt} />
							</IconButton>
						)}
					</ActionButtons>
					<Footer>
						<span>
							<strong>{menu?.sections?.length} </strong>
							{sectionTitle(menu?.sections?.length)}
						</span>
						<span>
							<strong>{productsCount()} </strong>
							{productTitle(productsCount())}
						</span>
					</Footer>
				</MenuRight>
			</Menu>

			<ConfirmationModal
				title="Delete menu"
				text={`Are you sure you want to delete the menu "${menu.name}"?`}
				show={deleteConfirmationModalVisible}
				onConfirm={deleteMenu}
				onCancel={() => setDeleteConfirmationModalVisible(false)}
				confirmButtonProps={{
					label: "Delete",
					icon: faTrashAlt,
					variant: "danger",
					isLoading: isDeleteLoading
				}}
			/>

			<Modal title="Edit menu" show={editMenuModalVisible} onHide={closeEditModal}>
				<SaveMenu
					onSave={async () => {
						await onSave({ showSpinner: false });
						closeEditModal();
					}}
					isEdit={true}
					initialData={menu}
					onCancel={closeEditModal}
				/>
			</Modal>
		</>
	);
};

export default MenuCard;

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { httpClient } from "data";
import React, { useCallback, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "core/components";

const FormItemButton = ({ row, item }) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	if (!item.onApiCall) throw new Error("Missing function onApiCall to get api call params");

	const onButtonClick = useCallback(async () => {
		setLoading(true);
		setError(null);
		setSuccess(null);

		const params = item.onApiCall(row, item);

		if (
			!row?.enablePrinter ||
			!row?.printerIP?.length > 0 ||
			!row?.printerPort?.length > 0 ||
			!row?.printerType?.length > 0
		) {
			setError(`IP/Port/Type is required to test print`);
			setLoading(false);
			return;
		}

		await httpClient
			.fetch(params.path, params)
			.then(result => {
				setSuccess(result.success);
				setError(result.error);
			})
			.catch(setError)
			.finally(() => setLoading(false));
	}, [item, row]);

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-end"
			}}
		>
			<div style={{ marginRight: 16 }}>
				{success === true && (
					<FontAwesomeIcon icon={faCheck} style={{ color: "#41ce84" }} />
				)}
				{error && (
					<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{error}</Tooltip>}>
						<FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
					</OverlayTrigger>
				)}
			</div>

			<Button onClick={onButtonClick} variant="primary" icon={item.icon} isLoading={loading}>
				{item.buttonText}
			</Button>
		</div>
	);
};

export default FormItemButton;

import moment from "moment-timezone";
import React from "react";
import { Group, Label, Value } from "./styles";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Emails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Email = styled.div`
	display: flex;
	align-items: center;
	font-size: 15px;
	margin-bottom: ${p => p.theme.space.small};

	&:last-child {
		margin-bottom: 0;
	}
`;

const EmailIcon = styled(FontAwesomeIcon)`
	margin-right: ${p => p.theme.space.small};
	margin-left: 1px;
`;

const Details = ({ active, nextRun, emails }) => {
	return (
		<>
			<Group>
				<Label>Next sendout</Label>
				<Value>
					{active
						? nextRun
							? moment(nextRun).format("YYYY-MM-DD - HH:mm")
							: "Calculating..."
						: "---"}
				</Value>
			</Group>

			<Group>
				<Label>Recipients</Label>

				<Emails>
					{emails?.map(email => (
						<Email key={email}>
							{" "}
							<EmailIcon icon={faEnvelope} />
							{email}
						</Email>
					))}
				</Emails>
			</Group>
		</>
	);
};

export default Details;

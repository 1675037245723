import { httpClient } from "data";
import urlcat from "urlcat";

const reportingRepo = {
	getCashRegister: ({ storeId }) =>
		httpClient.get(`/reporting/cu/cash-registers?storeId=${storeId}`),
	activateCashRegister: ({ storeId, cashRegister }) =>
		httpClient.post(`/reporting/cu/cash-registers?storeId=${storeId}`, {
			body: { ...cashRegister, cashRegisterName: storeId.substr(0, 10) }
		}),
	updateAutomaticClosingHour: ({ cashRegisterId, storeId, automaticClosingHour }) =>
		httpClient.patch(`/reporting/cu/cash-registers/${cashRegisterId}?storeId=${storeId}`, {
			body: {
				automaticClosingHour
			}
		}),
	getZReports: ({ storeId }) => httpClient.get(`/reporting/cu/z-reports/?storeId=${storeId}`),
	getZReport: ({ storeId, zReportId, format }) =>
		httpClient
			.get(
				`/reporting/cu/z-reports/${zReportId}?storeId=${storeId}&format=${encodeURIComponent(
					JSON.stringify(format)
				)}`
			)
			.then(r => r.getZReportText),
	getXReport: ({ storeId, format }) =>
		httpClient.get(
			`/reporting/cu/x-reports/?storeId=${storeId}&format=${encodeURIComponent(
				JSON.stringify(format)
			)}`,
			{}
		),
	createReceiptCopy: ({ storeId, orderId }) => {
		return httpClient.post(urlcat("/reporting/cu/receipts", { storeId, orderId }), {
			body: { operation: "COPY" }
		});
	},
	getOrSetFortnoxSettings: body =>
		httpClient.put("/reporting/fortnox-merchant/", {
			body
		})
};

export default reportingRepo;

import { Button as CoreButton } from "core/components";
import React, { useMemo } from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	padding: 4px 8px;
	border-radius: 6px;
	border: 1px solid #e1e1e1;
	background: #fafafa;
`;

const Label = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	margin-right: 16px;
	margin-left: 8px;
	white-space: nowrap;
`;
const Button = styled(CoreButton)`
	margin-right: ${p => (p.last ? 0 : "8px")};
`;
const ButtonContainer = styled.div`
	display: flex;
`;

const StationPicker = ({ stations, selectedStations, onChange }) => {
	const isSelected = useMemo(
		() => stationId => !!selectedStations.find(f => f === stationId),
		[selectedStations]
	);

	const handleClick = stationId => {
		const stations = isSelected(stationId)
			? selectedStations.filter(f => f !== stationId)
			: [...selectedStations, stationId];
		onChange(stations);
	};

	if (!stations) return <></>;
	return (
		<Container>
			<Label>Filter station</Label>
			<ButtonContainer>
				{stations.map((s, i) => (
					<Button
						last={i + 1 === stations.length}
						key={s.stationId}
						variant={isSelected(s.stationId) ? "primary" : "secondary"}
						onClick={() => handleClick(s.stationId)}
					>
						{s.name}
					</Button>
				))}
			</ButtonContainer>
		</Container>
	);
};

export default StationPicker;

import React from "react";
import Ticket from "./Ticket";
import { TicketConfig } from "./functions";

const OrderTicketCompleted = ({ orderTicket, station }) => {
	const config = TicketConfig(
		{ orderTicket, station },
		{
			headerColor: "green",
			time: orderTicket.timeDone
		}
	);

	return <Ticket {...config} />;
};

export default OrderTicketCompleted;

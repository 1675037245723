import { faCog, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { GridEdit } from "core/components";
import React from "react";

const Component = ({ station, onSave }) => {
	const { hasFunctionAccess, currentStore } = useMerchantContext({});

	if (!station) {
		return null;
	}

	const editStationPrinterAccess = hasFunctionAccess("station_printer_edit");
	const editStationAccess = hasFunctionAccess("station_edit");

	const { stationId, name } = station;
	const apiPath = `/stations/${stationId}?storeId=${currentStore.storeId}`;
	const title = `${name} station`;
	const schema = [
		...(editStationAccess
			? [
					{
						prop: "sound",
						type: "switch",
						label: "Play sounds"
					},
					{
						prop: "alert",
						type: "switch",
						label: "Highlight uncompleted order tickets"
					},
					{
						prop: "alertDelay",
						type: "number",
						label: "Highlight delay (minutes)",
						editStyle: { width: 100 },
						visible: editRow => editRow?.alert
					},
					{
						prop: "scheduleActive",
						type: "switch",
						label: "Use Schedule"
					},
					{
						prop: "schedule",
						containerStyle: { width: "100%" },
						type: "schedule",
						label: "Schedule",
						visible: editRow => editRow?.scheduleActive
					}
			  ]
			: []),
		...(editStationPrinterAccess
			? [
					{
						prop: "enablePrinter",
						type: "switch",
						label: "Enable printer (Epson/Star)"
					},
					{
						prop: "printerIP",
						type: "text",
						label: "IP",
						group: "printer",
						groupSortOrder: 1,
						editStyle: { width: 110 },
						placeholder: "e.g. 45.1.94.12",
						visible: editRow => editRow?.enablePrinter,
						required: true
					},
					{
						prop: "printerPort",
						type: "text",
						label: "Port",
						group: "printer",
						groupSortOrder: 2,
						placeholder: "e.g. 9100",
						editStyle: { width: 75 },
						visible: editRow => editRow?.enablePrinter,
						required: true
					},
					{
						prop: "printerType",
						type: "dropdown",
						label: "Type",
						group: "printer",
						groupSortOrder: 3,
						editStyle: { width: 75 },
						values: () => [
							{ value: "epson", label: "Epson" },
							{ value: "star", label: "Star" }
						],
						visible: editRow => editRow?.enablePrinter,
						required: true
					},
					{
						prop: "printerBeep",
						type: "switch",
						label: "Beep",
						group: "printer",
						labelOnTop: true,
						groupSortOrder: 4,
						visible: editRow => editRow?.enablePrinter,
						required: true
					},
					{
						prop: "testButton",
						type: "button",
						label: <div>&nbsp;</div>,
						buttonText: "Test",
						icon: faPrint,
						onApiCall: row => ({
							path: `/test-print`,
							method: "POST",
							body: {
								ip: row.printerIP,
								port: row.printerPort,
								type: row.printerType,
								beep: row.printerBeep
							}
						}),
						group: "printer",
						visible: editRow => editRow?.enablePrinter,
						groupSortOrder: 3,
						editStyle: { width: 75 }
					}
			  ]
			: [])
	];

	const handleChange = async () => onSave();

	if (!editStationAccess && !editStationPrinterAccess) return null;

	return (
		<GridEdit
			row={station}
			buttonStyle={s.buttonStyle}
			onChange={handleChange}
			apiMethod={"PATCH"}
			buttonProps={{ label: "Settings", icon: faCog, size: "medium" }}
			{...{ apiPath, title, schema }}
		/>
	);
};

Component.displayName = "OrderTicketDesinationConfigModal";

export default Component;

const s = {
	buttonStyle: {
		width: "auto",
		height: "auto",
		backgroundColor: "#f1f1f1",
		marginTop: 0,
		padding: 0,
		paddingLeft: 10,
		paddingRight: 10,
		fontSize: 14
	}
};

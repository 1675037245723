import React from "react";

const Component = ({ prop, row, children }) => {
	if (row === false) {
		return <th style={s.th}>{children}</th>;
	}
	return <td style={s.td}>{row[prop]}</td>;
};
Component.isColumn = true;
Component.displayName = "GridColumn";
Component.defaultProps = {
	style: {},
	imgStyle: {}
};

export default Component;

const s = {
	buttonStyle: {
		marginTop: "-3px",
		padding: "5px 7px",
		fontSize: "10px"
	},
	thead: {
		textTransform: "uppercase",
		fontSize: 14,
		fontWeight: "500",
		color: "#333"
	},
	th: {
		fontSize: 14,
		padding: 10,
		borderBottom: "1px solid #eaeaea",
		borderTop: "0px",
		backgroundColor: "#EEEEEE"
	},
	thFirst: {
		paddingLeft: 0
	},

	trBody: {
		// cursor: "pointer",
	},
	td: {
		color: "#3c5746",
		fontSize: 14,

		lineHeight: "20px"
	},
	tdFirst: {
		width: "5px",
		paddingLeft: 0
	},
	tdButtons: {
		width: "5px",
		paddingLeft: 0
	},
	moreIcon: {
		color: "#666"
	}
};

import RSpinner from "react-spinkit";
import styled from "styled-components";
import { always, cond, equals } from "ramda";

const getSize = cond([
	[equals("small"), always(9)],
	[equals("medium"), always(12)],
	[equals("large"), always(16)]
]);

const getAlignmentCorrection = cond([
	[equals("small"), always(-1)],
	[equals("medium"), always(-2.5)],
	[equals("large"), always(-3)]
]);

const Container = styled.div`
	display: flex;
	justify-content: ${p => (p.center ? "center" : "flex-start")};
	align-items: center;
	flex-grow: 1;
	padding: ${p => (p.padding ? p.theme.space[p.padding] : 0)};
`;

const Dots = styled(RSpinner)`
	height: 100%;
	position: relative;
	bottom: ${p => getAlignmentCorrection(p.size)}px;

	& > div {
		width: ${p => getSize(p.size)}px;
		height: ${p => getSize(p.size)}px;
	}
`;

export { Container, Dots };

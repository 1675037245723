import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { stationRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useStations = () => {
	const [stations, setStations] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const {
		currentStore: { storeId }
	} = useMerchantContext();

	const refresh = useCallback(
		(args = { loader: true }) => {
			args?.loader && startLoading();

			stationRepo.getStations({ storeId }).then(setStations).finally(stopLoading);
		},
		[startLoading, stopLoading, storeId, setStations]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [stations, isLoading, refresh];
};

export default useStations;

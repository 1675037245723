import { isNilOrEmpty } from "core/utils";
import { curry, path } from "ramda";

const allowEmpty = curry((fn, propPath, row) => {
	const value = path(propPath, row);
	if (isNilOrEmpty(value)) return null;

	return fn(propPath, row);
});

export default allowEmpty;

import { useEffect, useState } from "react";

const getItemByKey = (key, defualt) => {
	const item = window.localStorage.getItem(key);
	if (item === null) return defualt;
	if (item === "undefined") return undefined;

	return JSON.parse(item);
};

const usePersistedState = (key, defualt) => {
	const [storedValue, setStoredValue] = useState(getItemByKey(key, defualt));

	useEffect(() => {
		setStoredValue(getItemByKey(key, defualt));
	}, [key, defualt]);

	useEffect(() => {
		if (!key) {
			return;
		}

		const jsonValue = JSON.stringify(storedValue);
		window.localStorage.setItem(key, jsonValue);
	}, [key, storedValue]);

	return [storedValue, setStoredValue];
};

export default usePersistedState;

import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "core/components";
import Alert from "react-bootstrap/Alert";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import EditSchedule from "../../EditSchedule/EditSchedule";
import { useMerchantContext } from "context";
import { menuRepo } from "data/repos";

const BtnGroup = styled.div`
	border-top: 1px solid #dee2e6;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	align-content: space-between;
	padding-top: 0.75rem;

	& button:first-child {
		margin-right: 0.5rem;
	}
`;

const SaveMenuForm = ({ initialData, isEdit, onCancel, onSave }) => {
	const menuData = initialData
		? {
				name: initialData?.name,
				description: initialData?.description,
				active: initialData?.active,
				scheduleActive: initialData?.scheduleActive,
				schedule: initialData?.schedule
		  }
		: {
				name: "",
				description: "",
				active: false,
				scheduleActive: false,
				schedule: null
		  };
	const { currentStore } = useMerchantContext();
	const [errorVisible, setErrorVisible] = useState(false);
	const [savingMenu, setSavingMenu] = useState(false);
	const [values, setValues] = useState(menuData);

	const handleInputChange = event => {
		const { name, value } = event.target;
		setValues({ ...values, [name]: value });
	};

	const saveMenu = async e => {
		e.preventDefault();
		setSavingMenu(true);
		const response = isEdit
			? await menuRepo.updateMenu({ menuId: initialData?.menuId, ...values })
			: await menuRepo.createMenu({ storeId: currentStore.storeId, ...values });

		if (response?.code) {
			setErrorVisible(true);
		} else {
			await onSave();
		}

		setSavingMenu(false);
	};

	const setSchedule = value => {
		setValues(prevValues => ({
			...prevValues,
			schedule: value
		}));
	};

	return (
		<>
			{errorVisible ? (
				<Alert variant="danger">Something when wrong. Please try again.</Alert>
			) : null}
			<Form onSubmit={saveMenu}>
				<Form.Group>
					<Form.Label>Name</Form.Label>
					<Form.Control
						name="name"
						type="text"
						onChange={handleInputChange}
						value={values.name}
						required
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Description</Form.Label>
					<Form.Control
						name="description"
						type="text"
						onChange={handleInputChange}
						value={values.description}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Check
						type="switch"
						label="Active"
						id={"activeCheckbox"}
						onChange={event => {
							event.persist();
							setValues(prevValues => ({
								...prevValues,
								active: event?.target?.checked
							}));
						}}
						checked={values?.active}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Check
						type="switch"
						label="Use schedule"
						id={"scheduleCheckbox"}
						onChange={event => {
							event.persist();
							setValues(prevValues => ({
								...prevValues,
								scheduleActive: event?.target?.checked
							}));
						}}
						checked={values?.scheduleActive}
					/>
				</Form.Group>
				{values.scheduleActive && (
					<EditSchedule setSchedule={setSchedule} schedule={values.schedule} />
				)}
				<BtnGroup>
					<Button icon={faUndo} onClick={onCancel} size="large" collapse={false}>
						Cancel
					</Button>

					<Button
						variant="primary"
						icon={faSave}
						isLoading={savingMenu}
						type="submit"
						size="large"
						collapse={false}
					>
						Save
					</Button>
				</BtnGroup>
			</Form>
		</>
	);
};

export default SaveMenuForm;

import React from "react";
import schema from "./schema";
import { Grid } from "core/components";
import styled from "styled-components";
import PayoutDetails from "./PayoutDetails";

const Container = styled.div`
	width: 100%;
`;
const PayoutsGrid = ({ payouts, timeZone }) => {
	const gridSchema = schema({ timeZone });

	return (
		<Container data-test="payouts">
			<Grid
				schema={gridSchema}
				expandedRowContent={parentRow => <PayoutDetails payoutId={parentRow.payoutId} />}
				tableKey="payoutId"
				data={payouts}
				pagination
			/>
		</Container>
	);
};

export default PayoutsGrid;

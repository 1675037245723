import { Grid } from "core/components";
import React from "react";
import QuantityColumn from "./QuantityColumn";

const gridSchema = makePrinterFriendly => [
	{
		prop: "quantity",
		type: "number",
		label: "quantity",
		minimumGridVisibility: "xs",
		...(!makePrinterFriendly && { formatter: QuantityColumn })
	},
	{
		align: "left",
		prop: "productName",
		type: "text",
		label: "Product",
		minimumGridVisibility: "xs"
	}
];

const EventItemsColumn = (cell, makePrinterFriendly) => {
	return (
		<Grid
			schema={gridSchema(makePrinterFriendly)}
			tableKey="rowId"
			data={cell}
			rowStyle={{ ...(!makePrinterFriendly && { display: "flex", marginLeft: -8 }) }}
			noHeader
			noBorder
			flexContainer
			pagination={false}
		/>
	);
};

export default EventItemsColumn;

import { map } from "ramda";
import React from "react";
import { Container, Aside, Nav, Title, Content } from "./SideNav.styles";
import Item from "./Item";

const SideNav = ({ title, items, activeItem, onActiveItemChanged }) => {
	const renderItems = map(i => (
		<Item item={i} activeItem={activeItem} onClick={onActiveItemChanged} key={i.id} />
	));

	return (
		<Container>
			<Aside>
				<Title>{title}</Title>

				<Nav>{renderItems(items)}</Nav>
			</Aside>

			<Content>{activeItem?.content}</Content>
		</Container>
	);
};

export default SideNav;

import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;

const fadeInAnimation = () =>
	css`
		${fadeIn} 200ms
	`;

const Backdrop = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	animation: ${fadeInAnimation};
`;

export default Backdrop;

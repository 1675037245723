import { PageContainer } from "auth/components";
import { welcomeTokenRepo } from "data/repos";
import React, { useCallback, useEffect, useState } from "react";
import ActivateForm from "./ActivateForm";
import Expired from "./Expired";
import Consumed from "./Consumed";
import Invalid from "./Invalid";
import { useParams } from "react-router-dom";

const WelcomePage = () => {
	const params = useParams();
	const token = params?.token;

	const [email, setEmail] = useState(null);
	const [expired, setExpired] = useState(false);
	const [invalid, setInvalid] = useState(false);
	const [consumed, setConsumed] = useState(false);

	const verifyToken = useCallback(async () => {
		const { email, invalid, consumed, expired } = await welcomeTokenRepo.verifyWelcomeToken(
			token
		);

		setEmail(email);
		setInvalid(invalid);
		setExpired(expired);
		setConsumed(consumed);
	}, [token]);

	useEffect(() => {
		verifyToken();
	});

	if (consumed)
		return (
			<PageContainer>
				<Consumed />
			</PageContainer>
		);

	if (invalid)
		return (
			<PageContainer>
				<Invalid />
			</PageContainer>
		);

	if (expired)
		return (
			<PageContainer>
				<Expired />
			</PageContainer>
		);

	if (email)
		return (
			<PageContainer>
				<ActivateForm token={token} email={email} />
			</PageContainer>
		);

	if (expired) return <PageContainer />;

	return <PageContainer />;
};

export default WelcomePage;

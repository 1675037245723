import brandColors from "./brand-colors";

const theme = {
	pageContentMaxWidth: "1610px",
	pageContentPadding: "12px",
	pageContentPaddingLarge: "22px",
	space: {
		tiny: "2px",
		mini: "4px",
		small: "8px",
		medium: "12px",
		large: "16px",
		huge: "22px",
		giant: "28px"
	},
	color: {
		...brandColors,
		text: {
			primary: brandColors.black,
			muted: brandColors.midGray,
			white: brandColors.white
		},
		border: {
			primary: brandColors.softGray,
			muted: brandColors.dirtyWhite
		},
		icon: {
			black: brandColors.black,
			muted: brandColors.lightGray,
			white: brandColors.white
		}
	},
	typography: {
		heading: `"Peak", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
		text: `"RedHatText", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
	}
};

export default theme;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { httpClient } from "data";
import React, { useCallback, useState } from "react";
import GridModal from "./GridModal";
import Button from "../Button";
import { useSwitch } from "core/utils";

const GridCreate = ({
	onChange,
	title,
	description,
	schema,
	row,
	apiPath,
	apiMethod,
	buttonProps
}) => {
	const [show, open, close] = useSwitch(false);
	const [errorMessage, setErrorMessage] = useState();
	const {
		label: buttonLabel,
		icon: buttonIcon,
		size: buttonSize,
		variant: buttonVariant
	} = buttonProps;

	const onSave = useCallback(
		async data => {
			const response = await httpClient.fetch(apiPath, {
				method: apiMethod,
				body: data
			});
			if (response.code) {
				setErrorMessage(response.message || "Could not save changes");
				return;
			} else {
				setErrorMessage(undefined);
				close();
				await onChange();
			}
		},
		[apiPath, apiMethod, onChange, close]
	);

	return (
		<>
			<GridModal
				{...{ schema, row, title, description, show, errorMessage, onSave }}
				onHide={close}
			/>

			<Button
				icon={buttonIcon || faPlus}
				variant={buttonVariant}
				size={buttonSize}
				onClick={open}
			>
				{buttonLabel || "New"}
			</Button>
		</>
	);
};

GridCreate.defaultProps = {
	buttonProps: {}
};

export default GridCreate;

import CONFIG from "core/config";
import { mergeLeft } from "ramda";
import FetchConfig from "./FetchConfig";

const applyConfig = (fetch, defaulConfig) => (path, config) =>
	fetch(path, mergeLeft(defaulConfig, config));

const HttpClient = () => {
	var merchantId = null;
	var user = null;

	const setMerchantId = m => {
		merchantId = m;
	};

	const setUser = u => {
		user = u;
	};

	const _fetch = async (path, config = {}) => {
		const url = `${CONFIG.apiUrl}${path}`;
		const authToken = user && (await user.getIdToken());
		const fetchConfig = FetchConfig({ merchantId, authToken, ...config });

		return fetch(url, fetchConfig)
			.then(response => response.text())
			.then(response => {
				if (response.length > 0) return JSON.parse(response);
			})
			.catch(err => {
				// TODO: fix error handling
				console.error("API CALL error", err);
				return { error: "Something went wrong", code: "API_CALL_ERROR" };
			});
	};

	const getFetch = applyConfig(_fetch, { method: "GET" });

	const postFetch = applyConfig(_fetch, { method: "POST" });

	const putFetch = applyConfig(_fetch, { method: "PUT" });

	const patchFetch = applyConfig(_fetch, { method: "PATCH" });

	const deleteFetch = applyConfig(_fetch, { method: "DELETE" });

	return {
		fetch: _fetch,
		get: getFetch,
		post: postFetch,
		put: putFetch,
		patch: patchFetch,
		delete: deleteFetch,
		setMerchantId,
		setUser: setUser
	};
};

export default HttpClient();

import { useMerchantContext } from "context";
import { compose, map, propEq, reject } from "ramda";
import React from "react";
import Store from "./Store";

const Stores = ({ onSelect }) => {
	const { switchStore, stores, currentStore } = useMerchantContext();

	const handleSelect = s => {
		switchStore(s.storeId);
		onSelect(s);
	};

	const renderStores = compose(
		map(s => <Store store={s} onSelect={handleSelect} key={s.storeId} />),
		reject(propEq("storeId", currentStore?.storeId))
	);

	return renderStores(stores);
};

export default Stores;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
	padding-bottom: ${p => p.theme.space.medium};
	margin-bottom: ${p => (p.noMargin ? 0 : p.theme.space.large)};
	box-shadow: inset 0 -1px #ebeef1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 24px;
	margin-right: ${p => p.theme.space.small};
	align-items: baseline;
	color: ${p => p.theme.color.midGray};
	display: flex;
`;

const Section = ({ title, noMargin, children }) => {
	return (
		<Container noMargin={noMargin}>
			{title && (
				<Title>
					<div>{title}</div>
				</Title>
			)}
			{children}
		</Container>
	);
};

export default Section;

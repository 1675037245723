import logEvent from "core/utils/log-event";
import { Howl, Howler } from "howler";
import { useCallback, useEffect, useState } from "react";

Howler.autoUnlock = true;

const useSound = (src, { enabled, volume = 1, playbackRate = 1 }) => {
	const [sound, setSound] = useState(null);
	const [isSoundLoaded, setSoundLoaded] = useState(false);

	const handleLoad = useCallback(function () {
		setSound(this);
		setSoundLoaded(true);
	}, []);

	useEffect(() => {
		if (!enabled) return;

		const howl = new Howl({
			src,
			rate: playbackRate,
			onload: handleLoad,
			volume,
			onloaderror: (soundId, errorMessage) =>
				logEvent("load_sound_error", { soundId, errorMessage }),
			onunlock: () => logEvent("sound_unlock"),
			onend: soundId => logEvent("play_sound_end", { soundId }),
			onplayerror: (soundId, errorMessage) => {
				logEvent("play_sound_error", { soundId, errorMessage });

				howl.once("unlock", () => howl.play());
			}
		});

		return () => {
			try {
				howl.unload();
			} catch (err) {
				console.error(err);
			}
		};
	}, [src, playbackRate, volume, enabled, handleLoad]);

	const play = useCallback(
		(options = {}) => {
			if (!sound) {
				return;
			}

			if (options.playbackRate) {
				sound.rate(options.playbackRate);
			}

			return sound.play(options.id);
		},
		[sound]
	);

	return [play, isSoundLoaded];
};

export default useSound;

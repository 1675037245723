import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
	faAngleDown,
	faGripLines,
	faPen,
	faPlus,
	faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMerchantContext } from "context";
import { ConfirmationModal, Modal } from "core/components";
import { useSwitch } from "core/utils";
import { menuRepo } from "data/repos";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Badge from "react-bootstrap/Badge";
import styled from "styled-components";
import SaveSectionForm from "../SaveSectionForm";
import MenuProduct from "./MenuProduct";
import SaveProductForm from "./MenuProduct/SaveProductForm";

const IconButton = styled.span`
	padding: 5px;
`;
const SectionContainer = styled.div`
	background: #fafafa;
	border-radius: 5px;
	cursor: pointer;
	transition: border 0.2s ease;
	border: 1px solid #dedede;
	&:hover {
		border: 1px solid #adadad;
	}
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%), 0 4px 15px 0 rgb(0 0 0 / 5%);
	user-select: none;
`;
const Section = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	padding: 8px;
`;
const SectionLeftContainer = styled.div`
	display: flex;
`;
const SectionLeft = styled.div`
	display: flex;
	align-items: center;
`;
const HeaderContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
`;
const Description = styled.div`
	font-size: 14px;
	color: #4e4e4e;
`;
const HeaderTitle = styled.h4`
	margin-bottom: 0;
	display: flex;
	align-items: center;
	font-size: 20px;
	margin-right: 10px;
`;
const HeaderBadges = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: -4px;
`;
const ScheduledBadge = styled(Badge)`
	background-color: #8e72f9;
	font-size: 14px;
	margin-top: 4px;
`;
const InactiveBadge = styled(Badge)`
	margin-right: 10px;
	background-color: #ff735e;
	font-size: 14px;
	margin-top: 4px;
`;
const Grip = styled.div`
	height: 100%;
	padding: 15px;
	margin-right: 0px;
	display: flex;
	align-items: center;
	height: 100%;
`;
const ActionButtons = styled.div`
	display: flex;
	justify-content: space-evenly;
	padding-right: 5px;
	align-items: flex-end;
`;
const ActionButtonIcon = styled(FontAwesomeIcon)`
	color: #999;
	margin: 5px;
	&:hover {
		color: #41ce84;
	}
`;
const Products = styled.div`
	padding: 0 15px;
	padding-right: 0px;
	margin-bottom: 40px;
`;
const NoProducts = styled.div`
	padding: 10px 20px;
	margin: 10px 20px;
	background: #f5f5f5;
	border-radius: 10px;
`;

const MenuSection = ({
	section,
	menuId,
	reloadMenu,
	products,
	dragHandleProps,
	onDeleteProduct,
	onProductChange
}) => {
	const [editSectionModalVisible, setEditSectionModalVisible] = useState(false);
	const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);
	const [newProductModalVisible, setNewProductModalVisible] = useState(false);
	const [isDeleteLoading, startDeleteLoading, stopDeleteLoading] = useSwitch(false);
	const [open, setOpen] = useState(false);
	const { hasFunctionAccess } = useMerchantContext();

	const deleteMenuSection = async () => {
		startDeleteLoading();
		await menuRepo.deleteSection({ menuId, menuSectionId: section?.menuSectionId });
		await reloadMenu();
		setDeleteConfirmationModalVisible(false);
		stopDeleteLoading();
	};

	const onSaveMenuSection = async () => {
		await reloadMenu();
		setEditSectionModalVisible(false);
	};

	const onSaveProduct = async () => {
		await reloadMenu();
		setNewProductModalVisible(false);
		setOpen(true);
	};

	return (
		<>
			<SectionContainer onClick={() => setOpen(state => !state)}>
				<Section>
					<SectionLeftContainer>
						<Grip {...dragHandleProps}>
							<FontAwesomeIcon
								icon={faGripLines}
								color="silver"
								style={{ fontSize: 16 }}
							/>
						</Grip>
						<SectionLeft>
							<div>
								<HeaderContainer>
									<HeaderTitle>{section?.name}</HeaderTitle>
									<HeaderBadges>
										{!section?.active && (
											<InactiveBadge variant="danger">Inactive</InactiveBadge>
										)}
										{section?.scheduleActive && (
											<ScheduledBadge variant="secondary">
												<FontAwesomeIcon icon={faClock} color="white" />
												<span style={{ marginLeft: 4 }}>Scheduled</span>
											</ScheduledBadge>
										)}
									</HeaderBadges>
								</HeaderContainer>
								{section?.description && (
									<Description>{section.description}</Description>
								)}
							</div>
						</SectionLeft>
					</SectionLeftContainer>
					{hasFunctionAccess("menu_edit") && (
						<ActionButtons>
							<IconButton
								onClick={e => {
									e.stopPropagation();
									setNewProductModalVisible(true);
								}}
							>
								<ActionButtonIcon icon={faPlus} />
							</IconButton>
							<IconButton
								onClick={e => {
									e.stopPropagation();
									setEditSectionModalVisible(true);
								}}
							>
								<ActionButtonIcon icon={faPen} />
							</IconButton>
							<IconButton
								onClick={e => {
									e.stopPropagation();
									setDeleteConfirmationModalVisible(true);
								}}
							>
								<ActionButtonIcon icon={faTrashAlt} />
							</IconButton>
							<IconButton style={{ marginLeft: 8 }}>
								<ActionButtonIcon
									icon={faAngleDown}
									rotation={open ? 180 : 0}
									color="darkgrey"
								/>
							</IconButton>
						</ActionButtons>
					)}
				</Section>
			</SectionContainer>

			{open &&
				(section?.items.length > 0 ? (
					<Droppable
						droppableId={section?.menuSectionId}
						type={`MenuProduct_${section?.menuSectionId}`}
					>
						{provided => (
							<Products {...provided.droppableProps} ref={provided.innerRef}>
								{section?.items.map((menuItem, index) => (
									<Draggable
										key={menuItem?.product?.productId}
										draggableId={`${section?.menuSectionId}_${menuItem?.product?.productId}`}
										index={index}
										type={`MenuProduct_${section?.menuSectionId}`}
									>
										{provided => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<MenuProduct
													key={menuItem?.product?.productId}
													item={menuItem}
													menuId={menuId}
													menuSectionId={section?.menuSectionId}
													onDelete={onDeleteProduct}
													onProductChange={onProductChange}
												/>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</Products>
						)}
					</Droppable>
				) : (
					<NoProducts>The section does not contain any products yet.</NoProducts>
				))}
			<Modal
				title={"Edit section"}
				show={editSectionModalVisible}
				onHide={() => setEditSectionModalVisible(false)}
			>
				<SaveSectionForm
					onSave={onSaveMenuSection}
					onCancel={() => setEditSectionModalVisible(false)}
					menuId={menuId}
					isEdit={true}
					initialData={section}
				/>
			</Modal>

			<Modal
				title={`Add product to ${section.name}`}
				show={newProductModalVisible}
				onHide={() => setNewProductModalVisible(false)}
			>
				<SaveProductForm
					products={products}
					onCancel={() => setNewProductModalVisible(false)}
					onSave={onSaveProduct}
					menuId={menuId}
					menuSectionId={section?.menuSectionId}
					sectionItems={section?.items}
				/>
			</Modal>

			<ConfirmationModal
				title="Delete section"
				text={`Are you sure you want to delete the menu section "${section.name}"?`}
				show={deleteConfirmationModalVisible}
				onConfirm={deleteMenuSection}
				onCancel={() => setDeleteConfirmationModalVisible(false)}
				confirmButtonProps={{
					label: "Delete",
					icon: faTrashAlt,
					variant: "danger",
					isLoading: isDeleteLoading
				}}
			/>
		</>
	);
};

export default MenuSection;

import React, { useState } from "react";

const Component = ({ textOn, textOff, value, disabled, handleChange }) => {
	const [currentState, setCurrentState] = useState(value || false);

	return (
		<div
			style={{
				display: "flex",
				border: `1px solid ${disabled ? "#ccc" : currentState ? "#41ce84" : "#ff735e"}`,
				borderRadius: 4,
				background: disabled ? "#fefefe" : "#fff",
				color: "#fff"
			}}
		>
			<div
				style={{
					textAlign: "center",
					...(currentState && {
						background: disabled ? "#ccc" : "#41ce84"
					}),
					...(!currentState && {
						color: disabled ? "#ccc" : "#ff735e"
					}),
					cursor: "pointer",
					userSelect: "none",
					paddingTop: 2,
					paddingBottom: 2,
					paddingLeft: 6,
					paddingRight: 6,
					margin: 4,
					borderRadius: 4,
					marginRight: 8
				}}
				onClick={async () => {
					if (disabled) return;
					setCurrentState(true);
					const result = await handleChange(true);
					if (result?.success === false) setCurrentState(false);
				}}
			>
				{textOn || "Yes"}
			</div>
			<div
				style={{
					userSelect: "none",
					cursor: "pointer",
					textAlign: "center",
					paddingTop: 2,
					paddingBottom: 2,
					paddingLeft: 6,
					paddingRight: 6,
					margin: 4,
					borderRadius: 4,
					...(!currentState && {
						background: disabled ? "#ccc" : "#ff735e"
					}),
					...(currentState && {
						color: disabled ? "#ccc" : "#41ce84"
					})
				}}
				onClick={async () => {
					if (disabled) return;
					setCurrentState(false);
					const result = await handleChange(false);
					if (result?.success === false) setCurrentState(false);
				}}
			>
				{textOff || "No"}
			</div>
		</div>
	);
};

Component.displayName = "ToggleSwitch";

export default Component;

import { curry, compose, defaultTo, prop, head, sortBy } from "ramda";

import findStoreById from "./find-store-by-id";

const resolveCurrentStore = curry((stores, currentStoreId) =>
	compose(
		defaultTo(compose(head, sortBy(prop("name")))(stores)),
		findStoreById(stores)
	)(currentStoreId)
);

export default resolveCurrentStore;

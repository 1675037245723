import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const DetailsRow = styled.div`
	display: flex;
	margin-bottom: 8px;
`;
const RowLabel = styled.div`
	width: ${p => (p.width ? `${p.width - 20}px` : "auto")};
	color: ${p => p.theme.color.lightGray};
	@media ${mediaQuery(device.tablet)} {
		width: ${p => (p.width ? `${p.width}px` : "auto")};
	}
`;
const DetailsItem = ({ label, value, width, children }) => {
	return (
		<DetailsRow>
			<RowLabel width={width || 80}>{label}</RowLabel>
			<div>{value || children}</div>
		</DetailsRow>
	);
};

export default DetailsItem;

import React from "react";
import { Loader } from "core/components";
import ReportsGrid from "./ReportsGrid";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import { useMerchantContext } from "context";
import { isEmpty } from "ramda";

const Content = styled.div`
	width: 100%;
`;

const XReport = ({ zReports, isLoading, refresh }) => {
	const { currentMerchant } = useMerchantContext();
	const timeZone = currentMerchant?.timeZone || "Europe/Stockholm";
	const haveReports = !isLoading && !isEmpty(zReports);

	if (isLoading) return <Loader />;

	return (
		<div>
			<Content>
				{haveReports && <ReportsGrid {...{ reports: zReports, refresh, timeZone }} />}

				<Alert
					show={!haveReports}
					variant="warning"
					style={{ alignSelf: "flex-start" }}
					data-test="reports-empty-message"
				>
					No Z reports have been generated
				</Alert>
			</Content>
		</div>
	);
};
export default XReport;

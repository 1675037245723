import { useMerchantContext } from "context";
import { SectionHeader, SectionLoader } from "core/components";
import { httpClient } from "data";
import { isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import Container from "./Container";
import CreateReportButton from "./CreateReportButton";
import ReportsGrid from "./ReportsGrid";
import useReports from "./use-reports";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Reports = () => {
	const { hasFunctionAccess, currentMerchant } = useMerchantContext();
	const timeZone = currentMerchant?.timeZone || "Europe/Stockholm";
	const [reportFunctions, setReportFunctions] = useState([]);
	const [reports, isLoading, refresh] = useReports();
	const haveReports = !isLoading && !isEmpty(reports);

	useEffect(() => {
		httpClient.get("/reporting/report-functions/").then(setReportFunctions);
	}, [currentMerchant]);

	const renderContent = () => (
		<Content>
			{haveReports && <ReportsGrid {...{ reports, refresh, timeZone }} />}

			<Alert
				show={!haveReports}
				variant="warning"
				style={{ alignSelf: "flex-start" }}
				data-test="reports-empty-message"
			>
				You haven&apos;t created any reports yet
			</Alert>
		</Content>
	);

	return (
		<Container>
			<SectionHeader heading="Reports">
				{hasFunctionAccess("report_edit") && (
					<CreateReportButton {...{ reportFunctions, refresh, timeZone }} />
				)}
			</SectionHeader>

			{!isLoading && renderContent()}
			{isLoading && <SectionLoader />}
		</Container>
	);
};

export default Reports;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { SectionHeader, TabContainer, Button } from "core/components";
import { useSwitch } from "core/utils";
import { httpClient } from "data";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BookkeepingAccounts from "./BookkeepingAccounts";
import Files from "./Files";
import SIEFileModal from "./SIEFileModal";
import Status from "./Status";

const SIE = ({ refresh }) => {
	const { currentMerchant, currentStore, hasFunctionAccess } = useMerchantContext();
	const [showModal, openModal, closeModal] = useSwitch(false);
	const [sieFiles, setSIEFiles] = useState([]);
	const [isFetching, setIsFetching] = useState(true);
	const [timeZone, setTimeZone] = useState("Europe/Stockholm");
	const [integrationSettings, setIntegrationSettings] = useState();
	const [oldIntegrationSettings, setOldIntegrationSettings] = useState();
	const [exportFromDate, setExportFromDate] = useState();
	const [productGroups, setProductGroups] = useState([]);

	const handleSave = useCallback(
		() =>
			httpClient.patch("/reporting/sie-merchant", {
				body: { ...integrationSettings, productGroups }
			}),
		[integrationSettings, productGroups]
	);

	const tabs = useMemo(
		() => [
			...(hasFunctionAccess("report_edit") && [
				{
					id: "status",
					label: "Status",
					content: (
						<Status
							{...{
								refresh,
								integrationSettings,
								setIntegrationSettings,
								oldIntegrationSettings,
								exportFromDate,
								setExportFromDate,
								onSave: handleSave
							}}
						/>
					)
				}
			]),
			...(hasFunctionAccess("report_view") && [
				{
					id: "files",
					label: "Files",
					content: <Files {...{ sieFiles, isFetching, timeZone }} />
				}
			]),

			...(hasFunctionAccess("report_edit") && [
				{
					id: "accounts",
					label: "Accounts",
					content: (
						<BookkeepingAccounts
							{...{
								integrationSettings,
								setIntegrationSettings,
								oldIntegrationSettings,
								setOldIntegrationSettings,
								handleSave,
								productGroups,
								setProductGroups,
								onSave: handleSave
							}}
						/>
					)
				}
			])
		],
		[
			hasFunctionAccess,
			refresh,
			sieFiles,
			isFetching,
			timeZone,
			integrationSettings,
			oldIntegrationSettings,
			exportFromDate,
			handleSave,
			productGroups
		]
	);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	useEffect(() => {
		httpClient
			.put("/reporting/sie-merchant/", {
				body: {
					stripeAccount: currentMerchant.stripeId,
					timeZone: currentMerchant.timeZone,
					orgNr: currentStore.orgNo,
					companyName: currentMerchant.name
				}
			})
			.then(r => {
				setIntegrationSettings(r);
				setOldIntegrationSettings(r);
				if (r.exportFromDate) setExportFromDate(new Date(r.exportFromDate));
			});
	}, [currentMerchant, currentStore]);

	const fetchFiles = () => {
		httpClient.get("/reporting/sie-merchant/files").then(r => {
			setSIEFiles(r);
			setIsFetching(false);
		});
	};

	useEffect(() => {
		fetchFiles();
		if (currentMerchant.timeZone) setTimeZone(currentMerchant.timeZone);
	}, [currentMerchant]);

	const handleCreateSubmit = () => {
		closeModal();
		fetchFiles();
	};

	return (
		<>
			<SectionHeader heading="SIE" {...{ tabs, activeTab, onTabSelected: setActiveTab }}>
				{hasFunctionAccess("report_edit") && activeTab.id === "files" && (
					<Button variant="primary" icon={faPlus} onClick={openModal}>
						New file
					</Button>
				)}
			</SectionHeader>

			<TabContainer {...{ tabs, activeTab }} />

			<SIEFileModal
				show={showModal}
				onSubmit={handleCreateSubmit}
				onCancel={closeModal}
				timeZone={timeZone}
			/>
		</>
	);
};

SIE.defaultProps = {
	refresh: () => {}
};

export default SIE;

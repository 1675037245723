import { useMerchantContext } from "context";
import { Grid } from "core/components";
import React from "react";
import { useHistory } from "react-router-dom";
import schema from "./schema";

const RefundsGrid = ({ refunds, clearRefundPickerState }) => {
	const history = useHistory();
	const {
		currentMerchant: { timeZone }
	} = useMerchantContext();
	const gridSchema = schema(timeZone);

	const handleRowClick = (e, row) => {
		if (typeof clearRefundPickerState === "function") clearRefundPickerState(false);
		history.push(`/orders/order/${row.orderId}`);
	};

	return (
		<Grid
			tableKey={"orderId"}
			pagination={false}
			noHeader
			data={refunds}
			schema={gridSchema}
			onRowClick={handleRowClick}
			rowStyle={{ cursor: "pointer" }}
		/>
	);
};

export default RefundsGrid;

import React, { useState } from "react";
import { useEffect } from "react";
import { FormControl } from "react-bootstrap";

const Component = ({
	dataKey,
	text,
	accountRangeFrom,
	accountRangeTo,
	accounts,
	selectedAccount,
	handleChange,
	isLoading,
	disabled
}) => {
	const [currentAccount, setCurrentAccount] = useState();

	useEffect(() => {
		setCurrentAccount(selectedAccount || 0);
	}, [selectedAccount]);

	const getMerchantAccounts = () => {
		if (disabled) {
			return (
				<>
					{!selectedAccount && (
						<option key={0} value={0}>
							No accounts avaliable
						</option>
					)}
					{selectedAccount && (
						<option key={selectedAccount} value={selectedAccount}>
							{selectedAccount} (current)
						</option>
					)}
				</>
			);
		} else if (isLoading)
			return (
				<option key={0} value={0}>
					Loading accounts...
				</option>
			);
		else {
			return (
				<>
					{!selectedAccount && (
						<option key={0} value={0}>
							Choose account...
						</option>
					)}
					{accounts
						.filter(f => f.number >= accountRangeFrom && f.number <= accountRangeTo)
						.map(a => (
							<option key={a.number} value={a.number}>
								{`${a.number} - ${a.description}`}
							</option>
						))}
				</>
			);
		}
	};

	return (
		<div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
			<div
				style={{
					marginRight: 8,
					width: 260,
					height: 50,
					display: "flex",
					alignItems: "flex-end"
				}}
			>
				<div>
					<div>{text}</div>
					<div style={{ fontSize: 12, color: "#666" }}>
						{`${accountRangeFrom} - ${accountRangeTo}`}
					</div>
				</div>
			</div>
			<div style={{ display: "flex" }}>
				<FormControl
					style={{ width: 400, marginLeft: 8 }}
					as="select"
					className="mr-sm-2"
					id="inlineFormCustomSelectPref"
					custom
					value={currentAccount}
					disabled={isLoading || disabled}
					onChange={e => {
						setCurrentAccount(e.target.value);
						handleChange({
							[dataKey]: Number(e.target.value) > 0 ? Number(e.target.value) : null
						});
					}}
				>
					{getMerchantAccounts()}
				</FormControl>
			</div>
		</div>
	);
};

Component.displayName = "ReportingBookkeepingAccount";

export default Component;

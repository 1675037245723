const { default: styled } = require("styled-components");

export const Heading = styled.div`
	font-family: Peak;
	font-weight: 500;
	margin-bottom: 12px;
	font-size: 32px;
	line-height: 0.95;
`;

export const ExplainerText = styled.div`
	font-size: 16px;
	line-height: 1.4;
	margin-bottom: 8px;
`;

export const Email = styled.div`
	font-weight: 500;
`;

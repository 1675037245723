import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import Barcodes from "./Barcodes";
import Button from "./Button";
import Divider from "./Divider";
import Dropdown from "./Dropdown";
import Image from "./Image";
import MultiText from "./MultiText";
import Number from "./Number";
import Percentage from "./Percentage";
import Products from "./Products";
import Radio from "./Radio";
import Schedule from "./Schedule";
import SortableOptions from "./SortableOptions";
import Switch from "./Switch";
import Text from "./Text";
import Typeahead from "./Typeahead";

const components = {
	divider: <Divider />,
	typeahead: <Typeahead />,
	number: <Number />,
	percentage: <Percentage />,
	text: <Text />,
	image: <Image />,
	dropdown: <Dropdown />,
	switch: <Switch />,
	button: <Button />,
	schedule: <Schedule />,
	barcodes: <Barcodes />,
	multiText: <MultiText />,
	products: <Products />,
	radio: <Radio />,
	sortableOptions: <SortableOptions />
};

const InvalidMessage = styled.div`
	color: rgb(255, 115, 94);
	font-size: 13px;
`;

const StyledFormGroup = styled(Form.Group)`
	margin-bottom: 0px;
`;

const StyledFormLabel = styled(Form.Label)`
	font-weight: 500;
	margin-bottom: 0.2rem;
`;
const StyledFormDescription = styled(Form.Label)`
	font-weight: 400;
	margin-bottom: ${p => p.theme.space.small};
	margin-top: ${p => p.theme.space.mini};
	color: ${p => p.theme.color.text.muted};
	font-size: 13.5px;
`;

const FormItem = props => {
	const { item } = props;
	const Component = components[item.type];

	if (!Component) throw new Error(`Component ${item.type} not implemented`);

	const label = item.editLabel || item.label;
	// Hide labels for switch since the label is visible in the form item component
	const hideLabel = (item.type === "switch" || !label) && !item.labelOnTop;

	if (!props.row) return null;
	return (
		<StyledFormGroup>
			{!hideLabel && (
				<div>
					<StyledFormLabel>{label}</StyledFormLabel>
				</div>
			)}
			{React.cloneElement(Component, props)}
			{item.description && (
				<div>
					<StyledFormDescription>{item.description}</StyledFormDescription>
				</div>
			)}
			{props.validated && props.invalidMessage && (
				<InvalidMessage>{props.invalidMessage}</InvalidMessage>
			)}
			{item.helperText && <Form.Text className="text-muted">{item.helperText}</Form.Text>}
		</StyledFormGroup>
	);
};

export default FormItem;

import { Grid } from "core/components";
import React from "react";
import actions from "./actions";
import schema from "./schema";
import styled from "styled-components";

const Container = styled.div`
	margin-bottom: 32px;
`;
const OrderItemsGrid = ({
	order,
	refresh,
	refundRows,
	setRefundRows,
	setMappedItems,
	refundPickerActive
}) => {
	const gridActions = actions({ refundRows, setRefundRows, setMappedItems });
	const gridSchema = schema({ refundPickerActive });

	return (
		<Container>
			<Grid
				schema={gridSchema}
				actions={refundPickerActive ? gridActions : null}
				tableKey="orderItemId"
				pagination={false}
				parentRowData={order}
				data={order.items}
				reload={refresh}
				noDataText="No order items available"
				refundPickerActive
				transparentHeader
			/>
		</Container>
	);
};

export default OrderItemsGrid;

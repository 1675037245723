import { faArrowLeft, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMerchantContext } from "context";
import { Modal, PageLayout, SectionHeader, SectionLoader, Button } from "core/components";
import { httpClient } from "data";
import { menuRepo } from "data/repos";
import produce from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Alert from "react-bootstrap/Alert";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import SaveMenuForm from "../MenusOverview/SaveMenuForm";
import MenuSection from "./MenuSection";
import SaveSectionForm from "./SaveSectionForm";

const DROP_AREA_SECTION = "Section";

const PageHeader = styled.div`
	display: flex;
	align-items: center;
`;

const BackButton = styled.a`
	color: #1d1d1d;
	font-size: 16px;
	cursor: pointer;
	margin-right: 16px;
	text-align: center;
`;

const PageTitle = styled.div`
	margin-right: 8px;
`;

const BackButtonText = styled.div`
	font-size: 10;
	margin-top: -2px;
`;
const Container = styled.div`
	max-width: 650px;
`;

/* The animation for drag and drop is a little 
buggy if a margin is not set directly on the first child */
const DraggableSection = styled.div`
	margin-top: 20px;
`;

const Menu = () => {
	const params = useParams();
	const history = useHistory();
	const menuIdFromQuery = params?.id;
	const { hasFunctionAccess, currentStore, currentPriceListId } = useMerchantContext();
	const [menu, setMenu] = useState({});
	const [sections, setSections] = useState([]);
	const [products, setProducts] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [newSectionModalVisible, setNewSectionModalVisible] = useState(false);
	const [editMenuModalVisible, setEditMenuModalVisible] = useState(false);

	useEffect(() => {
		if (!menu?.storeId || !currentStore) {
			return;
		}

		if (menu?.storeId === currentStore.storeId) {
			return;
		}

		history.push("/menus");
	}, [currentStore, menu, history]);

	const onSaveMenuSection = async () => {
		await fetchMenus({ showSpinner: false });
		setNewSectionModalVisible(false);
	};

	const fetchProducts = useCallback(() => {
		if (!currentStore) return;
		httpClient
			.fetch(`/products?storeId=${currentStore.storeId}&priceListId=${currentPriceListId}`)
			.then(setProducts);
	}, [currentStore]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchMenus = useCallback(
		async ({ showSpinner } = false) => {
			if (!currentStore) return;

			if (showSpinner) setLoading(true);

			const menus = await menuRepo.getMenus(currentStore.storeId);
			const updatedMenu = menus.find(menu => menu.menuId === menuIdFromQuery);

			if (updatedMenu) {
				setMenu(updatedMenu);
				setSections(updatedMenu?.sections);
			}

			setLoading(false);
		},
		[currentStore, menuIdFromQuery]
	);

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}

		if (result.type === DROP_AREA_SECTION) reOrderSections(result);
		else reOrderProducts(result);
	};

	const reOrderSections = dropResult => {
		const reOrderedSections = [...sections];
		const [removed] = reOrderedSections.splice(dropResult?.source?.index, 1);
		reOrderedSections.splice(dropResult?.destination?.index, 0, removed);
		setSections(reOrderedSections);
		reOrderSectionsInDB(reOrderedSections);
	};

	const reOrderSectionsInDB = reOrderedSections => {
		const sections = reOrderedSections.map((section, index) => ({
			menuSectionId: section?.menuSectionId,
			sortOrder: index
		}));

		menuRepo.reorderSections({ menuId: menuIdFromQuery, sections });
	};

	const reOrderProducts = dropResult => {
		const menuSectionId = dropResult?.source?.droppableId;
		const sectionIndex = sections.findIndex(section => section.menuSectionId === menuSectionId);
		const section = { ...sections[sectionIndex] };
		const reOrderedProducts = [...section.items];
		const [removed] = reOrderedProducts.splice(dropResult?.source?.index, 1);
		reOrderedProducts.splice(dropResult?.destination?.index, 0, removed);

		setSections(
			produce(draft => {
				draft[sectionIndex].items = reOrderedProducts;
			})
		);
		reOrderProductsInDB(menuSectionId, reOrderedProducts);
	};

	const reOrderProductsInDB = (menuSectionId, reOrderedProducts) => {
		const products = reOrderedProducts.map((product, index) => ({
			productId: product?.product?.productId,
			sortOrder: index
		}));

		menuRepo.reorderProducts({ menuId: menuIdFromQuery, menuSectionId, products });
		// TODO: error handling
	};

	const deleteProduct = (productId, sectionId) => {
		setSections(
			produce(draft => {
				const section = draft.find(section => section.menuSectionId === sectionId);
				section.items = section.items.filter(
					item => item?.product?.productId !== productId
				);
			})
		);
	};

	useEffect(() => {
		fetchMenus({ showSpinner: false });
		fetchProducts();
	}, [fetchMenus, fetchProducts]);

	if (isLoading) {
		return (
			<PageLayout>
				<SectionLoader />
			</PageLayout>
		);
	}

	return (
		<PageLayout>
			<>
				<div style={{ flexGrow: 1 }}>
					<SectionHeader
						heading={
							<PageHeader>
								<BackButton href="#" onClick={() => history.push(`/menus`)}>
									<div>
										<FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
									</div>
									<BackButtonText>back</BackButtonText>
								</BackButton>
								<PageTitle>{menu?.name}</PageTitle>
							</PageHeader>
						}
					>
						{hasFunctionAccess("menu_edit") && (
							<>
								<Button icon={faEdit} onClick={() => setEditMenuModalVisible(true)}>
									Edit menu
								</Button>

								<Button
									icon={faPlus}
									onClick={() => setNewSectionModalVisible(true)}
									variant="primary"
								>
									Add section
								</Button>
							</>
						)}
					</SectionHeader>

					<Container>
						{sections.length > 0 ? (
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId={menuIdFromQuery} type={DROP_AREA_SECTION}>
									{provided => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{sections &&
												sections?.map((section, index) => (
													<Draggable
														key={section?.menuSectionId}
														draggableId={section?.menuSectionId}
														index={index}
														type={DROP_AREA_SECTION}
													>
														{provided => (
															<DraggableSection
																key={section?.menuSectionId}
																ref={provided.innerRef}
																{...provided.draggableProps}
															>
																<MenuSection
																	section={section}
																	menuId={menuIdFromQuery}
																	reloadMenu={fetchMenus}
																	products={products}
																	onDeleteProduct={deleteProduct}
																	onProductChange={fetchMenus}
																	dragHandleProps={{
																		...provided.dragHandleProps
																	}}
																/>
															</DraggableSection>
														)}
													</Draggable>
												))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						) : (
							<Alert variant="warning" style={{ marginTop: 22 }}>
								The menu does not contain any sections yet
							</Alert>
						)}
					</Container>
				</div>

				<Modal
					title="Add section"
					show={newSectionModalVisible}
					onHide={() => setNewSectionModalVisible(false)}
				>
					<SaveSectionForm
						onSave={onSaveMenuSection}
						onCancel={() => setNewSectionModalVisible(false)}
						menuId={menuIdFromQuery}
					/>
				</Modal>
			</>

			<Modal
				title="Edit menu"
				show={editMenuModalVisible}
				onHide={() => setEditMenuModalVisible(false)}
			>
				<SaveMenuForm
					onSave={async () => {
						await fetchMenus({ showSpinner: false });
						setEditMenuModalVisible(false);
					}}
					isEdit={true}
					initialData={menu}
					onCancel={() => setEditMenuModalVisible(false)}
				/>
			</Modal>
		</PageLayout>
	);
};

export default Menu;

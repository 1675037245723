import React from "react";
import styled from "styled-components";

const Container = styled.div`
	font-weight: 500;
	width: 100%;
	padding: ${(p) => p.theme.space.medium} 0;
	
	&:first-child {
		padding-top: ${(p) => p.theme.space.mini};
	}
`;

const Section = ({ choice }) => <Container>{choice.label}</Container>;

export default Section;

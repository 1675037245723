import React, { useState } from "react";
import { Loader, MultiRowInput, Button, ToggleSwitch } from "core/components";
import ReactDatePicker from "react-datepicker";
import { faSave, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Alert, Card as BsCard } from "react-bootstrap";
import { useMerchantContext } from "context";
import { httpClient } from "data";
import moment from "moment-timezone";
import { validate as emailIsValid } from "react-email-validator";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Card = styled(BsCard)`
	background: ${p => p.theme.color.dirtyWhite};
`;

const CardBody = styled(BsCard.Body)``;

const CardFooter = styled(BsCard.Footer)`
	display: flex;
	justify-content: flex-end;
`;

const Status = ({
	refresh,
	setIntegrationSettings,
	oldIntegrationSettings,
	integrationSettings,
	exportFromDate,
	setExportFromDate,
	onSave
}) => {
	const { currentMerchant } = useMerchantContext();
	const [isLoading, setIsLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState();
	const [alertType, setAlertType] = useState("danger");
	const [showAlert, setShowAlert] = useState(false);

	const setEmails = emails => setIntegrationSettings({ ...integrationSettings, emails });

	const handleSieEnableChange = async enabled => {
		if (
			enabled &&
			[
				oldIntegrationSettings.accountChecking,
				oldIntegrationSettings.accountCheckingSwish,
				oldIntegrationSettings.accountReceivableStripe,
				oldIntegrationSettings.accountSales6,
				oldIntegrationSettings.accountSales12,
				oldIntegrationSettings.accountSales25,
				oldIntegrationSettings.accountVat6,
				oldIntegrationSettings.accountVat12,
				oldIntegrationSettings.accountVat25,
				oldIntegrationSettings.accountTransactionFee,
				oldIntegrationSettings.accountVatIncoming
			].includes(null)
		) {
			setAlertMessage("All bookkeeping accounts must be configured to enable SIE export");
			setAlertType("danger");
			setShowAlert(true);
			return { success: false };
		}
		setAlertMessage();
		setAlertType();
		setShowAlert(false);

		setIsLoading(true);
		return httpClient
			.post("/reporting/sie-merchant/toggle-export", {
				body: {
					exportEnabled: enabled,
					exportFromDate: exportFromDate
						? moment(exportFromDate).format("YYYY-MM-DD")
						: null
				}
			})
			.then(r => {
				if (!r || r?.error) {
					setAlertMessage(`Could not ${enabled ? "enable" : "disable"} SIE export.`);
					setAlertType("danger");
					setShowAlert(true);
					return { success: false };
				} else {
					setIntegrationSettings({
						...integrationSettings,
						exportEnabled: enabled,
						nextExportDate: null
					});
					setIsLoading(false);
					new Promise(() =>
						setTimeout(() => {
							const { stripeId: stripeAccount } = currentMerchant;

							return httpClient
								.put("/reporting/sie-merchant/", { body: { stripeAccount } })
								.then(setIntegrationSettings);
						}, 5000)
					);

					setAlertMessage();
					setAlertType();
					setShowAlert(false);
				}
				refresh();
			});
	};

	const handleSave = () => {
		if (integrationSettings.emails?.some(e => !emailIsValid(e))) {
			setAlertMessage("There are invalid email adresses.");
			setAlertType("danger");
			setShowAlert(true);
			return;
		}

		setAlertMessage("Successfully updated email recipients.");
		setAlertType("success");
		setShowAlert(true);

		onSave();
	};

	const renderSaveButton = () => (
		<Button variant="primary" icon={faSave} onClick={handleSave} size="large">
			Save
		</Button>
	);

	if (!integrationSettings) return <></>;

	return (
		<Container>
			<Alert
				variant={alertType}
				show={showAlert}
				onClose={() => setShowAlert(false)}
				dismissible
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					{alertMessage}
				</div>
			</Alert>

			<Card>
				<CardBody>
					<div style={{ display: "flex", alignItems: "flex-start" }}>
						<div
							style={{
								marginTop: 8,
								marginRight: 8,
								width: 260,
								height: 50,
								display: "flex"
							}}
						>
							Export sales data to SIE file:
						</div>
						<div
							style={{
								display: "flex",
								fontWeight: 500,
								alignItems: "center",
								color: integrationSettings.exportEnabled ? "#41ce84" : "#ff735e"
							}}
						>
							<ToggleSwitch
								disabled={alertType === "warning"}
								value={integrationSettings.exportEnabled}
								handleChange={handleSieEnableChange}
							/>
							{isLoading && (
								<div style={{ marginLeft: 16 }}>
									<Loader size="small" />
								</div>
							)}
						</div>
					</div>
					<div style={{ display: "flex", alignItems: "flex-baseline" }}>
						<div
							style={{
								marginRight: 8,
								width: 260,
								height: 50
							}}
						>
							Next export:
						</div>
						<div
							style={{
								display: "flex",
								fontWeight: 500,
								...(!integrationSettings?.nextExportDate && {
									color: "#999"
								})
							}}
						>
							{integrationSettings.exportEnabled
								? integrationSettings?.nextExportDate
									? moment(integrationSettings?.nextExportDate)
											.tz(integrationSettings?.timeZone)
											.format("YYYY-MM-DD")
									: "Calculating..."
								: "Unavaliabe"}
						</div>
					</div>
					<div style={{ display: "flex", alignItems: "flex-baseline" }}>
						<div
							style={{
								marginRight: 8,
								width: 260,
								height: 50
							}}
						>
							Last export:
						</div>
						<div
							style={{
								display: "flex",
								fontWeight: 500,
								...(!integrationSettings?.lastExportDate && {
									color: "#999"
								})
							}}
						>
							{integrationSettings?.lastExportDate
								? moment(integrationSettings?.lastExportDate)
										.tz(integrationSettings?.timeZone)
										.format("YYYY-MM-DD")
								: "Unavaliabe"}
						</div>
					</div>
					<div style={{ display: "flex", alignItems: "flex-baseline" }}>
						<div
							style={{
								marginRight: 8,
								width: 260,
								height: 50
							}}
						>
							Email recipients:
						</div>
						<div style={{ display: "flex", marginBottom: 16 }}>
							<MultiRowInput
								newRowButtonTitle="Add recipient"
								newRowButtonFontAwesomeIcon={faUserPlus}
								rows={integrationSettings.emails}
								setRows={setEmails}
								validationFunction={data =>
									!emailIsValid(data) ? "Invalid email" : null
								}
							/>
						</div>
					</div>
					<div style={{ display: "flex" }}>
						<div
							style={{
								marginRight: 8,
								width: 260,
								display: "flex",
								alignItems: "flex-end",
								marginBottom: 8
							}}
						>
							<div>Start import from:</div>
							<div style={{ fontSize: 12, color: "#666" }}></div>
						</div>

						<div>
							<ReactDatePicker
								className="datePicker"
								selected={exportFromDate}
								disabled={integrationSettings.exportEnabled}
								isClearable={integrationSettings?.exportEnabled === false}
								onChange={e => {
									setExportFromDate(e);
								}}
								dateFormat="MMMM d, yyyy"
							/>
						</div>
					</div>
				</CardBody>

				<CardFooter>{renderSaveButton()}</CardFooter>
			</Card>
		</Container>
	);
};

export default Status;

import React, { useCallback, useState } from "react";
import DropImage from "./DropImage";
import Crop from "./Crop";

import styled from "styled-components";
import ShowImage from "./ShowImage";

const ImageWrapper = styled.div`
	display: flex;
	max-width: 136px;
	max-height: 102px;
`;

const ImageCrop = ({ value, onChange }) => {
	const [imageSrc, setImageSrc] = useState(value);
	const [editMode, setEditMode] = useState(false);

	const onImageCrop = useCallback(
		croppedImage => {
			setImageSrc(croppedImage);
			onChange(croppedImage);
			setEditMode(false);
		},
		[setImageSrc, onChange, setEditMode]
	);

	const onCropCancel = useCallback(() => {
		setEditMode(false);
	}, [setEditMode]);

	const onImageDrop = useCallback(
		async imageDataUrl => {
			setImageSrc(imageDataUrl);
			setEditMode(true);
		},
		[setImageSrc, setEditMode]
	);

	if (editMode)
		return <Crop image={imageSrc} onCancel={onCropCancel} onImageCrop={onImageCrop} />;

	if (value)
		return (
			<ImageWrapper>
				<ShowImage image={value} onChange={onChange} />
			</ImageWrapper>
		);

	return (
		<ImageWrapper>
			<DropImage onImageDrop={onImageDrop} />
		</ImageWrapper>
	);
};

export default ImageCrop;

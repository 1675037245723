import imgLogo from "assets/passbuy-logo-204x50.png";
import React from "react";
import { Alert, Image } from "react-bootstrap";
import { Card } from "auth/components";
import Form from "react-bootstrap/Form";

const Expired = () => {
	return (
		<Card size="large">
			<Form>
				<Form.Group>
					<div style={{ display: "flex", justifyContent: "center", marginBottom: 24 }}>
						<Image style={{ height: 50 }} src={imgLogo} />
					</div>
				</Form.Group>

				<Form.Group>
					<div
						style={{
							display: "flex",
							justifyContent: "left"
						}}
					>
						<Alert style={{ width: "100%", marginBottom: 0 }} variant="danger">
							Reset password link is not valid anymore. Request a new one{" "}
							<a href="/reset-password">here</a>
						</Alert>
					</div>
				</Form.Group>
			</Form>
		</Card>
	);
};

export default Expired;

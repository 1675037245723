import React from "react";
import styled from "styled-components";

const Container = styled.li`
	padding-top: 4px;
	padding-bottom: 8px;
	padding-left: 1px;
	padding-right: 1px;
	margin-right: 28px;
	cursor: pointer;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	border-bottom-color: ${p => (p.active ? "#41CE84" : "transparent")};
	position: relative;
	bottom: -1px;
	transition: border-bottom 200ms;
`;

const Label = styled.span`
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	color: #666;
	user-select: none;
`;

const Tab = ({ tab, onClick, active }) => {
	return (
		<Container onClick={onClick} active={active}>
			<Label>{tab.label}</Label>
		</Container>
	);
};

export default Tab;

import { useMerchantContext } from "context";
import { Grid, GridCreate, SectionHeader, SectionLoader } from "core/components";
import { useSwitch } from "core/utils";
import { productGroupRepo, productRepo, stationRepo } from "data/repos";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import urlcat from "urlcat";
import getTableActions from "./get-table-actions";
import getTableSchema from "./get-table-schema";
import ProductSync from "./ProductSync";

const Container = styled.div`
	flex-direction: column;
	flex-grow: 1;
`;

const GridContainer = styled.div`
	margin-top: 12px;
`;

const ProductTable = () => {
	const { currentStore, currentMerchant, currentPriceListId, hasFunctionAccess } =
		useMerchantContext();
	const [schema, setSchema] = useState(null);
	const [actions, setActions] = useState(null);
	const [products, setProducts] = useState([]);
	const [totalProductCount, setTotalProductCount] = useState(0);
	const [sizePerPage, setSizePerPage] = useState(50);
	const [page, setPage] = useState(1);
	const [isFetching, startFetching, stopFetching] = useSwitch();
	const isLoading = !schema || !actions;

	const refreshProducts = useCallback(
		async ({ search, sortOrder, sortField, setFetching = true } = {}) => {
			if (setFetching) startFetching();
			const merchantProducts = await productRepo.getProducts({
				storeId: currentStore.storeId,
				priceListId: currentPriceListId,
				page,
				pageSize: sizePerPage,
				search,
				sortOrder,
				sortField
			});
			setTotalProductCount(merchantProducts?.totalProductCount);
			setProducts(merchantProducts?.products || []);
			if (setFetching) stopFetching();
		},
		[currentPriceListId, currentStore.storeId, page, sizePerPage, startFetching, stopFetching]
	);

	const refreshSchema = useCallback(
		async ({ setFetching = true }) => {
			if (setFetching) startFetching();
			const productGroups = await productGroupRepo.getProductGroups({
				storeId: currentStore.storeId
			});
			const stations = await stationRepo.getStations({ storeId: currentStore.storeId });

			setSchema(
				getTableSchema({
					merchant: currentMerchant,
					store: currentStore,
					stations,
					productGroups
				})
			);
			setActions(getTableActions({ hasFunctionAccess, merchant: currentMerchant }));
			if (setFetching) stopFetching();
		},
		[currentMerchant, currentStore, hasFunctionAccess, startFetching, stopFetching]
	);

	const refreshAll = useCallback(async () => {
		startFetching();
		refreshSchema({ setFetching: false })
			.then(() => refreshProducts({ setFetching: false }))
			.finally(stopFetching);
	}, [refreshSchema, refreshProducts, startFetching, stopFetching]);

	useEffect(() => {
		refreshAll();
	}, [refreshAll]);

	const renderGrid = () => (
		<GridContainer>
			<Grid
				isFetching={isFetching}
				actions={actions}
				schema={schema}
				tableKey="productId"
				data={products}
				reload={refreshProducts}
				activeStoreId={currentStore.storeId}
				activePriceListId={currentPriceListId}
				hideGridCreate={true}
				noDataText="No products available"
				remote={{
					search: true,
					pagination: true,
					sort: true
				}}
				remotePagination
				remotePaginationOptions={{
					page,
					setPage,
					setSizePerPage,
					sizePerPage,
					totalSize: totalProductCount
				}}
				search
				currentPriceListId={currentPriceListId}
			/>
		</GridContainer>
	);

	return (
		<Container>
			<SectionHeader heading="Products">
				{currentMerchant.externalProductSync ? (
					<ProductSync onRefresh={refreshAll}></ProductSync>
				) : (
					<GridCreate
						buttonProps={{
							label: "New product",
							variant: "primary"
						}}
						title="New product"
						schema={schema}
						onChange={refreshProducts}
						apiPath={urlcat("/products", {
							storeId: currentStore.storeId,
							priceListId: currentPriceListId
						})}
						apiMethod="PUT"
					/>
				)}
			</SectionHeader>

			{isLoading && <SectionLoader />}
			{!isLoading && renderGrid()}
		</Container>
	);
};

export default ProductTable;

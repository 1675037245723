import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "core/components";
import Alert from "react-bootstrap/Alert";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { menuRepo } from "data/repos";

const BtnGroup = styled.div`
	border-top: 1px solid #dee2e6;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	align-content: space-between;
	padding-top: 0.75rem;

	& button:first-child {
		margin-right: 0.5rem;
	}
`;

const DropdownItem = styled(MenuItem)`
	border-bottom: 1px solid #e1e1e1;
	white-space: normal;
	display: flex;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
`;

const Price = styled.div`
	white-space: nowrap;
	padding-left: 20px;

	& div {
		color: red;
	}
`;

const Description = styled.small`
	color: #666666;
`;

const SaveProductForm = ({ products, menuId, menuSectionId, sectionItems, onCancel, onSave }) => {
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [saving, setSaving] = useState(false);
	const [errorVisible, setErrorVisible] = useState(false);

	const saveProduct = async () => {
		setSaving(true);
		Promise.all(
			selectedProducts.map(async ({ productId }) => {
				await menuRepo.addProduct({ menuId, menuSectionId, productId });
			})
		).then(response => {
			setSaving(false);
			if (response?.code) setErrorVisible(true);
			else onSave();
		});
	};

	const labelWithPrice = option => {
		const price = option?.price ? `(${option?.price} kr)` : "";
		return `${option?.name} ${price}`;
	};

	return (
		<>
			{errorVisible ? (
				<Alert variant="danger">Something went wrong. Please try again.</Alert>
			) : null}

			<Form.Group>
				<Typeahead
					id="products-autocomplete"
					labelKey={option => labelWithPrice(option)}
					multiple
					options={products}
					placeholder="Choose product"
					selected={selectedProducts}
					positionFixed={false}
					onChange={e => {
						if (e.length === 0 || e[e.length - 1].price) {
							setSelectedProducts(e);
						}
					}}
					renderMenu={(results, menuProps) => {
						return (
							<Menu {...menuProps}>
								{results.map((result, index) => {
									if (
										sectionItems.some(
											item => item.product.productId === result.productId
										)
									)
										return null;

									return (
										<DropdownItem
											key={result?.productId}
											option={result}
											position={index}
											disabled={
												!result.price ||
												sectionItems.some(
													item =>
														item.product.productId === result.productId
												)
											}
										>
											<div>
												<div>{result.name}</div>
												<Description>{result.description}</Description>
											</div>
											<Price>
												{result?.price ? (
													`${result?.price} kr`
												) : (
													<div>Missing price</div>
												)}
											</Price>
										</DropdownItem>
									);
								})}
							</Menu>
						);
					}}
				/>
			</Form.Group>

			<BtnGroup>
				<Button icon={faUndo} onClick={onCancel} size="large" collapse={false}>
					Cancel
				</Button>

				<Button
					icon={faSave}
					isLoading={saving}
					variant="primary"
					onClick={saveProduct}
					size="large"
					collapse={false}
				>
					Save
				</Button>
			</BtnGroup>
		</>
	);
};

export default SaveProductForm;

import allowEmpty from "core/components/Grid/Validation/allow-empty";
import between from "core/components/Grid/Validation/between";
import required from "core/components/Grid/Validation/required";

export default ({ hasFunctionAccess }) => [
	{
		prop: "name",
		type: "text",
		minimumGridVisibility: "xs",
		width: 350,
		label: "Name",
		sort: true,
		editable: false,
		validate: [required("Name is missing")]
	},
	{
		prop: "vatRate",
		type: "dropdown",
		label: "VAT",
		width: 80,
		minimumGridVisibility: "xs",
		formatter: (cell, row) => {
			return row?.vatRate ? `${row?.vatRate * 100} %` : null;
		},
		sort: false,
		editable: false,
		values: () => [
			{ value: "", label: "-- SELECT VAT --" },
			{ value: 0.12, label: "12%" },
			{ value: 0.25, label: "25%" }
		],
		validate: [required("VAT must be selected")]
	},
	...(hasFunctionAccess("report_edit")
		? [
				{
					prop: "bookkeepingSalesAccount",
					type: "number",
					minimumGridVisibility: "md",
					helperText: "Bookkeeping account for sales with this product group. E.g. 3000",
					width: 80,
					label: "Bookkeeping Account (Sales)",
					sort: true,
					editable: false,
					validate: [allowEmpty(between(1000, 9999, "Account must be between 1000-9999"))]
				}
		  ]
		: [])
];

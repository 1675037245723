import { Grid, Loader } from "core/components";
import { httpClient } from "data";
import papa from "papaparse";
import { isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

const ReportCsvTable = ({ reportId }) => {
	const [reportData, setReportData] = useState();
	const [reportGridSchema, setReportGridSchema] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!reportId) {
			return;
		}

		httpClient.get(`/reporting/reports/${reportId}`).then(r => {
			if (isEmpty(r.data)) {
				setIsLoading(false);
				return;
			}
			papa.parse(r.data, {
				header: true,
				dynamicTyping: true,
				skipEmptyLines: true,
				transformHeader: header => {
					const h = header.replace(/([_])/g, " ").toLowerCase();
					return h.charAt(0).toUpperCase() + h.slice(1);
				},
				complete: parsedData => {
					setReportData(parsedData.data.map((d, i) => ({ ...d, rowId: i })));
					const schema = parsedData.meta.fields.map(f => {
						return {
							prop: f,
							label: f,
							minimumGridVisibility: "xs",
							sort: true
						};
					});
					setReportGridSchema(schema);
					setIsLoading(false);
				}
			});
		});
	}, [reportId]);

	if (isLoading)
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					margin: 12
				}}
			>
				<Loader size="small" />
			</div>
		);

	return reportData ? (
		<Grid
			schema={reportGridSchema}
			pagination
			tableKey="rowId"
			data={reportData}
			paginationOptions={{ sizePerPage: 500 }}
		/>
	) : (
		<Alert
			style={{
				justifyContent: "center",
				display: "flex",
				marginBottom: "0"
			}}
			variant="warning"
		>
			This report contains no transactions.
		</Alert>
	);
};

export default ReportCsvTable;

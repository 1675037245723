import { Card } from "auth/components";
import React from "react";

const Invalid = () => {
	return (
		<Card size="huge">
			<span>This link is not valid anymore.</span>

			<span>
				Go <a href="/reset-password">here</a> to reset your password.
			</span>
		</Card>
	);
};

export default Invalid;

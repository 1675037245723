import React, { useCallback, useRef } from "react";
import useOutsideClick from "./use-outside-click";
import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;

const fadeInAnimation = () =>
	css`
		${fadeIn} 200ms
	`;

const Container = styled.div`
	display: ${p => (p.isOpen ? "flex" : "none")};
	flex-direction: column;
	background: #fff;
	position: absolute;
	z-index: 999;
	top: 100%;
	right: ${p => (p.direction === "right" ? "0" : "auto")};
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	animation: ${fadeInAnimation};
`;

const Content = styled.div`
	animation: ${fadeInAnimation};
`;

const Dropdown = ({ isOpen, onClose, buttonRef, right, children }) => {
	const containerRef = useRef(null);
	const direction = right ? "right" : "left";

	const handleOutsideClick = useCallback(() => onClose(), [onClose]);

	useOutsideClick(containerRef, buttonRef, handleOutsideClick);

	return (
		<Container isOpen={isOpen} ref={containerRef} direction={direction}>
			<Content>{children}</Content>
		</Container>
	);
};

export default Dropdown;

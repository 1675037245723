import React, { useState } from "react";
import { useEffect } from "react";
import { FormControl } from "react-bootstrap";

const Component = ({
	dataKey,
	text,
	accountRangeFrom,
	accountRangeTo,
	selectedAccount,
	handleChange
}) => {
	const [currentAccount, setCurrentAccount] = useState();

	useEffect(() => {
		setCurrentAccount(selectedAccount);
	}, [selectedAccount]);

	return (
		<div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
			<div
				style={{
					marginRight: 8,
					width: 260,
					height: 50,
					display: "flex",
					alignItems: "flex-end"
				}}
			>
				<div>
					<div>{text}</div>
					<div style={{ fontSize: 12, color: "#666" }}>
						{`${accountRangeFrom} - ${accountRangeTo}`}
					</div>
				</div>
			</div>
			<div style={{ display: "flex" }}>
				<FormControl
					style={{ marginLeft: 8, borderRadius: 4 }}
					type="text"
					value={currentAccount || ""}
					onChange={e => {
						setCurrentAccount(e.target.value);
						handleChange({
							[dataKey]: Number(e.target.value) > 0 ? Number(e.target.value) : null
						});
					}}
				/>
			</div>
		</div>
	);
};

Component.displayName = "ReportingTextBookkeepingAccount";

export default Component;

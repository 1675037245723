/* eslint-disable react/display-name */
import React from "react";
import { TimeCreated } from "./fields";
import TimeInterval from "./fields/TimeInterval";

const schema = ({ timeZone }) => [
	{
		prop: "timeClosed",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Created",
		sort: true,
		formatter: cell => <TimeCreated time={cell} timeZone={timeZone} />
	},
	{
		prop: "dummyDuration",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Duration",
		sort: true,
		formatter: (cell, row) => (
			<TimeInterval
				fromTime={row.previousTimeClosed}
				toTime={row.timeClosed}
				timeZone={timeZone}
			/>
		)
	},
	{
		prop: "serialNumber",
		type: "text",
		label: "Serial Number",
		minimumGridVisibility: "xs",
		sort: true
	}
];

export default schema;

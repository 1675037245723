import { eqProps, isEmpty } from "ramda";
import React from "react";
import { Badge } from "react-bootstrap";

export default stations => {
	if (!Array.isArray(stations) || isEmpty(stations)) {
		return [];
	}

	return [
		{
			prop: "stationId",
			type: "dropdown",
			label: "Station",
			editable: false,
			sort: false,
			width: 150,
			// eslint-disable-next-line react/display-name
			formatter: (cell, row) => {
				const stationName = stations.filter(eqProps("stationId", row))[0]?.name;
				const label = row.stationId ? stationName : "";
				const badgeStyle = {
					...s.badge,
					...(row.stationId ? s.badgeGray : s.badgeRed)
				};

				return <Badge style={badgeStyle}>{label}</Badge>;
			},
			values: () => [
				...[{ value: "", label: "-- NO STATION --" }],
				...stations.map(b => ({
					value: b.stationId,
					label: b.name
				}))
			]
		}
	];
};

const s = {
	badge: {
		color: "#fff",
		padding: 4,
		marginRight: 30,
		fontSize: 12,
		wordBreak: "break-word",
		whiteSpace: "break-spaces",
		margin: "auto"
	},
	badgeGray: {
		backgroundColor: "#666666"
	},
	badgeRed: {
		backgroundColor: "#FF735E"
	}
};

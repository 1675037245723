import React from "react";
import schema from "./schema";
import { Grid } from "core/components";
import styled from "styled-components";
import { useMerchantContext } from "context";
import { useHistory } from "react-router-dom";

const Container = styled.div`
	width: 100%;
`;
const OrdersGrid = ({
	orders,
	refresh,
	setPage,
	setPageSize,
	page,
	pageSize,
	orderCount,
	noDataText,
	isLoading
}) => {
	const history = useHistory();
	const {
		currentMerchant: { timeZone }
	} = useMerchantContext();

	const gridSchema = schema(timeZone);

	const handleRowClick = (e, row) => {
		history.push(`/orders/order/${row.orderId}`);
	};

	return (
		<Container data-test="orders">
			<Grid
				schema={gridSchema}
				isFetching={isLoading}
				tableKey="orderId"
				data={orders}
				noDataText={noDataText}
				pagination
				search
				remote={{
					pagination: true,
					search: true,
					sort: true
				}}
				remotePagination
				remotePaginationOptions={{
					setPage: setPage,
					setSizePerPage: setPageSize,
					page: page,
					sizePerPage: pageSize,
					totalSize: Number(orderCount)
				}}
				reload={refresh}
				onRowClick={handleRowClick}
				rowStyle={{ cursor: "pointer" }}
			/>
		</Container>
	);
};

export default OrdersGrid;

import styled from "styled-components";

const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #41ce83;
`;

export default PageContainer;

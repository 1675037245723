import React from "react";
import { Form } from "react-bootstrap";

const getValues = (row, values) => {
	if (typeof values === "function") {
		return values(row);
	}

	return values;
};

const renderOptions = (row, item, values) => {
	const vals = getValues(row, values);

	return vals.map(v => (
		<option key={v.value} value={v.value}>
			{v.label}
		</option>
	));
};

const Dropdown = ({ row, value, item, onChange }) => (
	<Form.Control
		size="sm"
		as="select"
		onChange={e => onChange(e.target.value)}
		value={value}
		id="inlineFormCustomSelectPref"
		custom
	>
		{renderOptions(row, item, item.values)}
	</Form.Control>
);

export default Dropdown;

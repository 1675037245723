/* eslint-disable react/display-name */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { httpClient } from "data";
import { add, isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import ReportJob from "./ReportJob";
import styled from "styled-components";
import { device, mediaQuery, displayOn } from "styles/device";
import { SectionHeader, SectionLoader, Button } from "core/components";

const Container = styled.div`
	width: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: ${p => p.theme.space.large};
`;

const ReportsJobs = styled.div`
	width: 100%;
	display: grid;
	align-content: stretch;
	row-gap: 16px;
	width: 100%;
	grid-template-columns: 1fr;
	padding-bottom: 32px;

	@media ${mediaQuery(device.tabletLarge)} {
		grid-template-columns: 1fr 1fr;
		column-gap: 22px;
		row-gap: 22px;
	}

	@media ${mediaQuery(device.desktop)} {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

const CreateButtonWrapper = styled.div`
	${displayOn({ min: device.tablet })}
`;

const ScheduledReports = () => {
	const { hasFunctionAccess, currentMerchant } = useMerchantContext();
	const timeZone = currentMerchant?.timeZone || "Europe/Stockholm";
	const [reportFunctions, setReportFunctions] = useState([]);
	const [reportJobs, setReportJobs] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch(false);
	const haveReports = !isLoading && !isEmpty(reportJobs);

	useEffect(() => {
		httpClient.get("/reporting/report-functions/").then(setReportFunctions);
	}, [currentMerchant]);

	const setKey = obj =>
		obj.map((r, i) => {
			return {
				...r,
				key: `${i}${Math.floor(100000 + Math.random() * 900000)}`
			};
		}) || [];

	useEffect(() => {
		startLoading();
		httpClient
			.get("/reporting/report-jobs/")
			.then(r => setReportJobs(setKey(r)))
			.finally(stopLoading);
	}, [startLoading, stopLoading]);

	const refresh = async () => {
		httpClient.get("/reporting/report-jobs/").then(r => {
			const newReports = reportJobs.filter(f => f.new);
			setReportJobs([...setKey(r), ...newReports]);
		});
	};

	const fetchNextRun = async ({ reportJobId }) => {
		await new Promise(r => setTimeout(r, 5000));
		return await httpClient.get("/reporting/report-jobs/").then(r => {
			const fetchedReportJob = r.find(f => f.reportJobId === reportJobId);
			return fetchedReportJob.nextRun;
		});
	};

	const handleCreate = async ({ reportJobKey, data }) => {
		const createdReportJob = await httpClient.post(`/reporting/report-jobs`, { body: data });
		if (!createdReportJob) return { errorMessage: "Could not create schedule" };
		const rj = JSON.parse(JSON.stringify(reportJobs || []));
		const reportJob = rj.find(f => f.key === reportJobKey);
		reportJob.reportJobId = createdReportJob.reportJobId;
		reportJob.new = false;
		setReportJobs(rj);
		const nextRun = fetchNextRun({
			reportJobId: createdReportJob.reportJobId
		});
		return { nextRun };
	};

	const handleUpdate = async ({ reportJobId, data }) => {
		const r = await httpClient.patch(`/reporting/report-jobs/${reportJobId}`, { body: data });
		if (!r) return { errorMessage: "Could not update schedule" };
		const nextRun = fetchNextRun({
			reportJobId
		});
		return { nextRun };
	};
	const handleRemove = async reportJobData => {
		if (!reportJobData.new) {
			try {
				await httpClient.delete(`/reporting/report-jobs/${reportJobData.reportJobId}`);
			} catch (e) {
				return { errorMessage: "Could not remove schedule" };
			}
		}
		const rj = JSON.parse(JSON.stringify(reportJobs || []));
		setReportJobs(rj.filter(f => f.key !== reportJobData.key));
	};

	const renderReportJobs = () => {
		return reportJobs.map(r => (
			<ReportJob
				key={r.key}
				reportJob={r}
				timeZone={timeZone}
				reportFunctions={reportFunctions}
				refresh={refresh}
				handleCreate={handleCreate}
				handleUpdate={handleUpdate}
				handleRemove={handleRemove}
			/>
		));
	};

	const handleNewReportJob = () => {
		// allow only one "new" schedule at a time.
		if (reportJobs.find(f => f.new)) return;

		const rj = JSON.parse(JSON.stringify(reportJobs || []));
		const nextIndex =
			reportJobs?.length > 0 ? add(reportJobs[reportJobs.length - 1].key[0], 1) : 0;
		rj.unshift({
			key: `${nextIndex}${Math.floor(100000 + Math.random() * 900000)}`,
			new: true
		});
		setReportJobs(rj);
	};

	const renderContent = () => (
		<Content>
			<Alert show={!haveReports} variant="warning">
				You haven&apos;t created any scheduled reports yet
			</Alert>

			{haveReports && <ReportsJobs>{renderReportJobs()}</ReportsJobs>}
		</Content>
	);

	const renderCreateButton = () => (
		<CreateButtonWrapper>
			<Button variant="primary" icon={faPlus} onClick={handleNewReportJob}>
				New report
			</Button>
		</CreateButtonWrapper>
	);

	return (
		<Container>
			<SectionHeader heading="Scheduled reports">
				{hasFunctionAccess("report_edit") && renderCreateButton()}
			</SectionHeader>

			{isLoading && <SectionLoader />}
			{!isLoading && renderContent()}
		</Container>
	);
};

export default ScheduledReports;

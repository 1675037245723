import { Button as CoreButton } from "core/components";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	padding: 4px 8px;
	border-radius: 6px;
	border: 1px solid #e1e1e1;
	background: #fafafa;
`;

const Button = styled(CoreButton)`
	margin-right: ${p => (p.last ? "0" : "8px")};
`;

const ButtonContainer = styled.div`
	display: flex;
`;

const Label = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	margin-right: 16px;
	margin-left: 8px;
`;

const EventGroupingPicker = ({ onChange }) => {
	const [groupTimeSlot, setGroupTimeSlot] = useState(true);
	const [groupCheckin, setGroupCheckin] = useState(true);

	useEffect(() => {
		onChange({ timeSlot: groupTimeSlot, checkin: groupCheckin });
	}, [onChange, groupTimeSlot, groupCheckin]);

	const handleTimeSlotClick = useCallback(() => {
		setGroupTimeSlot(!groupTimeSlot);
	}, [groupTimeSlot]);

	const handleCheckinClick = useCallback(() => {
		setGroupCheckin(!groupCheckin);
	}, [groupCheckin]);

	return (
		<Container>
			<Label>Show</Label>
			<ButtonContainer>
				<Button
					variant={groupTimeSlot ? "primary" : "secondary"}
					onClick={handleTimeSlotClick}
				>
					Time slots
				</Button>
				<Button
					last
					variant={groupCheckin ? "primary" : "secondary"}
					onClick={handleCheckinClick}
				>
					Seats
				</Button>
			</ButtonContainer>
		</Container>
	);
};

export default EventGroupingPicker;

import styled from "styled-components";
import media from "styled-media-query";

const cardMinWidth = ({ size }) => {
	if (size === "huge") return "750px";
	else if (size === "large") return "450px";

	return "350px";
};

const Card = styled.div`
	background-color: #fff;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 0px;
	padding: 28px;

	${media.greaterThan("small")`
		width: 20rem;
		height: auto;
		min-width: ${cardMinWidth};
		margin-bottom: 10%;
		border-radius: 12px;
  `}
`;

export default Card;

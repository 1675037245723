import React from "react";
import styled from "styled-components";
import OrdersGrid from "./OrdersGrid";
import useOrders from "./use-orders";

const Container = styled.div`
	width: 100%;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: ${p => p.theme.space.large};
`;

const Purchases = () => {
	const [
		orders,
		orderCount,
		isLoading,
		refresh,
		setPageSize,
		setPage,
		pageSize,
		page,
		searching
	] = useOrders({ orderType: "PURCHASE" });

	return (
		<Container>
			<Content>
				<OrdersGrid
					{...{
						orders,
						refresh,
						setPageSize,
						setPage,
						pageSize,
						page,
						orderCount,
						isLoading,
						noDataText: searching
							? "No purchases found."
							: "No purchases have been made."
					}}
				/>
			</Content>
		</Container>
	);
};

export default Purchases;

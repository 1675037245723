import React from "react";
import { map } from "ramda";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import { device, displayOn } from "styles/device";
import { Container as CoreContainer } from "core/components";
import menuItems from "../MainNav/items";
import SignOutItem from "./SignOutItem";
import Item from "./Item";

// Collapse can't have any margin or padding on the direct child
const Container = styled.div`
	width: 100%;
	${displayOn({ max: device.tabletLarge })}
`;

const ContainerInner = styled(CoreContainer)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	padding-top: 8px;
	padding-bottom: 12px;
`;

const Divider = styled.div`
	border-bottom: 1px solid #333;
	margin-top: 4px;
	margin-bottom: 4px;
`;

const HamburgerMenu = ({ isOpen }) => {
	const renderItems = map(i => <Item item={i} key={i.label} />);

	return (
		<Collapse in={isOpen}>
			<Container>
				<ContainerInner>
					{renderItems(menuItems)}

					<Divider />

					<SignOutItem />
				</ContainerInner>
			</Container>
		</Collapse>
	);
};

export default HamburgerMenu;

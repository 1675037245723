import React from "react";
import styled from "styled-components";
import CoreLoader from "../Loader";
import { always, equals, T, cond } from "ramda";

const Container = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const color = cond([
	[equals("primary"), always("white")],
	[equals("danger"), always("white")],
	[equals("default"), always("green")],
	[T, always("green")]
]);

const Loader = ({ isLoading, variant }) => {
	if (!isLoading) {
		return null;
	}

	return (
		<Container>
			<CoreLoader color={color(variant)} size="small" center />
		</Container>
	);
};

export default Loader;

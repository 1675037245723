import { LoadingModal } from "core/components";
import React, { useContext } from "react";
import { useUserContext } from "../UserContext";
import useCurrentMerchant from "./use-current-merchant";
import useCurrentStore from "./use-current-store";
import useMerchants from "./use-merchants";
import useStores from "./use-stores";

const MerchantContext = React.createContext();

const MerchantProvider = ({ children }) => {
	const { user } = useUserContext();
	const [merchants, isMerchantsLoading] = useMerchants();
	const [currentMerchant, switchMerchant] = useCurrentMerchant({ merchants, isMerchantsLoading });
	const [stores, isStoresLoading] = useStores(currentMerchant);
	const [currentStore, switchStore] = useCurrentStore({ stores, isStoresLoading });
	const isLoading =
		isMerchantsLoading ||
		isStoresLoading ||
		!currentMerchant ||
		!currentStore ||
		currentMerchant?.merchantId !== currentStore?.merchantId;

	const hasFunctionAccess = name => currentMerchant?.functions?.includes(name) === true;

	if (user && isLoading) {
		return <LoadingModal isOpen={true} label="Loading..." />;
	}

	return (
		<MerchantContext.Provider
			value={{
				merchants,
				stores,
				currentMerchant,
				currentStore,
				currentPriceListId: currentStore?.priceListId,
				switchMerchant,
				switchStore,
				hasFunctionAccess // TODO: move to userContext
			}}
		>
			{children}
		</MerchantContext.Provider>
	);
};

const useMerchantContext = () => {
	const context = useContext(MerchantContext);
	if (context === undefined) {
		throw new Error("useMerchantContext must be used within a MerchantProvider");
	}
	return context;
};

export { MerchantProvider, useMerchantContext };

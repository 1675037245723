import { darken } from "polished";
import { Button as BsButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { cond, always, equals, T } from "ramda";
import { device, mediaQuery } from "styles/device";

const Btn = styled(BsButton)`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: 500;
	text-shadow: 0xp 0px 2px rgba(0, 0, 0, 0.2) !important;
	position: relative;
	box-shadow: none !important;
	outline: none;
	line-height: 1.6 !important;

	${({ $size }) =>
		cond([
			[
				equals("small"),
				always(`
						font-size: 12.5px !important;
					`)
			],
			[
				equals("large"),
				always(`
						padding: 5px 10px !important;
						font-size: 15px !important;
					`)
			],
			[T, always(``)]
		])($size)}
`;

const BtnDefault = styled(Btn)`
	&.btn,
	&.btn:focus {
		background-color: ${p => p.theme.color.dirtyWhite} !important;
		border-color: ${p => darken(0.05, p.theme.color.border.primary)} !important;
		color: ${p => p.theme.color.text.primary} !important;

		&:hover {
			background-color: ${p => darken(0.05, p.theme.color.dirtyWhite)} !important;
			border-color: ${p => darken(0.1, p.theme.color.border.primary)} !important;
		}
	}
`;

const BtnPrimary = styled(Btn)`
	&.btn,
	&.btn:focus {
		background-color: ${p => p.theme.color.emeraldGreen} !important;
		border-color: ${p => darken(0.05, p.theme.color.emeraldGreen)} !important;
		color: ${p => p.theme.color.white} !important;

		&:hover {
			background-color: ${p => darken(0.1, p.theme.color.emeraldGreen)} !important;
			border-color: ${p => darken(0.15, p.theme.color.emeraldGreen)} !important;
		}
	}
`;

const BtnDanger = styled(Btn)`
	&.btn,
	&.btn:focus {
		background-color: ${p => p.theme.color.bittersweetRed} !important;
		border-color: ${p => darken(0.05, p.theme.color.bittersweetRed)} !important;
		color: ${p => p.theme.color.white} !important;

		&:hover {
			background-color: ${p => darken(0.1, p.theme.color.bittersweetRed)} !important;
			border-color: ${p => darken(0.15, p.theme.color.bittersweetRed)} !important;
		}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	visibility: ${p => (p.hide ? "hidden" : "visible")};
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${p => {
		if (!p.fixedHeight) {
			return;
		}

		return cond([
			[equals("small"), always("20px")],
			[equals("medium"), always("21px")],
			[equals("large"), always("24px")]
		])(p.size);
	}};
`;

const Icon = styled(FontAwesomeIcon)``;

const Label = styled.span`
	display: ${p => (p.collapse ? "none" : "inline")};
	margin-left: ${p => p.isIcon && p.theme.space.small};

	@media ${mediaQuery(device.tablet)} {
		display: inline;
	}
`;

export { BtnPrimary, BtnDanger, BtnDefault, Content, IconContainer, Icon, Label };

import React, { useCallback } from "react";
import { Content, IconContainer, Icon, Label } from "./Button.styles";
import Loader from "./Loader";
import useIsLoading from "./use-is-loading";
import Variant from "./Variant";

const Button = ({
	children: label,
	icon,
	onClick,
	variant,
	"data-test": dataTest,
	size,
	isLoading: isLoadingProp,
	className,
	style,
	type,
	block,
	disabled,
	collapse: collapseProp
}) => {
	const isLoading = useIsLoading(isLoadingProp);
	const isDisabled = isLoading || disabled;
	const collapse = !!icon && (!label || collapseProp);

	const handleClick = useCallback(
		e => {
			if (isDisabled) {
				return;
			}

			onClick(e);
		},
		[onClick, isDisabled]
	);

	const renderContent = useCallback(
		() => (
			<Content hide={isLoading}>
				{icon && (
					<IconContainer fixedHeight={collapse} size={size}>
						<Icon icon={icon} fixedWidth={collapse} />
					</IconContainer>
				)}

				{label && (
					<Label isIcon={!!icon} collapse={collapse}>
						{label}
					</Label>
				)}
			</Content>
		),
		[icon, isLoading, label, size, collapse]
	);

	return (
		<Variant
			variant={variant}
			data-test={dataTest}
			onClick={handleClick}
			disabled={disabled}
			{...{ className, style, type, size, block }}
		>
			{renderContent()}
			<Loader isLoading={isLoading} variant={variant} />
		</Variant>
	);
};

Button.defaultProps = {
	size: "medium",
	variant: "default",
	onClick: () => {},
	isLoading: false,
	collapse: true
};

export default Button;

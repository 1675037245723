import { useCallback, useState } from "react";

const useSwitch = (initialState = false) => {
	const [state, setState] = useState(initialState);

	const switchOn = useCallback(() => setState(true), []);

	const switchOff = useCallback(() => setState(false), []);

	return [state, switchOn, switchOff];
};

export default useSwitch;

import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const Item = styled.div`
	height: 30px;
	display: flex;
`;

const Grip = styled.div`
	height: 100%;
	padding: 5px;
	margin-right: 0px;
	display: flex;
	align-items: center;
	height: 100%;
`;

const Content = styled.div`
	margin-top: 3px;
	flex-grow: 1;
`;

const SortableOptionsItem = ({ provided, onSwitch, option }) => {
	const itemProps = provided && { ...provided.draggableProps, ref: provided.innerRef };

	return (
		<Item {...itemProps}>
			<Content>
				<Form.Check
					type="switch"
					id={`switch-${option.key}`}
					label={option.label}
					style={{ marginBottom: 8 }}
					checked={option.checked}
					onChange={e => onSwitch(option.key, e.target.checked)}
				/>
			</Content>
			{option.checked && provided && (
				<Grip {...provided.dragHandleProps}>
					<FontAwesomeIcon icon={faGripLines} color="silver" style={{ fontSize: 16 }} />
				</Grip>
			)}
		</Item>
	);
};

export default SortableOptionsItem;

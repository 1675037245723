import styled from "styled-components";

const Card = styled.div`
	cursor: ${p => (p.isClickable ? "pointer" : "auto")};
	align-self: stretch;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	user-select: none;
`;

export default Card;

import React from "react";
import { path } from "ramda";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";
import Graph from "./Graph";

const MiniChart = ({ title, datakey, data, dashboard }) => {
	const labelFormatter = (unused, d) => path([0, "payload", "name"], d)?.substr(0, 10);

	if (!dashboard) {
		return null;
	}

	return (
		<Graph title={title} from={dashboard.from} to={dashboard.to} value={dashboard[datakey]}>
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 0,
						right: 0,
						left: 0,
						bottom: 0
					}}
				>
					<CartesianGrid stroke="#f0f0f0" strokeDasharray="3 3" />
					<Tooltip
						labelFormatter={labelFormatter}
						formatter={amount => [amount, title]}
					/>
					<Line
						type="monotone"
						dataKey={datakey}
						dot={false}
						stroke="#8884d8"
						activeDot={{ r: 8 }}
					/>
				</LineChart>
			</ResponsiveContainer>
		</Graph>
	);
};

export default MiniChart;

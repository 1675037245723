import { Card } from "auth/components";
import React from "react";

const Expired = () => {
	return (
		<Card size="huge">
			<span>This link has expired.</span>

			<span>
				Go <a href="/reset-password">here</a> to reset your password.
			</span>
		</Card>
	);
};

export default Expired;

import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import DetailsItem from "./DetailsItem";
import Moment from "react-moment";
import PreOrderActive from "./PreOrderActive";
import Checkmark from "core/components/Checkmark";

const DetailsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin-bottom: 16px;
`;

const DetailsGrid = styled.div`
	display: flex;
`;

const DetailsGridSection = styled.div`
	margin-right: 24px;
	@media ${mediaQuery(device.tablet)} {
		margin-right: 128px;
	}
`;

const DateContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 88px;
	@media ${mediaQuery(device.tablet)} {
		flex-direction: unset;
		width: auto;
	}
`;

const DateDiv = styled.div`
	width: unset;
	margin-right: 0px;
	@media ${mediaQuery(device.tablet)} {
		width: 88px;
		margin-right: 4px;
	}
`;

const DetailsList = ({ event }) => {
	return (
		<DetailsContainer>
			<DetailsGrid>
				<DetailsGridSection>
					<DetailsItem label="Date">
						<DateContainer>
							<DateDiv>
								<Moment
									date={event.timeStart}
									format="YYYY-MM-DD"
									style={{ fontSize: 14 }}
								/>
							</DateDiv>
							<div>
								<Moment
									date={event.timeStart}
									format="HH:mm"
									style={{ fontSize: 14 }}
								/>
							</div>
						</DateContainer>
					</DetailsItem>
					<DetailsItem label="Has time slots">
						<Checkmark value={event.hasTimeSlots} />
						<div style={{ display: "flex", flexWrap: "wrap" }}></div>
					</DetailsItem>
				</DetailsGridSection>
				<DetailsGridSection>
					<DetailsItem label="Pre order">
						<PreOrderActive event={event} />
					</DetailsItem>
					<DetailsItem label="No. of pre orders">{event.preOrderCount}</DetailsItem>
				</DetailsGridSection>
			</DetailsGrid>
		</DetailsContainer>
	);
};
export default DetailsList;

import React, { useCallback, useState } from "react";
import RotateLeftButton from "./Buttons/RotateLeftButton";
import RotateRightButton from "./Buttons/RotateRightButton";
import Button from "../../../Button";
import Cropper from "react-easy-crop";
import {
	ToolbarContainer,
	ButtonsContainer,
	ActionsContainer,
	ZoomSlider,
	CropContainer
} from "./Crop.styles";
import { getCroppedImage } from "core/utils/canvas";

const Crop = ({ image, onImageCrop, onCancel }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const flipHorizontal = () => setRotation(r => r - 90);
	const flipVertical = () => setRotation(r => r + 90);

	const showCroppedImage = useCallback(async () => {
		const croppedImage = await getCroppedImage(image, croppedAreaPixels, rotation);

		onImageCrop(croppedImage);
	}, [image, croppedAreaPixels, rotation, onImageCrop]);

	return (
		<>
			<CropContainer>
				<Cropper
					style={{
						cropAreaStyle: cropperStyles.cropAreaStyle
					}}
					image={image}
					crop={crop}
					rotation={rotation}
					zoom={zoom}
					viewMode={2}
					aspect={4 / 3}
					onCropChange={setCrop}
					onRotationChange={setRotation}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
				/>
			</CropContainer>
			<ToolbarContainer>
				<ButtonsContainer>
					<Button
						style={{ marginRight: 8 }}
						onClick={showCroppedImage}
						variant="primary"
						size="small"
					>
						OK
					</Button>
					<Button onClick={onCancel} size="small">
						Cancel
					</Button>
				</ButtonsContainer>
				<ActionsContainer>
					<ZoomSlider
						onChange={event => setZoom(event.target.value)}
						type="range"
						min={1}
						max={3}
						defaultValue={0}
						step={0.01}
					/>
					<RotateLeftButton style={{ marginRight: 8 }} onClick={flipHorizontal} />
					<RotateRightButton onClick={flipVertical} />
				</ActionsContainer>
			</ToolbarContainer>
		</>
	);
};

const cropperStyles = {
	cropAreaStyle: {
		border: "1px solid red"
	}
};

export default Crop;

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Button } from "core/components";
import { useSwitch } from "core/utils";
import React from "react";
import TestAudioModal from "./TestAudioModal";

const TestSoundButton = () => {
	const [isModalOpen, openModal, closeModal] = useSwitch(false);

	return (
		<>
			<TestAudioModal show={isModalOpen} onClose={closeModal} />
			<Button onClick={openModal} icon={faBell} data-test="change-station-button">
				Test sound
			</Button>
		</>
	);
};

export default TestSoundButton;

import React from "react";
import { Dropdown as BsDropdown } from "react-bootstrap";
import OutputType from "./OutputType";
import styled from "styled-components";

const Container = styled(BsDropdown.Item)`
	padding-left: ${p => p.theme.space.large};
	padding-right: ${p => p.theme.space.large};
`;

const ContainerInner = styled.div`
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 8px;
`;

const DropdownItem = ({ reportFunction, onClick }) => {
	const { key, reportFunctionId, name, functionOutputType } = reportFunction;

	const handleClick = () => onClick(reportFunction);

	return (
		<Container key={key} value={reportFunctionId} onClick={handleClick}>
			<ContainerInner>
				<div style={{ marginRight: 8 }}>
					<div style={{ fontWeight: 500 }}>{name}</div>
				</div>

				<OutputType type={functionOutputType} />
			</ContainerInner>
		</Container>
	);
};

export default DropdownItem;

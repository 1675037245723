import { propOr } from "ramda";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import ProductImportPopover from "../ProductImportPopover";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	align-items: center;
	margin-right: ${p => p.theme.space.medium};
`;

const Description = styled.div`
	display: flex;
	align-items: center;
	margin-top: 5px;
	font-size: 13px;
	display: none;
	@media ${mediaQuery(device.tablet)} {
		display: inline-block;
		margin-right: ${p => p.theme.space.small};
	}
`;

const ProductImportStatus = ({ productImport }) => {
	const warnings = propOr([], "warnings", productImport);

	const description = productImport.timeCompleted
		? `Last synced${warnings.length > 0 ? " with issues" : ""}`
		: "Last sync was unsuccessful";

	return (
		<Container>
			<Description className="text-muted">
				{description}&nbsp;
				<Moment
					calendar={{
						lastDay: "[yesterday at] HH:mm",
						sameDay: "[today at] HH:mm",
						lastWeek: "YYYY-MM-DD HH:mm",
						sameElse: "YYYY-MM-DD HH:mm"
					}}
				>
					{productImport.timeCompleted || productImport.timeError}
				</Moment>
			</Description>
			<ProductImportPopover productImport={productImport} />
		</Container>
	);
};

export default ProductImportStatus;

import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfinity } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
	font-size: 14px;
`;

const TimeInterval = ({ fromTime, toTime, timeZone }) => {
	if (!fromTime) {
		return (
			<Container>
				<FontAwesomeIcon icon={faInfinity} />
			</Container>
		);
	}

	const duration = moment
		.duration(moment.tz(toTime, timeZone).diff(moment.tz(fromTime, timeZone)))
		.humanize();

	return <Container>{duration}</Container>;
};
export default TimeInterval;

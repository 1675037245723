import styled from "styled-components";

const Label = styled.div`
	font-size: 15px;
	color: #333;
	text-align: center;
	margin-bottom: 8px;
`;

export default Label;

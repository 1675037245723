import { useEffect, useState, useCallback } from "react";
import { httpClient } from "data";
import { compose, isEmpty } from "ramda";
import { useUserContext } from "context";
import { usePersistedStateEffect } from "core/utils";
import { findMerchantById, resolveCurrentMerchant } from "./functions";

const useCurrentMerchant = ({ merchants, isMerchantsLoading }) => {
	const { user } = useUserContext();
	const [merchant, setCurrentMerchant] = useState();
	const cachedMerchantId = usePersistedStateEffect(
		user && `${user.uid}-currentMerchantId`,
		merchant?.merchantId
	);

	const updateMerchant = useCallback(
		(resolveFn, merchantId) => compose(setCurrentMerchant, resolveFn(merchants))(merchantId),
		[merchants]
	);

	useEffect(() => {
		if (!user) {
			setCurrentMerchant();
			return;
		}

		if (!merchants || isEmpty(merchants)) {
			setCurrentMerchant();
			return;
		}

		if (isMerchantsLoading) {
			setCurrentMerchant();
			return;
		}

		updateMerchant(resolveCurrentMerchant, cachedMerchantId);
	}, [user, merchants, isMerchantsLoading, updateMerchant, cachedMerchantId]);

	useEffect(() => {
		httpClient.setMerchantId(merchant?.merchantId);
	}, [merchant]);

	const switchMerchant = useCallback(
		merchantId => updateMerchant(findMerchantById, merchantId),
		[updateMerchant]
	);

	return [merchant, switchMerchant];
};

export default useCurrentMerchant;

import React from "react";
import { Image } from "react-bootstrap";
import imgLogo from "assets/passbuy-logo-black.svg";
import imgFortnoxLogo from "assets/fortnox-logo-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useQuery } from "core/utils";
import { useHistory } from "react-router-dom";

const cardMinWidth = ({ size }) => {
	if (size === "huge") return "750px";
	else if (size === "large") return "450px";

	return "350px";
};

const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: ${p => (p.success ? p.theme.color.emeraldGreen : p.theme.color.bittersweetRed)};
`;

const Card = styled.div`
	background-color: #fff;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 0px;
	padding: 28px;
	width: 20rem;
	height: auto;
	min-width: ${cardMinWidth};
	margin-bottom: 10%;
	border-radius: 12px;
`;

const LogoContainer = styled.div`
	display: grid;
	justify-content: center;
	margin-bottom: 24px;
	align-items: center;
`;

const InnerDiv = styled.div`
	display: flex;
	margin-bottom: 24px;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const PassbuyLogo = styled(Image)`
	width: 200px;
`;

const FortnoxLogo = styled(Image)`
	width: 120px;
`;

const StatusIcon = styled(FontAwesomeIcon)`
	color: ${p => (p.success ? p.theme.color.emeraldGreen : p.theme.color.bittersweetRed)};
`;

const ActivationStatus = () => {
	const queryParams = useQuery();
	const history = useHistory();
	const hasInvalidQueryParam = !["true", "false"].includes(queryParams.get("success"));
	if (hasInvalidQueryParam) {
		history.push("/home");
	}

	const success = queryParams.get("success") === "true";

	const statusIcon = success ? faHeart : faHeartBroken;
	const statusText = success
		? "Successfully connected to Fortnox."
		: "There was a problem connecting to Fortnox, please try again.";

	return (
		<PageContainer success={success}>
			<Card>
				<LogoContainer>
					<InnerDiv>
						<PassbuyLogo src={imgLogo} />
					</InnerDiv>
					<InnerDiv>
						<StatusIcon success={success} icon={statusIcon} size={"3x"} />
					</InnerDiv>
					<InnerDiv>
						<FortnoxLogo src={imgFortnoxLogo} />
					</InnerDiv>
				</LogoContainer>
				<InnerDiv>{statusText}</InnerDiv>
			</Card>
		</PageContainer>
	);
};

export default ActivationStatus;

import { useEffect } from "react";
import usePersistedState from "./use-persisted-state";

const usePersistedStateEffect = (key, value) => {
	const [cachedValue, setCachedValue] = usePersistedState(key);

	useEffect(() => {
		value && setCachedValue(value);
	}, [value, setCachedValue]);

	return cachedValue;
};

export default usePersistedStateEffect;

import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const getValues = (row, values) => {
	if (typeof values === "function") return values(row);
	return values;
};

const Check = styled(Form.Check)`
	margin-bottom: 8px;
	padding: 5px 30px;
`;

const Radio = ({ row, value, item, onChange }) => {
	const itemValues = getValues(row, item.values);

	const handleCheckboxChange = useCallback(
		v => onChange(v.value, { ...item.setProps, ...v.setProps }),
		[onChange, item]
	);

	return itemValues
		.filter(v => typeof v.visible !== "function" || v.visible(row))
		.map(v => (
			<Check
				key={v.value}
				active={v.value === value}
				name={`radio-${item.prop}`}
				type="radio"
				id={`radio-${v.value}`}
			>
				<Form.Check.Input
					style={{ cursor: "pointer" }}
					checked={value === v.value}
					onChange={() => handleCheckboxChange(v)}
					value={v.value}
					type="radio"
				/>
				<Form.Check.Label style={{ cursor: "pointer" }}>{v.label}</Form.Check.Label>
				{v.description && <Form.Text muted>{v.description}</Form.Text>}
			</Check>
		));
};

export default Radio;

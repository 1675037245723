import { useEffect } from "react";

const useOutsideClick = (ref, buttonRef, callback) => {
	useEffect(() => {
		const handleClickOutside = event => {
			if (!ref.current || !buttonRef.current) {
				return;
			}

			if (ref.current.contains(event.target)) {
				return;
			}

			if (buttonRef.current.contains(event.target)) {
				return;
			}

			callback();
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [ref, buttonRef, callback]);
};

export default useOutsideClick;

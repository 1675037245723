const getTableActions = ({ hasFunctionAccess }) => {
	if (!hasFunctionAccess("deal_edit")) return {};

	return {
		delete: {
			apiPath: "/deals/{dealId}",
			apiMethod: "DELETE"
		},
		edit: {
			inline: true,
			modal: true,
			modalTitle: "Edit product",
			apiPath: "/deals/{dealId}?storeId={activeStoreId}",
			apiExtraParams: ["activeStoreId"],
			apiMethod: "PUT"
		}
	};
};

export default getTableActions;

import React from "react";
import { TicketConfig } from "./functions";
import Ticket from "./Ticket";

const OrderTicketOpen = ({ orderTicket, station, onDoubleClick }) => {
	const config = TicketConfig(
		{ orderTicket, station },
		{
			headerColor: "purple",
			timeAsDuration: true,
			liveTime: true,
			flashOnEnter: true,
			alert: station.alert,
			soundOnEnter: station.sound,
			onDoubleClick
		}
	);

	return <Ticket {...config} />;
};

export default OrderTicketOpen;

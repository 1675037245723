import { httpClient } from "data";

const menuRepo = {
	getMenus: storeId => httpClient.get(`/menus/?storeId=${storeId}`),

	createMenu: ({ storeId, name, description = null, active, scheduleActive, schedule }) =>
		httpClient.put(`/menus?storeId=${storeId}`, {
			body: {
				name,
				description,
				active,
				scheduleActive,
				schedule
			}
		}),

	updateMenu: ({ menuId, name, description = null, active, scheduleActive, schedule }) =>
		httpClient.patch(`/menus/${menuId}`, {
			body: {
				name,
				description,
				active,
				scheduleActive,
				schedule
			}
		}),

	deleteMenu: menuId => httpClient.delete(`/menus/${menuId}`),

	addSection: ({ menuId, name, description = null, active, scheduleActive, schedule }) =>
		httpClient.put(`/menus/${menuId}/sections`, {
			body: {
				name,
				description,
				active,
				scheduleActive,
				schedule
			}
		}),

	updateSection: ({
		menuId,
		menuSectionId,
		name,
		description = null,
		active,
		scheduleActive,
		schedule
	}) =>
		httpClient.patch(`/menus/${menuId}/sections/${menuSectionId}`, {
			body: {
				name,
				description,
				active,
				scheduleActive,
				schedule
			}
		}),

	reorderSections: ({ menuId, sections }) =>
		httpClient.patch(`/menus/${menuId}`, {
			body: { sections }
		}),

	deleteSection: ({ menuId, menuSectionId }) =>
		httpClient.delete(`/menus/${menuId}/sections/${menuSectionId}`),

	addProduct: ({ menuId, menuSectionId, productId }) =>
		httpClient.put(`/menus/${menuId}/sections/${menuSectionId}/products`, {
			body: {
				productId,
				sortOrder: 0
			}
		}),

	reorderProducts: ({ menuId, menuSectionId, products }) =>
		httpClient.patch(`/menus/${menuId}/sections/${menuSectionId}`, {
			body: { products }
		}),

	removeProduct: ({ menuId, menuSectionId, productId }) =>
		httpClient.delete(`/menus/${menuId}/sections/${menuSectionId}/products/${productId}`),

	editProduct: ({ menuId, menuSectionId, productId, product }) =>
		httpClient.patch(`/menus/${menuId}/sections/${menuSectionId}/products/${productId}`, {
			body: product
		})
};

export default menuRepo;

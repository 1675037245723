import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import theme from "styles/theme";

const CellContainer = styled.div`
	display: flex;
	align-items: center;
`;
const CellText = styled.div`
	margin: ${props => props.theme.space.small};
	color: ${props => props.theme.color.midGray};
`;

const CheckinGroupActiveColumn = (cell, row) => {
	if (!row.checkinGroupActive && cell)
		return (
			<CellContainer>
				<FontAwesomeIcon icon={faTimes} color={theme.color.bittersweetRed} />
				<CellText>(Group inactive)</CellText>
			</CellContainer>
		);

	return (
		<FontAwesomeIcon
			icon={cell ? faCheck : faTimes}
			color={cell ? theme.color.emeraldGreen : theme.color.bittersweetRed}
		/>
	);
};

export default CheckinGroupActiveColumn;

import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	min-width: 140px;
`;

const DateContainer = styled.div`
	margin-right: 4px;
	display: flex;
	flex-wrap: no-wrap;
`;

const TimeContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	color: ${p => p.theme.color.midGray};
	font-size: 14px;
`;

const Created = ({ cell, timeZone }) => {
	return (
		<Container>
			<DateContainer>{moment.tz(cell, timeZone).format("YYYY-MM-DD")}</DateContainer>
			<TimeContainer>{moment.tz(cell, timeZone).format("HH:mm")}</TimeContainer>
		</Container>
	);
};

export default Created;

import required from "core/components/Grid/Validation/required";

const getCheckinGroupSchema = ({ checkinGroups }) => {
	return [
		{
			prop: "checkinGroupId",
			type: "dropdown",
			label: "Group",
			editable: false,
			width: 150,
			columnVisible: false,
			values: () => [
				...[{ value: "", label: "- Select -" }],
				...checkinGroups.map(b => ({
					value: b.checkinGroupId,
					label: `${b?.name}`
				}))
			],
			validate: [required("Select a group")],
			modalSortOrder: 0
		},
		{
			prop: "checkinGroupName",
			label: "Group",
			type: "text",
			sort: true,
			editable: false,
			width: 150,
			visible: () => false
		}
	];
};

export default getCheckinGroupSchema;

import React from "react";
import GridEdit from "./GridEdit";
import GridDelete from "./GridDelete";
import theme from "styles/theme";

const GridTopAction = ({ selectedRows, action, reload, row, apiPath, apiMethod }) => {
	if (action.hideOnEmptySelect && selectedRows.length > 0) return <></>;

	if (action.type === "edit")
		return (
			<div style={{ marginRight: theme.space.small }}>
				<GridEdit
					schema={[
						...action.schema,
						...(action.selectedKeyProp
							? [
									{
										prop: action.selectedKeyProp,
										type: "text",
										visible: () => false
									}
							  ]
							: [])
					]}
					disabled={
						(action.disabledOnEmptySelect && selectedRows.length === 0) ||
						!action.disabledOnEmptySelect
					}
					onChange={reload}
					buttonProps={action.buttonProps}
					title={action.modalTitle?.replace("$selectedCount$", selectedRows.length)}
					message={action.modalMessage?.replace("$selectedCount$", selectedRows.length)}
					row={row}
					apiPath={apiPath}
					apiMethod={apiMethod}
					style={{ marginRight: 8 }}
				/>
			</div>
		);
	if (action.type === "delete")
		return (
			<div style={{ marginRight: theme.space.small }}>
				<GridDelete
					disabled={
						(action.disabledOnEmptySelect && selectedRows.length === 0) ||
						!action.disabledOnEmptySelect
					}
					message={action.modalMessage?.replace("$selectedCount$", selectedRows.length)}
					onChange={reload}
					row={row}
					apiPath={apiPath}
					apiMethod={apiMethod}
					buttonProps={action.buttonProps}
				/>
			</div>
		);
};

export default GridTopAction;

import React from "react";
import styled from "styled-components";
import { always, cond, equals, propEq, T } from "ramda";

const Badge = styled.div`
	background: ${cond([
		[propEq("type", "CSV"), always("#e9e3ff")],
		[propEq("type", "JSON"), always("#D3FFE8")],
		[T, always("#e1e1e1")]
	])};
	color: ${cond([
		[propEq("type", "CSV"), p => p.theme.color.purpleStreet],
		[propEq("type", "JSON"), p => p.theme.color.emeraldGreen],
		[T, always("#666")]
	])};
	border-radius: 4px;
	font-weight: 600;
	font-size: 12px;
	padding: ${p => p.theme.space.mini} ${p => p.theme.space.small};
`;

const OutputType = ({ type }) => {
	const label = cond([
		[equals("CSV"), always("CSV ATTACHMENT")],
		[equals("JSON"), always("EMAIL MESSAGE")],
		[T, always("UNKNOWN")]
	])(type);

	return <Badge type={type}>{label}</Badge>;
};

export default OutputType;

import React from "react";
import styled from "styled-components";
import Body from "./Body";
import MainNav from "./MainNav";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Header = () => {
	return (
		<Container>
			<Body />
			<MainNav />
		</Container>
	);
};

export default Header;

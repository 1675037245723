import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";

const currencySuffixes = {
	SEK: "kr"
};

const Container = styled.div`
	width: 100%;
	text-align: ${p => p.align};
`;

const Amount = ({ value, currency = "SEK", align = "right" }) =>
	value ? (
		<Container align={align}>
			<NumberFormat
				value={value}
				displayType={"text"}
				thousandSeparator={"\xA0"}
				decimalSeparator=","
				fixedDecimalScale={true}
				suffix={"\xA0" + currencySuffixes[currency]}
				decimalScale={2}
			/>
		</Container>
	) : null;

export default Amount;

import React, { useEffect, useState } from "react";

const Expire = props => {
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setVisible(false);
		}, props.delay);

		return () => clearTimeout(timeout);
	}, [props.delay]);

	return visible ? <div>{props.children}</div> : <div />;
};

export default Expire;

import React from "react";
import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;

const fadeInAnimation = () =>
	css`
		${fadeIn} 350ms
	`;

const Message = styled.div`
	font-size: 14px;
	color: #666;
	margin: 8px 0;
	animation: ${fadeInAnimation};
	user-select: none;
`;

const WaitingMessage = () => {
	return <Message data-test="open-tab-waiting-message">Waiting for order tickets...</Message>;
};

export default WaitingMessage;

const getTableActions = ({ hasFunctionAccess, merchant }) => {
	return hasFunctionAccess("product_edit")
		? {
				delete: !merchant?.externalProductSync && {
					apiPath: "/products/{productId}?storeId={activeStoreId}",
					apiMethod: "DELETE",
					apiExtraParams: ["activeStoreId"]
				},
				edit: {
					inline: true,
					modal: true,
					modalTitle: "Edit product",
					apiPath:
						"/products/{productId}?priceListId={activePriceListId}&storeId={activeStoreId}",
					apiExtraParams: ["activePriceListId", "activeStoreId"],
					apiMethod: "PUT"
				}
		  }
		: {};
};

export default getTableActions;

import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import GridInfoModal from "core/components/Grid/GridInfoModal";
import CONFIG from "core/config";
import { darken } from "polished";
import QRCode from "qrcode.react";
import { eqProps, find } from "ramda";
import React from "react";
import { Dropdown as ReactBootstrapDropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

const QrContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const Dropdown = styled(ReactBootstrapDropdown)`
	display: flex;
	justify-content: flex-end;
`;

const DropdownToggle = styled(Dropdown.Toggle)`
	background-color: ${p => p.theme.color.white} !important;
	outline: none;
	border: none;
	text-align: right;
	padding: 3px 6px;
	color: ${p => p.theme.color.text.muted} !important;

	&:hover {
		background-color: ${p => darken(0.05, p.theme.color.dirtyWhite)} !important;
		border-color: ${p => darken(0.1, p.theme.color.border.primary)} !important;
	}
`;

const QRModal = ({ rowData, checkinGroups }) => {
	const qrUrl = `${CONFIG.qrBaseUrl}/?checkinId=${rowData.checkinId}`;
	return (
		<GridInfoModal
			row={{
				...rowData,
				...find(eqProps("checkinGroupId", rowData), checkinGroups)
			}}
			body={
				<>
					<QrContainer>
						<QRCode size={isMobile ? 100 : 200} value={qrUrl} />
					</QrContainer>

					<Dropdown variant="secondary">
						<DropdownToggle />

						<Dropdown.Menu>
							<Dropdown.Item href={qrUrl} target="blank">
								Visit QR code
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</>
			}
			title={"{checkinLabelPrefix} {label}"}
			buttonProps={{ icon: faQrcode }}
			confirmButtonProps={{ label: "Close" }}
		/>
	);
};

export default QRModal;

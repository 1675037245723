import { faQuestionCircle, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiRowInput } from "core/components";
import moment from "moment";
import React, { useState } from "react";
import { Form, FormControl, OverlayTrigger, Popover } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { validate as emailIsValid } from "react-email-validator";
import { getScheduleDays, getScheduleTypes } from "./schedule";
import { Group, Label } from "./styles";

const popoverScheduleType = schedule => (
	<Popover id="popover-basic">
		<Popover.Content>
			<div>Includes transactions between {schedule?.description}</div>
		</Popover.Content>
	</Popover>
);

const EditReportJobForm = ({
	reportJobData,
	schedule,
	handleScheduleChange,
	active,
	setActive,
	emails,
	setEmails
}) => {
	const [useScheduleTime, setUseScheduleTime] = useState(
		schedule?.IncludeEventsUntilScheduleTime || false
	);
	const [scheduleTime, setScheduleTime] = useState({
		hour: schedule?.hour || "20",
		minute: schedule?.minute || "00"
	});
	const [scheduleType, setScheduleType] = useState(schedule?.type);
	const [scheduleDay, setScheduleDay] = useState(schedule?.dayNumber || 1);

	return (
		<>
			<Group style={{ /* temporarily inactivate active flag */ display: "none" }}>
				<Label>Active</Label>

				<Form.Check
					type="switch"
					id={`switch-active-${reportJobData.reportJobId}`}
					style={{ marginBottom: 8 }}
					label={""}
					checked={active}
					onChange={() => setActive(!active)}
				/>
			</Group>

			<Group>
				<Label>Schedule</Label>

				<div style={{ display: "flex", alignItems: "center" }}>
					<FormControl
						style={{ width: 300 }}
						as="select"
						className="mr-sm-2"
						id="inlineFormCustomSelectPref"
						value={scheduleType}
						onChange={e => {
							setScheduleType(e.target.value);
							handleScheduleChange(
								e.target.value,
								useScheduleTime,
								scheduleDay,
								scheduleTime
							);
						}}
						custom
					>
						{getScheduleTypes().map((t, i) => (
							<option key={i} value={t.type}>
								{t.text}
							</option>
						))}
					</FormControl>

					{schedule?.type && (
						<div style={{ marginLeft: 8 }}>
							<OverlayTrigger
								trigger="click"
								rootClose
								placement="top"
								overlay={popoverScheduleType(schedule)}
							>
								<FontAwesomeIcon
									icon={faQuestionCircle}
									style={{ cursor: "hand" }}
								/>
							</OverlayTrigger>
						</div>
					)}
				</div>

				<Form.Text>Reports are sent at 03.00 unless schedule is cut</Form.Text>

				<div style={{ display: "flex", flexDirection: "column", marginTop: 16 }}>
					<Form.Check
						type="switch"
						id={`switch-useScheduleTime-${reportJobData.reportJobId}`}
						label={"Cut schedule"}
						checked={useScheduleTime}
						onChange={e => {
							setUseScheduleTime(!useScheduleTime);
							handleScheduleChange(
								scheduleType,
								e.target.checked,
								scheduleDay,
								scheduleTime
							);
						}}
					/>

					{useScheduleTime && (
						<div style={{ display: "flex", marginTop: 12 }}>
							{scheduleType === "weekly" && (
								<FormControl
									style={{ width: 180 }}
									as="select"
									className="mr-sm-2"
									id="inlineFormCustomSelectPref"
									value={scheduleDay}
									onChange={e => {
										setScheduleDay(e.target.value);
										handleScheduleChange(
											scheduleType,
											useScheduleTime,
											e.target.value,
											scheduleTime
										);
									}}
									custom
								>
									{getScheduleDays().map(t => (
										<option key={t.day} value={t.day}>
											{t.text}
										</option>
									))}
								</FormControl>
							)}

							<DatePicker
								className="timePicker"
								selected={moment()
									.hour(scheduleTime.hour)
									.minute(scheduleTime.minute)
									.toDate()}
								onChange={e => {
									const selectedTime = {
										hour: moment(e).format("HH"),
										minute: moment(e).format("mm")
									};
									setScheduleTime(selectedTime);
									handleScheduleChange(
										scheduleType,
										useScheduleTime,
										scheduleDay,
										selectedTime
									);
								}}
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={15}
								timeCaption="Time"
								timeFormat="HH:mm"
								dateFormat="HH:mm"
							/>
						</div>
					)}
				</div>
			</Group>

			<Group>
				<Label>Recipients</Label>
				<MultiRowInput
					newRowButtonTitle="Add recipient"
					newRowButtonFontAwesomeIcon={faUserPlus}
					rows={emails}
					setRows={setEmails}
					validationFunction={data => (!emailIsValid(data) ? "Invalid email" : null)}
				/>
			</Group>
		</>
	);
};

export default EditReportJobForm;

import { map } from "ramda";
import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import OrderTicket from "./OrderTicket";

const Grid = styled.div`
	display: grid;
	align-content: stretch;
	row-gap: 16px;
	width: 100%;
	grid-template-columns: 1fr;
	padding-bottom: 32px;

	@media ${mediaQuery(device.tablet)} {
		grid-template-columns: 1fr 1fr;
		column-gap: 22px;
		row-gap: 22px;
	}

	@media ${mediaQuery(device.tabletLarge)} {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

const OrderTicketGrid = ({ orderTickets = [], station, onDoubleClick }) => {
	const renderOrderTickets = map(orderTicket => {
		const handelDoubleClick = () => onDoubleClick(orderTicket);

		return (
			<OrderTicket
				orderTicket={orderTicket}
				station={station}
				onDoubleClick={handelDoubleClick}
				key={orderTicket.orderTicketId}
			/>
		);
	});

	return <Grid>{renderOrderTickets(orderTickets)}</Grid>;
};

export default OrderTicketGrid;

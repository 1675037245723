import { httpClient } from "data";
import urlcat from "urlcat";

const eventRepo = {
	getEvents: ({ storeId, dateFilter, page, pageSize, sortField, sortOrder, search }) =>
		httpClient.get(
			urlcat("/events", {
				storeId,
				dateFilter,
				page,
				pageSize,
				sortField,
				sortOrder,
				search
			})
		),
	getEvent: ({ storeId, eventId }) => httpClient.get(urlcat(`/events/${eventId}`, { storeId })),
	createOrderTickets: ({ storeId, eventId, eventTimeSlotId, stationIds }) =>
		httpClient.post(urlcat(`/events/${eventId}/order-tickets`, { storeId }), {
			body: { eventTimeSlotId, stationIds }
		}),
	getEventReservations: ({ storeId, eventId }) =>
		httpClient.get(urlcat(`/events/${eventId}/reservations`, { storeId }))
};

export default eventRepo;

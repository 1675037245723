import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	background-color: #f6f6f6;
	border-radius: 12px;
	border: 1px solid #eee;
	padding: 16px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	margin-bottom: ${p => p.theme.space.large};
	
	@media ${mediaQuery(device.tablet)} {
		padding: 22px;
	}
`;

const Name = styled.span`
	font-size: 16px;
	font-family: Peak;
	line-height: 1.2;

	@media ${mediaQuery(device.tablet)} {
		font-size: 18px;
	}
`;

const Icons = styled.div`
	display: flex;
	align-items: center;
	flex-shrink: 1;
	padding-left: 32px;
`;

const Icon = styled(FontAwesomeIcon)`
	font-size: 16px;
	margin-right: 12px;
	color: ${p => p.theme.color.emeraldGreen};

	&:last-child {
		margin-right: 0;
	}
`;

const ExportType = ({ exportType, onClick, name }) => {
	const handleClick = () => onClick(exportType);

	return (
		<Container onClick={handleClick}>
			<Name>{name}</Name>
			<Icons>
				<Icon icon={faCheckCircle} />
			</Icons>
		</Container>
	);
};

export default ExportType;

import theme from "styles/theme";

const statuses = {
	COMPLETED: {
		color: theme.color.emeraldGreen,
		label: "Paid"
	},
	PENDING: { color: theme.color.goldenSand, label: "Pending" },
	FAILED: { color: theme.color.bittersweetRed, label: "Failed" },
	ABANDONED: { color: theme.color.lightGray, label: "Abandoned" }
};

const getOrderstatus = order => {
	// Display different text and icon for refunded purchase orders and refund orders
	if (order.status === "COMPLETED" && order.type === "REFUND")
		return { color: theme.color.purpleStreet, label: "Refunded" };
	if (order.status === "PENDING" && order.type === "REFUND")
		return { color: theme.color.goldenSand, label: "In progress" };
	if (order.status === "COMPLETED" && order.fullyRefunded)
		return { color: theme.color.lightGray, label: "Refunded" };
	if (order.status === "COMPLETED" && order.partiallyRefunded)
		return {
			color: theme.color.lightGray,
			label: "Partially refunded"
		};

	const status = statuses[order.status];

	return status;
};
export default getOrderstatus;

import { ConfirmationModal } from "core/components";
import React from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const DeleteReportModal = ({ show, onConfirm, onClose, isLoading }) => {
	const handleContainerClick = e => e.stopPropagation();

	return (
		<div onClick={handleContainerClick}>
			<ConfirmationModal
				title="Delete report"
				text="Are you sure you want to delete this report?"
				data-test="reports-delete-modal-confirm-button"
				onCancel={onClose}
				confirmButtonProps={{
					label: "Delete",
					icon: faTrashAlt,
					variant: "danger",
					isLoading: isLoading
				}}
				{...{ show, onConfirm }}
			/>
		</div>
	);
};

export default DeleteReportModal;

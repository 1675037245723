import { Loader } from "core/components";
import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import useReceipt from "./use-receipt";

const Container = styled.div`
	display: flex;
	justify-content: center;
`;
const Receipt = ({ order }) => {
	const [receipt, isLoading, error] = useReceipt({ orderId: order.orderId });

	useEffect(() => {
		if (receipt?.pdf) {
			const dataBuffer = Buffer.from(receipt.pdf.data, "base64");
			var data = new Blob([dataBuffer], { type: "application/pdf" });
			var dataURL = window.URL.createObjectURL(data);
			const tempLink = document.createElement("a");
			tempLink.href = dataURL;
			tempLink.setAttribute("download", receipt.pdf.filename);
			tempLink.click();
		}
	}, [receipt]);

	if (isLoading) return <Loader center />;

	if (error)
		return (
			<div>
				<Alert variant="warning">{error}</Alert>
			</div>
		);

	return (
		<Container>
			{receipt?.svg && <div dangerouslySetInnerHTML={{ __html: receipt.svg }} />}
		</Container>
	);
};
export default Receipt;

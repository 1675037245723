import { not } from "ramda";
import { useState } from "react";

const useSwitch = initialState => {
	const [state, setState] = useState(initialState);

	const toggle = () => setState(not);

	return [state, toggle];
};

export default useSwitch;

import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-times/css/material/default.css";

const ScheduleTime = ({ timeIndex, day, fromTime, toTime, setTime, removeTime }) => {
	const [currentFromTime, setCurrentFromTime] = useState(fromTime ? fromTime?.split(":") : null);
	const [currentToTime, setCurrentToTime] = useState(toTime ? toTime?.split(":") : null);

	useEffect(() => {
		setCurrentFromTime(fromTime ? fromTime?.split(":") : null);
		setCurrentToTime(toTime ? toTime?.split(":") : null);
	}, [timeIndex, day, fromTime, toTime]);

	return (
		<div style={{ display: "flex", paddingTop: 8 }}>
			<Badge
				style={{
					backgroundColor: "#efefef",
					border: "1px solid #dadada",
					display: "flex",
					height: "38px",
					alignItems: "center",
					marginLeft: 8
				}}
			>
				<ReactDatePicker
					className="timePickerMenu"
					selected={moment().hour(currentFromTime[0]).minute(currentFromTime[1]).toDate()}
					onChange={e => {
						const selectedTime = [moment(e).format("HH"), moment(e).format("mm")];
						setCurrentFromTime(selectedTime);

						setTime(timeIndex, {
							day: day,
							fromTime: `${selectedTime[0]}:${selectedTime[1]}`,
							toTime: `${currentToTime[0]}:${currentToTime[1]}`
						});
					}}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption="Time"
					timeFormat="HH:mm"
					dateFormat="HH:mm"
				/>

				<div style={{ marginLeft: 3, marginRight: 3 }}>-</div>

				<ReactDatePicker
					className="timePickerMenu"
					selected={moment().hour(currentToTime[0]).minute(currentToTime[1]).toDate()}
					onChange={e => {
						const selectedTime = [moment(e).format("HH"), moment(e).format("mm")];
						setCurrentToTime(selectedTime);
						setTime(timeIndex, {
							day: day,
							fromTime: `${currentFromTime[0]}:${currentFromTime[1]}`,
							toTime: `${selectedTime[0]}:${selectedTime[1]}`
						});
					}}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption="Time"
					timeFormat="HH:mm"
					dateFormat="HH:mm"
				/>
			</Badge>
			<div
				style={{
					cursor: "pointer",
					position: "relative",
					left: "-7px",
					fontSize: "8px",
					height: "14px",
					display: "block",
					top: "-5px",
					padding: "0px 4px",
					borderRadius: "30px",
					background: "#FF735E",
					color: "white",
					lineHeight: "14px"
				}}
				title="Remove time interval"
				onClick={() => removeTime(timeIndex, day)}
			>
				<FontAwesomeIcon icon={faMinus} />
			</div>
		</div>
	);
};

export default ScheduleTime;

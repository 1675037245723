import { faHandPointer } from "@fortawesome/free-regular-svg-icons";
import { faExchangeAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useMerchantContext } from "context";
import { Button, Modal } from "core/components";
import { useSwitch } from "core/utils";
import { httpClient } from "data";
import { map, pick, sum } from "ramda";
import React, { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { Checkmark } from "react-checkmark";
import styled from "styled-components";

const RefundActionsContainer = styled.div`
	display: flex;
	align-items: center;
	& > button {
		margin-right: ${p => p.theme.space.small};
	}
`;

const Component = ({
	order,
	refundPickerActive,
	setRefundPickerActive,
	refresh,
	mappedItems,
	clearState
}) => {
	const { hasFunctionAccess } = useMerchantContext({});
	const [modalVisible, setModalVisible] = useState(false);
	const [modalConfirmed, setModalConfirmed] = useState(false);
	const [modalError, setModalError] = useState();
	const [isLoading, startloading, stopLoading] = useSwitch(false);
	const [fullRefund, setFullRefund] = useState(false);
	const [refundTip, setRefundTip] = useState(false);

	const refundAllowed = order.status === "COMPLETED" && order.type === "PURCHASE";
	const noPreviousRefunds = !order.items.find(f => f.refundedCount || f.refundingCount);
	const allItemsRefunded =
		sum(order.items.map(o => o.refundedCount + o.refundingCount)) ===
		sum(order.items.map(o => o.quantity));

	const createRefund = useCallback(async () => {
		startloading();
		return httpClient
			.post("/refunds", {
				body: {
					orderId: order.orderId,
					orderItems: map(
						pick(["productId", "quantity"]),
						fullRefund ? order.items : mappedItems
					),
					refundTip
				}
			})
			.finally(stopLoading);
	}, [order, mappedItems, fullRefund, refundTip]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = () => {
		setModalVisible(false);
	};

	const renderConfirmModal = () => (
		<Modal
			show={modalVisible}
			onHide={handleClose}
			title={
				!modalConfirmed
					? fullRefund
						? "Do you want to refund this order?"
						: "Do you want refund these items?"
					: !modalError
					? "Refund created"
					: "Refund error"
			}
			body={
				<>
					{!modalConfirmed &&
						mappedItems?.map((x, i) => (
							<div key={i} style={{ display: "flex" }}>
								<div style={{ width: 30 }}>{x.quantity}x </div>
								<div>{x.rowData.product.name}</div>
							</div>
						))}
					{!modalConfirmed && fullRefund && (
						<Form.Check
							type="switch"
							id={`switch-refund-tip-${order.orderId}`}
							label={"Refund tip"}
							checked={refundTip}
							onChange={() => {
								setRefundTip(!refundTip);
							}}
						/>
					)}
					{modalConfirmed && !modalError && <Checkmark size="xxLarge" color="#41CE84" />}

					{modalConfirmed && modalError && (
						<div>
							<svg
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 130.2 130.2"
								className="errorSvg"
							>
								<circle
									className="ErrorSvgPath circle"
									fill="none"
									stroke="#FF735E"
									strokeWidth="6"
									strokeMiterlimit="10"
									cx="65.1"
									cy="65.1"
									r="62.1"
								/>
								<line
									className="ErrorSvgPath line"
									fill="none"
									stroke="#FF735E"
									strokeWidth="6"
									strokeLinecap="round"
									strokeMiterlimit="10"
									x1="34.4"
									y1="37.9"
									x2="95.8"
									y2="92.3"
								/>
								<line
									className="ErrorSvgPath line"
									fill="none"
									stroke="#FF735E"
									strokeWidth="6"
									strokeLinecap="round"
									strokeMiterlimit="10"
									x1="95.8"
									y1="38"
									x2="34.4"
									y2="92.2"
								/>
							</svg>
							<div style={{ margin: 16, textAlign: "center" }}>{modalError}</div>
						</div>
					)}
				</>
			}
			footer={
				<>
					{!modalConfirmed && (
						<>
							<Button
								{...(modalConfirmed && { disabled: true })}
								onClick={handleClose}
								icon={faUndo}
								size="large"
								collapse={false}
							>
								Cancel
							</Button>

							<Button
								variant="primary"
								isLoading={isLoading}
								size="large"
								icon={faExchangeAlt}
								collapse={false}
								{...(modalConfirmed && { disabled: true })}
								onClick={async () => {
									const status = await createRefund();
									if (!status || status?.code) {
										setModalError(
											status.errorMessage || "Could not create refund"
										);
									} else {
										clearState();
									}
									setModalConfirmed(true);
								}}
							>
								Refund
							</Button>
						</>
					)}

					{modalConfirmed && (
						<Button
							onClick={() => {
								setModalVisible(false);
								refresh();
							}}
							size="large"
						>
							Close
						</Button>
					)}
				</>
			}
		/>
	);

	const renderRefundActions = () => (
		<>
			<RefundActionsContainer>
				{!mappedItems && hasFunctionAccess("order_refund") && noPreviousRefunds && (
					<Button
						icon={faExchangeAlt}
						size="small"
						onClick={() => {
							setFullRefund(true);
							setModalVisible(true);
							setModalConfirmed(false);
						}}
					>
						Full refund
					</Button>
				)}
				{!mappedItems && hasFunctionAccess("order_refund") && (
					<Button
						icon={faHandPointer}
						onClick={() => {
							setRefundPickerActive(!refundPickerActive);
							setFullRefund(false);
							setRefundTip(false);
						}}
						size="small"
					>
						Partial refund
					</Button>
				)}

				{mappedItems && (
					<>
						<Button icon={faUndo} onClick={clearState} size="small">
							Cancel
						</Button>

						<Button
							variant="primary"
							icon={faExchangeAlt}
							size="small"
							onClick={() => {
								setModalVisible(true);
								setModalConfirmed(false);
								setFullRefund(false);
							}}
						>
							Refund
						</Button>
					</>
				)}

				{renderConfirmModal()}
			</RefundActionsContainer>
		</>
	);

	if (!refundAllowed) return <></>;

	return <>{!allItemsRefunded && renderRefundActions()}</>;
};

export default Component;

import { Form } from "react-bootstrap";
import styled from "styled-components";
import { Card } from "react-bootstrap";

const Container = styled(Card)`
	width: 100%;
	background: ${p => p.theme.color.dirtyWhite};

	${p =>
		p.$isNew &&
		`
		border-color: ${p.theme.color.emeraldGreen};
		box-shadow: 0px 0px 8px ${p.theme.color.emeraldGreen + "33"}
		`}
`;

const Header = styled(Card.Header)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${p => p.theme.space.small} ${p => p.theme.space.medium};
	padding-bottom: ${p => p.theme.space.small};
`;

const Body = Card.Body;

const Group = styled(Form.Group)`
	margin-bottom: ${p => p.theme.space.giant};

	&:last-child {
		margin-bottom: ${p => p.theme.space.small};
	}
`;

const Label = styled(Form.Label)`
	font-weight: 500;
	width: 100%;
`;

const Value = styled.div`
	font-size: 16px;
	font-weight: 400;
`;

export { Container, Header, Body, Group, Label, Value };

import { useMerchantContext } from "context";
import { Grid } from "core/components";
import React from "react";
import styled from "styled-components";
import ReportTable from "./ReportTable";
import schema from "./schema";

const Container = styled.div`
	min-width: 600px;
`;

const ReportsGrid = ({ reports, refresh, timeZone }) => {
	const { hasFunctionAccess } = useMerchantContext();
	const gridSchema = schema({ timeZone, hasFunctionAccess, refresh });

	return (
		<Container data-test="zreports">
			<Grid
				schema={gridSchema}
				expandedRowContent={parentRow => <ReportTable zReportId={parentRow.zReportId} />}
				tableKey="zReportId"
				data={reports}
				pagination
			/>
		</Container>
	);
};

export default ReportsGrid;

import React from "react";
import { Dropdown, DropdownButton, Pagination } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import { isMobileOnly } from "react-device-detect";
import { darken } from "polished";

const Container = styled.div`
	width: 100%;
	display: flex;
	margin-top: ${p => p.theme.space.large};
	justify-content: space-between;
`;

const PaginationSizeContainer = styled.div`
	width: 200px;
	display: flex;
	flex-direction: column;
	@media ${mediaQuery(device.tablet)} {
		flex-direction: row;
	}
`;

const CustomPaginationPageSizeItem = styled.div`
	margin-right: ${p => p.theme.space.medium};
`;

const CustomPaginationItem = styled.div`
	padding-top: 8px;
	padding-left: 5px;
	font-size: 15px;
`;

const First = styled(Pagination.First)`
	span {
		color: ${p => p.theme.color.text.primary};
	}
`;
const Last = styled(Pagination.Last)`
	span {
		color: ${p => p.theme.color.text.primary};
	}
`;
const Prev = styled(Pagination.Prev)`
	span {
		color: ${p => p.theme.color.text.primary};
	}
`;
const Next = styled(Pagination.Next)`
	span {
		color: ${p => p.theme.color.text.primary};
	}
`;
const Page = styled(Pagination.Item)`
	span {
		background: #666 !important;
		border-color: #666 !important;
		background-color: ${p => p.theme.color.dirtyWhite} !important;
		border-color: ${p => darken(0.05, p.theme.color.border.primary)} !important;
		color: ${p => p.theme.color.text.primary} !important;

		&:hover {
			background-color: ${p => darken(0.05, p.theme.color.dirtyWhite)} !important;
			border-color: ${p => darken(0.1, p.theme.color.border.primary)} !important;
		}
	}

	a {
		color: ${p => (p.active ? "#FFF" : p.theme.color.text.primary)} !important;
	}
`;

const getVisiblePages = (pages, rest, activePage) => {
	if (!pages && pages !== 0) return [];

	const allPages = [...Array(pages + (rest > 0 ? 1 : 0)).keys()];
	const maxPages = isMobileOnly ? 5 : 15;
	const pageRange = isMobileOnly ? 2 : 5;
	if (pages >= 0 && pages <= maxPages) return allPages;

	const currentPage = activePage - 1;
	const potentialPages = [0, pages - 1];
	for (let p = currentPage - pageRange; p < currentPage + pageRange; p++) {
		if (p <= 0 || p >= pages) continue;
		if (potentialPages.includes(p)) continue;

		potentialPages.push(p);
	}

	return potentialPages.sort((a, b) => (a > b ? 1 : -1));
};

const PaginationRenderer = ({
	paginationProps,
	pageSizes,
	handlePage,
	handleNextPage,
	handlePrevPage,
	handleSizePerPage,
	page,
	sizePerPage,
	totalSize
}) => {
	const rest = totalSize % sizePerPage;
	const pages = (totalSize - rest) / sizePerPage;

	const showPrev = page > 1;
	const showNext = page < pages + (rest > 0 ? 1 : 0);
	const visiblePages = getVisiblePages(pages, rest, page);

	return (
		visiblePages.length > 0 && (
			<Container>
				<PaginationSizeContainer>
					<CustomPaginationPageSizeItem>
						<DropdownButton
							id="dropdown-basic-button"
							title={sizePerPage}
							variant="secondary"
						>
							{pageSizes.map(size => (
								<Dropdown.Item
									onClick={handleSizePerPage(paginationProps, size)}
									key={size}
								>
									{size}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</CustomPaginationPageSizeItem>
					<CustomPaginationItem>
						{(page - 1) * sizePerPage + 1}-{Math.min(totalSize, page * sizePerPage)} of{" "}
						{totalSize}
					</CustomPaginationItem>
				</PaginationSizeContainer>
				<div
					className="col-md-6 col-xs-6 col-sm-6 col-lg-6"
					style={{ display: "flex", justifyContent: "flex-end" }}
				>
					<Pagination>
						{pages >= 10 && page !== 1 && (
							<First onClick={handlePage(paginationProps, 1)} />
						)}
						{showPrev && <Prev onClick={handlePrevPage(paginationProps)} />}
						{pages >= 0 &&
							visiblePages.map(p => (
								<Page
									key={p}
									active={page === p + 1}
									onClick={handlePage(paginationProps, p + 1)}
								>
									{p + 1}
								</Page>
							))}
						{showNext && <Next onClick={handleNextPage(paginationProps)} />}
						{pages >= 10 && page !== pages && (
							<Last onClick={handlePage(paginationProps, pages)} />
						)}
					</Pagination>
				</div>
			</Container>
		)
	);
};

export default PaginationRenderer;

const formatOrderTicketLabel = orderTicket => {
	const { checkinLabel, checkinLabelPrefix, tableId } = orderTicket;

	// Support old order tickets that doesn't have tableId
	if (!checkinLabel) return tableId;

	if (checkinLabelPrefix) return `${checkinLabelPrefix} ${checkinLabel}`;

	return checkinLabel;
};

export default formatOrderTicketLabel;

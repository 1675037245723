import React, { useMemo } from "react";
import { OrderTicketGrid } from "../../components";
import { useOrderTickets } from "../../functions";

const CompletedTab = ({ station }) => {
	const statuses = useMemo(() => ["DONE"], []);
	const { orderTickets } = useOrderTickets({
		stationId: station?.stationId,
		statuses,
		limit: 24,
		orderBy: "timeDone",
		orderDirection: "desc"
	});

	return <OrderTicketGrid {...{ orderTickets, station }} />;
};

export default CompletedTab;

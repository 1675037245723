/* eslint-disable react/display-name */
import React from "react";
import { Actions, Period, TimeCreated } from "./fields";

const schema = ({ timeZone, refresh }) => [
	{
		prop: "name",
		type: "text",
		minimumGridVisibility: "xs",
		label: "Report",
		sort: true,
		formatter: cell => <span style={{ fontWeight: 500 }}>{cell}</span>
	},
	{
		prop: "row.reportFunctionParameters.fromDate",
		type: "text",
		minimumGridVisibility: "xs",
		sort: true,
		label: "Period",
		formatter: (ignore, row) => <Period row={row} timeZone={timeZone} />
	},
	{
		prop: "timeCreated",
		type: "text",
		label: "Created",
		sort: true,
		formatter: cell => <TimeCreated time={cell} timeZone={timeZone} />
	},
	{
		prop: "dummy",
		type: "text",
		minimumGridVisibility: "xs",
		label: "",
		formatter: (ignore, row) => <Actions row={row} refresh={refresh} />
	}
];

export default schema;

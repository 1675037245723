import { Card } from "auth/components";
import { useUserContext } from "context/UserContext";
import React, { useCallback, useState } from "react";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { Button } from "core/components";

const Heading = styled.div`
	font-family: Peak;
	font-weight: 500;
	margin-bottom: 12px;
	font-size: 32px;
	line-height: 0.95;
`;

const ExplainerText = styled.div`
	font-size: 14px;
	line-height: 1.4;
	margin-bottom: 8px;
`;

const RequestPasswordResetForm = () => {
	const { sendPasswordResetEmail } = useUserContext();
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState();
	const [email, setEmail] = useState();
	const [errorMessage, setErrorMessage] = useState();

	const handleSubmit = useCallback(() => {
		setLoading(true);
		try {
			sendPasswordResetEmail(email)
				.then(() => setSuccess(true))
				.catch(error => {
					console.warn(error.message);
					setErrorMessage(error.message);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
			setErrorMessage("Reset password was unsuccessful.");
		}
	}, [email, setLoading, setErrorMessage, sendPasswordResetEmail]);

	return (
		<Card size="huge">
			<Form
				onSubmit={e => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				{success ? (
					<>
						<Form.Group>
							<Form.Group>
								<Heading>Password reset email was sent</Heading>
								<ExplainerText>
									An email has been sent to your inbox with a link to reset your
									password.
								</ExplainerText>
							</Form.Group>
						</Form.Group>
					</>
				) : (
					<>
						<Form.Group>
							<Heading>Reset your password</Heading>
							<ExplainerText>
								Enter the email address associated with your account and we will
								send you a link to reset your password.
							</ExplainerText>
						</Form.Group>
						<Form.Group>
							{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
						</Form.Group>

						<Form.Group>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								value={email}
								onChange={e => setEmail(e?.target?.value)}
							/>
						</Form.Group>

						<Form.Group>
							<Button isLoading={loading} type="submit" size="large">
								Reset password
							</Button>
						</Form.Group>
					</>
				)}
			</Form>
		</Card>
	);
};

export default RequestPasswordResetForm;

import { useCallback, useEffect, useState } from "react";
import { compose, isEmpty } from "ramda";
import { useUserContext } from "context";
import { usePersistedStateEffect } from "core/utils";
import { findStoreById, resolveCurrentStore } from "./functions";

const useCurrentStore = ({ stores, isStoresLoading }) => {
	const { user } = useUserContext();
	const [store, setStore] = useState();
	const cashedStoreId = usePersistedStateEffect(
		user && `${user.uid}-currentStoreId`,
		store?.storeId
	);

	const updateStore = useCallback(
		(resolveFn, storeId) => compose(setStore, resolveFn(stores))(storeId),
		[stores]
	);

	useEffect(() => {
		if (!user) {
			setStore();
			return;
		}

		if (!stores || isEmpty(stores)) {
			setStore();
			return;
		}

		if (isStoresLoading) {
			setStore();
			return;
		}

		updateStore(resolveCurrentStore, cashedStoreId);
	}, [user, stores, isStoresLoading, cashedStoreId, updateStore]);

	const switchStore = useCallback(storeId => updateStore(findStoreById, storeId), [updateStore]);

	return [store, switchStore];
};

export default useCurrentStore;

import React, { useEffect, useState } from "react";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Alert, FormControl, Card as BsCard } from "react-bootstrap";
import BookkeepingAccount from "./BookkeepingAccount";
import { httpClient } from "data";
import { useMerchantContext } from "context";
import styled from "styled-components";
import { Button } from "core/components";

const Card = styled(BsCard)`
	background: ${p => p.theme.color.dirtyWhite};
`;

const CardBody = styled(BsCard.Body)``;

const CardFooter = styled(BsCard.Footer)`
	display: flex;
	justify-content: flex-end;
`;

const BookkeepingAccounts = ({
	integrationSettings,
	setIntegrationSettings,
	oldIntegrationSettings,
	setOldIntegrationSettings
}) => {
	const { currentMerchant } = useMerchantContext();
	const [merchantAccounts, setMerchantAccounts] = useState([]);
	const [merchantVoucherSeries, setMerchantVoucherSeries] = useState([]);

	const [alertMessageTop, setAlertMessageTop] = useState();
	const [alertTypeTop, setAlertTypeTop] = useState("danger");
	const [showAlertTop, setShowAlertTop] = useState(false);

	const [alertMessageBottom, setAlertMessageBottom] = useState();
	const [alertTypeBottom, setAlertTypeBottom] = useState("danger");
	const [showAlertBottom, setShowAlertBottom] = useState(false);

	const [error, setError] = useState();

	useEffect(() => {
		httpClient.get("/reporting/fortnox-merchant/accounts").then(r => {
			if (r.accounts) {
				setMerchantAccounts(r.accounts);
				setMerchantVoucherSeries(r.voucherSeries);
			} else {
				setAlertMessageTop("Could not fetch accounts from Fortnox");
				setAlertTypeTop("danger");
				setShowAlertTop(true);
				setError("ACCOUNT_FETCH_ERROR");
			}
		});
	}, [
		currentMerchant,
		integrationSettings,
		setAlertMessageTop,
		setAlertTypeTop,
		setShowAlertTop
	]);

	const getMerchantVoucherSeries = () => {
		if (error === "ACCOUNT_FETCH_ERROR") {
			return (
				<>
					{integrationSettings.voucherSeries && (
						<option
							key={integrationSettings.voucherSeries}
							value={integrationSettings.voucherSeries}
						>
							{integrationSettings.voucherSeries} (current)
						</option>
					)}
					{!integrationSettings.voucherSeries && (
						<option key={0} value={0}>
							Could not fetch Journals
						</option>
					)}
				</>
			);
		} else if (merchantVoucherSeries.length === 0)
			return (
				<option key={0} value={0}>
					Loading journals...
				</option>
			);
		else {
			return (
				<>
					{!integrationSettings.voucherSeries && (
						<option key={0} value={0}>
							Choose journal...
						</option>
					)}
					{merchantVoucherSeries
						.sort((a, b) => (a.isManual > b.isManual ? -1 : 1))
						.map(a => (
							<option key={a.code} value={a.code}>
								{a.isManual && `${a.code} - ${a.description}`}
								{!a.isManual && `[UNAVALIABLE] - ${a.code} - ${a.description}`}
							</option>
						))}
				</>
			);
		}
	};

	const handleAccountChange = data => {
		setIntegrationSettings({ ...integrationSettings, ...data });
		if (
			JSON.stringify(oldIntegrationSettings) !==
			JSON.stringify({ ...integrationSettings, ...data })
		) {
			setAlertMessageBottom("You have unsaved changes.");
			setAlertTypeBottom("warning");
			setShowAlertBottom(true);
		} else {
			setAlertMessageBottom();
			setAlertTypeBottom();
			setShowAlertBottom(false);
		}
	};

	const handleFortnoxMerchantUpdate = () => {
		return httpClient
			.patch("/reporting/fortnox-merchant", { body: integrationSettings })
			.then(r => {
				if (!r || r?.error) {
					setAlertMessageBottom(`Could not save changes.`);
					setAlertTypeBottom("danger");
					setShowAlertBottom(true);
					return { success: false };
				} else {
					setAlertMessageBottom("Successfully saved account settings.");
					setAlertTypeBottom("success");
					setShowAlertBottom(true);
					setOldIntegrationSettings(integrationSettings);
				}
			});
	};
	const handleDiscardChanges = () => {
		setIntegrationSettings(oldIntegrationSettings);
		setAlertMessageBottom();
		setAlertTypeBottom();
		setShowAlertBottom(false);
	};

	return (
		<Card>
			<CardBody>
				<Alert
					variant={alertTypeTop}
					show={showAlertTop}
					onClose={() => setShowAlertTop(false)}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						{alertMessageTop}
					</div>
				</Alert>

				<div style={{ width: "100%" }}>
					<Alert
						variant={alertTypeBottom}
						show={showAlertBottom}
						style={{ width: 676 }}
						onClose={() => setShowAlertBottom(false)}
						{...(alertTypeBottom !== "warning" && { dismissible: true })}
					>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "space-between",
								alignItems: "center"
							}}
						>
							{alertMessageBottom}
							{alertTypeBottom === "warning" && (
								<Button icon={faUndo} onClick={handleDiscardChanges}>
									Discard changes
								</Button>
							)}
						</div>
					</Alert>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: 8
						}}
					>
						<div
							style={{
								marginRight: 8,
								width: 260,
								height: 50,
								display: "flex",
								alignItems: "flex-end"
							}}
						>
							<div>
								<div>Journal:</div>
								<div style={{ fontSize: 12, color: "#666" }}>
									Needs &quot;manuell kontering&quot; enabled in Fortnox
								</div>
							</div>
						</div>
						<div style={{ display: "flex" }}>
							<FormControl
								style={{ width: 400, marginLeft: 8 }}
								as="select"
								className="mr-sm-2"
								id="inlineFormCustomSelectPref"
								custom
								value={integrationSettings.voucherSeries}
								disabled={merchantVoucherSeries.length === 0}
								onChange={e => {
									const selectedVoucherSeries = merchantVoucherSeries.find(
										f => f.code === e.target.value
									);
									if (selectedVoucherSeries && !selectedVoucherSeries.isManual) {
										setAlertMessageBottom(
											`${selectedVoucherSeries.code} - ${selectedVoucherSeries.description} can't be selected. MANUELL KONTERING needs to be enabled for it in Fortnox.`
										);
										setAlertTypeBottom("danger");
										setShowAlertBottom(true);
										e.target.value = 0;
										return;
									}
									handleAccountChange({
										voucherSeries: e.target.value
									});
								}}
							>
								{getMerchantVoucherSeries()}
							</FormControl>
						</div>
					</div>
					<BookkeepingAccount
						text="Checking account"
						dataKey="accountChecking"
						accountRangeFrom={1900}
						accountRangeTo={1999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountChecking}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Checking account (SWISH)"
						dataKey="accountCheckingSwish"
						accountRangeFrom={1900}
						accountRangeTo={1999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountCheckingSwish}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Accounts receivable Passbuy"
						dataKey="accountReceivableStripe"
						accountRangeFrom={1500}
						accountRangeTo={1799}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountReceivableStripe}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Sales 6% VAT"
						dataKey="accountSales6"
						accountRangeFrom={3000}
						accountRangeTo={3700}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountSales6}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Sales 12% VAT"
						dataKey="accountSales12"
						accountRangeFrom={3000}
						accountRangeTo={3700}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountSales12}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Sales 25% VAT"
						dataKey="accountSales25"
						accountRangeFrom={3000}
						accountRangeTo={3700}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountSales25}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Outgoing VAT 6%"
						dataKey="accountVat6"
						accountRangeFrom={2400}
						accountRangeTo={2999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountVat6}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Outgoing VAT 12%"
						dataKey="accountVat12"
						accountRangeFrom={2400}
						accountRangeTo={2999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountVat12}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Outgoing VAT 25%"
						dataKey="accountVat25"
						accountRangeFrom={2400}
						accountRangeTo={2999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountVat25}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Passbuy service fee"
						dataKey="accountTransactionFee"
						accountRangeFrom={5000}
						accountRangeTo={6999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountTransactionFee}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Incoming VAT"
						dataKey="accountVatIncoming"
						accountRangeFrom={2400}
						accountRangeTo={2999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountVatIncoming}
						handleChange={handleAccountChange}
					/>
					<BookkeepingAccount
						text="Employee tips"
						dataKey="accountTips"
						accountRangeFrom={2400}
						accountRangeTo={2999}
						accounts={merchantAccounts}
						disabled={error === "ACCOUNT_FETCH_ERROR"}
						isLoading={merchantAccounts && merchantAccounts.length === 0}
						selectedAccount={integrationSettings.accountTips}
						handleChange={handleAccountChange}
					/>
				</div>
			</CardBody>

			<CardFooter>
				<Button variant="primary" icon={faSave} onClick={handleFortnoxMerchantUpdate} size="large">
					Save
				</Button>
			</CardFooter>
		</Card>
	);
};

export default BookkeepingAccounts;

import { useEffect } from "react";
import { useSwitch } from "core/utils";


const useDelay = delay => {
	const [isVisible, show] = useSwitch(!delay);

	useEffect(() => {
		if (!delay) {
			return;
		}

		const timeout = setTimeout(() => show(), 750);
		return () => clearTimeout(timeout);
	}, [show, delay]);

	return isVisible;
};

export default useDelay;

import { useEffect, useState } from "react";
import { calculateSchedule } from "./schedule";

const useReportSchedule = ({ timeZone, initialCronExpression, initialIntervalSettings }) => {
	const [schedule, setSchedule] = useState();
	const [cronExpression, setCronExpression] = useState(initialCronExpression);
	const [intervalSettings, setIntervalSettings] = useState(initialIntervalSettings);

	useEffect(() => {
		setSchedule(calculateSchedule({ cronExpression, intervalSettings, timeZone }));
	}, [cronExpression, intervalSettings, timeZone]);

	const reset = () => {
		setCronExpression(initialCronExpression);
		setIntervalSettings(initialIntervalSettings);
	};

	const updateSchedule = (type, useScheduleTime, day, time) => {
		if (type === "none") {
			setCronExpression(null);
			setIntervalSettings(null);
		}
		if (type === "daily") {
			if (useScheduleTime) {
				setCronExpression(`${time.minute} ${time.hour} * * *`);
				setIntervalSettings({
					useToDateAsFromDate: true,
					from: { useDatetime: false, inclusive: true },
					to: { useDateTime: true, inclusive: false }
				});
			} else {
				setCronExpression(`00 03 * * *`);
				setIntervalSettings({
					useToDateAsFromDate: false,
					from: { useDatetime: false, inclusive: true },
					to: { useDateTime: false, inclusive: false }
				});
			}
		}
		if (type === "weekly") {
			if (useScheduleTime) {
				setCronExpression(`${time.minute} ${time.hour} * * ${day}`);
				setIntervalSettings({
					useToDateAsFromDate: false,
					from: { useDateTime: true, inclusive: true },
					to: { useDateTime: true, inclusive: false }
				});
			} else {
				setCronExpression(`00 03 * * 1`);
				setIntervalSettings({
					useToDateAsFromDate: false,
					from: { useDateTime: false, inclusive: true },
					to: { useDateTime: false, inclusive: false }
				});
			}
		}
		if (type === "monthly") {
			if (useScheduleTime) {
				setCronExpression(`${time.minute} ${time.hour} 1 * *`);
				setIntervalSettings({
					useToDateAsFromDate: false,
					from: { useDateTime: true, inclusive: true },
					to: { useDateTime: true, inclusive: false }
				});
			} else {
				setCronExpression(`00 03 1 * *`);
				setIntervalSettings({
					useToDateAsFromDate: false,
					from: { useDateTime: false, inclusive: true },
					to: { useDateTime: false, inclusive: false }
				});
			}
		}
		if (type === "quarterly") {
			if (useScheduleTime) {
				setCronExpression(`${time.minute} ${time.hour} 1 1/3 *`);

				setIntervalSettings({
					useToDateAsFromDate: false,
					from: { useDateTime: true, inclusive: true },
					to: { useDateTime: true, inclusive: false }
				});
			} else {
				setCronExpression(`00 03 1 1/3 *`);
				setIntervalSettings({
					useToDateAsFromDate: false,
					from: { useDateTime: false, inclusive: true },
					to: { useDateTime: false, inclusive: false }
				});
			}
		}
	};

	return [schedule, cronExpression, intervalSettings, updateSchedule, reset];
};

export default useReportSchedule;

import React from "react";
import moment from "moment-timezone";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	@media ${mediaQuery(device.tablet)} {
		flex-wrap: inherit;
	}
`;

const Label = styled.span`
	margin-left: ${p => p.theme.space.small};
	font-size: 12px;
	color: ${p => p.theme.color.text.muted};
	display: none;
	@media ${mediaQuery(device.tablet)} {
		display: inline-block;
	}
`;

const Spacer = styled.span`
	margin-left: ${p => p.theme.space.small};
	margin-right: ${p => p.theme.space.small};
`;

const parseDate = date => moment(date.replace(/-/g, "/"));

const Period = ({ row, timeZone }) => {
	const fromDate = parseDate(row.reportFunctionParameters.fromDate)
		.tz(timeZone)
		.format("YYYY-MM-DD");
	const toDate = parseDate(row.reportFunctionParameters.toDate)
		.tz(timeZone)
		.subtract(1, "day")
		.format("YYYY-MM-DD");
	const duration = moment
		.duration(parseDate(toDate).add(1, "day").diff(parseDate(fromDate)))
		.humanize();

	const badgeStyle = {
		color: "#666",
		backgroundColor: "#f1f1f1",
		padding: 4,
		fontSize: 14,
		fontWeight: 500
	};

	if (fromDate === toDate) {
		return (
			<Container>
				<Badge style={badgeStyle}>{fromDate}</Badge>
				<Label>(a day)</Label>
			</Container>
		);
	}

	return (
		<Container>
			<Badge style={badgeStyle}>{fromDate}</Badge>
			<Spacer>-</Spacer>
			<Badge style={badgeStyle}>{toDate}</Badge>
			<Label>({duration})</Label>
		</Container>
	);
};

export default Period;

import { useRef, useEffect } from "react";

export default (callback, delay, disabled) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		if (disabled) {
			return;
		}

		const tick = () => {
			savedCallback.current();
		};

		const id = setInterval(tick, delay);
		return () => clearInterval(id);
	}, [delay, disabled]);
};

import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";

const StyledInputGroupText = styled(InputGroup.Text)`
	border-radius: 0.2rem;
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;
	padding: 0px;
	border-right: 0px;
	padding-left: 8px;
	padding-right: 8px;
`;

const Text = ({ item, value, onChange, row }) => {
	const getElement = () => (
		<Form.Control
			size="sm"
			type="text"
			placeholder={item.placeholder}
			style={item.editStyle}
			onChange={e => onChange(e.target.value)}
			value={value}
			required={item.required}
		/>
	);

	if (typeof item.inputGroup === "function") {
		return (
			<InputGroup size="sm">
				<StyledInputGroupText id={`${item.prop}_group`}>
					{item.inputGroup({ item, row })}
				</StyledInputGroupText>
				{getElement()}
			</InputGroup>
		);
	}

	return getElement();
};
export default Text;

import formatPercentage from "core/utils/format-percentage";
import isStringInteger from "core/utils/is-string-integer";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";

const Percentage = ({ item, value, onChange }) => {
	const valueAsPercentage = formatPercentage(value);
	const handleChange = useCallback(
		e => {
			const fractionalValue = isStringInteger(e.target.value)
				? parseInt(e.target.value) / 100
				: null;

			onChange(fractionalValue);
		},
		[onChange]
	);

	return (
		<Form.Control
			pattern="\d*"
			size="sm"
			type="text"
			placeholder={item.placeholder}
			style={item.editStyle}
			onChange={handleChange}
			value={valueAsPercentage}
		/>
	);
};

export default Percentage;

import React, { useState, useEffect } from "react";
import { SectionHeader, SectionLoader } from "core/components";
import { useMerchantContext } from "context";
import { httpClient } from "data";
import ExportType from "./Export";
import styled from "styled-components";
import { useSwitch } from "core/utils";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Lead = styled.div`
	font-size: 16px;
	line-height: 1.3;
	margin-bottom: ${p => p.theme.space.large};
`;

const Content = styled.div`
	padding-top: ${p => p.theme.space.large};
	display: flex;
	flex-direction: column;
`;

const ExportTypes = styled.div`
	max-width: 400px;
`;

const Accounting = () => {
	const { currentMerchant } = useMerchantContext();
	const [merchantExports, setMerchantExports] = useState({});
	const [isLoading, startLoading, stopLoading] = useSwitch(true);
	const history = useHistory();
	const { fortnox, sie } = merchantExports;
	const isEmptyMessage = !isLoading && !fortnox && !sie;

	useEffect(() => {
		startLoading();
		httpClient.get("/reporting/merchant/exports").then(setMerchantExports).finally(stopLoading);
	}, [currentMerchant, startLoading, stopLoading]);

	const handleClick = exportType => history.push(`/reporting/${exportType}`);

	const renderExport = () => (
		<ExportTypes>
			{sie && <ExportType name="SIE" exportType={"sie"} onClick={handleClick} />}
			{fortnox && <ExportType name="Fortnox" exportType={"fortnox"} onClick={handleClick} />}
		</ExportTypes>
	);

	const renderContent = () => (
		<Content>
			{isLoading && <SectionLoader />}
			{!isLoading && renderEmptyMessage()}
			{!isLoading && renderExport()}
		</Content>
	);

	const renderEmptyMessage = () => (
		<Alert show={isEmptyMessage} variant="warning" style={{ alignSelf: "flex-start" }}>
			You haven&apos;t enabled any accounting exports. To get started select one from the menu
			to the left.
		</Alert>
	);

	return (
		<>
			<SectionHeader heading="Accounting" />
			<Lead>These are your enabled accounting exports.</Lead>

			{renderContent()}
		</>
	);
};

export default Accounting;

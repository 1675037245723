import React, { useMemo } from "react";
import Moment from "react-moment";
import styled from "styled-components";
import PreOrders from "./PreOrders";

const Container = styled.div`
	margin: 20px;
`;

const EventNameContainer = styled.div``;

const EventName = styled.div`
	margin-right: 16px;
`;

const StationText = styled.div`
	font-weight: 500;
	font-size: 32px;
	margin-right: 8px;
`;

const StationsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 32px;
	margin-right: ${p => p.theme.space.small};
	align-items: baseline;
	color: ${p => p.theme.color.midGray};
	display: flex;
	margin-bottom: 32px;
	justify-content: space-between;
`;

const PrintEvent = ({ event, stations, selectedPreOrderStations, selectedPreOrderGrouping }) => {
	const hasSelectedPreOrderStations = selectedPreOrderStations.length > 0;

	const selectedStationNames = useMemo(
		() =>
			stations &&
			stations
				.filter(s => selectedPreOrderStations.includes(s.stationId))
				.map(r => r.name)
				.join(", "),
		[stations, selectedPreOrderStations]
	);

	return (
		<Container>
			<Title>
				<EventNameContainer>
					<EventName>{event.name}</EventName>
					<Moment date={event.timeStart} format={"YYYY-MM-DD HH:mm"} />
				</EventNameContainer>
				{hasSelectedPreOrderStations && (
					<div>
						<StationsContainer>
							<StationText>
								Station{selectedPreOrderStations.length > 1 && "s"}:
							</StationText>
							<div>{selectedStationNames}</div>
						</StationsContainer>
					</div>
				)}
			</Title>
			<PreOrders
				event={event}
				selectedStations={selectedPreOrderStations}
				selectedGrouping={selectedPreOrderGrouping}
				makePrinterFriendly
			/>
		</Container>
	);
};

export default PrintEvent;

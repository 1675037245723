import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`;

const fadeInAnimation = () =>
	css`
		${fadeIn} 200ms
	`;

const Modal = styled.div`
	width: 300px;
	height: 190px;
	padding: 32px;
	background: #fff;
	border: 1px solid #eee;
	border-radius: 4px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	bottom: 10%;
	animation: ${fadeInAnimation};
`;

export default Modal;

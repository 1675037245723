import React from "react";
import { map, compose, sortBy, prop } from "ramda";
import { FormControl } from "react-bootstrap";
import { useQuery } from "core/utils";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { useMerchantContext } from "context";

const Container = styled.div`
	padding: 8px 12px;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
`;

const MerchantSwitcher = ({ onSelect, onLoading, onLoadingEnd }) => {
	const history = useHistory();
	const query = useQuery();
	const pathname = useLocation().pathname;
	const { switchMerchant, merchants, currentMerchant } = useMerchantContext();

	const handleChange = async e => {
		onLoading();
		const merchantId = e?.target?.value;

		if (!merchantId) {
			onLoadingEnd();
			return;
		}

		const stationId = query.get("stationId");

		if (stationId) history.push(pathname);

		onSelect(merchantId);

		try {
			await switchMerchant(merchantId);
		} finally {
			onLoadingEnd();
		}
	};

	const renderMerchant = m => (
		<option key={m.merchantId} value={m.merchantId}>
			{m.name}
		</option>
	);

	const renderMerchants = compose(map(renderMerchant), sortBy(prop("name")));

	if (!merchants || merchants.length <= 1) {
		return null;
	}

	return (
		<Container data-test="merchant-switcher">
			<FormControl
				size="sm"
				as="select"
				className="mr-sm-2"
				value={currentMerchant?.merchantId}
				onChange={handleChange}
				custom
				data-test="merchant-switcher-select"
			>
				{renderMerchants(merchants)};
			</FormControl>
		</Container>
	);
};

export default MerchantSwitcher;

import {
	faChartBar,
	faCopy,
	faFolderOpen,
	faHome,
	faPercent,
	faQrcode,
	faUtensils,
	faWindowRestore
} from "@fortawesome/free-solid-svg-icons";

const menuItems = [
	{
		label: "Home",
		icon: faHome,
		to: "/home",
		functionAccess: "dashboard_view"
	},
	{
		label: "Menus",
		icon: faUtensils,
		to: "/menus",
		functionAccess: "menu_view"
	},
	{
		label: "Products",
		icon: faFolderOpen,
		to: "/products",
		functionAccess: "product_view"
	},
	{
		label: "Orders",
		icon: faCopy,
		to: "/orders",
		functionAccess: "order_view"
	},
	{
		label: "Order Tickets",
		icon: faWindowRestore,
		to: "/stations",
		functionAccess: "order_ticket_view"
	},
	{
		label: "Events",
		icon: faPercent,
		to: "/events",
		functionAccess: "events_view"
	},
	{
		label: "Deals",
		icon: faPercent,
		to: "/deals",
		functionAccess: "deal_view"
	},
	{
		label: "QR Codes",
		icon: faQrcode,
		to: "/qr-codes",
		functionAccess: "checkin_view"
	},
	{
		label: "Reporting",
		icon: faChartBar,
		to: "/reporting",
		functionAccess: "report_view"
	}
];

export default menuItems;

import React from "react";
import { Table } from "react-bootstrap";
import { Graph } from "./components";

const TopProducts = ({ dashboard }) => {
	if (!dashboard) {
		return null;
	}

	return (
		<Graph contentHeight="100%" title="Top Products" height="100%">
			<Table striped hover size="sm">
				<thead>
					<tr>
						<th></th>
						<th>Sold</th>
					</tr>
				</thead>
				<tbody>
					{dashboard.popular_products.map(p => (
						<tr key={p.name}>
							<td>{p.name}</td>
							<td>{p.count}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Graph>
	);
};

export default TopProducts;

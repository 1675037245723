import required from "core/components/Grid/Validation/required";
import { eqProps } from "ramda";

const getProductGroupSchema = ({ merchant, productGroups }) => {
	return [
		{
			prop: "productGroupId",
			type: "dropdown",
			label: "Product Group",
			editable: false,
			sort: false,
			width: 150,
			formatter: (cell, row) => {
				const productGroupName = productGroups.find(eqProps("productGroupId", row))?.name;
				const label = row.productGroupId ? productGroupName : "";
				return label;
			},
			values: () => [
				...[{ value: "", label: "-- NO PRODUCT GROUP --" }],
				...productGroups.map(b => ({
					value: b.productGroupId,
					label: b.name
				}))
			],
			visible: () => !merchant?.externalProductSync,
			validate: [required("Select a product group")]
		}
	];
};

export default getProductGroupSchema;

import React, { useMemo } from "react";
import { PageLayout, SectionHeader, TabContainer } from "core/components";
import { useTabs } from "core/utils";
import { Purchases, Refunds } from "./components";

const Orders = () => {
	const tabs = useMemo(
		() => [
			{
				id: "purchases",
				label: "Purchases",
				content: <Purchases />
			},
			{
				id: "refunds",
				label: "Refunds",
				content: <Refunds />
			}
		],
		[]
	);

	const [activeTab, setActiveTab] = useTabs("purchases", tabs);

	return (
		<PageLayout>
			<SectionHeader
				heading="Orders"
				tabs={tabs}
				activeTab={activeTab}
				onTabSelected={setActiveTab}
			/>
			<TabContainer tabs={tabs} activeTab={activeTab} />
		</PageLayout>
	);
};

export default Orders;

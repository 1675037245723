import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import between from "core/components/Grid/Validation/between";
import required from "core/components/Grid/Validation/required";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import getDealPropertiesSchema from "./get-deal-properties-schema";
/* eslint-disable react/display-name */

const ActiveIcon = styled(FontAwesomeIcon)`
	color: ${p => p.theme.color.emeraldGreen};
`;
const InactiveIcon = styled(FontAwesomeIcon)`
	color: ${p => p.theme.color.bittersweetRed};
`;
export default () => [
	{
		prop: "description",
		type: "text",
		minimumGridVisibility: "xs",
		width: 350,
		label: "Description",
		sort: true,
		editable: false,
		validate: [required("Description is missing")]
	},
	{
		prop: "active",
		type: "switch",
		minimumGridVisibility: "md",
		width: 50,
		label: "Active",
		sort: true,
		editable: false,
		formatter: (cell, row) =>
			row?.active ? <ActiveIcon icon={faCheck} /> : <InactiveIcon icon={faTimes} />
	},
	{
		prop: "firstTimeUsersOnly",
		type: "switch",
		width: 50,
		label: "First time users only",
		sort: true,
		editable: false,
		formatter: (cell, row) =>
			row?.firstTimeUsersOnly ? (
				<ActiveIcon icon={faCheck} />
			) : (
				<InactiveIcon icon={faTimes} />
			)
	},
	{
		prop: "category",
		type: "radio",
		minimumGridVisibility: "md",
		label: "Type of deal",
		width: 100,
		sort: true,
		setProps: { properties: {} },
		editable: false,
		columnVisible: true,
		formatter: (cell, row) => {
			return row.category ? _.startCase(row.category.toLowerCase()) : null;
		},
		values: () => [
			{
				value: "CART",
				label: "Cart",
				setProps: { type: "FLAT_PERCENTAGE" },
				description:
					"Cart deals affect the entire cart. Only one cart deal per cart will be used."
			},
			{
				value: "PRODUCT",
				label: "Product",
				setProps: { type: "PRODUCT_PERCENTAGE" },
				description: "Product deals only affect the selected products"
			}
		]
	},
	{
		prop: "type",
		type: "radio",
		minimumGridVisibility: "md",
		label: "Type",
		columnVisible: true,
		formatter: (cell, row) => {
			return row.type ? _.startCase(row.type.toLowerCase()) : null;
		},
		width: 200,
		sort: true,
		setProps: { properties: {} },
		noWrap: true,
		editable: false,
		values: () => [
			{
				value: "PRODUCT_PERCENTAGE",
				label: "Percentage",
				required: true,
				description: "Discount the product(s) with a percentage",
				visible: row => row.category === "PRODUCT"
			},
			{
				value: "BUY_X_PAY_FOR_Y",
				required: true,
				label: "Buy X for the price of Y",
				description: "E.g. buy 4 cookies for the price of 3 cookies",
				visible: row => row.category === "PRODUCT"
			},
			{
				value: "BUY_X_PAY_FOR_AMOUNT",
				label: "Buy X for amount",
				description: "E.g. buy 3 cookies for 50 kr",
				visible: row => row.category === "PRODUCT"
			},
			{
				value: "FLAT_PERCENTAGE",
				label: "Flat percentage",
				description: "E.g. Always have a 10% discount on the cart",
				visible: row => row.category === "CART"
			},
			{
				value: "FLAT_AMOUNT",
				label: "Flat discount",
				description: "E.g. always discount 50 kr from the cart",
				visible: row => row.category === "CART"
			}
		]
	},
	...getDealPropertiesSchema(),
	{
		prop: "productIds",
		type: "products",
		width: 350,
		columnVisible: false,
		label: "Affected Products",
		sort: true,
		editable: false,
		visible: row => row.category === "PRODUCT",
		validate: [required("At least one product have to be selected")]
	},
	{
		prop: "priority",
		type: "number",
		width: 50,
		label: "Priority",
		sort: true,
		editable: false,
		visible: () => true,
		validate: [
			required("Priority is missing"),
			between(1, 1000, "Priority must be between 1 and 1000")
		]
	}
];

import { useMerchantContext } from "context";
import { useSwitch } from "core/utils";
import { orderRepo } from "data/repos";
import { useCallback, useEffect, useState } from "react";

const useReports = ({ orderType }) => {
	const [orders, setOrders] = useState([]);
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const [orderCount, setOrderCount] = useState(0);
	const [search, setSearch] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch();

	const {
		currentStore: { storeId }
	} = useMerchantContext();
	const refresh = useCallback(
		(args = {}) => {
			startLoading();
			return orderRepo
				.getOrders({
					storeId,
					orderType,
					page: page || 1,
					pageSize,
					sortField: args.sortField,
					sortOrder: args.sortOrder,
					search: args.search
				})
				.then(orders => {
					setSearch(args.search);
					setOrderCount(orders.totalOrderCount);
					setOrders(orders.orders);
				})
				.finally(stopLoading);
		},
		[startLoading, stopLoading, page, pageSize, orderType, storeId]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return [orders, orderCount, isLoading, refresh, setPageSize, setPage, pageSize, page, search];
};

export default useReports;
